import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf, pureComponent } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getCachedPostcode,
  getPostcodePredictions,
} from "../../api/admin/AdminPostcodesApi";
import {
  getCachedServiceType,
  getServiceTypePredictions,
} from "../../api/admin/AdminServiceTypesApi";
import {
  getCachedCity,
  getCachedCountry,
  getCityPredictions,
  getCountryPredictions,
} from "../../api/shared/CountryV2Api";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/supplier/SupplierDriverApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/supplier/SupplierCustomerApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/supplier/SupplierWarehouseApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import OrderFilterForm from "../../components/orders-core/OrderFilterForm";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  withState("state", "setState", {
    toggleContainer: null,
    filterNameContainer: null,
  }),
  useSheet({
    paper: {
      "@media (max-width: 998px)": {
        minWidth: "300px",
      },
    },
  }),
  withState("state", "setState", { toggleContainer: null }),
  pureComponent(fp.pick(["state", "filter"])),
);

SupplierOrderFilterWrapper.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  showFinanceMarketplace: PropTypes.bool,
  isCustomFilter: PropTypes.bool,
  showSearch: PropTypes.bool,
  getMarketplace: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  classes: PropTypes.object,
};

SupplierOrderFilterWrapper.defaultProps = {
  showFinanceMarketplace: false,
  showSearch: false,
};

function SupplierOrderFilterWrapper(props) {
  return (
    <ModalPaper
      open={props.open}
      paperClassName={props.classes.paper}
      title={
        <FlexBox flex={true}>
          <FlexBox>{props.getLocalisationMessage("order_filter")}</FlexBox>

          {props.isCustomFilter && (
            <FlexBox flex={true} justify="center">
              <div
                ref={x => props.setState(fp.set("filterNameContainer", x))}
              />
            </FlexBox>
          )}
        </FlexBox>
      }
      onRequestClose={props.onRequestClose}
      rightIcon={
        <div ref={x => props.setState(fp.set("toggleContainer", x))} />
      }
    >
      <FlexBox container={8} direction="column">
        <OrderFilterForm
          showCompany={true}
          showMerchant={false}
          showPromiseDate={true}
          showSearch={props.showSearch}
          filter={props.filter}
          onDismiss={props.onRequestClose}
          onFilterChange={props.onFilterChange}
          toggleContainer={props.state.toggleContainer}
          getCachedCustomer={getCachedCustomer}
          getCustomerPredictions={getCustomerPredictions}
          getCachedDriver={getCachedDriver}
          getDriverPredictions={getDriverPredictions}
          getCachedWarehouse={getCachedWarehouse}
          getWarehousePredictions={getWarehousePredictions}
          getCachedServiceType={getCachedServiceType}
          getServiceTypePredictions={getServiceTypePredictions}
          getCachedCity={getCachedCity}
          getCityPredictions={getCityPredictions}
          getCachedCountry={getCachedCountry}
          getCountryPredictions={getCountryPredictions}
          getCachedPostcode={getCachedPostcode}
          getPostcodePredictions={getPostcodePredictions}
          showFinanceMarketplace={props.showFinanceMarketplace}
          getMarketplace={props.getMarketplace}
          filterNameContainer={props.state.filterNameContainer}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(SupplierOrderFilterWrapper);
