import { Observable } from "rxjs";
import React from "react";
import { Map, List } from "immutable";
import fp from "lodash/fp";
import { compose, withState, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { ContactPhone } from "@material-ui/icons";
import FlagIcon from "react-flag-kit/lib/CDNFlagIcon";
import OrderCreateWizardAvatars from "../internal/OrderCreateWizardAvatars";
import OrderCreateWizardStepCard from "../internal/OrderCreateWizardStepCard";
import FormTextField from "../../form/FormTextField";
import FormGeoAutoComplete from "../../form/FormGeoAutoComplete";
import FormCountryAutoComplete from "../../form/FormCountryAutoComplete";
import FlexBox from "../../ui-core/FlexBox";
import FormMapPinDialog from "../../deprecated/FormMapPinDialog";
import AddressBookSelectDialog from "../../address-book-core/AddressBookSelectDialog";
import { getValue, isEqualData } from "../../../helpers/DataUtils";
import { parsePhone } from "../../../helpers/FormatUtils";
import { getMessage } from "../../../reducers/MessageReducer";
import {
  getMarketplace,
  getMarketplaceInternationalEnabled,
  marketplaceMandatoryFieldsDisabled,
} from "../../../reducers/MarketplaceReducer";
import { getMessages } from "../../../reducers/LocalizationReducer";

const valueSelector = formValueSelector("OrderCreateWizardSupplier");

const enhancer = compose(
  reduxForm({
    form: "OrderCreateWizardSupplier",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  connect(state => ({
    disableInternationalOrders: getMarketplaceInternationalEnabled(state),
    title: getMessage(
      state,
      getMessage(
        state,
        "to_whom_are_we_shipping_it",
        "To whom are we shipping it?",
      ),
      getMessages(state).get("dropoff_location", "Drop-off Location"),
    ),
    values: valueSelector(
      state,
      "dropoffCountry",
      "dropoffLocation",
      "initialDropoffCountry",
      "initialDropoffLocation",
    ),
    orderReferenceIdVisibility: getMarketplace(state).getIn(
      ["setting", "settings", "orderReferenceIdVisibility"],
      false,
    ),
    i18n: getMessages(state),
    disabledOrderMandatoryFields: marketplaceMandatoryFieldsDisabled(state),
  })),
  withState("state", "setState", {
    focusCountry: false,
    showMapDialog: false,
    showAddressDialog: false,
  }),
  mapPropsStream(propsStream => {
    const sideEffectsStream = Observable.merge(
      propsStream
        .distinctUntilKeyChanged("state", isEqualData)
        .delay(200)
        .do(props => {
          if (props.state.focusCountry) {
            props.setState(fp.set("focusCountry", false));
          }
        }),
      propsStream.take(1).do(props => {
        const { initialDropoffCountry, initialDropoffLocation } = props.values;

        if (initialDropoffCountry || initialDropoffLocation) {
          props.change("initialDropoffCountry", null);
          props.change("initialDropoffLocation", null);

          if (initialDropoffCountry) {
            props.change(
              "dropoffCountry",
              props.countries.find(
                x => x.get("code") === initialDropoffCountry,
              ),
            );
          }

          if (initialDropoffLocation) {
            props.change("dropoffLocation", initialDropoffLocation);
          }

          props.touch(
            "dropoffCountry",
            "dropoffLocation",
            "dropoffDetails",
            "dropoffContactPhone",
            "dropoffContactName",
          );

          props.onShowForm();
        }

        if (props.values.initialDropoffLocation) {
          props.change("initialDropoffLocation", null);
          props.change("dropoffLocation", props.values.initialDropoffLocation);

          props.touch("dropoffLocation");

          props.onShowForm();
        }
      }),
      propsStream
        .distinctUntilKeyChanged("showForm")
        .skip(1)
        .do(props => {
          if (props.valid && props.showForm) {
            props.handleSubmit();
          }
        }),
    )
      .switchMapTo(Observable.never())
      .startWith(null);

    return propsStream
      .combineLatest(sideEffectsStream, fp.identity)
      .distinctUntilChanged(isEqualData);
  }),
);

OrderCreateWizardStepToForm.propTypes = {
  valid: PropTypes.bool,
  touch: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,

  state: PropTypes.object,
  setState: PropTypes.func,

  showForm: PropTypes.bool,
  onShowForm: PropTypes.func,

  orderReferenceIdVisibility: PropTypes.bool,
  showContent: PropTypes.bool,

  onSubmit: PropTypes.func.isRequired,
  countries: PropTypes.instanceOf(Map).isRequired,
  suggestions: PropTypes.instanceOf(List).isRequired,

  title: PropTypes.string,
  disableInternationalOrders: PropTypes.bool,
  i18n: PropTypes.instanceOf(Map),
  disabledOrderMandatoryFields: PropTypes.bool,
};

function OrderCreateWizardStepToForm(props) {
  const { state, title, i18n, disabledOrderMandatoryFields } = props;

  const countryCode = getValue(props.values.dropoffCountry, "code");
  const showForm = props.showForm && props.showContent;

  return (
    <OrderCreateWizardStepCard
      title={title}
      showContent={showForm}
      disableTitleClick={showForm && false}
      onSubmit={props.handleSubmit}
      showSubmit={showForm && props.valid}
      onTitleClick={props.onShowForm}
      subContent={
        Boolean(!props.showForm && props.showContent) && (
          <OrderCreateWizardAvatars
            onAddNew={props.onShowForm}
            onAddressBookClick={() =>
              props.setState(fp.set("showAddressDialog", true))
            }
            suggestions={props.suggestions}
            onSuggestionClick={item => {
              props.change("dropoffLocation", {
                lat: item.get("lat"),
                lng: item.get("lon"),
                country: countryCode,
                address: item.get("address"),
              });

              props.change("dropoffDetails", item.get("details"));
              props.change("dropoffContactPhone", item.get("phone"));
              props.change("dropoffContactName", item.get("contact_name"));

              props.touch(
                "dropoffLocation",
                "dropoffDetails",
                "dropoffContactPhone",
                "dropoffContactName",
              );

              props.onShowForm();
            }}
          />
        )
      }
    >
      <AddressBookSelectDialog
        open={state.showAddressDialog}
        onRequestClose={() =>
          props.setState(fp.set("showAddressDialog", false))
        }
        onSubmit={values => {
          props.setState(fp.set("showAddressDialog", false));

          props.change("dropoffLocation", {
            country: countryCode,
            lat: values.address.lat,
            lng: values.address.lon,
            address: values.address.address,
          });
          props.change("dropoffDetails", values.address.details);
          props.change("dropoffContactPhone", values.address.phone);
          props.change("dropoffContactName", values.address.contact_name);

          props.touch(
            "dropoffLocation",
            "dropoffDetails",
            "dropoffContactPhone",
            "dropoffContactName",
          );

          if (!props.showForm) {
            props.onShowForm();
          }
        }}
      />

      <FlexBox>
        <FlexBox
          align="flex-end"
          justify="center"
          style={{
            paddingBottom: "10px",
            paddingRight: "8px",
            "align-self": "flex-end",
          }}
        >
          <FlagIcon
            code={countryCode}
            style={{ cursor: "pointer" }}
            onClick={() => props.setState(fp.set("focusCountry", true))}
          />
        </FlexBox>

        <FlexBox flex={true}>
          <FormCountryAutoComplete
            fullWidth={true}
            disabled={props.disableInternationalOrders}
            name="dropoffCountry"
            onChange={() => {
              props.change("dropoffLocation", "");
            }}
            focus={state.focusCountry}
            label={`${i18n.get("country", "Country")} *`}
            hintText={i18n.get("type_to_search", "Type to search ...")}
          />
        </FlexBox>
      </FlexBox>

      {countryCode && (
        <FlexBox gutter={8}>
          <FormMapPinDialog
            countryCode={countryCode}
            open={state.showMapDialog}
            initialValues={{ location: props.values.dropoffLocation }}
            onRequestClose={() =>
              props.setState(fp.set("showMapDialog", false))
            }
            onSubmit={values => {
              props.setState(fp.set("showMapDialog", false));
              props.change("dropoffLocation", values.location);
            }}
          />

          <FlexBox flex={true}>
            <FormGeoAutoComplete
              fullWidth={true}
              name="dropoffLocation"
              countryCode={countryCode}
              label={`${i18n.get("address", "Address")} *`}
              popoverProps={{ style: { minWidth: "352px" } }}
              onOpenMap={() => props.setState(fp.set("showMapDialog", true))}
            />
          </FlexBox>

          <FlexBox flex={3}>
            <FormTextField
              fullWidth={true}
              name="dropoffDetails"
              label={`${i18n.get("suite", "Suite")} # ${
                disabledOrderMandatoryFields ? "" : " *"
              }`}
            />
          </FlexBox>
        </FlexBox>
      )}

      <FlexBox>
        <FlexBox flex={true} direction="column">
          <FormTextField
            name="dropoffContactName"
            fullWidth={true}
            label={`${i18n.get("recipient_name", "Recipient Name")} *`}
          />
        </FlexBox>

        <FlexBox align="center">
          <IconButton
            onClick={() => props.setState(fp.set("showAddressDialog", true))}
          >
            <ContactPhone />
          </IconButton>
        </FlexBox>
      </FlexBox>

      <FormTextField
        parse={parsePhone}
        name="dropoffContactPhone"
        fullWidth={true}
        label={`${i18n.get("recipient_phone", "Recipient Phone")} ${
          disabledOrderMandatoryFields ? "" : " *"
        }`}
      />

      {props.orderReferenceIdVisibility && (
        <FormTextField
          name="reference_id"
          autoWidth={true}
          fullWidth={true}
          label={i18n.get("reference_id", "Reference ID")}
        />
      )}
    </OrderCreateWizardStepCard>
  );
}

export default enhancer(OrderCreateWizardStepToForm);
