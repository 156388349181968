import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { formatNumber, formatPercentage } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    container: {
      width: "100%",
      padding: {
        top: "5px",
        left: "15px",
        bottom: "15px",
        right: "15px",
      },
      color: "#43425D",
    },
    trend: {
      fontSize: "30px",
      marginTop: "30px",
      marginBottom: "10px",
      paddingLeft: "10px",
    },
    value: {
      fontSize: "30px",
      color: "#43425D",
      padding: "8px",
    },
    title: {
      fontSize: "18px",
      textTransform: "uppercase",
      padding: "8px",
      textAlign: "center",
      lineHeight: "14px",
    },
    stats: {
      fontSize: "12px",
      lineHeight: "16px",
      textTransform: "uppercase",
    },
    flexPadding: {
      paddingTop: "20px",
      padding: "8px",
    },
  }),
  mapPropsStream(propsStream => {
    const responseStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .switchMap(props =>
        props.getReports(props.filter).catch(() => Observable.of({})),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], Map()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData)
      .filter(response => response.get("payload"))
      .map(response => {
        const info = List().asMutable();
        const report = response.get("payload");

        const created = report.get("order_create_count");
        const pickUp = report.get("pickup_time");

        const percentage = (pickUp / created) * 100;

        info.push(
          Map({
            label: "created",
            align: "flex-start",
            value: created,
          }),
        );
        info.push(
          Map({
            label: "picked_up",
            align: "flex-end",
            value: pickUp,
          }),
        );

        return Map({
          percentage,
          info: info.asImmutable(),
        });
      })
      .startWith(Map())
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(responseStream, (props, response) => ({
      ...props,
      data: response,
    }));
  }),
);

PickupRateWidget.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.instanceOf(Map),
  getReports: PropTypes.func.isRequired,
  reports: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  title: PropTypes.string,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function PickupRateWidget(props) {
  const { classes, data } = props;

  return (
    <Card className={classes.container}>
      <FlexBox>
        <FlexBox flex={true}>
          <FlexBox
            flex={true}
            justify="center"
            align="center"
            className={classes.trend}
          >
            {formatPercentage(data.get("percentage", 0))}
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <FlexBox flex={true} justify="center" className={classes.title}>
        {props.getLocalisationMessage("pick_up_rate", "Pick Up Rate")}
      </FlexBox>
      <FlexBox justify="space-between">
        {data.get("info") &&
          data.get("info").map((item, idx) => (
            <FlexBox
              key={idx}
              flex={true}
              justify={item.get("align")}
              className={classes.flexPadding}
            >
              <h4 className={classes.stats}>
                {props.getLocalisationMessage(item.get("label"))}:{" "}
                {formatNumber(item.get("value"))}
              </h4>
            </FlexBox>
          ))}
      </FlexBox>
    </Card>
  );
}

export default enhancer(PickupRateWidget);
