import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getCachedReference,
  getReferencePredictions,
} from "../../api/shared/ReferenceApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/supplier/SupplierDriverApi";
import { createVehicle } from "../../api/supplier/SupplierVehicleApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import VehicleForm from "../../components/vehicles-core/VehicleForm";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

SupplierVehicleCreateDialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,

  onDriverClick: PropTypes.func,
  onCreateDriverClick: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function SupplierVehicleCreateDialogWrapper(props) {
  return (
    <ModalPaper
      title={props.getLocalisationMessage("create_vehicle", "Create Vehicle")}
      open={props.open}
      onRequestClose={props.onRequestClose}
    >
      <FlexBox container={8} direction="column">
        <VehicleForm
          onDismiss={props.onRequestClose}
          onSubmit={values =>
            createVehicle(toSnakeCase(values)).catch(ResponseError.throw)
          }
          onSubmitSuccess={() => {
            props.onRequestClose();
            props.showSuccessMessage(
              props.getLocalisationMessage(
                "successfully_saved",
                "Successfully saved",
              ),
            );
          }}
          onSubmitFail={props.showErrorMessage}
          getCachedDriver={getCachedDriver}
          getDriverPredictions={getDriverPredictions}
          getCachedReference={getCachedReference}
          getReferencePredictions={getReferencePredictions}
          onDriverClick={props.onDriverClick}
          onCreateDriverClick={props.onCreateDriverClick}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(SupplierVehicleCreateDialogWrapper);
