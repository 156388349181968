import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const DRIVER_LIST_URL = `${API_ROOT_URL}/supplier/drivers`;
const DRIVERS_LOCATION_URL = `${API_ROOT_URL}/supplier/drivers/locations`;
const DRIVER_CREATE_URL = `${API_ROOT_URL}/supplier/driver`;
const DRIVER_ITEM_URL = `${API_ROOT_URL}/supplier/driver/%s`;
const DRIVER_CHOOSE_URL = `${API_ROOT_URL}/supplier/driver/choose`;
const DRIVER_LOCATION_ITEM_URL = `${API_ROOT_URL}/supplier/driver/%s/locations`;
const UPDATE_BOUNDARY_URL = `${API_ROOT_URL}/radar/supplier/boundary/`;
const DRIVER_LIABILITY_URL = `${API_ROOT_URL}/supplier/driver/%s/liability`;
const DRIVER_LIABILITY_TOTALS_URL = `${API_ROOT_URL}/supplier/driver/%s/liability_totals`;
const CASHIER_LIABILITY_TOTALS_OPEN_URL = `${API_ROOT_URL}/supplier_cashier/driver/%s/open_liability_totals`;

const CASHIER_LIABILITY_TOTALS_URL = `${API_ROOT_URL}/supplier_cashier/driver/%s/liability_totals`;

const DRIVER_ORDERS_LOCATION_URL = `${API_ROOT_URL}/supplier/driver/%s/order/locations`;

const CALCULATE_LIABILITY_TOTAL_URL = `${API_ROOT_URL}/supplier_cashier/driver/total_liability_for_selected`;

const UPDATE_DRIVER_CASHIER_PSF_COD_CHARGE = `${API_ROOT_URL}/supplier_cashier/order/update/psf_cod_charge`;

const STATUS_TOTALS_URL = `${API_ROOT_URL}/supplier/driver/%s/status_totals`;

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(DRIVER_ITEM_URL, id)),
});

const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(DRIVER_CHOOSE_URL, { params: request.getDefinedValues() }),
});

export const getDriver = id => itemCache.refresh(id);

export const getCachedDriver = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));

export const getDriverPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export const createDriver = body => api.post(DRIVER_CREATE_URL, { body });

export const updateDriver = (id, body) =>
  api.put(sprintf(DRIVER_ITEM_URL, id), { body });

export const getDriverList = request =>
  api.getStream(DRIVER_LIST_URL, { params: request.getDefinedValues() });

export const getDriverLocationList = () => api.getStream(DRIVERS_LOCATION_URL);

export const updateBoundary = body => api.put(UPDATE_BOUNDARY_URL, { body });

export const getDriverLocationItem = (driverId, request: DataListFilter) =>
  api.getStream(sprintf(DRIVER_LOCATION_ITEM_URL, driverId), {
    params: request.getDefinedValues(),
  });

export const getDriverOrdersLocation = driverId =>
  api.getStream(sprintf(DRIVER_ORDERS_LOCATION_URL, driverId));

export const getDriverLiability = (driverId, request: DataListFilter) =>
  api.getStream(sprintf(DRIVER_LIABILITY_URL, driverId), {
    params: request.getDefinedValues(),
  });

export const updateCashierOrderUpdatePsfCodCharge = body =>
  api.put(UPDATE_DRIVER_CASHIER_PSF_COD_CHARGE, { body });

export const getDriverLiabilityTotals = driverId =>
  api.getStream(sprintf(DRIVER_LIABILITY_TOTALS_URL, driverId));

export const getCashierLiabilityTotals = (driverId, request: DataListFilter) =>
  api.getStream(sprintf(CASHIER_LIABILITY_TOTALS_URL, driverId), {
    params: request.getDefinedValues(),
  });

export const getCashierOpenLiabilityTotals = driverId =>
  api.getStream(sprintf(CASHIER_LIABILITY_TOTALS_OPEN_URL, driverId));

export const getCalculateLiabilityTotal = body =>
  api.post(CALCULATE_LIABILITY_TOTAL_URL, { body });

export const getStatusTotal = (driverId, request: DataListFilter) =>
  api.getStream(sprintf(STATUS_TOTALS_URL, driverId), {
    params: request.getDefinedValues(),
  });
