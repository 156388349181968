import { Observable } from "rxjs";
import React from "react";
import { isAfter, isBefore, format as dateFormat } from "date-fns";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, getFormValues } from "redux-form";
import { Card } from "@material-ui/core";
import { connect } from "react-redux";
import LineChart from "../charts/FilledLineChart";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDate, formatNumber } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import {
  getMessage,
  getCurrentLanguage,
} from "../../reducers/LocalizationReducer";

const getValues = getFormValues("ArrivalTimeChartForm");
const getFormattedNumber = fp.flow(fp.round, formatNumber);
// const getAVG = fp.flow(fp.mean, getFormattedNumber);
/* const getAVGofETA = fp.flow(
  filterCurrentMonthReports,
  fp.flow(fp.meanBy("delivery_eta_in_minute_avg"), fp.defaultTo(0)),
  getFormattedNumber,
);
const getAVGofATA = fp.flow(
  filterCurrentMonthReports,
  fp.flow(fp.meanBy("delivery_ata_in_minute_avg"), fp.defaultTo(0)),
  getFormattedNumber,
); */

const formatDayMonthYear = (year, month, day) =>
  dateFormat(new Date(year, month, day), "dd MMM yyyy");

const enhancer = compose(
  reduxForm({
    form: "ArrivalTimeChartForm",
    enableReinitialize: true,
  }),
  connect(state => ({
    values: getValues(state),
    langCode: getCurrentLanguage(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const valueStream = propsStream
      /* .distinctUntilKeyChanged("values", isEqualData) */
      .distinctUntilKeyChanged("filter", isEqualData)
      .switchMap(props =>
        props.getReports(props.filter).catch(() => Observable.of({})),
      )
      .startWith(Map())
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    const statsStream = valueStream
      .filter(reports => reports && !reports.get("pending"))
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .map(([reports, props]) => canvas => {
        const labels = [];
        const atdArray = [];
        /* const ataAVGArray = []; */
        const etdArray = [];
        /*   const etaAVGArray = []; */

        const ctx = canvas.getContext("2d");
        const gradient1 = ctx.createLinearGradient(0, 0, 0, 400);
        gradient1.addColorStop(0, "rgba(33,150,243 ,0.9)");
        gradient1.addColorStop(1, "rgba(100,181,246 ,0.5)");

        const gradient2 = ctx.createLinearGradient(0, 0, 0, 400);
        gradient2.addColorStop(0, "rgba(233,30,99 ,0.9) ");
        gradient2.addColorStop(1, "rgba(240,98,146 ,0.5)");

        const sortedReports = reports.get("payload").sort((a, b) => {
          const aDate = new Date(
            a.get("year"),
            a.get("month") - 1,
            a.get("day"),
          );

          const bDate = new Date(
            b.get("year"),
            b.get("month") - 1,
            b.get("day"),
          );

          if (isAfter(aDate, bDate)) return 1;

          if (isBefore(aDate, bDate)) return -1;

          return 0;
        });

        sortedReports.forEach(item => {
          labels.push(
            formatDate(
              formatDayMonthYear(
                item.get("year"),
                item.get("month") - 1,
                item.get("day"),
              ),
            ),
          );
          etdArray.push(item.get("etd", 0));
          /* etaAVGArray.push(item.get("delivery_eta_in_minute_avg", 0)); */
          atdArray.push(item.get("atd", 0));
          /* ataAVGArray.push(item.get("delivery_ata_in_minute_avg", 0)); */
        });

        return {
          labels,
          datasets: [
            {
              label: `${props.getLocalisationMessage("etd_label")}`,
              fill: true,
              backgroundColor: "rgba(33,150,243 ,0.3)",
              borderColor: "rgba(33,150,243 ,0.7)",
              borderWidth: 1,
              strokeColor: "red",
              pointStrokeColor: "red",
              pointHighlightStroke: "red",
              data: etdArray,
              /* avgData: etaAVGArray, */
            },
            {
              label: `${props.getLocalisationMessage("atd_label")} `,
              fill: true,
              backgroundColor: "rgba(233,30,99 ,0.3)",
              borderColor: "rgba(233,30,99 ,0.7)",
              borderWidth: 1,
              strokeColor: "blue",
              pointStrokeColor: "blue",
              pointHighlightStroke: "blue",
              data: atdArray,
              /* avgData: ataAVGArray, */
            },
          ],
        };
      })
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(statsStream, valueStream, (props, stats) => ({
        ...props,
        stats,
      }));
  }),
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
    },
    title: {
      fontSize: "18px",
      textTransform: "uppercase",
    },
    info: {
      paddingTop: "20px",
    },
  }),
);

ArrivalTimeChartForm.propTypes = {
  classes: PropTypes.object,
  stats: PropTypes.func,
  langCode: PropTypes.string,
  initialValues: PropTypes.object,
  getReports: PropTypes.func.isRequired,
  title: PropTypes.string,
  filter: PropTypes.instanceOf(DataListFilter),
  width: PropTypes.number,
  height: PropTypes.number,
  getLocalisationMessage: PropTypes.func,
};

ArrivalTimeChartForm.defaultProps = {
  width: 100,
  height: 400,
};

function ArrivalTimeChartForm(props) {
  const { classes, stats, getLocalisationMessage } = props;

  return (
    <Card className={classes.container}>
      <FlexBox direction="row" className={classes.title}>
        <FlexBox flex={true} align="center">
          <span
            title={getLocalisationMessage(
              "estimated_vs_actual_time_of_delivery",
              "Estimated vs Actual Time of Delivery",
            )}
          >
            {getLocalisationMessage("etd_vs_atd", "ETD vs ATD")}
          </span>
        </FlexBox>
      </FlexBox>

      <FlexBox direction="column">
        <LineChart
          backgroundColor="#FFB300"
          color="#ffffff"
          data={stats}
          options={{
            scales: {
              yAxes: [
                {
                  ticks: {
                    callback(label) {
                      return label;
                    },
                  },
                },
              ],
            },
            tooltips: {
              mode: "index",
              intersect: false,
              callbacks: {
                label(tooltipItem, data) {
                  let result = "";
                  const label =
                    data.datasets[tooltipItem.datasetIndex].label || "";
                  if (label) {
                    result += ": ";
                  }
                  result += getFormattedNumber(tooltipItem.yLabel);
                  // if (Number(tooltipItem.yLabel)) {
                  //   const array =
                  //     data.datasets[tooltipItem.datasetIndex];
                  //   result += getFormattedNumber(array[tooltipItem.index]);
                  // }
                  return result;
                },
                footer(tooltipItems, data) {
                  let difference = 0;

                  tooltipItems.forEach(tooltipItem => {
                    if (tooltipItem.datasetIndex === 0) {
                      difference =
                        data.datasets[tooltipItem.datasetIndex].data[
                          tooltipItem.index
                        ];
                    } else {
                      difference -=
                        data.datasets[tooltipItem.datasetIndex].data[
                          tooltipItem.index
                        ];
                    }
                  });
                  return `${getLocalisationMessage(
                    "difference",
                    "Difference",
                  )}: ${getFormattedNumber(difference)}`;
                },
              },
            },
            hover: {
              mode: "index",
              intersect: false,
            },
          }}
        />
      </FlexBox>
    </Card>
  );
}

export default enhancer(ArrivalTimeChartForm);
