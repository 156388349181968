import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import {
  types,
  mapSchema,
  queryTypes,
} from "../../shared/helpers/ObjectMapper";

export const toDashboardFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),
    warehouseId: types.ID,
    customerIds: queryTypes.IDSeq,
    supplierIds: queryTypes.IDSeq,
    driverIds: queryTypes.IDSeq,
    from_date_time: types.string,
    to_date_time: types.string,
  }),
);
