import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Card } from "@material-ui/core";
import DataListFilter from "../../../helpers/DataListFilter";
import { getKPIMissesReports } from "../../../api/supplier/SupplierChartApi";
import MissesBarChartWidget from "../../../components/dashboard-core/MissesBarChartWidget";

const enhancer = compose(
  useSheet({
    container: {
      width: "100%",
      minHeight: "400px",
      padding: "15px",
      position: "relative",
    },
  }),
);

SupplierKPIMissesChartWrapper.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  location: PropTypes.object,
  filter: PropTypes.instanceOf(DataListFilter),
};

function SupplierKPIMissesChartWrapper(props) {
  const { classes } = props;

  return (
    <Card className={classes.container}>
      <MissesBarChartWidget
        showFilter={false}
        filter={props.filter}
        title={props.title}
        kpiMissesReports={getKPIMissesReports}
      />
    </Card>
  );
}

export default enhancer(SupplierKPIMissesChartWrapper);
