import React from "react";
import { List } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import DataListFilter from "../../helpers/DataListFilter";
import FlexBox from "../../components/ui-core/FlexBox";
import OrderCustomFilterTabs from "../../components/orders-core/OrderCustomFilterTabs";

const enhancer = compose(
  useSheet({
    loader: {
      height: "48px",
      backgroundColor: "#2E3359",
    },
  }),
);

SupplierOrderFilterTabsWrapper.propTypes = {
  classes: PropTypes.object,

  isLoading: PropTypes.bool,
  filterList: PropTypes.instanceOf(List),

  batchGetOrderListCount: PropTypes.func,

  basePathname: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
};

function SupplierOrderFilterTabsWrapper(props) {
  const { classes } = props;

  return props.isLoading ? (
    <FlexBox align="center" justify="center" className={classes.loader}>
      <CircularProgress color="secondary" size={24} thickness={2} />
    </FlexBox>
  ) : (
    <OrderCustomFilterTabs
      filterList={props.filterList}
      isLoading={props.isLoading}
      basePathname={props.basePathname}
      filter={props.filter}
      batchGetOrderListCount={props.batchGetOrderListCount}
    />
  );
}

export default enhancer(SupplierOrderFilterTabsWrapper);
