import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  CardHeader,
  TableHead,
  TableCell,
} from "@material-ui/core";
import { connect } from "react-redux";
import TextWithLink from "../router/TextWithLink";
import AvatarWithName from "../avatars/AvatarWithName";
import FlexBox from "../ui-core/FlexBox";
import LinkButton from "../ui-core/LinkButton";
import RatingStars from "../customer-feedback/RatingStars";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDateTime } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import MultiLineCell from "../../components/data-list/MultiLineCell";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    container: {
      width: "100%",
      padding: {
        top: "10px",
        left: "15px",
        bottom: "30px",
        right: "15px",
      },
      color: "#43425D",
    },
    cardHeader: {
      width: "100%",
      "& > div": {
        paddingRight: 0,
        width: "100%",
      },
    },
    tableHeader: {
      "& > th:first-child": {
        display: "none",
      },
    },
    multiLineFirst: {
      fontSize: "16px",
      lineHeight: "16px",
      paddingTop: "10px",
      width: "100%",
      whiteSpace: "break-spaces",
    },
    driverColumn: {
      width: "200px",
    },
    multiLineSecond: { color: "rgba(0,0,0,0.54)" },
    tableRowColumn: {
      paddingTop: "5px",
      paddingBottom: "5px",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  }),
  mapPropsStream(propsStream => {
    const responseStream = propsStream
      .distinctUntilChanged(isEqualData)
      .switchMap(props =>
        props
          .getCustomerFeedbacks(
            new DataListFilter({
              order_by: "created_date",
              order_by_direction: "desc",
              use_solr: true,
              size: 5,
            }),
          )
          .catch(() => Observable.of({})),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              payload: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(responseStream, (props, response) => ({
      ...props,
      feedbackList: response.get("payload"),
      isLoading: response.get("pending"),
    }));
  }),
);

LatestCustomerFeedback.propTypes = {
  classes: PropTypes.object,
  feedbackList: PropTypes.instanceOf(List),
  getCustomerFeedbacks: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onClickOrderUrl: PropTypes.func,
  createMoreFeedbackListHref: PropTypes.func,
  title: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
};

LatestCustomerFeedback.defaultProps = {
  createMoreFeedbackListHref: fp.noop,
};

function LatestCustomerFeedback(props) {
  const { classes, getLocalisationMessage } = props;
  const NA = getLocalisationMessage("na", "N/A");

  return (
    <Card className={classes.container}>
      <CardHeader
        className={classes.cardHeader}
        title={
          <FlexBox style={{ width: "100%" }}>
            <FlexBox flex={true}>{props.title}</FlexBox>
            <FlexBox flex={true} justify="flex-end">
              <TextWithLink to={props.createMoreFeedbackListHref()}>
                {getLocalisationMessage(
                  "show_in_customer_feedback",
                  "Show in Customer Feedback",
                )}
              </TextWithLink>
            </FlexBox>
          </FlexBox>
        }
      />
      <FlexBox direction="column">
        <FlexBox direction="row" flex={true}>
          {!props.isLoading && (
            <Table selectable={false}>
              <TableHead displaySelectAll={false} enableSelectAll={false}>
                <TableRow className={classes.tableHeader} selectable={false}>
                  <TableCell className={classes.driverColumn}>
                    {getLocalisationMessage("customer_order", "Customer")}
                  </TableCell>
                  <TableCell className={classes.driverColumn}>
                    {getLocalisationMessage(
                      "driver_supplier",
                      "Driver / Supplier",
                    )}
                  </TableCell>
                  <TableCell className={classes.tableRowColumn}>
                    {getLocalisationMessage("score", "Score")}
                  </TableCell>
                  <TableCell className={classes.tableRowColumn}>
                    {getLocalisationMessage("created_date", "Created Date")}
                  </TableCell>
                  <TableCell className={classes.tableRowColumn}>
                    {getLocalisationMessage("note", "Note")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody displayRowCheckbox={false}>
                {props.feedbackList.map((feedback, key) => (
                  <TableRow selectable={false} key={key}>
                    <TableCell
                      className={cx(
                        classes.tableRowColumn,
                        classes.driverColumn,
                      )}
                    >
                      <AvatarWithName
                        name={feedback.getIn(["user", "name"], NA)}
                      >
                        <MultiLineCell
                          firstLine={
                            <div className={classes.multiLineFirst}>
                              {feedback.getIn(["user", "name"], NA)}
                            </div>
                          }
                          secondLine={
                            <div className={classes.multiLineSecond}>
                              <LinkButton
                                onClick={() =>
                                  props.onClickOrderUrl(
                                    feedback.get("order_id"),
                                    feedback.get("version"),
                                  )
                                }
                              >
                                {`#${feedback.get("order_number", NA)}`}
                              </LinkButton>
                            </div>
                          }
                        />
                      </AvatarWithName>
                    </TableCell>
                    <TableCell className={classes.driverColumn}>
                      <AvatarWithName
                        imagePath={feedback.getIn(["driver", "photo"])}
                        name={feedback.getIn(["driver", "name"], NA)}
                      >
                        <MultiLineCell
                          firstLine={
                            <div className={classes.multiLineFirst}>
                              {feedback.getIn(["driver", "name"], NA)}
                            </div>
                          }
                          secondLine={
                            <div className={classes.multiLineSecond}>
                              {feedback.getIn(["supplier", "name"], NA)}
                            </div>
                          }
                        />
                      </AvatarWithName>
                    </TableCell>
                    <TableCell className={classes.tableRowColumn}>
                      <RatingStars score={feedback.get("score")} />
                    </TableCell>
                    <TableCell className={classes.tableRowColumn}>
                      {formatDateTime(feedback.get("created_date"))}
                    </TableCell>
                    <TableCell
                      className={classes.tableRowColumn}
                      style={{ wordWrap: "break-word" }}
                    >
                      {feedback.get("note", NA)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </FlexBox>
      </FlexBox>
    </Card>
  );
}

export default enhancer(LatestCustomerFeedback);
