import React from "react";
import _ from "lodash";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { SubmissionError } from "redux-form";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import OrderStatusCodes from "../../constants/OrderStatusCodes";
import SupplierCODCodes from "../../constants/SupplierCODCodes";
import SupplierPrivacyTypes from "../../constants/SupplierPrivacyTypes";
import { batchAsyncUpdateOrder } from "../../api/supplier/SupplierOrderApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/supplier/SupplierDriverApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/supplier/SupplierWarehouseApi";
import BatchUpdateJobDialog from "../../components/order-job/BatchUpdateJobDialog";

SupplierBatchUpdateJobDialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  initialValues: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
};

export default function SupplierBatchUpdateJobDialogWrapper(props) {
  return (
    <BatchUpdateJobDialog
      open={props.open}
      onRequestClose={props.onRequestClose}
      initialValues={props.initialValues}
      onSubmit={fp.flow(toSnakeCase, (values) =>
        batchAsyncUpdateOrder(values)
          .catch(ResponseError.throw)
          .then((response) => {
            if (_.isArray(response.data) && response.data.length > 0) {
              const orderNumbers = response.data.join(", ");

              throw new SubmissionError({
                orderNumbers: `Could not update ${orderNumbers}.`,
              });
            }

            return response;
          }),
      )}
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
      codCodes={SupplierCODCodes}
      statusCodes={OrderStatusCodes}
      privacyTypes={SupplierPrivacyTypes}
      getCachedDriver={getCachedDriver}
      getCachedWarehouse={getCachedWarehouse}
      getDriverPredictions={getDriverPredictions}
      getWarehousePredictions={getWarehousePredictions}
    />
  );
}
