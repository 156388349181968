import React from "react";
import _ from "lodash";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import FormChipInput from "../form/FormChipInput";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import ScannerTextField from "../deprecated/ScannerTextField";
import { createNotEmptyValidator } from "../../helpers/FormUtils";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import CODCollectionStatuses from "../../constants/CODCollectionStatuses";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  reduxForm({
    form: "CODCollectionStatusForm",
    enableReinitialize: true,
  }),
  formValues("orderNumbers"),
);

CODCollectionStatusForm.propTypes = {
  handleSubmit: PropTypes.func,
  onDismiss: PropTypes.func,
  orderNumbers: PropTypes.array,
  change: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CODCollectionStatusForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <Card>
      <CardContent>
        <FlexBox gutter={8} flex={true} direction="column">
          <FlexBox>
            <ScannerTextField
              fullWidth={true}
              focus={true}
              hintText={getLocalisationMessage("add_order", "Add Order")}
              onChange={value => {
                if (_.toFinite(value) > 0) {
                  props.change("orderNumbers", [...props.orderNumbers, value]);
                }
              }}
            />
          </FlexBox>

          <FlexBox>
            <FormChipInput
              label={getLocalisationMessage("order_numbers", "Order numbers")}
              name="orderNumbers"
              readOnly={true}
              fullWidth={true}
              validate={createNotEmptyValidator(
                getLocalisationMessage(
                  "enter_order_numbers",
                  "Enter Order Numbers",
                ),
              )}
            />
          </FlexBox>

          <FlexBox>
            <FormSelectField
              name="collectionStatus"
              options={CODCollectionStatuses}
              formatOption={x => formatText(getLocalisationMessage(x, x))}
              label={getLocalisationMessage(
                "cod_collection_status",
                "COD Collection Status",
              )}
              fullWidth={true}
            />
          </FlexBox>
        </FlexBox>
      </CardContent>

      <FlexBox justify="flex-end">
        <CardActions>
          {props.onDismiss && (
            <Button onClick={props.onDismiss}>
              {getLocalisationMessage("dismiss", "Dismiss")}
            </Button>
          )}
          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </CardActions>
      </FlexBox>
    </Card>
  );
}

export default enhancer(CODCollectionStatusForm);
