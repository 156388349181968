import { Observable } from "rxjs";
import React from "react";
import { fromJS, List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { connect } from "react-redux";
import AvatarWithName from "../avatars/AvatarWithName";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { formatPercentage } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    container: {
      width: "100%",
      padding: {
        top: "10px",
        left: "15px",
        bottom: "30px",
        right: "15px",
      },
      color: "#43425D",
    },
    cardHeader: {
      width: "100%",
      "& > div": {
        paddingRight: 0,
        width: "100%",
      },
    },
    difference: {
      color: "#fff",
      backgroundColor: "#7CB342",
      borderRadius: "5px",
      padding: "5px",
      width: "50px",
      textAlign: "center",
      fontSize: "11px",
    },
    customerColumnHeader: {
      width: "220px",
      minWidth: "170px",
      textAlign: "left",
    },
    customerColumn: {
      width: "220px",
      minWidth: "170px",
    },
    ratingColumn: {
      width: "100px",
    },
    numberColumn: {
      maxWidth: "100px",
      minWidth: "80px",
    },
    tableHeader: {
      "& > th": {
        whiteSpace: "break-spaces",
      },
      "& > th:first-child": {
        display: "none",
      },
    },
    tableRowColumn: { paddingTop: "5px", paddingBottom: "5px" },
  }),
  mapPropsStream((propsStream) => {
    const responseStream = propsStream
      .distinctUntilChanged(isEqualData)
      .switchMap((props) =>
        props.getDrivers(props.filter).catch(() => Observable.of({})),
      )
      .map(
        fp.flow(
          (response) => fromJS(response),
          (response) =>
            fromJS({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              payload: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(responseStream, (props, response) => ({
      ...props,
      list: response.get("payload"),
      total: response.get("total"),
      isLoading: response.get("pending"),
    }));
  }),
);

TopDriversWidget.propTypes = {
  classes: PropTypes.object,
  getDrivers: PropTypes.func.isRequired,
  list: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  driverUrl: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
  total: PropTypes.number,
};

function TopDriversWidget(props) {
  const { classes, getLocalisationMessage } = props;
  const NA = getLocalisationMessage("na", "N/A");

  return (
    <Card className={classes.container}>
      <CardHeader
        className={classes.cardHeader}
        title={
          <FlexBox style={{ width: "100%" }}>
            <FlexBox flex={true}>{props.title}</FlexBox>
          </FlexBox>
        }
      />

      <FlexBox direction="column">
        <FlexBox direction="row" flex={true}>
          {!props.isLoading && (
            <Table selectable={false}>
              <TableHead displaySelectAll={false} enableSelectAll={false}>
                <TableRow className={classes.tableHeader} selectable={false}>
                  <TableCell className={classes.customerColumnHeader}>
                    {getLocalisationMessage("driver", "Driver")}
                  </TableCell>
                  <TableCell
                    className={cx(classes.tableRowColumn, classes.numberColumn)}
                  >
                    {getLocalisationMessage(
                      "delivered_count",
                      "Delivered Count",
                    )}
                  </TableCell>
                  <TableCell
                    className={cx(classes.tableRowColumn, classes.numberColumn)}
                  >
                    {getLocalisationMessage("cod_pending_2", "Cod Pending")}
                  </TableCell>
                  <TableCell
                    className={cx(classes.tableRowColumn, classes.ratingColumn)}
                  >
                    {getLocalisationMessage("rating", "Rating")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody displayRowCheckbox={false}>
                {props.list.map((item, key) => (
                  <TableRow selectable={false} key={key}>
                    <TableCell
                      className={cx(
                        classes.tableRowColumn,
                        classes.customerColumn,
                      )}
                    >
                      <AvatarWithName
                        name={item.get("first_name", NA)}
                        imagePath={item.get("driver_photo")}
                      >
                        <div>
                          <span>{item.get("first_name")}</span>{" "}
                          <span>{item.get("last_name")}</span>
                        </div>
                      </AvatarWithName>
                    </TableCell>
                    <TableCell
                      className={cx(
                        classes.tableRowColumn,
                        classes.numberColumn,
                      )}
                    >
                      <div>{item.get("delivered_count", NA)}</div>
                    </TableCell>
                    <TableCell
                      className={cx(
                        classes.tableRowColumn,
                        classes.numberColumn,
                      )}
                    >
                      <div>{item.get("cod_pending", NA)}</div>
                    </TableCell>
                    <TableCell
                      className={cx(
                        classes.tableRowColumn,
                        classes.ratingColumn,
                      )}
                    >
                      <div className={classes.difference}>
                        {formatPercentage(item.get("rate"), "0.00%")}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </FlexBox>
      </FlexBox>
    </Card>
  );
}

export default enhancer(TopDriversWidget);
