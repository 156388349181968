import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import Cookies from "js-cookie";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { updatePassword } from "../../api/shared/AuthApi";
import { getUser, updateUser } from "../../api/shared/ProfileApi";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import ProfileForm from "../../components/deprecated/ProfileForm";
import ProfilePasswordForm from "../../components/deprecated/ProfilePasswordForm";

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const getUserRequestStream = propsStream
      .distinctUntilChanged(isEqualData)
      .switchMap(() =>
        getUser()
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(fp.flow(fp.update("pending", Boolean), fromJS))
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(getUserRequestStream, (props, getUserRequest) => ({
        ...props,
        onRequestRefresh,
        user: getUserRequest.get("payload"),
        isLoading: getUserRequest.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

SupplierProfile.propTypes = {
  user: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
};

function SupplierProfile(props) {
  const { i18n } = props;
  return (
    <SupplierAppLayout title={i18n.get("profile", "Profile")}>
      <PageLoading isLoading={props.isLoading} />
      <FlexBox container={8}>
        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true}>
            <ProfileForm
              disableCustomerName={true}
              onSubmit={values =>
                updateUser(toSnakeCase(values))
                  .catch(ResponseError.throw)
                  .then(() => ({
                    values,
                  }))
              }
              onSubmitSuccess={response => {
                if ("values" in response) {
                  Cookies.set("language", response.values.languageKey);
                }
                props.showSuccessMessage(
                  i18n.get("successfully_updated", "Successfully Updated"),
                );
                props.onRequestRefresh();
              }}
              onSubmitFail={props.showErrorMessage}
              initialValues={toCamelCase(props.user)}
            />
          </FlexBox>
          <FlexBox flex={true}>
            <ProfilePasswordForm
              onSubmit={({ password, currentPassword }) =>
                updatePassword(
                  toSnakeCase({ password, currentPassword }),
                ).catch(ResponseError.throw)
              }
              onSubmitSuccess={() => {
                props.showSuccessMessage(
                  i18n.get("successfully_changed", "Successfully Changed"),
                );
                props.onRequestRefresh();
              }}
              onSubmitFail={props.showErrorMessage}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierProfile);
