import {
  CHARTS_PATH,
  ORDER_LIST_URL,
  ORDER_JOBS_PATH,
  ORDER_LIST_PATH,
  DRIVER_LIST_PATH,
  ORDER_TRACK_PATH,
  DRIVER_RADAR_PATH,
  ORDER_CREATE_PATH,
  VEHICLE_LIST_PATH,
  ORDER_LIST_MAP_PATH,
  ORDER_TRACKING_PATH,
  REPORTING_ROOT_PATH,
  DRIVER_RADAR_V2_PATH,
  FINANCE_BALANCE_PATH,
  DRIVER_LIABILITY_PATH,
  FINANCE_ORDER_LIST_PATH,
  ORDER_SORTING_VIEW_PATH,
  SUPPLIER_USER_LIST_PATH,
  FINANCE_TRANSFER_ITEM_PATH,
  FINANCE_TRANSFER_LIST_PATH,
  REPORTING_OPERATIONAL_PATH,
  CUSTOMER_FEEDBACK_LIST_PATH,
  COMMUNICATION_SETTINGS_ITEM_PATH,
  COMMUNICATION_SETTINGS_LIST_PATH,
  COMMUNICATION_SETTINGS_CREATE_PATH,
} from "../constants/SupplierPathConstants";
import {
  PROFILE_PATH,
  SIGN_IN_PATH,
  SIGN_OUT_PATH,
  DASHBOARD_PATH,
  CHANGE_PASSWORD_PATH,
  RECOVER_PASSWORD_PATH,
} from "../constants/DashboardPathConstants";
import SignOut from "../components/auth/SignOut";
import ChangePasswordContainer from "../containers/shared/ChangePasswordContainer";
import RecoverPasswordContainer from "../containers/shared/RecoverPasswordContainer";
import Root from "../components/app-core/Root";
import NotFound from "../components/app-core/NotFound";
import SupplierRoot from "../containers/supplier/SupplierRoot";
import SupplierSignIn from "../containers/supplier/SupplierSignIn";
import SupplierProfile from "../containers/supplier/SupplierProfile";
import SupplierUserList from "../containers/supplier/SupplierUserList";
import SupplierOrderList from "../containers/supplier/SupplierOrderList";
import SupplierDriverList from "../containers/supplier/SupplierDriverList";
import SupplierVehicleList from "../containers/supplier/SupplierVehicleList";
import SupplierOrderJobList from "../containers/supplier/SupplierOrderJobList";
import SupplierOrderTracking from "../containers/supplier/SupplierOrderTracking";
import SupplierRadarContainer from "../containers/supplier/SupplierRadarContainer";
import SupplierReportContainer from "../containers/supplier/SupplierReportContainer";
import SupplierRadarContainerV2 from "../containers/supplier/SupplierRadarContainerV2";
import SupplierDashboardContainer from "../containers/supplier/SupplierDashboardContainer";
import SupplierCustomerFeedbackList from "../containers/supplier/SupplierCustomerFeedbackList";
import SupplierOrderCreateContainer from "../containers/supplier/SupplierOrderCreateContainer";
import SupplierOrderRoutingContainer from "../containers/supplier/SupplierOrderRoutingContainer";
import SupplierOrderSortingContainer from "../containers/supplier/SupplierOrderSortingContainer";
import SupplierCODTransferItemContainer from "../containers/supplier/SupplierCODTransferItemContainer";
import SupplierCODTransferListContainer from "../containers/supplier/SupplierCODTransferListContainer";
import SupplierDriverLiabilityContainer from "../containers/supplier/SupplierDriverLiabilityContainer";
import SupplierCommunicationSettingsItem from "../containers/supplier/SupplierCommunicationSettingsItem";
import SupplierCommunicationSettingsList from "../containers/supplier/SupplierCommunicationSettingsList";
import SupplierFinanceDashboardContainer from "../containers/supplier/SupplierFinanceDashboardContainer";
import SupplierFinanceOrderListContainer from "../containers/supplier/SupplierFinanceOrderListContainer";
import SupplierOperationalReportContainer from "../containers/supplier/SupplierOperationalReportContainer";
import SupplierOrderTrackingToolContainer from "../containers/supplier/SupplierOrderTrackingToolContainer";
import SupplierCommunicationSettingsCreate from "../containers/supplier/SupplierCommunicationSettingsCreate";
import { updateQuery } from "../../shared/helpers/UrlUtils";

export default {
  path: "/",
  component: Root,
  indexRoute: {
    onEnter: (state, replace) =>
      replace(updateQuery(ORDER_LIST_URL, state.location.query)),
  },
  childRoutes: [
    // Redirects.

    {
      path: "dashboard/order/list/all",
      onEnter: (state, redirect) =>
        redirect(updateQuery(ORDER_LIST_URL, state.location.query)),
    },

    {
      path: "dashboard/order/item/:id",
      onEnter: (state, redirect) =>
        redirect(
          updateQuery(ORDER_LIST_URL, {
            ...state.location.query,
            view: state.params.id,
          }),
        ),
    },

    // Auth.

    {
      path: SIGN_OUT_PATH,
      component: SignOut,
    },
    {
      path: RECOVER_PASSWORD_PATH,
      component: RecoverPasswordContainer,
    },
    {
      path: CHANGE_PASSWORD_PATH,
      component: ChangePasswordContainer,
    },
    {
      path: SIGN_IN_PATH,
      component: SupplierSignIn,
    },

    // Dashboard.

    {
      path: DASHBOARD_PATH,
      component: SupplierRoot,
      indexRoute: {
        onEnter: (state, replace) =>
          replace(updateQuery(ORDER_LIST_URL, state.location.query)),
      },
      childRoutes: [
        // Profile.

        {
          path: PROFILE_PATH,
          component: SupplierProfile,
        },
        {
          path: CHARTS_PATH,
          component: SupplierDashboardContainer,
        },

        // Order.
        {
          path: ORDER_CREATE_PATH,
          component: SupplierOrderCreateContainer,
        },
        {
          path: ORDER_LIST_PATH,
          component: SupplierOrderList,
        },
        {
          path: ORDER_TRACKING_PATH,
          component: SupplierOrderTracking,
        },
        {
          path: ORDER_TRACK_PATH,
          component: SupplierOrderTrackingToolContainer,
        },
        {
          path: ORDER_JOBS_PATH,
          component: SupplierOrderJobList,
        },
        {
          path: ORDER_SORTING_VIEW_PATH,
          component: SupplierOrderSortingContainer,
        },

        // Finance.

        {
          path: FINANCE_ORDER_LIST_PATH,
          component: SupplierFinanceOrderListContainer,
        },
        {
          path: FINANCE_TRANSFER_LIST_PATH,
          component: SupplierCODTransferListContainer,
        },
        {
          path: FINANCE_TRANSFER_ITEM_PATH,
          component: SupplierCODTransferItemContainer,
        },
        {
          path: FINANCE_BALANCE_PATH,
          component: SupplierFinanceDashboardContainer,
        },

        // Customer Feedback

        {
          path: CUSTOMER_FEEDBACK_LIST_PATH,
          component: SupplierCustomerFeedbackList,
        },

        {
          path: ORDER_LIST_MAP_PATH,
          component: SupplierOrderRoutingContainer,
        },
        // Customer Feedback

        {
          path: CUSTOMER_FEEDBACK_LIST_PATH,
          component: SupplierCustomerFeedbackList,
        },

        // Driver.

        {
          path: DRIVER_RADAR_PATH,
          component: SupplierRadarContainer,
        },
        {
          path: DRIVER_RADAR_V2_PATH,
          component: SupplierRadarContainerV2,
        },
        {
          path: DRIVER_LIST_PATH,
          component: SupplierDriverList,
        },
        {
          path: DRIVER_LIABILITY_PATH,
          component: SupplierDriverLiabilityContainer,
        },

        // Vehicle.

        {
          path: VEHICLE_LIST_PATH,
          component: SupplierVehicleList,
        },

        // Supplier Users.

        {
          path: SUPPLIER_USER_LIST_PATH,
          component: SupplierUserList,
        },

        // Settings.

        {
          path: COMMUNICATION_SETTINGS_LIST_PATH,
          component: SupplierCommunicationSettingsList,
        },
        {
          path: COMMUNICATION_SETTINGS_CREATE_PATH,
          component: SupplierCommunicationSettingsCreate,
        },
        {
          path: COMMUNICATION_SETTINGS_ITEM_PATH,
          component: SupplierCommunicationSettingsItem,
        },

        // Reporting

        {
          path: REPORTING_ROOT_PATH,
          component: SupplierReportContainer,
        },
        {
          path: REPORTING_OPERATIONAL_PATH,
          component: SupplierOperationalReportContainer,
        },
      ],
    },

    // Not Found Handler.

    { path: "*", component: NotFound },
  ],
};
