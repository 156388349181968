import React from "react";
import { Map, List } from "immutable";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues, getFormSyncErrors } from "redux-form";
import { Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import OrderCreateWizardStepCard from "../internal/OrderCreateWizardStepCard";
import OrderCreateWizardStepDetails from "../internal/OrderCreateWizardStepDetails";
import Text, { DANGER } from "../../ui-core/Text";
import FormTimeSlot from "../../time-slots-core/FormTimeSlot";
import { formatDate, DATE_SHORT_FORMAT } from "../../../helpers/FormatUtils";
import { isValidDate } from "../../../helpers/ValidateUtils";
import { formatTimeSlot } from "../../../helpers/TimeSlotHelper";
import { getMessage } from "../../../reducers/LocalizationReducer";
import { DROP, PICKUP } from "../../../constants/TimeSlotType";
import pickup from "./assets/pickup.png";
import dropoff from "./assets/dropoff.png";

const getSyncErrors = getFormSyncErrors("OrderCreateWizardSupplier");

const enhancer = compose(
  reduxForm({
    form: "OrderCreateWizardSupplier",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  formValues({
    pickupTimeSlot: "pickupTimeSlot",
    deliveryTimeSlot: "deliveryTimeSlot",
    estimatedPickupTime: "estimatedPickupTime",
    estimatedDeliveryTime: "estimatedDeliveryTime",
  }),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    errors: getSyncErrors(state),
  })),
  mapPropsStream(propsStream => {
    const { handler: setView, stream: viewStream } = createEventHandler();

    return propsStream.combineLatest(
      viewStream.startWith(null),
      (props, view) => ({
        ...props,
        setView,
        view,
      }),
    );
  }),
);

OrderCreateWizardStepWhenForm.propTypes = {
  view: PropTypes.string,
  setView: PropTypes.func,
  errors: PropTypes.object,
  initialValues: PropTypes.object,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,

  showContent: PropTypes.bool,

  pickupTimeSlot: PropTypes.instanceOf(Map),
  deliveryTimeSlot: PropTypes.instanceOf(Map),
  estimatedPickupTime: PropTypes.instanceOf(Date),
  estimatedDeliveryTime: PropTypes.instanceOf(Date),

  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  pickupTimeSlotsFetching: PropTypes.bool.isRequired,
  pickupTimeSlots: PropTypes.instanceOf(List).isRequired,
  deliveryTimeSlotsFetching: PropTypes.bool.isRequired,
  deliveryTimeSlots: PropTypes.instanceOf(List).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardStepWhenForm(props) {
  const { errors, getLocalisationMessage } = props;

  return (
    <OrderCreateWizardStepCard
      title={getLocalisationMessage("tell_us_when", "Tell us when?")}
      onSubmit={props.handleSubmit}
      showContent={props.showContent}
      showSubmit={Boolean(
        !props.view &&
          props.showContent &&
          !errors.pickupTimeSlot &&
          !errors.deliveryTimeSlot &&
          (props.pickupTimeSlot || props.deliveryTimeSlot),
      )}
      onDismiss={() => {
        if (props.view) {
          props.setView(null);
        } else {
          props.change(
            "pickupTimeSlot",
            props.initialValues.pickupTimeSlot || null,
          );
          props.change(
            "estimatedPickupTime",
            props.initialValues.estimatedPickupTime || null,
          );
          props.change(
            "deliveryTimeSlot",
            props.initialValues.deliveryTimeSlot || null,
          );
          props.change(
            "estimatedDeliveryTime",
            props.initialValues.estimatedDeliveryTime || null,
          );

          props.onDismiss();
        }
      }}
    >
      {!props.view && (
        <div>
          <OrderCreateWizardStepDetails
            primaryText={getLocalisationMessage("to_pickup", "To Pickup")}
            secondaryText={
              Boolean(
                props.pickupTimeSlot && isValidDate(props.estimatedPickupTime),
              ) && (
                <div>
                  {errors.pickupTimeSlot ? (
                    <Text type={DANGER}>{errors.pickupTimeSlot}</Text>
                  ) : (
                    <div>
                      {props.pickupTimeSlot.get("name")} (
                      {formatTimeSlot(props.pickupTimeSlot)}{" "}
                      {formatDate(props.estimatedPickupTime, DATE_SHORT_FORMAT)}
                      )
                    </div>
                  )}
                </div>
              )
            }
            onClick={() => props.setView(PICKUP)}
            avatar={
              <Avatar
                src={pickup}
                style={{
                  backgroundColor: "#fff",
                }}
              />
            }
          />

          <OrderCreateWizardStepDetails
            primaryText={getLocalisationMessage("to_dropoff", "To Dropoff")}
            secondaryText={
              Boolean(
                props.deliveryTimeSlot &&
                  isValidDate(props.estimatedDeliveryTime),
              ) && (
                <div>
                  {errors.deliveryTimeSlot ? (
                    <Text type={DANGER}>{errors.deliveryTimeSlot}</Text>
                  ) : (
                    <div>
                      {props.deliveryTimeSlot.get("name")} (
                      {formatTimeSlot(props.deliveryTimeSlot)}{" "}
                      {formatDate(
                        props.estimatedDeliveryTime,
                        DATE_SHORT_FORMAT,
                      )}
                      )
                    </div>
                  )}
                </div>
              )
            }
            onClick={() => props.setView(DROP)}
            avatar={
              <Avatar
                src={dropoff}
                style={{
                  backgroundColor: "#fff",
                }}
              />
            }
          />
        </div>
      )}

      {props.view === PICKUP && (
        <FormTimeSlot
          minDate={new Date()}
          onRequestClose={() => props.setView(null)}
          timeSlotField="pickupTimeSlot"
          timeSlotDateField="estimatedPickupTime"
          timeSlots={props.pickupTimeSlots}
          timeSlotsFetching={props.pickupTimeSlotsFetching}
        />
      )}

      {props.view === DROP && (
        <FormTimeSlot
          minDate={new Date()}
          onRequestClose={() => props.setView(null)}
          timeSlotField="deliveryTimeSlot"
          timeSlotDateField="estimatedDeliveryTime"
          timeSlots={props.deliveryTimeSlots}
          timeSlotsFetching={props.deliveryTimeSlotsFetching}
        />
      )}
    </OrderCreateWizardStepCard>
  );
}

export default enhancer(OrderCreateWizardStepWhenForm);
