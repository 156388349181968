import React from "react";
import { List, Map, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { Divider, IconButton, MenuItem, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add, FilterList } from "@material-ui/icons";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { pipeStreams } from "../../helpers/StreamUtils";
import { mergeFilterToFilter } from "../../helpers/FilterHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { stringifyArray } from "../../helpers/SerializeUtils";
import {
  formatDateValuesForDownloadCSV,
  mapOrderListPropsStream,
} from "../../helpers/OrderListHelper";
import { getMarketplaceId } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import {
  ORDER_CREATE_URL,
  ORDER_LIST_URL,
} from "../../constants/SupplierPathConstants";
import { OrderSettingsApi } from "../../api/shared/OrderSettingsApi";
import {
  getDynamicFields,
  getOrder,
  getOrderList,
} from "../../api/supplier/SupplierOrderApi";
import Toggle from "../../components/form/Toggle";
import Redirect from "../../components/router/Redirect";
import SupplierOrderFilterWrapper from "../../wrappers/supplier/SupplierOrderFilterWrapper";
import SupplierOrderEditDialogWrapper from "../../wrappers/supplier/SupplierOrderEditDialogWrapper";
import SupplierOrderFilterTabsWrapper from "../../wrappers/supplier/SupplierOrderFilterTabsWrapper";
import SupplierOrderEditDialogWrapperV2 from "../../wrappers/supplier/SupplierOrderEditDialogWrapperV2";
import SupplierOrderDetailsDialogWrapper from "../../wrappers/supplier/SupplierOrderDetailsDialogWrapper";
import SupplierOrderFilterButtonsWrapper from "../../wrappers/supplier/SupplierOrderFilterButtonsWrapper";
import SupplierParseBatchIdsDialogWrapper from "../../wrappers/supplier/SupplierParseBatchIdsDialogWrapper";
import SupplierBatchOrderNoteDialogWrapper from "../../wrappers/supplier/SupplierBatchOrderNoteDialogWrapper";
import SupplierOrderDetailsDialogWrapperV2 from "../../wrappers/supplier/SupplierOrderDetailsDialogWrapperV2";
import SupplierBatchUpdateOrderDialogWrapper from "../../wrappers/supplier/SupplierBatchUpdateOrderDialogWrapper";
import SupplierBatchUpdatesItemDialogWrapper from "../../wrappers/supplier/SupplierBatchUpdatesItemDialogWrapper";
import SupplierOverweightUploadDialogWrapper from "../../wrappers/supplier/SupplierOverweightUploadDialogWrapper";
import SupplierBatchUpdateBatchesDialogWrapper from "../../wrappers/supplier/SupplierBatchUpdateBatchesDialogWrapper";
import SupplierBatchRescheduleOrderDialogWrapper from "../../wrappers/supplier/SupplierBatchRescheduleOrderDialogWrapper";
import FlexBox from "../../components/ui-core/FlexBox";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import DownloadDynamicCSVDialog from "../../components/csv-core/DownloadDynamicCSVDialog";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import CustomizableOrderList from "../../components/orders-core/CustomizableOrderList";
import OrderAWBManifestPopover from "../../components/orders-core/OrderAWBManifestPopover";
import OrderListErrorNotification from "../../components/orders-core/OrderListErrorNotification";
import OrderAddressChangeNotificationDialog from "../../components/orders-core/OrderAddressChangeNotificationDialog";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  JOLIBEE_ID,
  LIFE_PHARMACY_ID,
  SUBWAY_ID,
  TIGER_EXPRESS_ID,
} from "../../../server/constants/MarketplaceId";
import { CREATE_ORDER_CSV_URL } from "../../../shared/constants/FileProxyControllerConstants";

const ORDER_FILTER_DIALOG_HASH = "#OFD";
const UPLOAD_OVERWEIGHT_CSV_DIALOG_HASH = "#UOCDH";
const BATCH_ORDERS_RESCHEDULE_DIALOG_HASH = "#BORDH";
const BATCH_CREATE_ORDER_NOTES_DIALOG_HASH = "#BCONDH";
const BATCH_ASYNC_ORDERS_UPDATE_DIALOG_HASH = "#BAOUDH";
const DOWNLOAD_DYNAMIC_CSV_DIALOG_HASH = "#DDCDH";
const ORDER_CUSTOM_FILTER_DIALOG_HASH = "#OCFDH";
const FETCH_BARCODES_DIALOG_HASH = "#FBDH";

const domesticOrders = Map({ is_uae: true });
const internationalOrders = Map({ is_uae: false });
const notSolr = Map({ use_solr: false });
const fromSolr = Map({ use_solr: true });

const enhancer = compose(
  useSheet({
    appBarRightAction: {
      height: "100%",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      float: "right",
      marginLeft: "12px",
    },
  }),
  getContext({
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
  connect(
    (state) => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      marketplaceId: getMarketplaceId(state),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(
    pipeStreams(
      mapOrderListPropsStream(
        getOrderList,
        (
          props,
          filter,
          response,
          onRowSelect,
          selectedItems,
          onRequestRefresh,
          batchGetOrderListCount,
        ) => ({
          ...props,
          filter,
          onRowSelect,
          selectedItems,
          onRequestRefresh,
          batchGetOrderListCount,
          list: response.get("list"),
          total: response.get("total"),
          error: response.get("error"),
          isLoading: response.get("isLoading"),
        }),
      ),
      (propsStream) => {
        const requestRefresh = createEventHandler();
        const settingsApi = new OrderSettingsApi();

        const filtersListStream = settingsApi
          .getCustomFilters()
          .repeatWhen(() => requestRefresh.stream);

        return propsStream.combineLatest(
          filtersListStream,
          (props, response) => ({
            ...props,

            filters: response.get("payload"),
            isLoadingFilters: response.get("pending"),
            onRequestRefreshFilters: requestRefresh.handler,
          }),
        );
      },
    ),
  ),
);

SupplierOrderList.propTypes = {
  sheet: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  location: PropTypes.object,
  replaceLocationHash: PropTypes.func,
  setLocationQuery: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  onRequestRefresh: PropTypes.func,
  batchGetOrderListCount: PropTypes.func,
  isLoading: PropTypes.bool,
  total: PropTypes.number,
  list: PropTypes.instanceOf(List),
  error: PropTypes.instanceOf(Error),
  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(OrderedMap),
  getLocalisationMessage: PropTypes.func,
  marketplaceId: PropTypes.number,

  filters: PropTypes.instanceOf(List),
  isLoadingFilters: PropTypes.bool,
  onRequestRefreshFilters: PropTypes.func,
};

function SupplierOrderList(props) {
  const {
    sheet: { classes },
    location,
  } = props;
  const isDomestic = props.filter.getBoolValue("is_uae");
  const useSolr = props.filter.getBoolValue("use_solr");
  const hidden = false;
  const orderNumbers = props.selectedItems.toOrderedSet();
  const selectedIds = props.selectedItems.keySeq().toArray();
  const orderNumbersArray = orderNumbers.toArray();
  const isSubwayMarketplaceId =
    props.marketplaceId === SUBWAY_ID || props.marketplaceId === JOLIBEE_ID;

  const searchTypes = OrderedMap()
    .set("all", props.getLocalisationMessage("everything", "Everything"))
    .set(
      "order_number",
      props.getLocalisationMessage("search_by_order_number", "Order Numbers"),
    )
    .set(
      "batch_update_number",
      props.getLocalisationMessage(
        "batch_update_number",
        "Batch Update Number",
      ),
    );

  return (
    <SupplierAppLayout
      slug={isDomestic ? "domestic_orders" : "international_orders"}
      title={
        isDomestic
          ? props.getLocalisationMessage("domestic", "Domestic")
          : props.getLocalisationMessage("international", "International")
      }
      appBarRightAction={
        <FlexBox
          flex={true}
          align="center"
          justify="flex-end"
          className={classes.appBarRightAction}
        >
          <SupplierOrderFilterButtonsWrapper
            filter={props.filter}
            onFilterClick={(x) => {
              const { tab } = location.query;
              const tabFilter = props.filters.find(
                (i) => fp.snakeCase(i.get("name")) === tab,
              );
              const queryFilter = new DataListFilter().setValueMap({
                ...x.toJS(),
                tab,
              });
              const mergedFilters = mergeFilterToFilter(
                new DataListFilter().setValueMap(
                  tabFilter ? tabFilter.get("filter") : {},
                ),
                queryFilter,
              );
              props.setLocationQuery(mergedFilters.getDefinedValues());
            }}
            openEditFiltersDialog={
              location.hash === ORDER_CUSTOM_FILTER_DIALOG_HASH
            }
            onToggleEditFiltersDialog={(x) =>
              props.replaceLocationHash(
                !x ? null : ORDER_CUSTOM_FILTER_DIALOG_HASH,
              )
            }
            filters={props.filters}
            isLoading={props.isLoadingFilters}
            onRequestRefresh={() => {
              props.onRequestRefreshFilters();
            }}
          />

          <Toggle
            accent={true}
            label={props.getLocalisationMessage(
              "international",
              "International",
            )}
            className={classes.appBarRightActionToggle}
            value={!isDomestic}
            onChange={() =>
              props.setLocationQueryFilter(
                props.filter.setValueMap(
                  isDomestic ? internationalOrders : domesticOrders,
                ),
              )
            }
          />

          <Toggle
            accent={true}
            label={props.getLocalisationMessage("fast_search", "Fast Search")}
            className={classes.appBarRightActionToggle}
            value={useSolr}
            onChange={() =>
              props.setLocationQueryFilter(
                props.filter.setValueMap(useSolr ? notSolr : fromSolr),
              )
            }
          />
        </FlexBox>
      }
    >
      <Redirect
        when={!searchTypes.has(location.query.search_type)}
        to={updateQuery(location, fp.set("search_type", "order_number"))}
      />

      <OrderListErrorNotification
        error={props.error}
        onFixSizeError={() =>
          props.setLocationQueryFilter(props.filter.setPageSize(0))
        }
      />

      <SupplierOrderFilterWrapper
        filter={props.filter}
        onCustomFiltersClick={() =>
          props.replaceLocationHash(ORDER_CUSTOM_FILTER_DIALOG_HASH)
        }
        withCustomFilters={true}
        onFilterChange={(filter) => {
          props.replaceLocationHash(null);
          props.setLocationQuery(
            filter.setValue("tab", location.query.tab).getDefinedValues(),
          );
        }}
        open={location.hash === ORDER_FILTER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      {hidden && (
        <SupplierOverweightUploadDialogWrapper
          open={location.hash === UPLOAD_OVERWEIGHT_CSV_DIALOG_HASH}
          onRequestClose={() => props.replaceLocationHash(null)}
        />
      )}

      <DownloadDynamicCSVDialog
        open={location.hash === DOWNLOAD_DYNAMIC_CSV_DIALOG_HASH}
        getDynamicFields={getDynamicFields}
        createDownloadCSVHref={(columns) =>
          formatDateValuesForDownloadCSV(
            props.filter.setValue("csv_columns", columns),
          ).toJS()
        }
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <SupplierBatchUpdatesItemDialogWrapper
        batchId={fp.toFinite(location.query.batch_id)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("batch_id"));
        }}
        onShowOrdersClick={(numbers) => {
          props.replaceLocationHash(null);
          props.setLocationQuery({ search: numbers.join(",") });
        }}
      />

      <SupplierOrderDetailsDialogWrapper
        tab={location.query.view_tab}
        orderId={fp.toFinite(location.query.view)}
        onTabChange={(tab) => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view", "view_tab"]))
        }
        onEditClick={() =>
          props.setLocationQuery(
            fp.flow(fp.unset("view"), fp.set("edit", location.query.view)),
          )
        }
        createDriverSuggestHref={() =>
          props.setLocationQuery(fp.flow(fp.set("driver_view", true)))
        }
        location={location}
      />

      <SupplierOrderDetailsDialogWrapperV2
        tab={location.query.view_tab}
        orderId={fp.toFinite(location.query.view_v2)}
        onTabChange={(tab) => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view_v2", "view_tab"]))
        }
        onEditClick={() =>
          props.setLocationQuery(
            fp.flow(
              fp.unset("view_v2"),
              fp.set("edit_v2", location.query.view_v2),
            ),
          )
        }
        createDriverSuggestHref={() =>
          props.setLocationQuery(fp.flow(fp.set("driver_view", true)))
        }
        location={location}
      />

      <SupplierOrderEditDialogWrapper
        orderId={fp.toFinite(location.query.edit)}
        onRequestClose={() =>
          props.setLocationQuery(
            fp.flow(fp.unset("edit"), fp.set("view", location.query.edit)),
          )
        }
      />

      <SupplierOrderEditDialogWrapperV2
        orderId={fp.toFinite(location.query.edit_v2)}
        onRequestClose={() =>
          props.setLocationQuery(
            fp.flow(
              fp.unset("edit_v2"),
              fp.set("view_v2", location.query.edit_v2),
            ),
          )
        }
      />

      <SupplierBatchUpdateOrderDialogWrapper
        open={location.hash === BATCH_ASYNC_ORDERS_UPDATE_DIALOG_HASH}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
        }}
        initialValues={{
          orderBarcodes: orderNumbersArray,
          supplierDriverOnly: true,
          privacy: PUBLIC,
        }}
        list={props.list}
        selectedItems={props.selectedItems}
        onSubmitSuccess={(response) => {
          props.replaceLocationHash(null);
          props.setLocationQuery(fp.set("batch_id", response.data.id));
        }}
        onSubmitFail={props.showErrorMessage}
      />

      <SupplierBatchRescheduleOrderDialogWrapper
        initialValues={{ orderNumbers: orderNumbersArray }}
        open={location.hash === BATCH_ORDERS_RESCHEDULE_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "successfully_rescheduled_orders",
              "Successfully Rescheduled Orders",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
      />

      <SupplierBatchOrderNoteDialogWrapper
        open={location.hash === BATCH_CREATE_ORDER_NOTES_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        initialValues={{ orderNumbers: orderNumbersArray }}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
          props.showSuccessMessage(
            props.getLocalisationMessage(
              "successfully_updated_order_notes",
              "Successfully Updated Order Notes",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
      />

      <OrderAddressChangeNotificationDialog
        orderId={fp.toFinite(location.query.address_view)}
        getOrder={getOrder}
        onRequestClose={() => {
          props.setLocationQuery(fp.omit(["address_view"]));
          props.replaceLocationHash(null);
        }}
      />

      {location.hash === FETCH_BARCODES_DIALOG_HASH && (
        <SupplierParseBatchIdsDialogWrapper
          open={true}
          showBatchAssignSupplier={false}
          onRequestClose={() => props.replaceLocationHash(null)}
          onSubmit={fp.flow(toSnakeCase, (values) => {
            props.replaceLocationHash(null);
            props.setLocationQuery(
              fp.flow(
                fp.set("batch_ids", stringifyArray(values.batch_numbers)),
              ),
            );
          })}
          onBatchAssignSupplier={(ids) => {
            props.replaceLocationHash(null);
            props.setLocationQuery(
              fp.flow(fp.set("batches", stringifyArray(ids))),
            );
          }}
        />
      )}

      {location.query.batch_ids && (
        <SupplierBatchUpdateBatchesDialogWrapper
          open={true}
          batch_ids={location.query.batch_ids}
          onRequestClose={() => props.setLocationQuery(fp.unset("batch_ids"))}
          onSubmitFail={props.showErrorMessage}
          onSubmitSuccess={(response) => {
            props.setLocationQuery(
              fp.flow(
                fp.unset("batch_ids"),
                fp.set("batch_id", response.data.id),
              ),
            );
          }}
        />
      )}

      <SupplierOrderFilterTabsWrapper
        filter={props.filter}
        basePathname={ORDER_LIST_URL}
        batchGetOrderListCount={props.batchGetOrderListCount}
        filterList={props.filters}
        isLoading={props.isLoadingFilters}
        onRequestRefresh={() => {
          props.onRequestRefreshFilters();
        }}
      />

      <CustomizableOrderList
        altHeader={
          <FlexBox>
            <FlexBox>
              <OrderAWBManifestPopover
                label={props.getLocalisationMessage("awb", "AWB")}
                withAWB={true}
                ids={selectedIds}
                withAWBZLabel={true}
                withAWBCompact={true}
              />
            </FlexBox>

            <FlexBox>
              <OrderAWBManifestPopover
                label={props.getLocalisationMessage("manifest", "Manifest")}
                ids={selectedIds}
                withManifest={true}
                withManifestCompact={true}
              />
            </FlexBox>
          </FlexBox>
        }
        cardActionIcons={
          <div>
            {(props.marketplaceId === SUBWAY_ID ||
              props.marketplaceId === TIGER_EXPRESS_ID ||
              props.marketplaceId === LIFE_PHARMACY_ID) && (
              <Link to={ORDER_CREATE_URL}>
                <Tooltip
                  title={props.getLocalisationMessage(
                    "create_order",
                    "Create Order",
                  )}
                >
                  <IconButton>
                    <Add />
                  </IconButton>
                </Tooltip>
              </Link>
            )}

            <Link to={updateHash(props.location, ORDER_FILTER_DIALOG_HASH)}>
              <Tooltip
                title={props.getLocalisationMessage(
                  "filter_orders",
                  "Filter Orders",
                )}
              >
                <IconButton>
                  <FilterList />
                </IconButton>
              </Tooltip>
            </Link>
            <MenuButtonMore>
              <MenuItem
                onClick={() =>
                  props.replaceLocationHash(DOWNLOAD_DYNAMIC_CSV_DIALOG_HASH)
                }
              >
                {props.getLocalisationMessage(
                  "dynamic_download_csv",
                  "Dynamic Download CSV",
                )}
              </MenuItem>

              <Divider />

              {hidden && (
                <MenuItem
                  target="_blank"
                  component="a"
                  href={updateQuery(
                    CREATE_ORDER_CSV_URL,
                    props.filter.getDefinedValues(),
                  )}
                >
                  {props.getLocalisationMessage(
                    "download_csv_old",
                    "Download CSV (OLD)",
                  )}
                </MenuItem>
              )}

              {hidden && (
                <MenuItem
                  target="_blank"
                  component="a"
                  href={updateQuery(
                    CREATE_ORDER_CSV_URL,
                    props.filter
                      .setValue("api_version", "v2")
                      .getDefinedValues(),
                  )}
                >
                  {props.getLocalisationMessage(
                    "download_csv_new",
                    "Download CSV (NEW)",
                  )}
                </MenuItem>
              )}

              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(CREATE_ORDER_CSV_URL, {
                  ...props.filter.getDefinedValues(),
                  is_note: true,
                })}
              >
                {props.getLocalisationMessage(
                  "download_note_csv",
                  "Download Note CSV",
                )}
              </MenuItem>

              <MenuItem
                onClick={() =>
                  props.replaceLocationHash(
                    BATCH_ASYNC_ORDERS_UPDATE_DIALOG_HASH,
                  )
                }
              >
                {props.getLocalisationMessage(
                  "batch_orders_update",
                  "Batch Orders Update",
                )}
              </MenuItem>

              <MenuItem
                onClick={() =>
                  props.replaceLocationHash(BATCH_ORDERS_RESCHEDULE_DIALOG_HASH)
                }
              >
                {props.getLocalisationMessage(
                  "batch_orders_reschedule",
                  "Batch Orders Reschedule",
                )}
              </MenuItem>

              <MenuItem
                onClick={() =>
                  props.replaceLocationHash(
                    BATCH_CREATE_ORDER_NOTES_DIALOG_HASH,
                  )
                }
              >
                {props.getLocalisationMessage(
                  "batch_create_order_notes",
                  "Batch Create Order Notes",
                )}
              </MenuItem>

              <MenuItem
                onClick={() =>
                  props.replaceLocationHash(FETCH_BARCODES_DIALOG_HASH)
                }
              >
                {props.getLocalisationMessage(
                  "finalize_transit",
                  "Finalize Transit",
                )}
              </MenuItem>

              {hidden && (
                <MenuItem
                  onClick={() =>
                    props.replaceLocationHash(UPLOAD_OVERWEIGHT_CSV_DIALOG_HASH)
                  }
                >
                  {props.getLocalisationMessage(
                    "upload_overweight_awbs",
                    "Upload Overweight AWBs",
                  )}
                </MenuItem>
              )}
            </MenuButtonMore>
          </div>
        }
        withCODStatus={false}
        withLastDriver={false}
        withLogisticType={true}
        withMerchantPromiseDate={!isSubwayMarketplaceId}
        withAttempts={!isSubwayMarketplaceId}
        withPieceCount={!isSubwayMarketplaceId}
        withTimeslot={!isSubwayMarketplaceId}
        withWarehouse={!isSubwayMarketplaceId}
        searchTypes={searchTypes}
        onRowSelect={props.onRowSelect}
        selectedItems={props.selectedItems}
        filter={props.filter}
        onFilterChange={(filter) => props.setLocationQueryFilter(filter)}
        list={props.list}
        totalCount={props.total}
        isLoading={props.isLoading}
        onFilterClick={() =>
          props.replaceLocationHash(ORDER_FILTER_DIALOG_HASH)
        }
        createOrderHref={(id, version) =>
          updateQuery(
            props.location,
            fp.set(version === 2 ? "view_v2" : "view", id),
          )
        }
        onStatusClick={(item) => {
          props.onRowSelect(
            OrderedMap().set(item.get("id"), item.get("order_number")),
          );
          props.replaceLocationHash(BATCH_ASYNC_ORDERS_UPDATE_DIALOG_HASH);
        }}
        createAddressChangedNotificationHref={(id) =>
          updateQuery(location, fp.set("address_view", id))
        }
      />
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierOrderList);
