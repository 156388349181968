import React from "react";
import { List } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import SupplierOrderFilterWrapper from "./SupplierOrderFilterWrapper";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { OrderSettingsApi } from "../../api/shared/OrderSettingsApi";
import FlexBox from "../../components/ui-core/FlexBox";
import OrderCustomFilterButtons from "../../components/orders-core/OrderCustomFilterButtons";
import OrderCustomFiltersDialog from "../../components/orders-core/OrderCustomFiltersDialog";

const enhancer = compose();

SupplierOrderFilterButtonsWrapper.propTypes = {
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  filters: PropTypes.instanceOf(List),

  filter: PropTypes.instanceOf(DataListFilter),

  openEditFiltersDialog: PropTypes.bool.isRequired,
  onToggleEditFiltersDialog: PropTypes.func.isRequired,

  onFilterClick: PropTypes.func.isRequired,

  onSubmitFiltersSuccess: PropTypes.func,
  onSubmitFiltersFail: PropTypes.func,
};

function SupplierOrderFilterButtonsWrapper(props) {
  const settingsApi = new OrderSettingsApi();

  return (
    <FlexBox flex={true} justify="flex-end">
      <OrderCustomFilterButtons
        filter={props.filter}
        filters={props.filters}
        isLoading={props.isLoading}
        onFilterClick={props.onFilterClick}
        onEditFiltersClick={() => props.onToggleEditFiltersDialog(true)}
      />

      {!props.isLoading && (
        <OrderCustomFiltersDialog
          open={props.openEditFiltersDialog}
          onRequestClose={() => props.onToggleEditFiltersDialog(false)}
          initialValues={{ filters: props.filters }}
          onSubmit={values =>
            settingsApi
              .saveCustomFilters(values.filters)
              .catch(ResponseError.throw)
          }
          onSubmitFail={x => {
            props.onRequestRefresh();

            if (props.onSubmitFiltersFail) {
              props.onSubmitFiltersFail(x);
            }
          }}
          onSubmitSuccess={x => {
            props.onRequestRefresh();
            props.onToggleEditFiltersDialog(false);

            if (props.onSubmitFiltersSuccess) {
              props.onSubmitFiltersSuccess(x);
            }
          }}
          renderFilterDialog={dialogProps => (
            <SupplierOrderFilterWrapper {...dialogProps} />
          )}
        />
      )}
    </FlexBox>
  );
}

export default enhancer(SupplierOrderFilterButtonsWrapper);
