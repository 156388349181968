import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Card } from "@material-ui/core";
import { loadAllValues } from "../../../helpers/ApiUtils";
import DataListFilter from "../../../helpers/DataListFilter";
import { getHeatMapLocations } from "../../../api/supplier/SupplierChartApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../../api/supplier/SupplierCustomerApi";
import GoogleHeatMap from "../../../components/google-map-react/GoogleHeatMap";

const getAllLocationsByFilter = loadAllValues(getHeatMapLocations);

const enhancer = compose(
  useSheet({
    container: {
      width: "100%",
      minHeight: "580px",
      padding: "15px",
      position: "relative",
    },
  }),
);

SupplierGoogleHeatMapWrapper.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  location: PropTypes.object,
  filter: PropTypes.instanceOf(DataListFilter),
};

function SupplierGoogleHeatMapWrapper(props) {
  const { classes } = props;

  return (
    <Card className={classes.container}>
      <GoogleHeatMap
        location={props.location}
        getCachedCustomer={getCachedCustomer}
        getCustomerPredictions={getCustomerPredictions}
        title={props.title}
        initialFilter={props.filter}
        getAllLocationsByFilter={getAllLocationsByFilter}
      />
    </Card>
  );
}

export default enhancer(SupplierGoogleHeatMapWrapper);
