import React from "react";
import _ from "lodash";
import { OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import {
  getMarketplaceDefaultBatchUpdateStatuses,
  supplierTransitOrdersVisibility,
} from "../../reducers/MarketplaceReducer";
import OrderStatusCodes, {
  DELIVERY_DELAY_DUE_TO_LATE_BOOKING,
  NO_CAPACITY_FOR_DELIVERY,
} from "../../constants/OrderStatusCodes";
import SupplierCODCodes from "../../constants/SupplierCODCodes";
import { CUSTOM_SUPPLIER } from "../../constants/TransitModeTypes";
import SupplierPrivacyTypes from "../../constants/SupplierPrivacyTypes";
import { getCachedSupplier } from "../../api/admin/AdminSupplierApi";
import { batchAsyncUpdateOrder } from "../../api/supplier/SupplierOrderApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/supplier/SupplierDriverApi";
import { getSupplierPredictions } from "../../api/supplier/SupplierSupplierApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/supplier/SupplierWarehouseApi";
import BatchUpdateOrderDialog from "../../components/orders-core/BatchUpdateOrderDialog";

const enhancer = compose(
  connect((state) => ({
    isSupplierTransitOrdersVisibility: supplierTransitOrdersVisibility(state),
    orderStatuses: getMarketplaceDefaultBatchUpdateStatuses(state),
  })),
);

SupplierBatchUpdateOrderDialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  initialValues: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  list: PropTypes.any,
  selectedItems: PropTypes.any,
  isSupplierTransitOrdersVisibility: PropTypes.bool,
  orderStatuses: PropTypes.object,
};

function SupplierBatchUpdateOrderDialogWrapper(props) {
  const statusList =
    props.orderStatuses && props.orderStatuses.size > 0
      ? props.orderStatuses
      : OrderStatusCodes;
  return (
    <BatchUpdateOrderDialog
      open={props.open}
      onRequestClose={props.onRequestClose}
      initialValues={props.initialValues}
      onSubmit={fp.flow(toSnakeCase, (values) =>
        batchAsyncUpdateOrder(values)
          .catch(ResponseError.throw)
          .then((response) => {
            if (_.isArray(response.data) && response.data.length > 0) {
              const orderNumbers = response.data.join(", ");

              throw new SubmissionError({
                orderNumbers: `Could not update ${orderNumbers}.`,
              });
            }

            return response;
          }),
      )}
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
      codCodes={SupplierCODCodes}
      withInTransitToSupplier={true}
      transitModeOptions={OrderedSet.of(CUSTOM_SUPPLIER)}
      showSupplierDriverOnly={false}
      isSupplierTransitOrdersVisibility={
        props.isSupplierTransitOrdersVisibility
      }
      statusCodes={statusList
        .delete(NO_CAPACITY_FOR_DELIVERY)
        .delete(DELIVERY_DELAY_DUE_TO_LATE_BOOKING)}
      privacyTypes={SupplierPrivacyTypes}
      getCachedSupplier={getCachedSupplier}
      getSupplierPredictions={getSupplierPredictions}
      getCachedDriver={getCachedDriver}
      getCachedWarehouse={getCachedWarehouse}
      getDriverPredictions={getDriverPredictions}
      getWarehousePredictions={getWarehousePredictions}
      selectedItems={props.selectedItems}
      list={props.list}
    />
  );
}

export default enhancer(SupplierBatchUpdateOrderDialogWrapper);
