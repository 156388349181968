import { Observable } from "rxjs";
import fp from "lodash/fp";
import { compose, withState, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { sockJSHOC } from "../../helpers/sockJSHOC";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { getSupplierId } from "../../reducers/UserReducer";
import { updateOrderReceivedAlerts } from "../../api/supplier/SupplierAlertsApi";
import { log } from "../../../shared/helpers/logger";
import { isTokenValid } from "../../../shared/reducers/AuthReducer";
import { HOST } from "../../../shared/constants/ApiConstants";
import { SUPPLIER_ALERT_QUEUE_CHANNEL } from "../../../shared/constants/PushNotificationChannelConstants";
// import AlertTone from "./alert_tones.mp3";
import AlertTone from "./alert_alarm_tone.mp3";

const audioTone = new Audio(AlertTone);
const getSockMessageOrderId = fp.flow(
  fp.get("body"),
  d => (d && JSON.parse(d)) || null,
  fp.get("order_id"),
);

const SOCK_URL = `${HOST}/websocket/tracker?access_token=%s`;
const enhancer = compose(
  connect(state => ({
    supplierId: getSupplierId(state),
    isTokenValid: isTokenValid(state),
  })),
  renderIf(fp.get("isTokenValid")),
  withState("state", "setState", {
    subscription: null,
  }),
  sockJSHOC(SOCK_URL),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const sideEffectsStream = propsStream
          .filter(
            () =>
              Boolean("Notification" in window) &&
              Boolean(Notification.permission !== "granted"),
          )
          .first()
          .distinctUntilChanged(isEqualData)
          .do(() => {
            Notification.requestPermission().then(result => {
              if (result === "denied") {
                log(
                  "Browser Notification Permission wasn't granted. Allow a retry.",
                );
              }
              if (result === "granted") {
                log("Browser Notification Permission request was granted.");

                // eslint-disable-next-line no-new
                new Notification("DONE", {
                  body: "granted notification",
                });
                audioTone.play();
              }
            });
          })
          .startWith({});

        return propsStream.combineLatest(sideEffectsStream, props => props);
      },
      propsStream => {
        const supplierIdStream = propsStream
          .map(fp.pick(["supplierId"]))
          .filter(fp.flow(fp.get("supplierId"), Boolean))
          .distinctUntilChanged(isEqualData)
          .switchMap(({ supplierId }) =>
            updateOrderReceivedAlerts({ supplier_id: supplierId }),
          )
          .startWith({});

        return propsStream.combineLatest(supplierIdStream, props => props);
      },
      propsStream => {
        const subscribingLocationStream = propsStream
          .filter(
            ({ socketClient }) =>
              socketClient.connected && fp.isEmpty(socketClient.subscriptions),
          )
          .distinctUntilKeyChanged("socketClient", isEqualData)
          .switchMap(
            ({ socketClient, setState }) =>
              new Observable(observer => {
                const subscription = socketClient.subscribe(
                  SUPPLIER_ALERT_QUEUE_CHANNEL,
                  data => {
                    log("SOCKET alert: ", data);
                    const notification = new Notification("ALERT", {
                      body: "Order has assigned to you!!!",
                    });
                    audioTone.play();
                    const orderId = getSockMessageOrderId(data);
                    notification.onclick = () => {
                      window.open(
                        `${window.location.origin}/dashboard/order/list&view=${
                          orderId
                        }`,
                        "_blank",
                      );
                      notification.close();
                    };
                    observer.next();
                  },
                );

                setState(state => ({ ...state, subscription }));
              }),
          )
          .startWith(null);

        return propsStream.combineLatest(subscribingLocationStream, props => ({
          ...props,
        }));
      },
    ),
  ),
);

PushNotification.propTypes = {
  state: PropTypes.object,
};

/**
 * @return {null}
 */
function PushNotification() {
  return null;
}

export default enhancer(PushNotification);
