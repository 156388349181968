import React from "react";
import fp from "lodash/fp";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf, pureComponent } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getCachedServiceType,
  getServiceTypePredictions,
} from "../../api/admin/AdminServiceTypesApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/supplier/SupplierDriverApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import CustomerFeedbackFilterForm from "../../components/customer-feedback/CustomerFeedbackFilterForm";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  renderIf("open"),
  withState("state", "setState", { toggleContainer: null }),
  pureComponent(fp.pick(["state", "filter"])),
);

CustomerFeedbackFilterDialog.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,

  open: PropTypes.bool.isRequired,

  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerFeedbackFilterDialog(props) {
  return (
    <ModalPaper
      open={props.open}
      autoScrollBodyContent={true}
      title={props.getLocalisationMessage(
        "customer_feedback_filter",
        "Customer Feedback Filter",
      )}
      onRequestClose={props.onRequestClose}
      rightIcon={
        <div ref={x => props.setState(fp.set("toggleContainer", x))} />
      }
    >
      <CustomerFeedbackFilterForm
        filter={props.filter}
        onRequestClose={props.onRequestClose}
        onFilterChange={props.onFilterChange}
        toggleContainer={props.state.toggleContainer}
        getCachedDriver={getCachedDriver}
        getDriverPredictions={getDriverPredictions}
        getCachedServiceType={getCachedServiceType}
        getServiceTypePredictions={getServiceTypePredictions}
      />
    </ModalPaper>
  );
}

export default enhancer(CustomerFeedbackFilterDialog);
