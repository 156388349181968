import { Observable } from "rxjs";
import React from "react";
import { List, fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toOrderFilter } from "../../helpers/OrderFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getOrderList } from "../../api/supplier/SupplierOrderApi";
import Redirect from "../../components/router/Redirect";
import SupplierOrderEditDialogWrapper from "../../wrappers/supplier/SupplierOrderEditDialogWrapper";
import SupplierOrderEditDialogWrapperV2 from "../../wrappers/supplier/SupplierOrderEditDialogWrapperV2";
import SupplierOrderDetailsDialogWrapper from "../../wrappers/supplier/SupplierOrderDetailsDialogWrapper";
import SupplierOrderDetailsDialogWrapperV2 from "../../wrappers/supplier/SupplierOrderDetailsDialogWrapperV2";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import OrderList from "../../components/orders-core/OrderList";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const filterStream = propsStream
          .pluck("location", "query")
          .map(
            fp.flow(toOrderFilter, (filter: DataListFilter) =>
              filter.setValue("is_uae", null),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const searchResultStream = filterStream
          .switchMap(
            (filter: DataListFilter) =>
              filter.getValue("search")
                ? getOrderList(filter)
                    .repeatWhen(() => onRequestRefreshStream)
                    .catch(error => Observable.of({ error }))
                : Observable.of({}),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  isLoading: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            filterStream,
            searchResultStream,
            (props, filter, searchResult) => ({
              ...props,
              filter,
              onRequestRefresh,
              list: searchResult.get("list"),
              total: searchResult.get("total"),
              isLoading: searchResult.get("isLoading"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const sideEffectsStream = Observable.merge(
          propsStream
            .map(props => ({
              replaceLocationQuery: props.replaceLocationQuery,
              order: props.list.size === 1 ? props.list.get(0, null) : null,
            }))
            .distinctUntilKeyChanged("order")
            .filter(props => props.order && props.order.get("id") > 0)
            .do(props =>
              props.replaceLocationQuery(
                fp.set(
                  props.order.get("version") === 2 ? "view_v2" : "view",
                  props.order.get("id"),
                ),
              ),
            ),
        )
          .mapTo(null)
          .startWith(null)
          .distinctUntilChanged();

        return propsStream.combineLatest(sideEffectsStream, fp.identity);
      },
    ),
  ),
);

SupplierOrderTrackingToolContainer.propTypes = {
  location: PropTypes.object,
  setLocationQuery: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  isLoading: PropTypes.bool,
  total: PropTypes.number,
  list: PropTypes.instanceOf(List),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SupplierOrderTrackingToolContainer(props) {
  const { location, getLocalisationMessage } = props;

  const searchTypes = OrderedMap()
    .set("all", getLocalisationMessage("everything", "Everything"))
    .set(
      "order_number",
      getLocalisationMessage("search_by_order_number", "Order Number"),
    );

  return (
    <SupplierAppLayout
      slug="order_tracking"
      title={getLocalisationMessage("order_tracking", "Order Tracking")}
    >
      <Redirect
        when={!searchTypes.has(location.query.search_type)}
        to={updateQuery(location, fp.set("search_type", "order_number"))}
      />

      <SupplierOrderDetailsDialogWrapper
        tab={props.location.query.view_tab}
        orderId={fp.toFinite(props.location.query.view)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view", "view_v2", "view_tab"]))
        }
        onEditClick={() =>
          props.setLocationQuery(
            fp.flow(
              fp.unset("view"),
              fp.unset("view_v2"),
              fp.set("edit", location.query.view),
            ),
          )
        }
        location={location}
      />

      <SupplierOrderDetailsDialogWrapperV2
        tab={props.location.query.view_tab}
        orderId={fp.toFinite(props.location.query.view_v2)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view", "view_v2", "view_tab"]))
        }
        onEditClick={() =>
          props.setLocationQuery(
            fp.flow(
              fp.unset("view"),
              fp.unset("view_v2"),
              fp.set("edit_v2", location.query.view_v2),
            ),
          )
        }
        createDriverSuggestHref={() =>
          props.setLocationQuery(fp.flow(fp.set("driver_view", true)))
        }
        location={location}
      />

      <SupplierOrderEditDialogWrapper
        orderId={fp.toFinite(location.query.edit)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(
            fp.flow(fp.unset("edit"), fp.unset("edit_v2")),
          );
        }}
      />

      <SupplierOrderEditDialogWrapperV2
        orderId={fp.toFinite(location.query.edit_v2)}
        onRequestClose={() =>
          props.setLocationQuery(fp.flow(fp.unset("edit"), fp.unset("edit_v2")))
        }
      />

      <OrderList
        maxSearchItems={1}
        filter={props.filter}
        searchTypes={searchTypes}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        list={props.list}
        totalCount={props.total}
        isLoading={props.isLoading}
      />
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierOrderTrackingToolContainer);
