import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { MenuItem, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDataListFilter } from "../../helpers/DataListFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getVehicleList } from "../../api/supplier/SupplierVehicleApi";
import SupplierDriverEditDialogWrapper from "../../wrappers/supplier/SupplierDriverEditDialogWrapper";
import SupplierVehicleEditDialogWrapper from "../../wrappers/supplier/SupplierVehicleEditDialogWrapper";
import SupplierDriverCreateDialogWrapper from "../../wrappers/supplier/SupplierDriverCreateDialogWrapper";
import SupplierVehicleCreateDialogWrapper from "../../wrappers/supplier/SupplierVehicleCreateDialogWrapper";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import OverallStatusTabs from "../../components/ui-core/OverallStatusTabs";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import CheckOverallStatus from "../../components/route-validators/CheckOverallStatus";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_VEHICLE_CSV_URL } from "../../../shared/constants/FileProxyControllerConstants";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toDataListFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getVehicleList(props.filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          );

        return propsStream
          .combineLatest(listResponseStream, (props, listResponse) => ({
            ...props,
            onRequestRefresh,
            list: listResponse.get("list"),
            total: listResponse.get("total"),
            isLoading: listResponse.get("pending"),
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

SupplierVehicleList.propTypes = {
  location: PropTypes.object,
  router: PropTypes.object,
  filter: PropTypes.instanceOf(DataListFilter),
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  list: PropTypes.instanceOf(List),
  setLocationQuery: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function SupplierVehicleList(props) {
  const { location, getLocalisationMessage } = props;

  return (
    <SupplierAppLayout
      slug="vehicles"
      title={getLocalisationMessage("vehicles", "Vehicles")}
    >
      <CheckOverallStatus location={location} />

      <OverallStatusTabs location={location} />

      <SupplierDriverCreateDialogWrapper
        open={location.query.create_driver === "true"}
        onRequestClose={() => props.setLocationQuery(fp.unset("create_driver"))}
        onVehicleClick={id =>
          props.setLocationQuery(
            fp.flow(
              fp.set("view", id),
              /* Close DriverDialog to avoid recursion. */
              fp.unset("view_driver"),
            ),
          )
        }
        onCreateVehicleClick={() =>
          props.setLocationQuery(fp.set("create", true))
        }
      />

      <SupplierDriverEditDialogWrapper
        driverId={fp.toFinite(location.query.view_driver)}
        onRequestClose={() => props.setLocationQuery(fp.unset("view_driver"))}
        onVehicleClick={id =>
          props.setLocationQuery(
            fp.flow(
              fp.set("view", id),
              /* Close DriverDialog to avoid recursion. */
              fp.unset("view_driver"),
            ),
          )
        }
        onCreateVehicleClick={() =>
          props.setLocationQuery(fp.set("create", true))
        }
      />

      <SupplierVehicleCreateDialogWrapper
        open={location.query.create === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
        onDriverClick={id => props.setLocationQuery(fp.set("view_driver", id))}
        onCreateDriverClick={() =>
          props.setLocationQuery(fp.set("create_driver", true))
        }
      />

      <SupplierVehicleEditDialogWrapper
        vehicleId={fp.toFinite(location.query.view)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("view"));
        }}
        onDriverClick={id => props.setLocationQuery(fp.set("view_driver", id))}
        onCreateDriverClick={() =>
          props.setLocationQuery(fp.set("create_driver", true))
        }
      />

      <DataList
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        rowCount={props.list.size}
        overscanRowCount={9}
        rowGetter={options => props.list.get(options.index)}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        cardActionIcons={
          <div>
            <Tooltip
              title={getLocalisationMessage(
                "create_new_vehicle",
                "Create New Vehicle",
              )}
            >
              <IconButton
                onClick={() => props.setLocationQuery(fp.set("create", true))}
              >
                <Add />
              </IconButton>
            </Tooltip>
            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_VEHICLE_CSV_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {getLocalisationMessage("download_csv", "Download CSV")}
              </MenuItem>
            </MenuButtonMore>
          </div>
        }
      >
        <DataListColumn
          width={104}
          label={getLocalisationMessage("id", "ID")}
          dataKey="id"
          disableSort={true}
          cellRenderer={row => (
            <strong>
              <Link
                to={updateQuery(
                  props.location,
                  fp.set("view", row.cellData.get("id")),
                )}
              >
                {row.cellData.get("id")}
              </Link>
            </strong>
          )}
        />
        <DataListColumn
          width={104}
          label={getLocalisationMessage("plate_number", "Plate number #")}
          dataKey="plate_number"
          disableSort={true}
          cellRenderer={row => row.cellData.get("plate_number")}
        />
        <DataListColumn
          width={104}
          label={getLocalisationMessage("manufacture", "Manufacture")}
          dataKey="make"
          cellRenderer={row => row.cellData.getIn(["make", "name"], "N/A")}
        />
        <DataListColumn
          width={104}
          label={getLocalisationMessage("model", "Model")}
          dataKey="model"
          cellRenderer={row => row.cellData.getIn(["model", "name"], "N/A")}
        />
        <DataListColumn
          width={104}
          label={getLocalisationMessage("vehicle_type", "Vehicle type")}
          dataKey="type"
          disableSort={true}
          cellRenderer={row => formatText(row.cellData.get("type") || "N/A")}
        />
        <DataListColumn
          width={104}
          justifyContent="center"
          label={getLocalisationMessage("color", "Color")}
          dataKey="color"
          cellRenderer={row => row.cellData.getIn(["color", "name"], "N/A")}
        />
        <DataListColumn
          width={104}
          justifyContent="center"
          label={getLocalisationMessage("year", "Year")}
          dataKey="year"
          cellRenderer={row => row.cellData.get("reg_year") || "N/A"}
        />
        <DataListColumn
          width={104}
          justifyContent="center"
          label={getLocalisationMessage("capacity", "Capacity")}
          dataKey="capacity"
          cellRenderer={row => row.cellData.get("cargo_capacity") || "N/A"}
        />
      </DataList>
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierVehicleList);
