import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const BATCH_ORDER_UPDATE_LIST = `${API_ROOT_URL}/supplier/batch_updates`;
const BATCH_ORDER_UPDATE_ITEM = `${API_ROOT_URL}/batch_update/%s`;
const GET_BATCH_ORDER_ITEMS = `${API_ROOT_URL}/supplier/batch_update/multi/%s`;

export const getBatchOrderUpdateList = (request: DataListFilter) =>
  api.getStream(BATCH_ORDER_UPDATE_LIST, {
    params: request.getDefinedValues(),
  });

export const getBatchOrderUpdateItem = id =>
  api.getStream(sprintf(BATCH_ORDER_UPDATE_ITEM, id));

export const getBatchOrdersItems = ids =>
  api.getStream(sprintf(GET_BATCH_ORDER_ITEMS, ids));
