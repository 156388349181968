import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, withState, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDateToUrl } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { supplierFilterReport } from "../../helpers/ReportsHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { toReportListFilter } from "../../helpers/ReportListMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  SCORE_RANKING,
  NOT_OFD_SHIPMENTS,
  PENDING_RTO_SHIPMENTS,
  UN_ATTEMPTED_SHIPMENTS,
} from "../../constants/OperationalReportTypes";
import { getOperationalReports } from "../../api/supplier/SupplierReportApi";
import AdminAppLayout from "../../components/admin/AdminAppLayout";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import ScoreRankingReport from "../../components/reports-core/ScoreRankingReport";
import OperationalReportForm from "../../components/reports-core/OperationalReportForm";
import PendingRTOShipmentsReport from "../../components/reports-core/PendingRTOShipmentsReport";
import UnattemptedAndNotOFDReport from "../../components/reports-core/UnattemptedAndNotOFDReport";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  withState("showBy", "onChangeShowBy", null),
  withState("groupBy", "onChangeGroupBy", null),
  withState("callsBy", "onChangeCallsBy", null),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(
            fp.flow(
              fp.get("location.query"),
              fp.set("size", 200),
              toReportListFilter,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(filterStream, (props, filter) => ({
            ...props,
            filter,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const reportListResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .filter(props => supplierFilterReport(props.filter))
          .switchMap(props =>
            getOperationalReports(props.filter).catch(error =>
              Observable.of({ error }),
            ),
          )
          .withLatestFrom(propsStream)
          .map(([data, props]) => ({
            ...data,
            reportType: props.filter.getValue("reportType"),
          }))
          .startWith({})
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            reportListResponseStream,
            (props, reportListResponse) => ({
              ...props,
              isLoading: reportListResponse.get("pending"),
              reportList: reportListResponse.get("payload"),
              listReportType: reportListResponse.get("reportType"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

SupplierOperationalReportContainer.propTypes = {
  isLoading: PropTypes.bool,
  filter: PropTypes.instanceOf(DataListFilter),
  reportList: PropTypes.instanceOf(Map),
  listReportType: PropTypes.string,
  setLocationQueryFilter: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function SupplierOperationalReportContainer(props) {
  const { filter, getLocalisationMessage } = props;

  return (
    <AdminAppLayout
      title={getLocalisationMessage(
        "operational_reports",
        "Operational Reports",
      )}
    >
      <PageLoading isLoading={props.isLoading} />

      <FlexBox container={8} flex={true}>
        <FlexBox gutter={16} flex={true} direction="column">
          <FlexBox flex="none">
            <OperationalReportForm
              withoutDate={true}
              operationalReportTypes={OrderedSet.of(
                UN_ATTEMPTED_SHIPMENTS,
                NOT_OFD_SHIPMENTS,
                PENDING_RTO_SHIPMENTS,
                SCORE_RANKING,
              )}
              reportType={filter.getValue("reportType")}
              showFilter={Boolean(filter.getValue("reportType"))}
              onChangeReportType={reportType => {
                props.setLocationQueryFilter(
                  filter.setValueMap({
                    reportType,
                    promise_date: null,
                    filter_by: null,
                    courier_types: null,
                    reportCustomerType: null,
                  }),
                );
              }}
              onChangeFilter={values => {
                switch (filter.getValue("reportType")) {
                  case UN_ATTEMPTED_SHIPMENTS:
                    props.setLocationQueryFilter(
                      props.filter.setValueMap({
                        report_customer_type: values.reportCustomerType,
                        promise_date: formatDateToUrl(values.promiseDate),
                        courier_types:
                          values.courierTypes &&
                          fp.toUpper(values.courierTypes.join(",")),
                      }),
                    );
                    break;

                  case NOT_OFD_SHIPMENTS:
                    props.setLocationQueryFilter(
                      props.filter.setValueMap({
                        report_customer_type: values.reportCustomerType,
                        promise_date: formatDateToUrl(values.promiseDate),
                        courier_types:
                          values.courierTypes &&
                          fp.toUpper(values.courierTypes.join(",")),
                      }),
                    );
                    break;

                  case PENDING_RTO_SHIPMENTS:
                    props.setLocationQueryFilter(
                      props.filter.setValueMap({
                        report_customer_type: values.reportCustomerType,
                      }),
                    );
                    break;

                  case SCORE_RANKING:
                    props.setLocationQueryFilter(
                      props.filter.setValueMap({
                        filter_by: values.filterBy,
                      }),
                    );
                    break;

                  default:
                  // Default Case
                }
              }}
            />
          </FlexBox>

          {filter.getValue("reportType") && (
            <FlexBox flex={true}>
              {filter.getValue("reportType") === NOT_OFD_SHIPMENTS && (
                <UnattemptedAndNotOFDReport
                  reportList={props.reportList}
                  showTable={Boolean(
                    props.reportList.size > 0 &&
                      props.listReportType === filter.getValue("reportType"),
                  )}
                  reportType={filter.getValue("reportType")}
                />
              )}

              {filter.getValue("reportType") === UN_ATTEMPTED_SHIPMENTS && (
                <UnattemptedAndNotOFDReport
                  reportList={props.reportList}
                  reportType={filter.getValue("reportType")}
                  showTable={Boolean(
                    props.reportList.size > 0 &&
                      props.listReportType === filter.getValue("reportType"),
                  )}
                />
              )}

              {filter.getValue("reportType") === PENDING_RTO_SHIPMENTS && (
                <PendingRTOShipmentsReport
                  reportList={props.reportList}
                  reportType={filter.getValue("reportType")}
                  showTable={Boolean(
                    props.reportList.size > 0 &&
                      props.listReportType === filter.getValue("reportType"),
                  )}
                />
              )}

              {filter.getValue("reportType") === SCORE_RANKING && (
                <ScoreRankingReport
                  reportList={props.reportList}
                  reportType={filter.getValue("reportType")}
                  showTable={Boolean(
                    props.reportList.size > 0 &&
                      props.listReportType === filter.getValue("reportType"),
                  )}
                />
              )}
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </AdminAppLayout>
  );
}

export default enhancer(SupplierOperationalReportContainer);
