import React from "react";
import { Map, List, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState, getContext } from "recompose";
import PropTypes from "prop-types";
import { MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import SupplierBatchUpdateOrderDialogWrapper from "./SupplierBatchUpdateOrderDialogWrapper";
import DataListFilter from "../../helpers/DataListFilter";
import { getUser } from "../../reducers/ProfileReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import MenuItemForm from "../../components/ui-core/MenuItemForm";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import JobOrderList from "../../components/order-job/JobOrderList";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { ROLE_ADMIN_VIEWER } from "../../../shared/constants/Authorities";
import { CREATE_ORDER_MANIFEST_URL } from "../../../shared/constants/FileProxyControllerConstants";
import { hasRole } from "../../helpers/RoleUtils";

const enhancer = compose(
  withState("isLoading", "setIsLoading", false),
  useSheet({
    container: {
      flex: "1 1 0%",
      display: "flex",
      flexDirection: "column",
      background: "#fff",
      minHeight: "200px",
    },
    row: {
      flex: "1 1 0%",
      display: "flex",
      flexDirection: "row",
      "& > div": { flex: "1 1 0%", display: "flex" },
    },
    colMargin: { margin: "6px" },

    contentCard: {
      flex: "1 1 0%",
      color: "#575757",
      display: "flex",
      padding: "12px",
      overflowY: "auto",
      flexDirection: "column",
      fontFamily: "Blogger Sans",
    },

    detailsTitle: {
      display: "block",
      fontWeight: "bold",
      textAlign: "center",
      textTransform: "uppercase",
    },

    itemTitle: { fontSize: "12px" },

    attachment: {
      marginRight: "-15px",
      border: `1px solid rgba(0, 0, 0, 0.26)`,
    },

    paymentPrices: { textTransform: "uppercase", marginTop: "12px" },

    serviceType: { color: "#bbc200" },

    listActionRoot: { zIndex: 20, marginTop: "-5px" },
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const userRoles = getUser(state).get("roles") || [];

      return {
        isAdminViewer: hasRole(userRoles, ROLE_ADMIN_VIEWER),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
);

const BATCH_JOB_ORDERS_UPDATE_DIALOG_HASH = "#BJOSUDH";
const JOB_ADD_ORDER_DIALOG_HASH = "#JAODH";

SupplierJobDetailsDialogOrders.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.instanceOf(Map).isRequired,
  list: PropTypes.instanceOf(List),
  totalCount: PropTypes.number,
  isLoading: PropTypes.bool,
  hideActualCharge: PropTypes.bool,
  replaceLocationHash: PropTypes.func,
  setLocationQuery: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  onRowSelect: PropTypes.func,
  onRequestClose: PropTypes.func,
  selectedItems: PropTypes.instanceOf(OrderedMap),
  filter: PropTypes.instanceOf(DataListFilter),

  location: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmitFail: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  setIsLoading: PropTypes.func,

  isAdminViewer: PropTypes.bool,
};

function SupplierJobDetailsDialogOrders(props) {
  const { classes, location } = props;

  const orderNumbers = props.selectedItems.toOrderedSet();
  const orderNumbersArray = orderNumbers.toArray();

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <div className={classes.colMargin}>
          <JobOrderList
            cardActionIcons={
              <div style={{ zIndex: 20, marginBottom: "-120px" }}>
                {!props.isAdminViewer && (
                  <MenuButtonMore style={{ marginRight: "-30px" }}>
                    <MenuItem
                      onClick={() =>
                        props.replaceLocationHash(
                          BATCH_JOB_ORDERS_UPDATE_DIALOG_HASH,
                        )
                      }
                    >
                      Batch Update
                    </MenuItem>
                    <MenuItemForm
                      url={CREATE_ORDER_MANIFEST_URL}
                      params={{
                        order_numbers: orderNumbersArray,
                      }}
                      primaryText="Print Manifest"
                    />
                    <MenuItem
                      onClick={() =>
                        props.replaceLocationHash(JOB_ADD_ORDER_DIALOG_HASH)
                      }
                    >
                      Download CSV
                    </MenuItem>
                  </MenuButtonMore>
                )}
              </div>
            }
            withAttempts={false}
            withPickupTime={false}
            withLastDriver={false}
            withCreatedTime={true}
            withEstDeliveryTime={false}
            withWarehouse={false}
            withLocation={false}
            withService={false}
            withAmount={true}
            withCODAmount={true}
            withDropOffCollectible={true}
            withDeliveredBy={true}
            withPickupCollectible={true}
            withPickedUpBy={true}
            list={props.list}
            totalCount={props.totalCount}
            isLoading={props.isLoading}
            filter={props.filter}
            createOrderHref={id => updateQuery(location, fp.set("view", id))}
            onFilterChange={filter => props.setLocationQueryFilter(filter)}
            selectedItems={props.selectedItems}
            onRowSelect={props.onRowSelect}
            onRequestClose={props.onRequestClose}
          />

          {location.hash === BATCH_JOB_ORDERS_UPDATE_DIALOG_HASH && (
            <SupplierBatchUpdateOrderDialogWrapper
              open={true}
              initialValues={{ orderNumbers: orderNumbersArray }}
              onRequestClose={() => props.replaceLocationHash(null)}
              onSubmitFail={props.showErrorMessage}
              onSubmitSuccess={response => {
                props.replaceLocationHash(null);
                props.setLocationQuery(fp.set("batch_id", response.data.id));
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default enhancer(SupplierJobDetailsDialogOrders);
