import { Observable } from "rxjs";
import React from "react";
import Immutable, { Map, List, fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { MenuItem, Button, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { FilterList } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { formatSupplierCODStatusCodeLocalised } from "../../helpers/OrderHelper";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { toOrderFilter } from "../../helpers/OrderFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  COD_PENDING,
  COD_COLLECTED,
  COD_WITH_WING,
  COD_TRANSFERRED,
  COD_WITH_DRIVER,
  COD_WITH_SAAS_BANK,
  COD_WITH_WING_BANK,
  COD_TO_BE_COLLECTED,
  COD_PAID_TO_MERCHANT,
  COD_TRANSFER_REJECTED,
  COD_WITHDRAW_REJECTED,
  COD_WITHDRAW_REQUESTED,
  ALL_STATUSES_IN_FINANCE,
} from "../../constants/CODStatus";
import { ORDER_LIST_URL } from "../../constants/SupplierPathConstants";
import { getMarketplace } from "../../api/shared/MarketplaceApi";
import { getOrderList } from "../../api/supplier/SupplierOrderApi";
import {
  getCODTotalCount,
  createCODTransfer,
  calculateCodTransferTotal,
} from "../../api/supplier/SupplierFinanceApi";
import { getSaaSMarketplacesList } from "../../api/supplier/SupplierMarketplacesApi";
import Toggle from "../../components/form/Toggle";
import Redirect from "../../components/router/Redirect";
import SupplierOrderFilterWrapper from "../../wrappers/supplier/SupplierOrderFilterWrapper";
import SupplierCODCollectionStatusDialogWrapper from "../../wrappers/supplier/SupplierCODCollectionStatusDialogWrapper";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import SupplierFinanceOrderListTabs from "../../components/supplier/SupplierFinanceOrderListTabs";
import OrderList, {
  SELECTION_ALL,
} from "../../components/orders-core/OrderList";
import CreateCODTransferDialog from "../../components/finance-core/CreateCODTransferDialog";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_ORDER_CSV_URL } from "../../../shared/constants/FileProxyControllerConstants";

const ALL_TAB = [
  ALL_STATUSES_IN_FINANCE,
  COD_COLLECTED,
  COD_TO_BE_COLLECTED,
  COD_WITH_WING,
  COD_WITH_WING_BANK,
  COD_WITH_SAAS_BANK,
  COD_PENDING,
  COD_PAID_TO_MERCHANT,
  COD_TRANSFERRED,
  COD_WITHDRAW_REQUESTED,
  COD_WITHDRAW_REJECTED,
  COD_TRANSFER_REJECTED,
].join(",");

const PENDING_TAB = [COD_COLLECTED, COD_PENDING].join(",");
const WITH_WING_TAB = [
  COD_WITH_WING,
  COD_WITH_WING_BANK,
  COD_PAID_TO_MERCHANT,
  COD_WITHDRAW_REQUESTED,
].join(",");

const ORDER_FILTER_DIALOG_HASH = "#OFD";
const CREATE_COD_TRANSFER_DIALOG_HASH = "#CCTD";
const COD_COLLECTION_STATUS_DIALOG_HASH = "#CCSDH";

const createOrderHref = id => updateQuery(ORDER_LIST_URL, { view: id });

const enhancer = compose(
  useSheet({
    appBarRightAction: {
      marginTop: "8px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      float: "right",
      marginLeft: "12px",
    },
  }),
  getContext({
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(propsStream => {
    const {
      stream: onRowSelectStream,
      handler: onRowSelect,
    } = createEventHandler();
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), toOrderFilter))
      .distinctUntilChanged(isEqualData);

    const listResponseStream = filterStream
      .switchMap(filter =>
        getOrderList(filter)
          .catch(error => Observable.of({ error }))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            Map({
              isLoading: response.get("pending", false),
              count: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData)
      .do(() => onRowSelect(OrderedMap()));

    const totalCountsStream = filterStream
      .map((filter: DataListFilter) => filter.setValue("cod_status", null))
      .distinctUntilChanged(isEqualData)
      .switchMap(filter =>
        getCODTotalCount(filter)
          .catch(() => Observable.of({}))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(fp.flow(fp.get("payload.data"), fp.keyBy("cod_status"), fromJS));

    const selectedNumbersStream = onRowSelectStream
      .distinctUntilChanged(isEqualData)
      .startWith(OrderedMap());

    const marketplaceStream = getMarketplace()
      .takeLast(1)
      .catch(() => Observable.of({}))
      .map(fp.flow(fp.get("payload.data"), fp.toPlainObject, Immutable.fromJS));

    const sideEffectsStream = Observable.merge(
      filterStream
        .map((filter: DataListFilter) =>
          filter.setValueMap({
            page: null,
            size: null,
            order_by: null,
            order_by_direction: null,
          }),
        )
        .distinctUntilChanged(isEqualData)
        .do(() => onRowSelect(OrderedMap())),
    ).startWith(null);

    return propsStream
      .combineLatest(
        filterStream,
        totalCountsStream,
        listResponseStream,
        selectedNumbersStream,
        marketplaceStream,
        sideEffectsStream,
        (
          props,
          filter,
          totalCounts,
          listResponse,
          selectedNumbers,
          marketplace,
        ) => ({
          ...props,
          onRequestRefresh,
          filter,
          totalCounts,
          list: listResponse.get("list") || List(),
          count: listResponse.get("count") || 0,
          isLoading: listResponse.get("isLoading"),
          onRowSelect,
          selectedNumbers,
          marketplace,
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

SupplierFinanceOrderListContainer.propTypes = {
  classes: PropTypes.object,
  replaceLocationHash: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  location: PropTypes.object,
  totalCounts: PropTypes.instanceOf(Map),
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  list: PropTypes.instanceOf(List),
  count: PropTypes.number,
  isLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  selectedNumbers: PropTypes.instanceOf(OrderedMap),
  onRowSelect: PropTypes.func,
  marketplace: PropTypes.instanceOf(Immutable.Map),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SupplierFinanceOrderListContainer(props) {
  const {
    location,
    location: { hash },
    classes,
    marketplace,
    getLocalisationMessage,
  } = props;

  const orderNumbers = props.selectedNumbers.toSet();
  const isDomestic = props.filter.getBoolValue("is_uae");

  const statusCodes = OrderedMap()
    .set(ALL_TAB, getLocalisationMessage("all", "All"))
    .set(
      COD_TO_BE_COLLECTED,
      formatSupplierCODStatusCodeLocalised(
        COD_TO_BE_COLLECTED,
        getLocalisationMessage,
      ),
    )
    .set(COD_WITH_DRIVER, getLocalisationMessage("with_driver", "With Driver"))
    .set(PENDING_TAB, getLocalisationMessage("pending", "Pending"))
    .set(
      COD_TRANSFERRED,
      formatSupplierCODStatusCodeLocalised(
        COD_TRANSFERRED,
        getLocalisationMessage,
      ),
    )
    .set(
      WITH_WING_TAB,
      getLocalisationMessage("paid_to_company", "Paid To Company"),
    );

  return (
    <SupplierAppLayout
      slug="finance_orders"
      title={getLocalisationMessage("finance_orders", "finance_orders")}
      appBarRightAction={
        <div className={classes.appBarRightAction}>
          <Toggle
            accent={true}
            value={!isDomestic}
            label={getLocalisationMessage("international", "International")}
            className={classes.appBarRightActionToggle}
            onChange={value =>
              props.setLocationQueryFilter(
                props.filter.setValue("is_uae", !value),
              )
            }
          />
        </div>
      }
    >
      <Redirect
        when={!statusCodes.has(location.query.cod_status)}
        to={updateQuery(
          location,
          fp.set("cod_status", statusCodes.keySeq().first()),
        )}
      />

      <CreateCODTransferDialog
        marketplace={marketplace}
        initialValues={{
          orderNumbers: orderNumbers.toArray(),
        }}
        open={hash === CREATE_COD_TRANSFER_DIALOG_HASH}
        calculateCodTransferTotal={calculateCodTransferTotal}
        onRequestClose={() => props.replaceLocationHash(null)}
        onSubmit={values =>
          createCODTransfer(toSnakeCase(values), getLocalisationMessage).catch(
            ResponseError.throw,
          )
        }
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
          props.showSuccessMessage(
            getLocalisationMessage(
              "cod_transfer_request_created",
              "COD Transfer Request Created",
            ),
          );
        }}
        onSubmitFailure={error => {
          props.onRequestRefresh();
          props.showErrorMessage(error);
        }}
      />

      <SupplierCODCollectionStatusDialogWrapper
        open={hash === COD_COLLECTION_STATUS_DIALOG_HASH}
        orderNumbers={orderNumbers.toArray()}
        onRequestClose={() => {
          props.replaceLocationHash(null);
          props.onRequestRefresh();
        }}
      />

      <SupplierOrderFilterWrapper
        open={hash === ORDER_FILTER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        filter={props.filter}
        showFinanceMarketplace={marketplace && marketplace.get("custom")}
        onFilterChange={filter => {
          props.replaceLocationHash(null);
          props.setLocationQueryFilter(filter);
        }}
        getMarketplace={getSaaSMarketplacesList}
      />

      <SupplierFinanceOrderListTabs
        totalCounts={props.totalCounts}
        location={location}
      />

      <OrderList
        cardActionIcons={
          <div>
            <Link to={updateHash(props.location, ORDER_FILTER_DIALOG_HASH)}>
              <Tooltip
                title={getLocalisationMessage("filter_orders", "Filter Orders")}
              >
                <IconButton
                  tooltip={getLocalisationMessage(
                    "filter_orders",
                    "Filter Orders",
                  )}
                >
                  <FilterList />
                </IconButton>
              </Tooltip>
            </Link>

            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_ORDER_CSV_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {getLocalisationMessage("download_csv", "Download CSV")}
              </MenuItem>

              <MenuItem
                onClick={() =>
                  props.replaceLocationHash(CREATE_COD_TRANSFER_DIALOG_HASH)
                }
              >
                {getLocalisationMessage(
                  "create_cod_transfer_request",
                  "Create COD Transfer Request",
                )}
              </MenuItem>

              <MenuItem
                onClick={() =>
                  props.replaceLocationHash(COD_COLLECTION_STATUS_DIALOG_HASH)
                }
              >
                {getLocalisationMessage(
                  "cod_collection_status",
                  "COD Collection Status",
                )}
              </MenuItem>
            </MenuButtonMore>
          </div>
        }
        selectionType={SELECTION_ALL}
        createOrderHref={createOrderHref}
        onFilterClick={() =>
          props.replaceLocationHash(ORDER_FILTER_DIALOG_HASH)
        }
        list={props.list}
        totalCount={props.count}
        isLoading={props.isLoading}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        selectedItems={props.selectedNumbers}
        onRowSelect={props.onRowSelect}
        altHeader={
          <div>
            <Button
              onClick={() =>
                props.replaceLocationHash(COD_COLLECTION_STATUS_DIALOG_HASH)
              }
            >
              {getLocalisationMessage(
                "cod_collection_status",
                "COD Collection Status",
              )}
            </Button>

            <Button
              onClick={() =>
                props.replaceLocationHash(CREATE_COD_TRANSFER_DIALOG_HASH)
              }
            >
              {getLocalisationMessage(
                "create_cod_transfer_request",
                "Create COD Transfer Request",
              )}
            </Button>

            <Button onClick={() => props.onRowSelect(OrderedMap())}>
              {getLocalisationMessage("clear_queue", "Clear Queue")}
            </Button>
          </div>
        }
      />
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierFinanceOrderListContainer);
