import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";
import FormSelectField from "../form/FormSelectField";
import BarChart from "../charts/Barchart";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData, isEqualWithoutFunctions } from "../../helpers/DataUtils";
import {
  formatText,
  formatNumber,
  formatPercentage,
  takeFirst20Chars,
} from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";

const BY_CITY = "BY_CITY";
const BY_SUPPLIER = "BY_SUPPLIER";

const getValues = getFormValues("MissesBarChartForm");

const enhancer = compose(
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .map(fp.flow(fp.set("kpiGroupType", BY_SUPPLIER)))
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        initialValues,
      }))
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
    },
    title: {
      fontSize: "16px",
      marginBottom: "20px",
    },
  }),
  reduxForm({
    form: "MissesBarChartForm",
    enableReinitialize: true,
  }),
  connect(state => ({
    values: getValues(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const valueStream = propsStream
      .distinctUntilKeyChanged("values", isEqualData)
      .map(props => ({
        ...props,
        filter: new DataListFilter({
          kpiGroupType: props.values.kpiGroupType,
        }).setValueMap(props.filter),
      }))
      .distinctUntilKeyChanged("filter", isEqualData)
      .switchMap(props =>
        props.kpiMissesReports(props.filter).catch(() => Observable.of({})),
      )
      .startWith(Map())
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(
                ["payload", "data", "kpi_report"],
                List(),
              ),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    const chartStream = valueStream
      .filter(reports => reports && !reports.get("pending"))
      .distinctUntilChanged(isEqualData)
      .map(reports =>
        Map().withMutations(items => {
          const labels = [];
          const ofd = [];
          const fdds = [];
          const dea = [];
          const dsr = [];
          const data = OrderedSet().asMutable();

          reports.get("payload").forEach(item => {
            labels.push(takeFirst20Chars(item.get("name")));
            ofd.push(formatNumber(item.get("ofd", 0)));
            dea.push(formatNumber(item.get("dea", 0)));
            fdds.push(formatNumber(item.get("fdds", 0)));
            dsr.push(formatNumber(item.get("dsr", 0)));
          });
          // todo props not seen here, that is why the func is null temporarily
          const getLocalisationMessage = null;

          data.add({
            label:
              (getLocalisationMessage &&
                getLocalisationMessage("ofd", "OFD")) ||
              "OFD",
            data: ofd,
            backgroundColor: "#E91E63",
          });
          data.add({
            label:
              (getLocalisationMessage &&
                getLocalisationMessage("dea", "DEA")) ||
              "DEA",
            data: dea,
            backgroundColor: "#2196F3",
          });
          data.add({
            label:
              (getLocalisationMessage &&
                getLocalisationMessage("fdds", "FDDS")) ||
              "FDDS",
            data: fdds,
            backgroundColor: "#8BC34A",
          });
          data.add({
            label:
              (getLocalisationMessage &&
                getLocalisationMessage("dsr", "DSR")) ||
              "DSR",
            data: dsr,
            backgroundColor: "#fcff1b",
          });

          items.set("chartData", Map({ labels, datasets: data }));
        }),
      );

    return propsStream
      .combineLatest(chartStream, valueStream, (props, chart) => ({
        ...props,
        chart,
      }))
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

MissesBarChartWidget.propTypes = {
  classes: PropTypes.object,
  initialValues: PropTypes.object,
  chart: PropTypes.object,
  kpiMissesReports: PropTypes.func,
  title: PropTypes.string,
  showFilter: PropTypes.bool,
  tooltipFormat: PropTypes.bool,
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func,
};

MissesBarChartWidget.defaultProps = {
  showFilter: true,
};

function MissesBarChartWidget(props) {
  const { classes, getLocalisationMessage } = props;

  const dataTypes = OrderedSet.of(BY_SUPPLIER, BY_CITY);

  return (
    <div className={classes.container}>
      <FlexBox direction="row" className={classes.title}>
        <FlexBox flex={true} align="center">
          {getLocalisationMessage("kpi", "KPI")}
        </FlexBox>
        <FlexBox flex={true} justify="flex-end">
          {props.showFilter && (
            <FormSelectField
              name="kpiGroupType"
              options={dataTypes}
              formatOption={m => getLocalisationMessage(m) || formatText(m)}
            />
          )}
        </FlexBox>
      </FlexBox>

      <FlexBox direction="column">
        {props.chart.get("chartData") && (
          <BarChart
            title={getLocalisationMessage("kpi", "KPI")}
            width={100}
            height={300}
            tooltipFormat={{
              callbacks: {
                label(tooltipItem, data) {
                  const datasetLabel =
                    data.datasets[tooltipItem.datasetIndex].label;
                  return `${datasetLabel}:  ${formatPercentage(
                    tooltipItem.yLabel,
                  )}`;
                },
              },
            }}
            data={props.chart.get("chartData").toJS()}
          />
        )}
      </FlexBox>
    </div>
  );
}

export default enhancer(MissesBarChartWidget);
