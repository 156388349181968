import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const WAREHOUSE_ITEM_URL = `${API_ROOT_URL}/supplier/warehouse/%s`;
const WAREHOUSE_CHOOSE_URL = `${API_ROOT_URL}/supplier/warehouse/choose`;

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(WAREHOUSE_ITEM_URL, id)),
});
const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(WAREHOUSE_CHOOSE_URL, { params: request.getDefinedValues() }),
});

export const getWarehouse = id => itemCache.refresh(id);
export const getCachedWarehouse = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));
export const getWarehousePredictions = (request: DataListFilter) =>
  predictionsCache.get(request);
