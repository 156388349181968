import React from "react";
import sprintf from "sprintf";
import { differenceInCalendarDays } from "date-fns";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Divider, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { red, green, orange } from "@material-ui/core/colors";
import Text, { DANGER, PRIMARY, SUCCESS, WARNING } from "../ui-core/Text";
import Audio from "../ui-core/Audio";
import FlexBox from "../ui-core/FlexBox";
import LinkButton from "../ui-core/LinkButton";
import { isEqualData } from "../../helpers/DataUtils";
import { safeParseDate } from "../../helpers/DateUtils";
import { formatText, formatDateDistanceToNow } from "../../helpers/FormatUtils";
import {
  formatCourierTypeLocalization,
  formatOrderStatusCodeForLocalisation,
} from "../../helpers/OrderHelper";
import { createOrderSortingBinCreator } from "../../helpers/OrderSortingHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import { RESIDENTIAL } from "../../constants/AddressType";
import OrderSizeCodes, {
  LARGE,
  SMALL,
  MEDIUM,
  EXTRA_LARGE,
} from "../../constants/OrderSizeCodes";
import { getStatusLocalisation } from "../../reducers/localisation/OrderStatusLocalisationReducer";
import BeepSound from "../../assets/voices/beep.wav";
import AlarmSound from "../../assets/voices/alarm.ogg";
import ErrorSound from "../../assets/voices/error.mp3";
import MultiBoxSound from "../../assets/voices/multi_box.mp3";

const formatSize = (size) => {
  switch (size) {
    case SMALL:
      return "S";
    case MEDIUM:
      return "M";
    case LARGE:
      return "L";
    case EXTRA_LARGE:
      return "XL";
    default:
      return size;
  }
};

const formatMultiBox = (pieceCount, scannedPieceCount) =>
  pieceCount > 1 ? (
    pieceCount > scannedPieceCount ? (
      <span style={{ color: orange[700] }}>MultiBox Pending</span>
    ) : (
      <span style={{ color: green[700] }}>MultiBox Complete</span>
    )
  ) : (
    <span style={{ color: green[700] }}>Single</span>
  );

const enhancer = compose(
  connect((state) => {
    const statusMessages = getStatusLocalisation(state);

    return {
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
      statusMessages,
    };
  }),
  useSheet({
    orderStatus: {
      fontSize: "16px",
      lineHeight: "24px",
      "& > strong": {
        fontSize: "24px",
        display: "block",
      },
      "& > span": {
        fontWeight: "bold",
        color: red[400],
        textTransform: "uppercase",
      },
    },
    orderDetails: {
      display: "none",
    },
    orderDetailsMobile: {
      fontSize: "20px",
      lineHeight: "initial",
      "& > div": {
        marginBottom: "15px",
      },
      "& h3": {
        fontSize: "16px",
      },
      "& h4": {
        fontSize: "14px",
        display: "block",
        lineHeight: "20px",
      },
    },
    "@media (min-width: 997px)": {
      orderStatus: {
        fontSize: "34px",
        lineHeight: "initial",
        "& > strong": {
          fontSize: "34px",
          display: "initial",
        },
        "& > span": {
          fontWeight: "bold",
          color: red[400],
          textTransform: "uppercase",
        },
      },
      orderDetails: { display: "flex" },
      orderDetailsMobile: { display: "none" },
    },
    "@media (max-width: 998px)": {
      orderBins: {
        fontSize: "20px",
        lineHeight: "initial",
        "& > strong": {
          fontSize: "34px",
          display: "block",
        },
      },
    },
  }),
  mapPropsStream((propsStream) => {
    const stateStream = propsStream
      .map(fp.pick(["orderNumber", "binRules", "allOrders", "queueOrders"]))
      .distinctUntilChanged(isEqualData)
      .map((props) => {
        const queueOrder = props.queueOrders.get(props.orderNumber);

        const state = {};

        if (queueOrder) {
          state.bin = queueOrder.get("bin");
          state.order = queueOrder.get("info");
          state.failed = queueOrder.get("failed");
          state.scannedAsBox = queueOrder.get("scanned_as_box");
        }

        if (!state.failed && !state.order) {
          state.order = props.allOrders.get(props.orderNumber);
        }

        if (state.order) {
          const promiseDate = safeParseDate(state.order.get("promise_date"));

          state.addressType = state.order.get("address_type");

          state.multiBox = formatMultiBox(
            state.order.get("piece_count"),
            state.order.get("scanned_piece_count"),
          );

          state.isMultiBox =
            state.order.get("piece_count") > 1
              ? state.order.get("piece_count") >
                state.order.get("scanned_piece_count")
                ? 1
                : 0
              : -1;

          state.multiBoxButShipmentScanned =
            !state.scannedAsBox && state.isMultiBox > -1;

          if (promiseDate) {
            const diffDate = differenceInCalendarDays(promiseDate, new Date());

            if (diffDate <= 1) {
              state.alarmPromiseDate = true;
            }

            if (diffDate <= 2) {
              state.showPromiseDate =
                diffDate === -1
                  ? "Yesterday"
                  : diffDate === 1
                  ? "Tomorrow"
                  : diffDate === 0
                  ? "Today"
                  : diffDate;
            }
          }
        }

        if (state.order && !state.bin) {
          const createBin = createOrderSortingBinCreator(props.binRules);

          state.bin = createBin(state.order);
        }

        return state;
      });

    return propsStream.combineLatest(stateStream, (props, state) => ({
      ...props,
      ...state,
    }));
  }),
);

OrderSortingCard.propTypes = {
  classes: PropTypes.object,

  bin: PropTypes.string,
  failed: PropTypes.bool,
  multiBoxButShipmentScanned: PropTypes.bool,
  addressType: PropTypes.string,
  multiBox: PropTypes.node,
  isMultiBox: PropTypes.number,
  alarmPromiseDate: PropTypes.bool,
  order: PropTypes.instanceOf(Map),
  showPromiseDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  onReloadClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,

  onSizeChange: PropTypes.func.isRequired,

  orderNumber: PropTypes.string.isRequired,
  binRules: PropTypes.instanceOf(Map).isRequired,
  allOrders: PropTypes.instanceOf(Map).isRequired,
  queueOrders: PropTypes.instanceOf(Map).isRequired,
  getLocalisationMessage: PropTypes.func,
  statusMessages: PropTypes.instanceOf(Map),
};

function OrderSortingCard(props) {
  const { order, showPromiseDate, classes, getLocalisationMessage } = props;

  let orderKeyNumber = props.orderNumber;

  if (props.isMultiBox === 1 && order) {
    orderKeyNumber = sprintf(
      "%s-%s-%s",
      props.orderNumber,
      order.get("scanned_piece_count", 0),
      order.get("piece_count", 0),
    );
  }

  const OrderSizeButtons =
    order && !props.failed
      ? OrderSizeCodes.map((size) => (
          <FlexBox key={size}>
            <Button
              onClick={() => props.onSizeChange(size)}
              color={order.get("size", null) === size ? "secondary" : "default"}
              variant="contained"
            >
              {" "}
              {formatSize(size)}{" "}
            </Button>
          </FlexBox>
        )).toArray()
      : null;

  return (
    <FlexBox container={8} direction="column">
      {Boolean(order || props.failed) && (
        <Audio
          play={true}
          key={orderKeyNumber}
          src={
            props.failed
              ? ErrorSound
              : props.isMultiBox === 1 || props.multiBoxButShipmentScanned
              ? MultiBoxSound
              : props.alarmPromiseDate
              ? AlarmSound
              : BeepSound
          }
        />
      )}

      <br />

      {props.failed ? (
        <Text element="h3" type="align-center" className={classes.orderStatus}>
          <strong>{props.orderNumber}</strong>{" "}
          {getLocalisationMessage("loading_failed")}. (
          <LinkButton onClick={props.onReloadClick}>
            {getLocalisationMessage("reload")}
          </LinkButton>{" "}
          /{" "}
          <LinkButton onClick={props.onRemoveClick}>
            {getLocalisationMessage("remove")}
          </LinkButton>
          )
        </Text>
      ) : !props.order ? (
        <Text element="h3" type="align-center" className={classes.orderStatus}>
          <strong>{props.orderNumber}</strong>{" "}
          {getLocalisationMessage("loading")}
        </Text>
      ) : props.multiBoxButShipmentScanned ? (
        <Text element="h3" type="align-center" className={classes.orderStatus}>
          <span>{getLocalisationMessage("warning")}:</span>{" "}
          <strong>{props.orderNumber}</strong>{" "}
          {getLocalisationMessage("is", "is")}{" "}
          <span>{getLocalisationMessage("multi_box", "MultiBox")}</span>.
        </Text>
      ) : (
        <FlexBox flex={true} direction="column">
          <Text element="h2" type="align-center" className={classes.orderBins}>
            <strong>{props.bin} </strong>
            <Text type={showPromiseDate < 2 ? DANGER : WARNING}>
              {showPromiseDate < -1 || showPromiseDate > 1
                ? ` ${
                    Math.sign(showPromiseDate) === 1
                      ? `+${showPromiseDate}`
                      : showPromiseDate
                  } ${getLocalisationMessage("days") || "days"}`
                : showPromiseDate || ""}
            </Text>{" "}
            {props.addressType && (
              <Text
                type={props.addressType === RESIDENTIAL ? SUCCESS : PRIMARY}
              >
                {getLocalisationMessage(props.addressType) ||
                  formatText(props.addressType)}
              </Text>
            )}{" "}
            {props.isMultiBox > -1 && (
              <Text>
                {props.multiBox}{" "}
                {`${order.get("scanned_piece_count")}/${order.get(
                  "piece_count",
                )}`}
              </Text>
            )}
          </Text>

          <br />

          <Divider />

          <br />

          <FlexBox gutter={8} flex={true} className={classes.orderDetails}>
            <FlexBox direction="column" flex={true}>
              <h4>{getLocalisationMessage("city")}:</h4>
              <h3>
                <strong>
                  {order.getIn(["package", "to_city"]) ||
                    getLocalisationMessage("na")}
                </strong>
              </h3>

              <br />

              <h5>{getLocalisationMessage("status")}:</h5>
              <h4>
                <strong>
                  {formatOrderStatusCodeForLocalisation(
                    order.get("status"),
                    getLocalisationMessage,
                  )}
                </strong>
                <br />
                {formatDateDistanceToNow(
                  order.getIn(["last_status_date"]),
                  null,
                  getLocalisationMessage,
                )}
              </h4>
            </FlexBox>

            <FlexBox direction="column" flex={true}>
              <h4>{getLocalisationMessage("supplier")}:</h4>
              <h3>
                <strong>
                  {order.getIn(["supplier", "name"]) ||
                    getLocalisationMessage("na")}
                </strong>
              </h3>

              <br />

              <h5>{getLocalisationMessage("service_type")}:</h5>
              <h4>
                <strong>
                  {formatCourierTypeLocalization(
                    order.getIn(["package", "courier_type"]),
                    getLocalisationMessage,
                  )}
                </strong>
              </h4>
            </FlexBox>

            <FlexBox direction="column" flex={true}>
              <h4>{getLocalisationMessage("warehouse")}:</h4>
              <h3>
                <strong>
                  {order.getIn(
                    ["destination_warehouse", "name"],
                    getLocalisationMessage("na"),
                  )}
                </strong>
              </h3>

              <br />

              <h5>{getLocalisationMessage("current_warehouse")}:</h5>
              <h4>
                <strong>
                  {order.getIn(
                    ["warehouse", "name"],
                    getLocalisationMessage("na"),
                  )}
                </strong>
              </h4>
            </FlexBox>

            <FlexBox direction="column" flex={true}>
              <h4>{getLocalisationMessage("assigned_to")}:</h4>
              <h3>
                <strong>
                  {order.getIn(
                    ["last_mile_driver", "name"],
                    getLocalisationMessage("na"),
                  )}
                </strong>
              </h3>

              <br />

              <h5>{getLocalisationMessage("currently_with")}:</h5>
              <h4>
                <strong>
                  {order.getIn(
                    ["driver", "name"],
                    getLocalisationMessage("na"),
                  )}
                </strong>
              </h4>

              <br />

              <FlexBox gutter={8} wrap={true}>
                {OrderSizeButtons}
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox
            flex={true}
            direction="column"
            className={classes.orderDetailsMobile}
          >
            <FlexBox direction="row" flex={true}>
              <FlexBox justify="flex-start" direction="column" flex={true}>
                <h4>{getLocalisationMessage("city")}:</h4>
                <h3>
                  <strong>
                    {order.getIn(["package", "to_city"]) ||
                      getLocalisationMessage("na")}
                  </strong>
                </h3>
              </FlexBox>
              <FlexBox justify="flex-start" direction="column" flex={true}>
                <h4>{getLocalisationMessage("warehouse")}:</h4>
                <h3>
                  <strong>
                    {order.getIn(
                      ["destination_warehouse", "name"],
                      getLocalisationMessage("na"),
                    )}
                  </strong>
                </h3>
              </FlexBox>
            </FlexBox>

            <FlexBox direction="row" flex={true}>
              <FlexBox justify="flex-start" direction="column" flex={true}>
                <h4>{getLocalisationMessage("supplier")}:</h4>
                <h3>
                  <strong>
                    {order.getIn(["supplier", "name"]) ||
                      getLocalisationMessage("na")}
                  </strong>
                </h3>
              </FlexBox>
              <FlexBox justify="flex-start" direction="column" flex={true}>
                <h4>{getLocalisationMessage("service_type")}:</h4>
                <h3>
                  <strong>
                    {formatCourierTypeLocalization(
                      order.getIn(["package", "courier_type"]),
                      getLocalisationMessage,
                    )}
                  </strong>
                </h3>
              </FlexBox>
            </FlexBox>

            <FlexBox direction="row" flex={true}>
              <FlexBox justify="flex-start" direction="column" flex={true}>
                <h4>{getLocalisationMessage("status")}:</h4>
                <h3>
                  <strong>
                    {formatOrderStatusCodeForLocalisation(
                      order.get("status"),
                      getLocalisationMessage,
                    )}
                  </strong>
                </h3>
              </FlexBox>
              <FlexBox
                style={{ textAlign: "right" }}
                direction="column"
                flex={true}
              >
                <h4 style={{ marginTop: "22px" }}>
                  {formatDateDistanceToNow(
                    order.getIn(["last_status_date"]),
                    null,
                    getLocalisationMessage,
                  )}
                </h4>
              </FlexBox>
            </FlexBox>

            <FlexBox direction="row" flex={true}>
              <FlexBox justify="flex-start" direction="column" flex={true}>
                <h4>{getLocalisationMessage("assigned_to")}:</h4>
                <h3>
                  <strong>
                    {order.getIn(
                      ["last_mile_driver", "name"],
                      getLocalisationMessage("na"),
                    )}
                  </strong>
                </h3>
              </FlexBox>
              <FlexBox direction="column" flex={true}>
                <h4>{getLocalisationMessage("currently_with")}:</h4>
                <h3>
                  <strong>
                    {order.getIn(
                      ["driver", "name"],
                      getLocalisationMessage("na"),
                    )}
                  </strong>
                </h3>
              </FlexBox>
            </FlexBox>

            <FlexBox flex={true}>{OrderSizeButtons}</FlexBox>
          </FlexBox>
        </FlexBox>
      )}
    </FlexBox>
  );
}

export default enhancer(OrderSortingCard);
