import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import OrderCreateWizardStepDetails from "../internal/OrderCreateWizardStepDetails";
import { getMessage } from "../../../reducers/LocalizationReducer";
import timeslot from "./assets/timeslot.png";

const valueSelector = formValueSelector("OrderCreateWizardSupplier");

const enhancer = compose(
  useSheet({
    text: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  }),
  connect(state => ({
    values: valueSelector(state, "pickupTimeSlot", "deliveryTimeSlot"),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

OrderCreateWizardStepWhenDetails.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardStepWhenDetails(props) {
  const { classes, values, getLocalisationMessage } = props;

  return (
    <OrderCreateWizardStepDetails
      label={getLocalisationMessage("when", "When")}
      onClick={props.onClick}
      avatar={
        <Avatar
          src={timeslot}
          style={{
            backgroundColor: "transparent",
          }}
        />
      }
      primaryText={
        <div className={classes.text}>
          <div>
            {values.pickupTimeSlot &&
              getLocalisationMessage("to_pickup", "To Pickup")}
          </div>
          <div>
            {values.deliveryTimeSlot &&
              getLocalisationMessage("to_delivery", "To Delivery")}
          </div>
        </div>
      }
      secondaryText={
        <div className={classes.text}>
          <div>
            {values.pickupTimeSlot && values.pickupTimeSlot.get("name")}
          </div>
          <div>
            {values.deliveryTimeSlot && values.deliveryTimeSlot.get("name")}
          </div>
        </div>
      }
    />
  );
}

export default enhancer(OrderCreateWizardStepWhenDetails);
