import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessages } from "../../reducers/LocalizationReducer";
import { PENDING } from "../../constants/CODTransferStatus";
import {
  getCODTransfer,
  getCODTransferOrders,
  updateCODTransferReference,
} from "../../api/supplier/SupplierFinanceApi";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import OrderList from "../../components/orders-core/OrderList";
import CODTransferDetails from "../../components/finance-core/CODTransferDetails";
import UpdateCODReferenceDialog from "../../components/finance-core/UpdateCODReferenceDialog";

const UPDATE_REFERENCE_DIALOG_HASH = "#URDH";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  getContext({
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const idStream = propsStream
      .map(fp.flow(fp.get("params.id"), fp.toFinite))
      .distinctUntilChanged();

    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), DataListFilter.create))
      .distinctUntilChanged(isEqualData);

    const itemResponseStream = idStream
      .switchMap(id =>
        getCODTransfer(id)
          .catch(() => Observable.of({}))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], Map()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    const ordersResponseStream = idStream
      .combineLatest(filterStream)
      .switchMap(([id, filter]) =>
        getCODTransferOrders(id, filter)
          .catch(() => Observable.of({}))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        filterStream,
        itemResponseStream,
        ordersResponseStream,
        (props, filter, itemResponse, ordersResponse) => ({
          ...props,
          filter,
          onRequestRefresh,
          item: itemResponse.get("payload"),
          isLoading: itemResponse.get("pending"),
          orders: ordersResponse.get("list"),
          ordersTotal: ordersResponse.get("total"),
          ordersLoading: ordersResponse.get("pending"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

SupplierCODTransferItemContainer.propTypes = {
  location: PropTypes.object,
  item: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  ordersTotal: PropTypes.number,
  ordersLoading: PropTypes.bool,
  orders: PropTypes.instanceOf(List),
  onRequestRefresh: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  i18n: PropTypes.instanceOf(Map),
};

function SupplierCODTransferItemContainer(props) {
  return (
    <SupplierAppLayout title={props.i18n.get("cod_transfer", "COD Transfer")}>
      <PageLoading isLoading={props.isLoading} />

      <UpdateCODReferenceDialog
        open={props.location.hash === UPDATE_REFERENCE_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        initialValues={{ reference: props.item.get("reference") }}
        onSubmit={fp.flow(toSnakeCase, values =>
          updateCODTransferReference(props.item.get("id"), values).catch(
            ResponseError.throw,
          ),
        )}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
        }}
        onSubmitFailure={() => props.onRequestRefresh()}
      />

      <FlexBox flex={true} direction="column" gutter={8}>
        {!props.isLoading && (
          <FlexBox direction="column">
            <CODTransferDetails
              item={props.item}
              onEditRefClick={
                props.item.get("status") === PENDING
                  ? null
                  : () =>
                      props.replaceLocationHash(UPDATE_REFERENCE_DIALOG_HASH)
              }
            />
          </FlexBox>
        )}

        <FlexBox flex={true} direction="column">
          <FlexBox flex={true} element={<Paper />}>
            <OrderList
              withShipmentNumber={false}
              withCODAmount={true}
              withReferenceId={false}
              withAmount={false}
              withCustomer={false}
              withWarehouse={false}
              withLocation={false}
              withService={false}
              maxSearchItems={0}
              withAttempts={false}
              withPickupTime={false}
              withLastDriver={false}
              withCreatedTime={false}
              withEstDeliveryTime={false}
              list={props.orders}
              totalCount={props.ordersTotal}
              isLoading={props.ordersLoading}
              filter={props.filter}
              onFilterChange={filter => props.setLocationQueryFilter(filter)}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierCODTransferItemContainer);
