import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { RECEIVED } from "../../constants/CODCollectionStatuses";
import {
  getOrdersCODTotal,
  updateCODCollectionStatus,
} from "../../api/supplier/SupplierOrderApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import PriceWrapper from "../../components/ui-core/PriceWrapper";
import CODCollectionStatusForm from "../../components/finance-core/CODCollectionStatusForm";

const enhancer = compose(
  renderIf("open"),
  useSheet({
    paper: { minWidth: "800px", maxWidth: "800px" },
  }),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onCalculateCODTotal,
      stream: onCalculateCODTotalStream,
    } = createEventHandler();

    const ordersTotalCountStream = propsStream
      .map(fp.get("orderNumbers"))
      .merge(onCalculateCODTotalStream.map(fp.get("orderNumbers")))
      .switchMap(
        orderNumbers =>
          !orderNumbers.length
            ? Observable.of(null)
            : getOrdersCODTotal({ order_numbers: orderNumbers }).catch(error =>
                Observable.of({ error }),
              ),
      )
      .map(fp.flow(fp.get("data"), fromJS));

    return propsStream
      .combineLatest(ordersTotalCountStream, (props, totalCount) => ({
        ...props,
        onCalculateCODTotal,
        totalCount,
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

SupplierCODCollectionStatusDialogWrapper.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  orderNumbers: PropTypes.array.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onCalculateCODTotal: PropTypes.func,
  totalCount: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SupplierCODCollectionStatusDialogWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={true}
      paperClassName={classes.paper}
      onRequestClose={props.onRequestClose}
      title={
        <span>
          {props.orderNumbers.length}{" "}
          {getLocalisationMessage(
            "orders_for_cod_collection_total_amount",
            "orders for COD collection. Total Amount:",
          )}{" "}
          {props.totalCount ? (
            <PriceWrapper
              price={props.totalCount.get("total", 0)}
              code={props.totalCount.getIn(["currency", "code"])}
            />
          ) : (
            getLocalisationMessage("calculating", "Calculating")
          )}
        </span>
      }
    >
      <CODCollectionStatusForm
        initialValues={{
          orderNumbers: props.orderNumbers,
          collectionStatus: RECEIVED,
        }}
        onChange={props.onCalculateCODTotal}
        onDismiss={props.onRequestClose}
        onSubmit={values =>
          updateCODCollectionStatus(toSnakeCase(values)).catch(
            ResponseError.throw,
          )
        }
        onSubmitFail={props.showErrorMessage}
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            getLocalisationMessage(
              "cod_collection_status_successfully_saved",
              "COD Collection Status Successfully Saved",
            ),
          );
          props.onRequestClose();
        }}
      />
    </ModalPaper>
  );
}

export default enhancer(SupplierCODCollectionStatusDialogWrapper);
