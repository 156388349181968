import React from "react";
import { List, Map, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { IconButton, MenuItem, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { FilterList } from "@material-ui/icons";
import DataListFilter from "../../helpers/DataListFilter";
import { mapOrderListPropsStream } from "../../helpers/OrderListHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getOrderList } from "../../api/supplier/SupplierOrderApi";
import Toggle from "../../components/form/Toggle";
import Redirect from "../../components/router/Redirect";
import SupplierOrderFilterWrapper from "../../wrappers/supplier/SupplierOrderFilterWrapper";
import SupplierOrderEditDialogWrapper from "../../wrappers/supplier/SupplierOrderEditDialogWrapper";
import SupplierOrderDetailsDialogWrapper from "../../wrappers/supplier/SupplierOrderDetailsDialogWrapper";
import SupplierBatchOrderNoteDialogWrapper from "../../wrappers/supplier/SupplierBatchOrderNoteDialogWrapper";
import SupplierBatchUpdateJobDialogWrapper from "../../wrappers/supplier/SupplierBatchUpdateJobDialogWrapper";
import SupplierOrderJobDetailsDialogWrapper from "../../wrappers/supplier/SupplierOrderJobDetailsDialogWrapper";
import SupplierBatchUpdateOrderDialogWrapper from "../../wrappers/supplier/SupplierBatchUpdateOrderDialogWrapper";
import SupplierBatchUpdatesItemDialogWrapper from "../../wrappers/supplier/SupplierBatchUpdatesItemDialogWrapper";
import SupplierOverweightUploadDialogWrapper from "../../wrappers/supplier/SupplierOverweightUploadDialogWrapper";
import SupplierBatchRescheduleOrderDialogWrapper from "../../wrappers/supplier/SupplierBatchRescheduleOrderDialogWrapper";
import FlexBox from "../../components/ui-core/FlexBox";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import JobList from "../../components/orders-core/JobList";
import OrderListJobTabs from "../../components/orders-core/OrderListJobTabs";
import OrderAWBManifestPopover from "../../components/orders-core/OrderAWBManifestPopover";
import OrderListErrorNotification from "../../components/orders-core/OrderListErrorNotification";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_ORDER_CSV_URL } from "../../../shared/constants/FileProxyControllerConstants";

const ORDER_FILTER_DIALOG_HASH = "#OFD";
const UPLOAD_OVERWEIGHT_CSV_DIALOG_HASH = "#UOCDH";
const BATCH_ORDERS_RESCHEDULE_DIALOG_HASH = "#BORDH";
const BATCH_CREATE_ORDER_NOTES_DIALOG_HASH = "#BCONDH";
const BATCH_ASYNC_ORDERS_UPDATE_DIALOG_HASH = "#BAOUDH";
const BATCH_ORDERS_UPDATE_DIALOG_HASH = "#BOUDH";

const domesticOrders = Map({ is_uae: true });
const internationalOrders = Map({ is_uae: false });
const notSolr = Map({ use_solr: false });
const fromSolr = Map({ use_solr: true });

const enhancer = compose(
  useSheet({
    appBarRightAction: { height: "100%" },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      float: "right",
      marginLeft: "12px",
    },
  }),
  getContext({
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(
    mapOrderListPropsStream(
      getOrderList,
      (
        props,
        filter,
        response,
        onRowSelect,
        selectedItems,
        onRequestRefresh,
        batchGetOrderListCount,
      ) => ({
        ...props,
        filter,
        onRowSelect,
        selectedItems,
        onRequestRefresh,
        batchGetOrderListCount,
        list: response.get("list"),
        total: response.get("total"),
        error: response.get("error"),
        isLoading: response.get("isLoading"),
      }),
    ),
  ),
);

SupplierJobOrderList.propTypes = {
  sheet: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  location: PropTypes.object,
  replaceLocationHash: PropTypes.func,
  setLocationQuery: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  onRequestRefresh: PropTypes.func,
  batchGetOrderListCount: PropTypes.func,
  isLoading: PropTypes.bool,
  total: PropTypes.number,
  list: PropTypes.instanceOf(List),
  error: PropTypes.instanceOf(Error),
  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(OrderedMap),
  getLocalisationMessage: PropTypes.func,
};

function SupplierJobOrderList(props) {
  const searchTypes = OrderedMap()
    .set(
      "order_number",
      props.getLocalisationMessage("order_numbers", "Order Numbers"),
    )
    .set(
      "pickup,delivery",
      props.getLocalisationMessage("all_types", "All Types"),
    )
    .set(
      "pickup",
      props.getLocalisationMessage("pick_up_types", "Pick Up Types"),
    )
    .set(
      "delivery",
      props.getLocalisationMessage("delivery_types", "Delivery Types"),
    );

  const {
    sheet: { classes },
    location,
    getLocalisationMessage,
  } = props;
  const isDomestic = props.filter.getBoolValue("is_uae");
  const useSolr = props.filter.getBoolValue("use_solr");
  const orderNumbers = props.selectedItems.toOrderedSet();
  const selectedIds = props.selectedItems.keySeq().toArray();
  const orderNumbersArray = orderNumbers.toArray();

  return (
    <SupplierAppLayout
      slug="jobs"
      title={getLocalisationMessage("jobs", "Jobs")}
      appBarRightAction={
        <FlexBox align="center" className={classes.appBarRightAction}>
          <Toggle
            accent={true}
            label={getLocalisationMessage("international", "International")}
            className={classes.appBarRightActionToggle}
            value={!isDomestic}
            onChange={() =>
              props.setLocationQueryFilter(
                props.filter.setValueMap(
                  isDomestic ? internationalOrders : domesticOrders,
                ),
              )
            }
          />

          <Toggle
            accent={true}
            label={getLocalisationMessage("fast_search", "Fast Search")}
            className={classes.appBarRightActionToggle}
            value={useSolr}
            onChange={() =>
              props.setLocationQueryFilter(
                props.filter.setValueMap(useSolr ? notSolr : fromSolr),
              )
            }
          />
        </FlexBox>
      }
    >
      <Redirect
        when={!searchTypes.has(location.query.types)}
        to={updateQuery(location, fp.set("types", "pickup,delivery"))}
      />

      <OrderListErrorNotification
        error={props.error}
        onFixSizeError={() =>
          props.setLocationQueryFilter(props.filter.setPageSize(0))
        }
      />

      <SupplierOrderFilterWrapper
        filter={props.filter}
        onFilterChange={filter => {
          props.replaceLocationHash(null);
          props.setLocationQueryFilter(filter);
        }}
        open={location.hash === ORDER_FILTER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <SupplierOverweightUploadDialogWrapper
        open={location.hash === UPLOAD_OVERWEIGHT_CSV_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />
      <SupplierBatchUpdatesItemDialogWrapper
        batchId={fp.toFinite(location.query.batch_id)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("batch_id"));
        }}
        onShowOrdersClick={numbers => {
          props.replaceLocationHash(null);
          props.setLocationQuery({ search: numbers.join(",") });
        }}
      />

      <SupplierOrderDetailsDialogWrapper
        tab={location.query.view_tab}
        orderId={fp.toFinite(location.query.view)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view", "view_tab"]))
        }
        onEditClick={() =>
          props.setLocationQuery(
            fp.flow(fp.unset("view"), fp.set("edit", location.query.view)),
          )
        }
        createDriverSuggestHref={() =>
          props.setLocationQuery(fp.flow(fp.set("driver_view", true)))
        }
        location={location}
      />

      <SupplierOrderJobDetailsDialogWrapper
        tab={location.query.view_tab}
        location={location}
        orderId={fp.toFinite(location.query.view_job)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestRefresh={props.onRequestRefresh}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view_job", "view_tab"]))
        }
        onEditClick={() =>
          props.setLocationQuery(
            fp.flow(
              fp.unset("view_job"),
              fp.set("edit", location.query.view_job),
            ),
          )
        }
      />

      <SupplierOrderEditDialogWrapper
        orderId={fp.toFinite(location.query.edit)}
        onRequestClose={() =>
          props.setLocationQuery(
            fp.flow(fp.unset("edit"), fp.set("view_job", location.query.edit)),
          )
        }
      />

      <SupplierBatchUpdateOrderDialogWrapper
        open={location.hash === BATCH_ASYNC_ORDERS_UPDATE_DIALOG_HASH}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
        }}
        initialValues={{ orderNumbers: orderNumbersArray }}
        onSubmitSuccess={response => {
          props.replaceLocationHash(null);
          props.setLocationQuery(fp.set("batch_id", response.data.id));
        }}
        onSubmitFail={props.showErrorMessage}
      />

      {location.hash === BATCH_ORDERS_UPDATE_DIALOG_HASH && (
        <SupplierBatchUpdateJobDialogWrapper
          open={true}
          initialValues={{ orderNumbers: orderNumbersArray }}
          onRequestClose={() => props.replaceLocationHash(null)}
          onSubmitFail={props.showErrorMessage}
          onSubmitSuccess={response => {
            props.replaceLocationHash(null);
            props.setLocationQuery(fp.set("batch_id", response.data.id));
          }}
        />
      )}

      <SupplierBatchRescheduleOrderDialogWrapper
        initialValues={{ orderNumbers: orderNumbersArray }}
        open={location.hash === BATCH_ORDERS_RESCHEDULE_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_rescheduled_orders",
              "Successfully Rescheduled Orders",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
      />

      <SupplierBatchOrderNoteDialogWrapper
        open={location.hash === BATCH_CREATE_ORDER_NOTES_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
        initialValues={{ orderNumbers: orderNumbersArray }}
        onSubmitSuccess={() => {
          props.onRequestRefresh();
          props.replaceLocationHash(null);
          props.showSuccessMessage(
            getLocalisationMessage(
              "successfully_updated_order_notes",
              "Successfully Updated Order Notes",
            ),
          );
        }}
        onSubmitFail={props.showErrorMessage}
      />

      <OrderListJobTabs
        filter={props.filter}
        basePathname={location.pathname}
        batchGetOrderListCount={props.batchGetOrderListCount}
      />

      <JobList
        altHeader={
          <FlexBox>
            <FlexBox>
              <OrderAWBManifestPopover
                label={getLocalisationMessage("awb", "AWB")}
                withAWB={true}
                ids={selectedIds}
                withAWBZLabel={true}
                withAWBCompact={true}
              />
            </FlexBox>

            <FlexBox>
              <OrderAWBManifestPopover
                label={getLocalisationMessage("manifest", "Manifest")}
                ids={selectedIds}
                withManifest={true}
                withManifestCompact={true}
              />
            </FlexBox>
          </FlexBox>
        }
        cardActionIcons={
          <div>
            <Link to={updateHash(props.location, ORDER_FILTER_DIALOG_HASH)}>
              <Tooltip
                title={getLocalisationMessage("filter_orders", "Filter Orders")}
              >
                <IconButton>
                  <FilterList />
                </IconButton>
              </Tooltip>
            </Link>
            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_ORDER_CSV_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {getLocalisationMessage(
                  "download_csv_old",
                  "Download CSV (OLD)",
                )}
              </MenuItem>

              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_ORDER_CSV_URL,
                  props.filter.setValue("api_version", "v2").getDefinedValues(),
                )}
              >
                {getLocalisationMessage(
                  "download_csv_new",
                  "Download CSV (NEW)",
                )}
              </MenuItem>

              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(CREATE_ORDER_CSV_URL, {
                  ...props.filter.getDefinedValues(),
                  is_note: true,
                })}
              >
                {getLocalisationMessage(
                  "download_note_csv",
                  "Download Note CSV",
                )}
              </MenuItem>

              <MenuItem
                onClick={() =>
                  props.replaceLocationHash(
                    BATCH_ASYNC_ORDERS_UPDATE_DIALOG_HASH,
                  )
                }
              >
                {getLocalisationMessage(
                  "batch_orders_update",
                  "Batch Orders Update",
                )}
              </MenuItem>

              <MenuItem
                onClick={() =>
                  props.replaceLocationHash(BATCH_ORDERS_RESCHEDULE_DIALOG_HASH)
                }
              >
                {getLocalisationMessage(
                  "batch_orders_reschedule",
                  "Batch Orders Reschedule",
                )}
              </MenuItem>

              <MenuItem
                onClick={() =>
                  props.replaceLocationHash(
                    BATCH_CREATE_ORDER_NOTES_DIALOG_HASH,
                  )
                }
              >
                {getLocalisationMessage(
                  "batch_create_order_notes",
                  "Batch Create Order Notes",
                )}
              </MenuItem>
            </MenuButtonMore>
          </div>
        }
        withCODStatus={false}
        withLastDriver={false}
        withMerchantPromiseDate={true}
        onRowSelect={props.onRowSelect}
        selectedItems={props.selectedItems}
        filter={props.filter}
        searchTypes={searchTypes}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        list={props.list}
        totalCount={props.total}
        isLoading={props.isLoading}
        onFilterClick={() =>
          props.replaceLocationHash(ORDER_FILTER_DIALOG_HASH)
        }
        createOrderHref={id => updateQuery(location, fp.set("view_job", id))}
      />
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierJobOrderList);
