import { Observable } from "rxjs";
import React from "react";
import { isAfter, isBefore, format as dateFormat } from "date-fns";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, getFormValues } from "redux-form";
import { Card } from "@material-ui/core";
import { connect } from "react-redux";
import LineChart from "../charts/FilledLineChart";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDate } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";

/*
const LAST_WEEK = 7;
const LAST_15_DAYS = 15;
const LAST_30_DAYS = 30;
*/

const getValues = getFormValues("AttemptsChartForm");

/* function formatDaysIntervalCodes(code, getLocalisationMessage) {
  switch (code) {
    case LAST_WEEK:
      return getLocalisationMessage("last_week", "Last Week");
    case LAST_15_DAYS:
      return getLocalisationMessage("last_15_days", "Last 15 Days");
    case LAST_30_DAYS:
      return getLocalisationMessage("last_30_days", "Last 30 Days");
    default:
      return getLocalisationMessage("last_15_days", "Last 15 days");
  }
} */

const formatDayMonthYear = (year, month, day) =>
  dateFormat(new Date(year, month, day), "dd MMM yyyy");

const enhancer = compose(
  /* mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .map(fp.flow(fp.set("limitDays", LAST_15_DAYS)))
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,
        initialValues,
      }))
      .distinctUntilChanged(isEqualWithoutFunctions);
  }), */
  reduxForm({
    form: "AttemptsChartForm",
    enableReinitialize: true,
  }),
  connect(state => ({
    values: getValues(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const valueStream = propsStream
      // .distinctUntilKeyChanged("values", isEqualData)
      // .map(props => ({
      //   ...props,
      //   filter: new DataListFilter({
      //     limit_days: props.values.limitDays,
      //   }).setValueMap(props.filter),
      // }))
      .distinctUntilKeyChanged("filter", isEqualData)
      .switchMap(props =>
        props.getReports(props.filter).catch(() => Observable.of({})),
      )
      .startWith(Map())
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    const statsStream = valueStream
      .filter(reports => reports && !reports.get("pending"))
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .map(([reports, props]) => canvas => {
        const labels = [];
        const dispatches = [];
        const attempts = [];

        const ctx = canvas.getContext("2d");
        const gradient1 = ctx.createLinearGradient(0, 0, 0, 400);
        gradient1.addColorStop(0, "rgba(33,150,243 ,0.9)");
        gradient1.addColorStop(1, "rgba(100,181,246 ,0.5)");

        const gradient2 = ctx.createLinearGradient(0, 0, 0, 400);
        gradient2.addColorStop(0, "rgba(233,30,99 ,0.9)");
        gradient2.addColorStop(1, "rgba(240,98,146 ,0.5)");

        const sortedReports = reports.get("payload").sort((a, b) => {
          const aDate = new Date(
            a.get("year"),
            a.get("month") - 1,
            a.get("day"),
          );

          const bDate = new Date(
            b.get("year"),
            b.get("month") - 1,
            b.get("day"),
          );

          if (isAfter(aDate, bDate)) return 1;

          if (isBefore(aDate, bDate)) return -1;

          return 0;
        });

        sortedReports.forEach(item => {
          labels.push(
            formatDate(
              formatDayMonthYear(
                item.get("year"),
                item.get("month") - 1,
                item.get("day"),
              ),
            ),
          );
          dispatches.push(item.get("dispatched", 0));
          attempts.push(item.get("delivery_attempted", 0));
        });

        return {
          labels,
          datasets: [
            {
              label: props.getLocalisationMessage("dispatches", "Dispatches"),
              fill: true,
              backgroundColor: gradient1,
              strokeColor: "#A3A0FB",
              pointColor: "#fff",
              pointStrokeColor: "#A3A0FB",
              pointHighlightFill: "#fff",
              pointHighlightStroke: "#A3A0FB",
              data: dispatches,
            },
            {
              label: props.getLocalisationMessage("attempts", "Attempts"),
              fill: true,
              backgroundColor: gradient2,
              strokeColor: "#54D8FF",
              pointColor: "#fff",
              pointStrokeColor: "#54D8FF",
              pointHighlightFill: "#fff",
              pointHighlightStroke: "#54D8FF",
              data: attempts,
            },
          ],
        };
      })
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(statsStream, valueStream, (props, stats) => ({
        ...props,
        stats,
      }));
  }),
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
    },
    title: {
      fontSize: "18px",
      textTransform: "uppercase",
    },
    info: {
      paddingTop: "20px",
    },
  }),
);

AttemptsChartForm.propTypes = {
  classes: PropTypes.object,
  stats: PropTypes.func,
  initialValues: PropTypes.object,
  getReports: PropTypes.func.isRequired,
  title: PropTypes.string,
  filter: PropTypes.instanceOf(DataListFilter),
  width: PropTypes.number,
  height: PropTypes.number,
  getLocalisationMessage: PropTypes.func,
};

AttemptsChartForm.defaultProps = {
  width: 100,
  height: 50,
};

function AttemptsChartForm(props) {
  const { classes, stats, getLocalisationMessage } = props;

  /* const dateTypes = OrderedSet.of(LAST_WEEK, LAST_15_DAYS, LAST_30_DAYS); */

  return (
    <Card className={classes.container}>
      <FlexBox direction="row" className={classes.title}>
        <FlexBox flex={true} align="center">
          {getLocalisationMessage(
            "dispatched_vs_attempted",
            "DISPATCHED VS ATTEMPTED",
          )}
        </FlexBox>
        {/* <FlexBox flex={true} justify="flex-end">
          <FormSelectField
            name="limitDays"
            options={dateTypes}
            formatOption={code =>
              formatDaysIntervalCodes(code, getLocalisationMessage)
            }
          />
        </FlexBox> */}
      </FlexBox>

      <FlexBox direction="column">
        <LineChart backgroundColor="#FFB300" color="#ffffff" data={stats} />
      </FlexBox>
    </Card>
  );
}

export default enhancer(AttemptsChartForm);
