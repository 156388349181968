import { Observable } from "rxjs";
import React from "react";
import Immutable from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mapListResponseStream } from "../../helpers/ApiUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  ARRIVED,
  ACCEPTED,
  PICKED_UP,
  IN_TRANSIT,
  ON_HIS_WAY,
  OUT_FOR_DELIVERY,
} from "../../constants/OrderStatusCodes";
import { getOrderList } from "../../api/supplier/SupplierOrderApi";
import { getDriverLocationList } from "../../api/supplier/SupplierDriverApi";
import PageLoading from "../../components/ui-core/PageLoading";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import DriversRadar from "../../components/drivers-core/DriversRadar";

const baseFilter = new DataListFilter({
  size: 1,
  status: [
    ACCEPTED,
    ON_HIS_WAY,
    ARRIVED,
    PICKED_UP,
    IN_TRANSIT,
    OUT_FOR_DELIVERY,
  ].join(","),
});

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  getContext({ setLocationQuery: PropTypes.func }),
  mapPropsStream(propsStream => {
    const driverIdStream = propsStream.map(
      fp.flow(fp.get("location.query.active_driver"), fp.toFinite),
    );

    const driverLocationsStream = getDriverLocationList()
      .takeLast(1)
      .map(fp.flow(fp.get("payload.data"), fp.toArray, Immutable.fromJS))
      .distinctUntilChanged(Immutable.is)
      .repeatWhen(stream => stream.delay(10 * 1000))
      .startWith(Immutable.List());

    const driverOrderStream = driverIdStream
      .distinctUntilChanged()
      .switchMap(driverId => {
        if (!driverId) {
          return Observable.of(null);
        }

        return getOrderList(baseFilter.setValue("driver_ids", driverId))
          .let(mapListResponseStream)
          .map(x => x.getIn(["payload", "list", 0]))
          .distinctUntilChanged(Immutable.is);
      });

    return propsStream.combineLatest(
      driverIdStream,
      driverOrderStream,
      driverLocationsStream,
      (props, driverId, driverOrder, driverLocations) => ({
        ...props,
        driverId,
        driverOrder,
        driverLocations,
      }),
    );
  }),
);

SupplierRadarContainer.propTypes = {
  setLocationQuery: PropTypes.func,

  onRequestRefresh: PropTypes.func,

  driverLocations: PropTypes.instanceOf(Immutable.List),

  driverId: PropTypes.number,
  driverOrder: PropTypes.instanceOf(Immutable.Map),
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SupplierRadarContainer(props) {
  return (
    <SupplierAppLayout
      slug="radar"
      title={props.getLocalisationMessage("drivers_radar", "Drivers Radar")}
    >
      <PageLoading isLoading={!props.driverLocations} />

      {Boolean(props.driverLocations) && (
        <DriversRadar
          activeDriverId={props.driverId}
          driverLocations={props.driverLocations}
          activeDriverOrder={props.driverOrder}
          onActivateDriver={id =>
            props.setLocationQuery(fp.set("active_driver", id))
          }
        />
      )}
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierRadarContainer);
