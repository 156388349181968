import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card } from "@material-ui/core";
import LineChart from "../charts/FilledLineChart";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";

const colorsTypes = [
  "#f44336",
  "#E91E63",
  "#673AB7",
  "#2196F3",
  "#009688",
  "#4CAF50",
  "#CDDC39",
  "#FF9800",
  "#795548",
  "#607D8B",
];

const enhancer = compose(
  mapPropsStream(propsStream => {
    const statsStream = propsStream
      .distinctUntilChanged(isEqualData)
      .map(props => {
        const datasets = [];

        const labels = fp.toArray(props.reports.get("labels")).reverse();
        const reports = props.reports.get("dataset", Map());

        labels.forEach((item, idx) => {
          if (reports.getIn([idx, "name"]))
            datasets.push({
              label: reports.getIn([idx, "name"]),
              borderColor: colorsTypes[idx],
              backgroundColor: "transparent",
              strokeColor: colorsTypes[idx],
              pointStrokeColor: colorsTypes[idx],
              pointHighlightStroke: colorsTypes[idx],
              data: fp.toArray(reports.getIn([idx, "values"])).reverse(),
            });
        });

        return {
          labels,
          datasets,
        };
      })
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(statsStream, (props, stats) => ({
        ...props,
        stats,
      }));
  }),
  reduxForm({
    form: "SupplierScoresWidgetForm",
    enableReinitialize: true,
  }),
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
    },
    title: {
      fontSize: "18px",
      textTransform: "uppercase",
    },
    info: {
      paddingTop: "30px",
    },
  }),
);

SupplierScoresWidget.propTypes = {
  classes: PropTypes.object,
  stats: PropTypes.object,
  reports: PropTypes.instanceOf(Map),
  initialValues: PropTypes.object,
  title: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

SupplierScoresWidget.defaultProps = {
  width: 100,
  height: 400,
};

function SupplierScoresWidget(props) {
  const { classes, stats } = props;

  return (
    <Card className={classes.container}>
      <FlexBox direction="row" className={classes.title}>
        <FlexBox flex={true} align="center">
          {props.title}
        </FlexBox>
      </FlexBox>

      <FlexBox direction="column" className={classes.info}>
        <LineChart backgroundColor="transparent" color="#ffffff" data={stats} />
      </FlexBox>
    </Card>
  );
}

export default enhancer(SupplierScoresWidget);
