import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import WingApi from "../helpers/WingApi";
import DataListFilter from "../helpers/DataListFilter";
import { FETCH_DRIVER, FETCH_DRIVER_CHOOSE } from "../reducers/DriverReducer";
import { API_ROOT_URL } from "../../shared/constants/ApiConstants";

const DRIVER_CHOOSE_URL = `${API_ROOT_URL}/supplier/driver/choose`;
const DRIVER_ITEM_URL = `${API_ROOT_URL}/supplier/driver/%s`;

export function fetchDriver(id) {
  return (dispatch, getState, api: WingApi) =>
    dispatch({
      meta: { id },
      type: FETCH_DRIVER,
      payload: api.get(sprintf(DRIVER_ITEM_URL, id)),
    });
}

export function loadDriverPredictions(request: DataListFilter) {
  return (dispatch, getState, api: WingApi) =>
    api
      .getStream(DRIVER_CHOOSE_URL, { params: request.getDefinedValues() })
      .map(fp.update("payload", fp.flow(fp.get("data"), fromJS)));
}

/**
 * @deprecated
 * @param request
 * @returns {function(*, *, WingApi)}
 */
export function fetchDriverChoose(request) {
  return (dispatch, getState, api: WingApi) =>
    dispatch({
      meta: { request },
      type: FETCH_DRIVER_CHOOSE,
      payload: api.get(DRIVER_CHOOSE_URL, {
        params: request.toRequestParams(),
      }),
    });
}
