import React from "react";
import PropTypes from "prop-types";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import ReasonTypes from "../../constants/ReasonTypes";
import InquiriesTypes from "../../constants/InquiriesTypes";
import { PUBLIC } from "../../constants/NotePrivacyTypes";
import SupplierPrivacyTypes from "../../constants/SupplierPrivacyTypes";
import { batchCreateNote } from "../../api/supplier/SupplierOrderApi";
import BatchOrderNoteDialog from "../../components/orders-core/BatchOrderNoteDialog";

SupplierBatchOrderNoteDialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
};

export default function SupplierBatchOrderNoteDialogWrapper(props) {
  return (
    <BatchOrderNoteDialog
      open={props.open}
      reasonTypes={ReasonTypes}
      inquiriesTypes={InquiriesTypes}
      onRequestClose={props.onRequestClose}
      initialValues={{
        privacy: PUBLIC,
        ...props.initialValues,
      }}
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
      onSubmit={({ orderNumbers, ...values }) =>
        batchCreateNote(orderNumbers, toSnakeCase(values)).catch(
          ResponseError.throw,
        )
      }
      privacyTypes={SupplierPrivacyTypes}
    />
  );
}
