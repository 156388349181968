import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card, ListSubheader } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  useSheet({
    container: {
      width: "100%",
      padding: {
        top: "5px",
        left: "10px",
        bottom: "10px",
        right: "10px",
      },
      color: "#43425D",
      "& h4": {
        fontSize: "18px",
        lineHeight: "20px",
      },
      "& h3": {
        lineHeight: "24px",
        fontSize: "22px",
      },
    },
    title: {
      fontSize: "14px",
      textTransform: "uppercase",
      padding: "8px",
      textAlign: "center",
    },
    trend: {
      fontSize: "12px",
    },
    difference: {
      color: "#fff",
      borderRadius: "5px",
      padding: "5px",
      minWidth: "30px",
      textAlign: "center",
      fontSize: "11px",
    },
  }),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const responseStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .switchMap(props =>
        props.getReport(props.filter).catch(() => Observable.of({})),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], Map()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData)
      .filter(response => response.get("payload"))
      .map(response => {
        const report = response.get("payload");
        const inBoundDifference = report.get("warehouse_transit_in_bound");
        const outBoundDifference = report.get("warehouse_transit_out_bound");

        return fromJS({
          values: [
            {
              value: inBoundDifference,
              title: "inbound",
            },
            {
              value: outBoundDifference,
              title: "outbound",
            },
          ],
        });
      })
      .startWith(Map())
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(responseStream, (props, response) => ({
      ...props,
      data: response,
    }));
  }),
);

WarehouseInboundOutboundWidget.propTypes = {
  classes: PropTypes.object,
  getReport: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Map),
  filter: PropTypes.instanceOf(DataListFilter),
  title: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
};

function WarehouseInboundOutboundWidget(props) {
  const { classes, data, title, getLocalisationMessage } = props;

  return (
    <Card className={classes.container}>
      <ListSubheader className={classes.title}>{title}</ListSubheader>
      <FlexBox>
        {data.get("values").map((item, key) => (
          <FlexBox flex={true} direction="column" align="center" key={key}>
            <h4>
              {getLocalisationMessage(item.get("title"), item.get("title"))}
            </h4>
            <h3>{item.get("value")}</h3>
          </FlexBox>
        ))}
      </FlexBox>
    </Card>
  );
}

export default enhancer(WarehouseInboundOutboundWidget);
