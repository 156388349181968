import React from "react";
import { Map, List, OrderedSet } from "immutable";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent, ListSubheader } from "@material-ui/core";
import { connect } from "react-redux";
import StackedBarChart from "../charts/HorizontalBarChart";
import FlexBox from "../ui-core/FlexBox";
import PriceWrapper from "../ui-core/PriceWrapper";
import { isEqualData } from "../../helpers/DataUtils";
import { getMarketplaceCurrency } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  COD_PENDING,
  COD_WITH_WING,
  COD_WITH_DRIVER,
  COD_WITH_WING_BANK,
  COD_TO_BE_COLLECTED,
  COD_PAID_TO_MERCHANT,
} from "../../constants/CODStatus";

export const statusCodes = OrderedSet.of(
  COD_TO_BE_COLLECTED,
  COD_WITH_WING,
  COD_WITH_WING_BANK,
  COD_PAID_TO_MERCHANT,
  COD_WITH_DRIVER,
  COD_PENDING,
);

function backgroundColorCODStatuses(status, notAvailableValue = "#000") {
  switch (status) {
    case COD_TO_BE_COLLECTED:
      return "#B0BEC5";
    case COD_WITH_DRIVER:
      return "#FFB300";
    case COD_WITH_WING:
      return "#F44336";
    case COD_WITH_WING_BANK:
      return "#D32F2F";
    case COD_PAID_TO_MERCHANT:
      return "#8BC34A";
    case COD_PENDING:
      return "#2850C3";
    default:
      return notAvailableValue;
  }
}

function formatCODStatusCodes(
  status,
  getLocalisationMessage,
  notAvailableValue = "#000",
) {
  switch (status) {
    case COD_TO_BE_COLLECTED:
      return (
        (getLocalisationMessage && getLocalisationMessage("not_collected")) ||
        "Not Collected"
      );
    case COD_WITH_DRIVER:
      return (
        (getLocalisationMessage && getLocalisationMessage("with_driver")) ||
        "With Driver"
      );
    case COD_WITH_WING:
      return (
        (getLocalisationMessage && getLocalisationMessage("with_cashier")) ||
        "With Cashier"
      );
    case COD_WITH_WING_BANK:
      return (
        (getLocalisationMessage && getLocalisationMessage("with_bank")) ||
        "With Bank"
      );
    case COD_PAID_TO_MERCHANT:
      return (
        (getLocalisationMessage && getLocalisationMessage("paid")) || "Paid"
      );
    case COD_PENDING:
      return (
        (getLocalisationMessage && getLocalisationMessage("pending")) ||
        "Pending"
      );
    default:
      return notAvailableValue;
  }
}

const enhancer = compose(
  connect(state => ({
    currency: getMarketplaceCurrency(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const statsStream = propsStream
      .distinctUntilChanged(isEqualData)
      .map(props =>
        Map().withMutations(items => {
          const data = OrderedSet().asMutable();
          const labels = [
            props.getLocalisationMessage &&
              props.getLocalisationMessage(
                "cash_on_delivery_report",
                "Cash On Delivery Report",
              ),
          ];
          let totalValue = 0;

          statusCodes.forEach(item => {
            const result = props.reports.find(
              o => o.get("cod_status") === item,
            );
            const backgroundColor = backgroundColorCODStatuses(item);
            const label = formatCODStatusCodes(
              item,
              props.getLocalisationMessage,
            );

            let info = Map({
              label,
              data: [0],
              backgroundColor,
            });

            if (result) {
              totalValue += result.get("total", 0);
              info = Map({
                label,
                data: [result.get("total", 0)],
                backgroundColor,
              });
            }

            data.add(info);
          });

          items.set("total", totalValue);
          items.set("chartData", Map({ labels, datasets: data.asImmutable() }));
        }),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(statsStream, (props, stats) => ({
        ...props,
        stats,
      }));
  }),
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
    },
    title: {
      fontSize: "18px",
    },
    mainContainer: {
      "& > div:nth-child(1)": {
        fontSize: "16px",
      },
      "& > div:nth-child(2)": {
        fontSize: "20px",
        color: "#E91E63",
        "& > span": {
          fontSize: "20px",
          lineHeight: "18px",
        },
      },
    },
    priceValue: {
      fontWeight: "bold",
      "& > span": {
        paddingRight: "5px",
      },
    },
  }),
);

CODChartComponent.propTypes = {
  classes: PropTypes.object,
  stats: PropTypes.object,
  reports: PropTypes.instanceOf(List),
  title: PropTypes.string,
  currency: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
};

function CODChartComponent(props) {
  const { classes, reports, getLocalisationMessage } = props;

  return (
    <FlexBox
      gutter={0}
      flex={true}
      element={<Card className={classes.container} />}
      direction="column"
    >
      <FlexBox
        element={<ListSubheader className={classes.title} />}
        flex={true}
      >
        {getLocalisationMessage("cash_on_delivery", "Cash On Delivery")}
      </FlexBox>
      <FlexBox element={<CardContent />} direction="column" flex={true}>
        <FlexBox direction="column">
          <FlexBox
            flex={true}
            direction="row"
            className={classes.mainContainer}
          >
            <FlexBox flex={true} className={classes.priceTitle}>
              {getLocalisationMessage("payable_cod", "Payable COD")}
            </FlexBox>
            <FlexBox
              flex={true}
              className={classes.priceValue}
              justify="flex-end"
              align="flex-end"
            >
              <span>{props.currency}</span>
              <PriceWrapper
                price={reports.get("payable_total", 0)}
                withCode={false}
              />
            </FlexBox>
          </FlexBox>
          <FlexBox flex={true} direction="row">
            <FlexBox flex={true} className={classes.priceTitle}>
              {getLocalisationMessage("total_cod", "Total COD")}
            </FlexBox>
            <FlexBox
              flex={true}
              className={classes.priceValue}
              justify="flex-end"
              align="flex-end"
            >
              <span>{props.currency}</span>
              <PriceWrapper price={props.stats.get("total")} withCode={false} />
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <div style={{ paddingTop: "15px", height: "150px" }}>
          <StackedBarChart
            title={getLocalisationMessage(
              "cash_on_delivery",
              "Cash On Delivery",
            )}
            width={100}
            height={5}
            max={props.stats.get("total", 0)}
            tooltipFormat={{
              displayColors: true,
              mode: "label",
              callbacks: {
                title() {
                  return null;
                },
              },
            }}
            data={props.stats.get("chartData").toJS()}
          />
        </div>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(CODChartComponent);
