import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import { getValue } from "../../../helpers/DataUtils";

const getValues = formValueSelector("OrderCreateWizardSupplier");

const enhancer = compose(
  reduxForm({
    form: "OrderCreateWizardSupplier",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  connect(state => ({
    values: getValues(
      state,
      "acceptedTerms",
      "pickupCountry",
      "dropoffCountry",
    ),
  })),
);

OrderCreateWizardStepTermsDialog.propTypes = {
  values: PropTypes.object,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

function OrderCreateWizardStepTermsDialog(props) {
  const { values } = props;

  const open =
    !values.acceptedTerms &&
    getValue(values.pickupCountry, "id") !==
      getValue(values.dropoffCountry, "id");

  return (
    <Dialog open={open} modal={true}>
      <DialogTitle>Terms and Conditions</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Shipments are subject to duties and taxes as determined by customs in
          the destination country.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div>
          <Button
            onClick={() => {
              props.change("acceptedTerms", true);
              props.handleSubmit();
            }}
          >
            Accept
          </Button>
          <Button onClick={props.onDismiss}>Dismiss</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderCreateWizardStepTermsDialog);
