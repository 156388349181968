import React from "react";
import { setHours, startOfTomorrow } from "date-fns";
import PropTypes from "prop-types";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { batchRescheduleOrder } from "../../api/supplier/SupplierOrderApi";
import BatchRescheduleOrderDialog from "../../components/orders-core/BatchRescheduleOrderDialog";

SupplierBatchRescheduleOrderDialogWrapper.propTypes = {
  initialValues: PropTypes.object,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default function SupplierBatchRescheduleOrderDialogWrapper(props) {
  return (
    <BatchRescheduleOrderDialog
      open={props.open}
      onRequestClose={props.onRequestClose}
      initialValues={{
        deadline: setHours(startOfTomorrow(), 9),
        ...props.initialValues,
      }}
      onSubmit={values =>
        batchRescheduleOrder(toSnakeCase(values)).catch(ResponseError.throw)
      }
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
    />
  );
}
