import { OrderedSet } from "immutable";

export const GIS = "GIS";
export const PLACE_API = "PLACE_API";
export const SOUQ_ADDRESS = "SOUQ_ADDRESS";
export const EXIST_LOCATION = "EXIST_LOCATION";
export const REGION_CENTER = "REGION_CENTER";
export const CITY_CENTER = "CITY_CENTER";
export const PLACE_LOCATION = "PLACE_LOCATION";

export default OrderedSet.of(
  GIS,
  PLACE_API,
  SOUQ_ADDRESS,
  EXIST_LOCATION,
  REGION_CENTER,
  CITY_CENTER,
  PLACE_LOCATION,
);
