import fp from "lodash/fp";
import DataListFilter, { getListFilterSchema } from "./DataListFilter";
import { types, mapSchema } from "../../shared/helpers/ObjectMapper";

export const baseFilter = {
  is_uae: types.booleanOr(true),
  use_solr: types.booleanOr(true),
  financial: types.booleanOr(true),
};

export const toCODFilter = fp.compose(
  DataListFilter.create,
  mapSchema({
    ...getListFilterSchema(),

    ...baseFilter,
  }),
);
