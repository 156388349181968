import React from "react";
import I, { Map, Set } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  Card,
  Table,
  CardContent,
  TableRow,
  TableBody,
  IconButton,
  TableCell,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Search } from "@material-ui/icons";
import { fetchPublicOrder } from "../../actions/PublicOrderActions";
import { withPropsSubscription } from "../../helpers/HOCUtils";
import { formatText } from "../../helpers/FormatUtils";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import { getPublicOrdersByIds } from "../../reducers/PublicOrderReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { ACTIVITIES } from "../../constants/OrderItemTabs";
import {
  ORDER_LIST_URL,
  ORDER_TRACKING_URL,
} from "../../constants/SupplierPathConstants";
import LinkButton from "../../components/ui-core/LinkButton";
import TooltipOverlay from "../../components/ui-core/TooltipOverlay";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import TextField from "../../components/deprecated/TextField";
import { getStatusLocalisation } from "../../reducers/localisation/OrderStatusLocalisationReducer";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  useSheet({
    textRight: { textAlign: "right" },
    textCenter: { textAlign: "center" },
    addCodeForm: {
      padding: "0 14px",
      position: "relative",
      backgroundColor: "#fff",
      borderBottom: "1px solid rgb(224, 224, 224)",
    },
    addCodeFormSubmit: { top: 0, right: 0, position: "absolute" },
  }),
  connect(
    (state, { params: { orderNumbers } }) => {
      const publicIds = Set(parseString(orderNumbers));
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        publicIds,
        orders: getPublicOrdersByIds(state, publicIds),
        getLocalisationMessage,
        statusMessages: getStatusLocalisation(state),
      };
    },
    { fetchPublicOrder },
  ),
  withPropsSubscription(
    ({ publicIds: i1 }, { publicIds: i2 }) => !I.is(i1, i2),
    props => {
      props.orders.forEach((item, id) => {
        if (
          !item.get("order") &&
          !item.get("isLoading") &&
          !item.get("isNotFound")
        ) {
          props.fetchPublicOrder(id);
        }
      });
    },
  ),
);

class SupplierOrderTracking extends React.Component {
  static propTypes = {
    sheet: PropTypes.object,
    orders: PropTypes.instanceOf(Map),
    publicIds: PropTypes.instanceOf(Set),
    fetchPublicOrder: PropTypes.func,
    getLocalisationMessage: PropTypes.func.isRequired,
    statusMessages: PropTypes.instanceOf(Map),
  };

  static contextTypes = { router: PropTypes.object.isRequired };

  constructor(props, context) {
    super(props, context);

    this.state = { searchText: "" };
  }

  handleAddOrderNumberSubmit = event => {
    const { publicIds } = this.props;
    const { searchText } = this.state;

    const orderNumbers = stringifyArray(
      publicIds.merge(parseString(searchText)).toArray(),
    );

    event.preventDefault();

    this.setState({ searchText: "" }, () => {
      this.context.router.push(ORDER_TRACKING_URL + orderNumbers);
    });
  };

  handleRemoveClick(event, key) {
    const { publicIds } = this.props;

    const { router } = this.context;

    const orderNumbers = stringifyArray(publicIds.delete(key).toArray());

    event.preventDefault();

    router.push(ORDER_TRACKING_URL + orderNumbers);
  }

  handleRefreshClick(event, key) {
    event.preventDefault();

    if (!this.props.orders.getIn([key, "isLoading"])) {
      this.props.fetchPublicOrder(key);
    }
  }

  renderColumns(item, key) {
    const {
      sheet: {
        classes: { textRight, textCenter },
      },
    } = this.props;

    if (item.get("isNotFound")) {
      return (
        <TableRow key={key}>
          <TableCell key="key">{key}</TableCell>
          <TableCell colSpan={4} key="not_found">
            {this.props.getLocalisationMessage(
              "order_not_found",
              "Order not found",
            )}
          </TableCell>
          <TableCell key="action" className={textRight}>
            <LinkButton onClick={event => this.handleRemoveClick(event, key)}>
              {this.props.getLocalisationMessage("remove", "Remove")}
            </LinkButton>
          </TableCell>
        </TableRow>
      );
    }

    if (item.get("isLoading") || !item.get("order")) {
      return (
        <TableRow key={key}>
          <TableCell key="key">{key}</TableCell>
          <TableCell colSpan={5} key="loading">
            {this.props.getLocalisationMessage(
              "loading_order",
              "Loading order ...",
            )}
          </TableCell>
        </TableRow>
      );
    }

    const NA = this.props.getLocalisationMessage("na", "N/A");

    const order = item.get("order");

    const id = order.get("id");
    const status = formatText(
      this.props.statusMessages.get(order.get("status"), order.get("status")),
    );

    const pickupLocation = order.getIn(["locations", 0, "address"]) || NA;
    const fromCity = order.getIn(["package", "from_city"]) || pickupLocation;

    const dropoffLocation = order.getIn(["locations", 0, "address"]) || NA;
    const toCity = order.getIn(["package", "from_city"]) || dropoffLocation;

    const packageMenu = order.getIn(["package", "menu", "name"]);
    const packageItem = order.getIn(["package", "delivery_label"]);

    const itemLocation = {
      pathname: updateQuery(ORDER_LIST_URL, { view: id }),
      query: { tab: ACTIVITIES },
    };

    return (
      <TableRow key={key}>
        <TableCell key="key">
          <Link target="_blank" to={itemLocation}>
            {key}
          </Link>
        </TableCell>
        <TableCell key="status" className={textCenter}>
          {status}
        </TableCell>
        <TooltipOverlay key="pickupLocation" label={pickupLocation}>
          <TableCell>{fromCity}</TableCell>
        </TooltipOverlay>
        <TooltipOverlay key="dropoffLocation" label={dropoffLocation}>
          <TableCell>{toCity}</TableCell>
        </TooltipOverlay>
        <TableCell key="package" className={textCenter}>
          {packageItem}
          <br />
          {packageMenu}
        </TableCell>
        <TableCell key="action" className={textRight}>
          <LinkButton onClick={event => this.handleRefreshClick(event, key)}>
            {this.props.getLocalisationMessage("refresh", "Refresh")}
          </LinkButton>
          /
          <LinkButton onClick={event => this.handleRemoveClick(event, key)}>
            {this.props.getLocalisationMessage("remove", "Remove")}
          </LinkButton>
        </TableCell>
      </TableRow>
    );
  }

  renderTable() {
    const { orders } = this.props;

    if (!orders || orders.isEmpty()) {
      return null;
    }

    return (
      <Table selectable={false} fixedHeader={false}>
        <TableBody displayRowCheckbox={false}>
          {orders.map((item, key) => this.renderColumns(item, key)).toArray()}
        </TableBody>
      </Table>
    );
  }

  render() {
    const {
      sheet: {
        classes: { addCodeForm, addCodeFormSubmit },
      },
    } = this.props;

    const { searchText } = this.state;

    return (
      <SupplierAppLayout
        slug="order_tracking"
        title={this.props.getLocalisationMessage(
          "order_tracking",
          "Order Tracking",
        )}
      >
        <Card>
          <CardContent>
            <form
              autoComplete="off"
              className={addCodeForm}
              onSubmit={this.handleAddOrderNumberSubmit}
            >
              <TextField
                fullWidth={true}
                hintText={this.props.getLocalisationMessage(
                  "add_order_numbers",
                  "Add Order Numbers",
                )}
                underlineShow={false}
                value={searchText}
                onChange={x => this.setState({ searchText: x })}
              />
              <div className={addCodeFormSubmit}>
                <IconButton type="submit">
                  <Search />
                </IconButton>
              </div>
            </form>
            {this.renderTable()}
          </CardContent>
        </Card>
      </SupplierAppLayout>
    );
  }
}

export default enhancer(SupplierOrderTracking);
