import { Observable } from "rxjs";
import React from "react";
import { fromJS, List, OrderedSet } from "immutable";
import fp from "lodash/fp";
import { compose, createEventHandler, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { parseString, stringifyArray } from "../../helpers/SerializeUtils";
import {
  getMarketplaceDefaultBatchUpdateStatuses,
  supplierTransitOrdersVisibility,
} from "../../reducers/MarketplaceReducer";
import OrderStatusCodes, {
  IN_TRANSIT_TO_SUPPLIER,
} from "../../constants/OrderStatusCodes";
import SupplierCODCodes from "../../constants/SupplierCODCodes";
import { CUSTOM_SUPPLIER } from "../../constants/TransitModeTypes";
import SupplierPrivacyTypes from "../../constants/SupplierPrivacyTypes";
import { getCachedSupplier } from "../../api/admin/AdminSupplierApi";
import { getBatchOrdersItems } from "../../api/supplier/SupplierBatchApi";
import { batchAsyncUpdateOrder } from "../../api/supplier/SupplierOrderApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/supplier/SupplierDriverApi";
import { getSupplierPredictions } from "../../api/supplier/SupplierSupplierApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/supplier/SupplierWarehouseApi";
import PageLoading from "../../components/ui-core/PageLoading";
import BatchUpdateOrderDialog from "../../components/orders-core/BatchUpdateOrderDialog";

const parseIdString = fp.flow(fp.trim, fp.split("-"), fp.compact, fp.uniq);

const extractSupplier = (batchIds) => {
  const response = { supplier: null, orderNumbers: null };
  let supplierId = null;
  let isSupplierUnique = true;
  const batchIdsArray = [];
  const ids = parseString(batchIds);

  ids.forEach((id) => {
    const [batchValue, supplierValue = null] = parseIdString(id);

    batchIdsArray.push(batchValue);

    if (supplierId === null) {
      supplierId = supplierValue;
    } else if (supplierId !== supplierValue) isSupplierUnique = false;
  });

  response.supplier = isSupplierUnique ? supplierId : null;
  response.orderNumbers = stringifyArray(batchIdsArray);

  return response;
};

const getOrderNumbers = (list) => {
  let orderNumbers = [];

  list.forEach((item) => {
    orderNumbers = fp.concat(
      orderNumbers,
      item.get("success_order_numbers").toArray(),
    );
  });

  return fp.uniq(orderNumbers);
};

const enhancer = compose(
  connect((state) => ({
    isSupplierTransitOrdersVisibility: supplierTransitOrdersVisibility(state),
    orderStatuses: getMarketplaceDefaultBatchUpdateStatuses(state),
  })),
  mapPropsStream((propsStream) => {
    const { handler: onBatchOrderRefresh, stream: onBatchOrderRefreshStream } =
      createEventHandler();

    const initialDataStream = propsStream
      .distinctUntilKeyChanged("batch_ids", isEqualData)
      .map((props) => ({
        data: extractSupplier(props.batch_ids),
      }))
      .distinctUntilChanged(isEqualData);

    const listResponseStream = initialDataStream
      .switchMap((initialData) =>
        getBatchOrdersItems(initialData.data.orderNumbers)
          .catch((error) => Observable.of({ error }))
          .repeatWhen(() => onBatchOrderRefreshStream),
      )
      .map(fp.flow((response) => fromJS(response)))
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        initialDataStream,
        listResponseStream,
        (props, initialData, listResponse) => ({
          ...props,
          onBatchOrderRefresh,
          initialData,
          isLoading: listResponse.get("pending", false),
          list: listResponse.getIn(["payload", "data"], List()),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

SupplierBatchUpdateBatchesDialogWrapper.propTypes = {
  open: PropTypes.bool,
  initialData: PropTypes.object,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  batch_ids: PropTypes.string,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  isSupplierTransitOrdersVisibility: PropTypes.bool,
  orderStatuses: PropTypes.object,
};

function SupplierBatchUpdateBatchesDialogWrapper(props) {
  if (props.isLoading) {
    return <PageLoading isLoading={true} />;
  }

  const orderNumbers = getOrderNumbers(props.list);
  const supplierId = props.initialData.data.supplier;

  const statusList =
    props.orderStatuses && props.orderStatuses.size > 0
      ? props.orderStatuses
      : OrderStatusCodes;

  return (
    <BatchUpdateOrderDialog
      open={props.open}
      onRequestClose={props.onRequestClose}
      codCodes={SupplierCODCodes}
      statusCodes={statusList}
      withInTransitToSupplier={true}
      privacyTypes={SupplierPrivacyTypes}
      transitModeOptions={OrderedSet.of(CUSTOM_SUPPLIER)}
      getCachedSupplier={getCachedSupplier}
      getSupplierPredictions={getSupplierPredictions}
      getCachedDriver={getCachedDriver}
      getDriverPredictions={getDriverPredictions}
      getCachedWarehouse={getCachedWarehouse}
      getWarehousePredictions={getWarehousePredictions}
      isSupplierTransitOrdersVisibility={
        props.isSupplierTransitOrdersVisibility
      }
      showSupplierDriverOnly={false}
      initialValues={{
        orderNumbers,
        supplier: { id: supplierId },
        orderStatus: IN_TRANSIT_TO_SUPPLIER,
        transitMode: "custom_supplier",
        supplierDriverOnly: true,
      }}
      onSubmit={fp.flow(toSnakeCase, (values) =>
        batchAsyncUpdateOrder(values).catch(ResponseError.throw),
      )}
      onSubmitSuccess={props.onSubmitSuccess}
      onSubmitFail={props.onSubmitFail}
    />
  );
}

export default enhancer(SupplierBatchUpdateBatchesDialogWrapper);
