import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const SUPPLIER_USER_LIST_URL = `${API_ROOT_URL}/supplier/users`;
const SUPPLIER_USER_ITEM_URL = `${API_ROOT_URL}/supplier/user/%s`;
const SUPPLIER_USER_CREATE_URL = `${API_ROOT_URL}/supplier/user`;

export const getSupplierUserList = (request: DataListFilter) =>
  api.getStream(SUPPLIER_USER_LIST_URL, { params: request.getDefinedValues() });

export const getSupplierUser = id =>
  api.getStream(sprintf(SUPPLIER_USER_ITEM_URL, id));
export const updateSupplierUser = (id, body) =>
  api.put(sprintf(SUPPLIER_USER_ITEM_URL, id), { body });

type SupplierUser = {
  authorities: string[],
  billing_address: {
    address: string,
    address_type: number,
    city: { id: number },
    country: { id: number },
    primary: boolean,
    zip: string,
  },
  code: string,
  contact_email: string,
  contact_first_name: string,
  contact_last_name: string,
  contact_password: string,
  contact_phone: string,
  created_date: string,
  fax: string,
  id: number,
  name: string,
  number_of_drivers: number,
  number_of_fleet: number,
  price_formula: string,
  shipping_address: {
    address: string,
    address_type: number,
    city: { id: number },
    country: { id: number },
    primary: boolean,
    zip: string,
  },
  status: string,
  supplier_email: string,
  supplier_phone: string,
  website: string,
};

export const createSupplierUser = (body: SupplierUser) =>
  api.post(SUPPLIER_USER_CREATE_URL, { body });
