import React from "react";
import { List } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import DataListFilter from "../../../helpers/DataListFilter";
import { getDispatchedAttemptedReportsV2 } from "../../../api/supplier/SupplierChartApi";
import AttemptsChartWidget from "../../../components/dashboard-core/AttemptsChartWidget";

const enhancer = compose(
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
      position: "relative",
    },
  }),
);

SupplierDispatchedVSAttemptedChartWrapper.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  location: PropTypes.object,
  reports: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
};

function SupplierDispatchedVSAttemptedChartWrapper(props) {
  return (
    <AttemptsChartWidget
      title={props.title}
      reports={props.reports}
      filter={props.filter}
      getReports={getDispatchedAttemptedReportsV2}
    />
  );
}

export default enhancer(SupplierDispatchedVSAttemptedChartWrapper);
