import React from "react";
import PropTypes from "prop-types";
import { getBatchOrderUpdateItem } from "../../api/supplier/SupplierBatchApi";
import BatchUpdatesItemDialog from "../../components/batch-updates-dialog/BatchUpdatesItemDialog";

SupplierBatchUpdatesItemDialogWrapper.propTypes = {
  batchId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,

  onShowOrdersClick: PropTypes.func,
};

export default function SupplierBatchUpdatesItemDialogWrapper(props) {
  return (
    <BatchUpdatesItemDialog
      batchId={props.batchId}
      onRequestClose={props.onRequestClose}
      onShowOrdersClick={props.onShowOrdersClick}
      getBatchOrderUpdateItem={getBatchOrderUpdateItem}
    />
  );
}
