import React from "react";
import _ from "lodash";
import { compose, getContext, withContext, withPropsOnChange } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { batchAssignOrderLastMileDriver } from "../../actions/AdminOrderActions";
import {
  fetchDriverSuggestions,
  updateDriverSuggestions,
} from "../../actions/AdminDriverActions";
import {
  fetchDriver,
  fetchDriverChoose,
  loadDriverPredictions,
} from "../../actions/SupplierDriverActions";
import { fetchWarehouse } from "../../actions/SupplierWarehouseActions";
import DataListFilter from "../../helpers/DataListFilter";
import { toOrderFilter } from "../../helpers/OrderFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getWarehouse as loadWarehouse,
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/supplier/SupplierWarehouseApi";
import { fetchOrderList } from "../../actions/supplier/SupplierOrderActions";
import SupplierOrderFilterWrapper from "../../wrappers/supplier/SupplierOrderFilterWrapper";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import OrderRouterIndex from "../../components/order-router/OrderRouterIndex";

const FILTER_DIALOG_HASH = "#FDH";

const enhancer = compose(
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  withPropsOnChange(
    ({ location: { query: a } }, { location: { query: b } }) =>
      !_.isEqual(a, b),
    props => {
      const { location: { query } } = props;

      return {
        orderFilter: toOrderFilter(query),
      };
    },
  ),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      fetchOrderList,
      batchAssignOrderLastMileDriver,
      fetchWarehouse,
      fetchDriver,
      fetchDriverChoose,
      loadDriverPredictions,
      fetchDriverSuggestions,
      updateDriverSuggestions,
    },
  ),
  withContext(
    {
      fetchOrderList: PropTypes.func,
      batchAssignOrderLastMileDriver: PropTypes.func,
      fetchDriver: PropTypes.func,
      fetchDriverChoose: PropTypes.func,
      loadDriverPredictions: PropTypes.func,
      fetchDriverSuggestions: PropTypes.func,
      updateDriverSuggestions: PropTypes.func,
    },
    props => ({
      fetchOrderList: props.fetchOrderList,
      batchAssignOrderLastMileDriver: props.batchAssignOrderLastMileDriver,
      fetchDriver: props.fetchDriver,
      fetchDriverChoose: props.fetchDriverChoose,
      loadDriverPredictions: props.loadDriverPredictions,
      fetchDriverSuggestions: props.fetchDriverSuggestions,
      updateDriverSuggestions: props.updateDriverSuggestions,
    }),
  ),
);

SupplierOrderRoutingContainer.propTypes = {
  location: PropTypes.object,
  setLocationQuery: PropTypes.func,
  fetchOrderList: PropTypes.func,
  batchAssignOrderLastMileDriver: PropTypes.func,
  fetchWarehouse: PropTypes.func,
  fetchDriver: PropTypes.func,
  fetchDriverChoose: PropTypes.func,
  loadDriverPredictions: PropTypes.func,
  loadWarehouse: PropTypes.func,
  getCachedWarehouse: PropTypes.func,
  getWarehousePredictions: PropTypes.func,
  fetchDriverSuggestions: PropTypes.func,
  updateDriverSuggestions: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  orderFilter: PropTypes.instanceOf(DataListFilter),
  setLocationQueryFilter: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};
function SupplierOrderRoutingContainer(props) {
  return (
    <SupplierAppLayout
      slug="orders_routing"
      title={props.getLocalisationMessage("orders_map", "Orders Map")}
    >
      <SupplierOrderFilterWrapper
        filter={props.orderFilter}
        onFilterChange={filter => {
          props.replaceLocationHash(null);
          props.setLocationQueryFilter(filter);
        }}
        open={props.location.hash === FILTER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <OrderRouterIndex
        location={props.location}
        onFilterClick={() => props.replaceLocationHash(FILTER_DIALOG_HASH)}
        loadWarehouse={loadWarehouse}
        fetchWarehouse={props.fetchWarehouse}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
      />
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierOrderRoutingContainer);
