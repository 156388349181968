import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { formatNumber, formatPercentage } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    container: {
      width: "100%",
      padding: {
        top: "5px",
        left: "10px",
        bottom: "10px",
        right: "10px",
      },
      color: "#43425D",
      "& h3": {
        lineHeight: "24px",
        fontSize: "22px",
      },
    },
    trend: {
      fontSize: "12px",
      marginBottom: "10px",
    },
    value: {
      fontSize: "30px",
      color: "#43425D",
      padding: "8px",
      marginTop: "30px",
    },
    title: {
      fontSize: "16px",
      textTransform: "uppercase",
      padding: "8px",
      textAlign: "center",
      lineHeight: "14px",
    },
    stats: {
      textTransform: "uppercase",
      fontSize: "12px",
      lineHeight: "16px",
    },
    difference: {
      color: "#fff",
      borderRadius: "5px",
      padding: "5px",
      minWidth: "30px",
      textAlign: "center",
      fontSize: "11px",
    },
    flexPadding: {
      paddingTop: "20px",
      padding: "8px",
    },
  }),
  mapPropsStream(propsStream => {
    const responseStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .switchMap(props =>
        props.getReport(props.filter).catch(() => Observable.of({})),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], Map()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData)
      .filter(response => response.get("payload"))
      .map(response => {
        const info = List().asMutable();
        const report = response.get("payload");

        const percentage =
          (report.get("delivery_time") * 100) /
          (report.get("delivery_attempted") > 0
            ? report.get("delivery_attempted")
            : 1);

        info.push(
          Map({
            label: "attempts",
            align: "flex-start",
            value: report.get("delivery_attempted"),
          }),
        );
        info.push(
          Map({
            label: "success",
            align: "flex-end",
            value: report.get("delivery_time"),
          }),
        );

        return fromJS({
          percentage,
          info: info.asImmutable(),
        });
      })
      .startWith(Map())
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(responseStream, (props, response) => ({
      ...props,
      data: response,
    }));
  }),
);

DeliveryAttemptsWidget.propTypes = {
  classes: PropTypes.object,
  getReport: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Map),
  filter: PropTypes.instanceOf(DataListFilter),
  title: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
};

function DeliveryAttemptsWidget(props) {
  const { classes, data, title, getLocalisationMessage } = props;

  return (
    <Card className={classes.container}>
      <FlexBox direction="column">
        <FlexBox
          flex={true}
          justify="center"
          align="center"
          className={classes.value}
        >
          {formatPercentage(data.get("percentage", 0))}
        </FlexBox>
        <FlexBox flex={true} justify="center" className={classes.title}>
          {title}
        </FlexBox>
        <FlexBox justify="space-between">
          {data.get("info") &&
            data.get("info").map((item, idx) => (
              <FlexBox
                key={idx}
                flex={true}
                justify={item.get("align")}
                className={classes.flexPadding}
              >
                <h4 className={classes.stats}>
                  {getLocalisationMessage(item.get("label"), item.get("label"))}
                  : {formatNumber(item.get("value"))}
                </h4>
              </FlexBox>
            ))}
        </FlexBox>
      </FlexBox>
    </Card>
  );
}

export default enhancer(DeliveryAttemptsWidget);
