import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const JOB_DRIVERS_SUGGESTION_URL = `${
  API_ROOT_URL
}/supplier/job/%s/driver/suggestion/`;

export const getSuggestedDrivers = (id, request: DataListFilter) =>
  api.getStream(sprintf(JOB_DRIVERS_SUGGESTION_URL, id), {
    params: request.getDefinedValues(),
  });
