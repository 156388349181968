import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import {
  Card,
  Table,
  CardContent,
  TableRow,
  ListSubheader,
  TableBody,
  Button,
  TableHead,
  TableCell,
} from "@material-ui/core";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDate, formatNumber } from "../../helpers/FormatUtils";
import {
  FINANCE_ORDER_LIST_URL,
  FINANCE_TRANSFER_ITEM_URL,
} from "../../constants/SupplierPathConstants";
import { getSupplierBalance } from "../../api/supplier/SupplierFinanceApi";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import PriceWrapper from "../../components/ui-core/PriceWrapper";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";

const ROWS = OrderedMap()
  .set("non_cod_orders", "Non COD Orders")
  .set("number_of_orders", "Number of COD Orders")
  .set("cod_balance_total", "COD value (AED)*");

const COLUMNS = OrderedMap()
  .set("notPickedUp", "Orders not picked up yet")
  .set("nonDelivered", "Non-delivered, picked up orders & COD value")
  .set("delivered", "Orders delivered/RTO & COD payable to Wing")
  .set("transferred", "COD payment sent to Wing, pending approval");

const enhancer = compose(
  useSheet({
    fullHeight: { height: "100%" },
    textCenter: { textAlign: "center" },
    th: { textAlign: "center", whiteSpace: "normal" },
    buttonLeft: { float: "left", marginBottom: "6px", marginLeft: "6px" },
    buttonRight: { float: "right", marginBottom: "6px", marginRight: "6px" },
  }),
  mapPropsStream(propsStream => {
    const supplierBalanceStream = propsStream
      .distinctUntilChanged(isEqualData)
      .switchMap(() =>
        getSupplierBalance().catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      );

    return propsStream
      .combineLatest(supplierBalanceStream, (props, supplierBalance) => ({
        ...props,
        balance: supplierBalance.get("payload"),
        isLoading: supplierBalance.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

SupplierFinanceDashboardContainer.propTypes = {
  classes: PropTypes.object,
  balance: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
};

function SupplierFinanceDashboardContainer(props) {
  const { classes } = props;

  return (
    <SupplierAppLayout title="Supplier Finance Dashboard">
      <PageLoading isLoading={props.isLoading} />

      <FlexBox container={8} flex="none" direction="column">
        <FlexBox gutter={8} direction="column">
          <FlexBox flex={true} direction="column">
            <Card>
              <ListSubheader>My Balance</ListSubheader>
              <Table selectable={false}>
                <TableHead displaySelectAll={false} adjustForCheckbox={false}>
                  <TableRow>
                    <TableCell className={classes.th} />
                    {COLUMNS.map((columnTitle, columnKey) => (
                      <TableCell key={columnKey} className={classes.th}>
                        {columnTitle}
                      </TableCell>
                    )).toArray()}
                  </TableRow>
                </TableHead>
                <TableBody selectable={false} displayRowCheckbox={false}>
                  {ROWS.map((rowTitle, rowKey) => (
                    <TableRow key={rowKey}>
                      <TableCell>{rowTitle}</TableCell>
                      {COLUMNS.map((columnTitle, columnKey) => (
                        <TableCell
                          key={columnKey}
                          className={classes.textCenter}
                        >
                          {formatNumber(
                            props.balance.getIn([
                              "balance_items",
                              columnKey,
                              rowKey,
                            ]),
                          )}
                        </TableCell>
                      )).toArray()}
                    </TableRow>
                  )).toArray()}
                </TableBody>
              </Table>
              <CardContent>
                <p>
                  * COD pending should be settled at minimum once a week unless
                  agreed for more frequest settlement in the agreement.
                </p>
              </CardContent>
            </Card>
          </FlexBox>
          <FlexBox flex={true} direction="column">
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true} direction="column">
                <Card className={classes.fullHeight}>
                  <ListSubheader>
                    YOUR COD PAYABLE TO WING{" "}
                    <PriceWrapper
                      price={props.balance.get("cod_balance")}
                      code={props.balance.getIn(["currency", "code"])}
                    />
                  </ListSubheader>
                  <CardContent>
                    {Boolean(
                      props.balance.get("last_payment_date") &&
                        props.balance.get("last_payment_amount"),
                    ) && (
                      <p>
                        Your last payment was on{" "}
                        {formatDate(props.balance.get("last_payment_date"))} for{" "}
                        <PriceWrapper
                          price={props.balance.get("last_payment_amount")}
                          code={props.balance.getIn(["currency", "code"])}
                        />
                      </p>
                    )}
                  </CardContent>
                  <Button
                    className={classes.buttonLeft}
                    disabled={!props.balance.get("last_payment_id")}
                    href={
                      FINANCE_TRANSFER_ITEM_URL +
                      props.balance.get("last_payment_id")
                    }
                  >
                    View Details
                  </Button>
                  <Button
                    className={classes.buttonRight}
                    href={FINANCE_ORDER_LIST_URL}
                  >
                    INITIATE PAYMENT
                  </Button>
                </Card>
              </FlexBox>
              <FlexBox flex={true} direction="column">
                <Card className={classes.fullHeight}>
                  <ListSubheader>
                    YOUR SETTLED COD TO DATE{" "}
                    <PriceWrapper
                      price={props.balance.getIn([
                        "balance_items",
                        "settled",
                        "cod_balance_total",
                      ])}
                    />
                    <PriceWrapper
                      code={props.balance.getIn(["currency", "code"])}
                      price={props.balance.getIn([
                        "balance_items",
                        "settled",
                        "cod_balance_total",
                      ])}
                    />
                  </ListSubheader>
                  <CardContent>
                    {Boolean(
                      props.balance.getIn([
                        "balance_items",
                        "settled",
                        "number_of_orders",
                      ]),
                    ) && (
                      <p>
                        For{" "}
                        {formatNumber(
                          props.balance.getIn([
                            "balance_items",
                            "settled",
                            "number_of_orders",
                          ]),
                        )}{" "}
                        orders
                      </p>
                    )}
                  </CardContent>
                </Card>
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <FlexBox flex={true} direction="column">
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true} direction="column">
                <Card>
                  <ListSubheader>Total orders assigned to date</ListSubheader>
                  <CardContent>
                    <p className={classes.textCenter}>
                      {props.balance.getIn(["summary_counts", "all"], "0")}
                    </p>
                  </CardContent>
                </Card>
              </FlexBox>
              <FlexBox flex={true} direction="column">
                <Card>
                  <ListSubheader>Delivered orders to date</ListSubheader>
                  <CardContent>
                    <p className={classes.textCenter}>
                      {`${props.balance.getIn(
                        ["summary_counts", "delivered"],
                        "0",
                      )} (${props.balance.getIn(
                        ["summary_counts", "delivered_percent"],
                        "0",
                      )} %)`}
                    </p>
                  </CardContent>
                </Card>
              </FlexBox>
              <FlexBox flex={true} direction="column">
                <Card>
                  <ListSubheader>RTO orders to date</ListSubheader>
                  <CardContent>
                    <p className={classes.textCenter}>
                      {`${props.balance.getIn(
                        ["summary_counts", "rto"],
                        "0",
                      )} (${props.balance.getIn(
                        ["summary_counts", "rto_percent"],
                        "0",
                      )} %)`}
                    </p>
                  </CardContent>
                </Card>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierFinanceDashboardContainer);
