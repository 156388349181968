import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const SUPPLIER_CHOOSE_URL = `${API_ROOT_URL}/supplier/supplier/choose`;
const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(SUPPLIER_CHOOSE_URL, { params: request.getDefinedValues() }),
});

export const getSupplierPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);
