import React from "react";
import { Map, OrderedMap } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FinanceOrderTableTabs from "../finance-core/FinanceOrderTableTabs";
import { formatSupplierCODStatusCodeLocalised } from "../../helpers/OrderHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  COD_PENDING,
  COD_COLLECTED,
  COD_WITH_WING,
  COD_TRANSFERRED,
  COD_WITH_DRIVER,
  COD_WITH_SAAS_BANK,
  COD_WITH_WING_BANK,
  COD_TO_BE_COLLECTED,
  COD_PAID_TO_MERCHANT,
  COD_TRANSFER_REJECTED,
  COD_WITHDRAW_REJECTED,
  COD_WITHDRAW_REQUESTED,
  ALL_STATUSES_IN_FINANCE,
} from "../../constants/CODStatus";

const ALL_TAB = [
  ALL_STATUSES_IN_FINANCE,
  COD_COLLECTED,
  COD_TO_BE_COLLECTED,
  COD_WITH_WING,
  COD_WITH_WING_BANK,
  COD_WITH_SAAS_BANK,
  COD_PENDING,
  COD_PAID_TO_MERCHANT,
  COD_TRANSFERRED,
  COD_WITHDRAW_REQUESTED,
  COD_WITHDRAW_REJECTED,
  COD_TRANSFER_REJECTED,
].join(",");

const PENDING_TAB = [COD_COLLECTED, COD_PENDING].join(",");
const WITH_WING_TAB = [
  COD_WITH_WING,
  COD_WITH_WING_BANK,
  COD_PAID_TO_MERCHANT,
  COD_WITHDRAW_REQUESTED,
].join(",");

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

SupplierFinanceOrderListTabs.propTypes = {
  location: PropTypes.object.isRequired,
  totalCounts: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
};

function SupplierFinanceOrderListTabs(props) {
  const { getLocalisationMessage } = props;

  const statusCodes = OrderedMap()
    .set(ALL_TAB, getLocalisationMessage("all", "All"))
    .set(
      COD_TO_BE_COLLECTED,
      formatSupplierCODStatusCodeLocalised(
        COD_TO_BE_COLLECTED,
        getLocalisationMessage,
      ),
    )
    .set(COD_WITH_DRIVER, getLocalisationMessage("with_driver", "With Driver"))
    .set(PENDING_TAB, getLocalisationMessage("pending", "Pending"))
    .set(
      COD_TRANSFERRED,
      formatSupplierCODStatusCodeLocalised(
        COD_TRANSFERRED,
        getLocalisationMessage,
      ),
    )
    .set(
      WITH_WING_TAB,
      getLocalisationMessage("paid_to_company", "Paid To Company"),
    );

  return (
    <FinanceOrderTableTabs
      location={props.location}
      statusCodes={statusCodes}
      totalCounts={props.totalCounts}
      formatCODStatusCode={code => statusCodes.get(code)}
    />
  );
}

export default enhancer(SupplierFinanceOrderListTabs);
