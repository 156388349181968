import sprintf from "sprintf";

import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const DRIVER_ALERT_URL = `${API_ROOT_URL}/supplier/alert`;
const ALERTS_LIST_URL = `${API_ROOT_URL}/supplier/alerts`;
const ALERT_ITEM_URL = `${API_ROOT_URL}/supplier/alert/%s`;
const ALERT_ORDER_RECEIVED_URL = `${
  API_ROOT_URL
}/alert/supplier/order_received`;
const DELETE_ALERT_ITEM_URL = `${API_ROOT_URL}/supplier/alert/%s/delete`;
const DISMISS_ALERT_ITEM_URL = `${API_ROOT_URL}/supplier/alert/%s/dismiss`;
const DRIVER_ALERTS_LIST_URL = `${API_ROOT_URL}/supplier/driver/%s/alerts`;
const DRIVER_GROUP_STATISTICS = `${
  API_ROOT_URL
}/supplier/driver_group_statistics`;
const DRIVER_DETAILED_ALERT_URL = `${
  API_ROOT_URL
}/supplier/driver/%s/detailed/alert`;

export type DriverAlertRequest = {
  created_date: string,
  dismissed_time: string,
  driver: {
    description: string,
    id: number,
    name: string,
    phone: string,
    photo: string,
  },
  id: number,
  order_id: number,
  status: string,
  type: string,
};

export const updateDriverAlert = (body: DriverAlertRequest) =>
  api.put(DRIVER_ALERT_URL, { body });
export const createDriverAlert = (body: DriverAlertRequest) =>
  api.post(DRIVER_ALERT_URL, { body });

export const deleteAlertItem = alertId =>
  api.put(sprintf(DELETE_ALERT_ITEM_URL, alertId));
export const dismissAlertItem = alertId =>
  api.put(sprintf(DISMISS_ALERT_ITEM_URL, alertId));

export const getAlertItem = alertId =>
  api.getStream(sprintf(ALERT_ITEM_URL, alertId));
export const getAlertsList = (request: DataListFilter) =>
  api.getStream(ALERTS_LIST_URL, { params: request.getDefinedValues() });

export const updateOrderReceivedAlerts = params =>
  api.putStream(ALERT_ORDER_RECEIVED_URL, { params });

export const getDriverGroupStatistics = params =>
  api.getStream(DRIVER_GROUP_STATISTICS, { params });
export const getDriverDetailedAlert = driverId =>
  api.getStream(sprintf(DRIVER_DETAILED_ALERT_URL, driverId));
export const getAlertsHistoryList = (driverId, request: DataListFilter) =>
  api.getStream(sprintf(DRIVER_ALERTS_LIST_URL, driverId), {
    params: request.getDefinedValues(),
  });
