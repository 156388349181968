import React from "react";
import _ from "lodash";
import { addMinutes } from "date-fns";
import I, { Map, Set, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  withHandlers,
  withPropsOnChange,
} from "recompose";
import PropTypes from "prop-types";
import { SubmissionError } from "redux-form";
import { Card, CardContent, CardHeader, Button } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import OrderListStore from "../../stores/OrderListStore";
import { changeOrderRoute } from "../../actions/OrderRoutingActions";
import { isValidObjectId } from "../../helpers/ValidateUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { parseIntString } from "../../helpers/SerializeUtils";
import { toOrderFilter } from "../../helpers/OrderFilterMapper";
import { isAnyOrderUpdating } from "../../reducers/OrderReducer";
import {
  getDriverSuggestions,
  getDriverCustomSuggestions,
} from "../../reducers/DriverReducer";
import { getWarehouse } from "../../reducers/WarehouseReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  getClusters,
  getClusteringError,
  isClusteringPending,
  getClusteringProgress,
  clearOrderClusterError,
} from "../../reducers/OrderClusterReducer";
import FormWarehouseDialog from "../../components/form/FormWarehouseDialog";
import AdminOrderListMapCluster from "../../components/admin/AdminOrderListMapCluster";
import PageLoading from "../../components/ui-core/PageLoading";
import PageProgressOverlay from "../../components/deprecated/PageProgressOverlay";
import OrderRouterMap from "../../components/order-router/OrderRouterMap";
import OrderRouterOrders from "../../components/order-router/OrderRouterOrders";
import OrderRouterSidebar from "../../components/order-router/OrderRouterSidebar";
import OrderRouterChangeClusterDialog from "../../components/order-router/OrderRouterChangeClusterDialog";
import Notification from "../../components/notifications/Notification";
import { updateQuery as updateLocationQuery } from "../../../shared/helpers/UrlUtils";
import { CREATE_DRIVER_RUNSHEET_URL } from "../../../shared/constants/FileProxyControllerConstants";
import neighbourhoods from "../../actions/_neighbourhoods.json";

const FIND_ORDERS_CARD = "findOrders";
const FIND_DRIVERS_CARD = "findDrivers";
const CLUSTER_ORDERS_CARD = "clusterOrders";

const zones = fromJS(neighbourhoods);

const cards = Set.of(FIND_ORDERS_CARD, FIND_DRIVERS_CARD, CLUSTER_ORDERS_CARD);
const mapInt = (value, notSetValue = null) =>
  _.isNil(value) ? notSetValue : _.toLength(value);

const updateQuery = (props, nextQuery, merge = true) => {
  props.router.push({
    pathname: props.location.pathname,
    query: merge
      ? _.omitBy(_.assign({}, props.location.query, nextQuery), _.isNil)
      : nextQuery,
  });
};

const createQueryChangeHandler = (
  mapArgumentToQuery,
  merge,
) => props => arg => {
  updateQuery(props, mapArgumentToQuery(arg, props), merge);
};

const normalizeWH = wh =>
  wh
    ? wh.merge({
        wkt: null,
        status: null,
        suppliers: null,
        lat: wh.get("lat"),
        lon: wh.get("lon"),
      })
    : null;

const enhancer = compose(
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    router: PropTypes.object,
    changeOrderRoute: PropTypes.func,
    fetchOrderList: PropTypes.func,
    fetchDriverSuggestions: PropTypes.func,
    updateDriverSuggestions: PropTypes.func,
    batchAssignOrderLastMileDriver: PropTypes.func,
    fetchSupplier: PropTypes.func,
    loadSupplierPredictions: PropTypes.func,
    fetchDriver: PropTypes.func,
    fetchDriverChoose: PropTypes.func,
    loadDriverPredictions: PropTypes.func,
    clearOrderClusterError: PropTypes.func,
  }),
  useSheet({
    card: { position: "relative" },
    driverSuggestions: { marginTop: "12px" },
  }),
  withPropsOnChange(
    ({ location: { query: a } }, { location: { query: b } }) =>
      !_.isEqual(a, b),
    props => {
      const {
        location: { query },
      } = props;
      const orderIds = Set(parseIntString(query.orderIds));

      return {
        editOrderId: mapInt(query.editOrder),
        selectedOrderId: mapInt(query.selectedOrder),
        selectedCluster: mapInt(query.selectedCluster),
        selectedCard: cards.has(query.selectedCard) ? query.selectedCard : null,
        warehouseId: mapInt(query.warehouse_id),
        destinationWarehouseId: mapInt(query.destination_warehouse_id),
        orderIds,
        queueFilter: DataListFilter.createFromIds(orderIds),
        orderFilter: toOrderFilter(query),
        clusterOptions: Map({
          avoidTolls: false,
          avoidHighways: false,
          durationInTraffic: false,
          mergeNoSupplier: query.mergeNoSupplier !== "false",
          maxDuration: mapInt(query.maxDuration, 120),
          sortingDuration: mapInt(query.sortingDuration, 0),
          handlingDuration: mapInt(query.handlingDuration, 15),
        }),
        fitToOrderIds: Set(parseIntString(query.fitToOrders)),
      };
    },
  ),
  connect(
    (state, props) => {
      const orderListStore: OrderListStore = state.OrderListStore;

      const orders = props.orderIds
        .toMap()
        .map(id => orderListStore.getShortItem(id))
        .filter(Boolean);

      const warehouse = getWarehouse(state, props.warehouseId);
      const destinationWarehouse = getWarehouse(
        state,
        props.destinationWarehouseId,
      );

      const clusterOptions = props.clusterOptions.merge({
        zones,
        warehouse: normalizeWH(warehouse),
        destinationWarehouse: normalizeWH(destinationWarehouse),
        locations: orders.map(item =>
          Map({
            size: item.get("size"),
            orderId: item.get("id"),
            supplierId: item.getIn(["supplier", "id"], null),
            lat: item.getIn(["locations", 1, "lat"]),
            lng: item.getIn(["locations", 1, "lon"]),
            lon: item.getIn(["locations", 1, "lon"]),
          }),
        ),
      });

      const clusters = getClusters(state, clusterOptions);
      const suggestions = getDriverSuggestions(state, clusters);

      return {
        zones,
        clusters,
        clusterOptions,

        orders,
        suggestions,

        clusteringError: getClusteringError(state, clusterOptions),
        isClusteringPending: isClusteringPending(state, clusterOptions),
        clusteringProgress: getClusteringProgress(state, clusterOptions),

        isOrdersUpdating: isAnyOrderUpdating(state),
        customSuggestions: getDriverCustomSuggestions(state, clusters),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    {
      changeOrderRoute,
      clearOrderClusterError,
    },
  ),
  withPropsOnChange(
    (a, b) =>
      !I.is(a.clusters, b.clusters) ||
      !I.is(a.suggestions, b.suggestions) ||
      !I.is(a.customSuggestions, b.customSuggestions),
    ({ clusters, suggestions, customSuggestions }) => {
      if (!clusters) {
        return { driverIds: List() };
      }

      return {
        driverIds: clusters.map((cluster, index) => {
          const driver =
            customSuggestions.get(index) ||
            suggestions.getIn([index, "driver"]);

          return _.toFinite(driver && driver.get("id"));
        }),
      };
    },
  ),
  withHandlers({
    onRemoveDuplicatedOrderClick: props => () => {
      const locations = props.clusterOptions.get("locations");
      const orderIds = Set().withMutations(set => {
        const items = {};

        locations.forEach(item => {
          const id = item.get("orderId");
          const hash = `${item.get("lat")}-${item.get("lng")}`;

          if (_.has(items, hash)) {
            set.delete(id);
            set.delete(items[hash]);
          } else {
            set.add(id);
            items[hash] = id;
          }
        });
      });

      props.router.push({
        pathname: props.location.pathname,
        query: { ...props.location.query, orderIds: orderIds.join(",") },
      });
    },

    onQueryChange: createQueryChangeHandler(_.identity),
    onFilterChange: createQueryChangeHandler(
      (filter, { location: { query } }) => filter.toRequestParams(query),
      false,
    ),
    onOrderSelect: createQueryChangeHandler(selectedOrder => ({
      selectedOrder,
      fitToOrders: selectedOrder,
    })),
    onFitToOrders: createQueryChangeHandler(ids => ({
      fitToOrders: ids.join(","),
    })),
    onMapCenterChange: createQueryChangeHandler(() => ({ fitToOrders: null })),
    onOrderEdit: createQueryChangeHandler(editOrder => ({ editOrder })),
    onOrderEditClose: createQueryChangeHandler(() => ({ editOrder: null })),
    onClusterSelect: createQueryChangeHandler(selectedCluster => ({
      selectedCluster,
    })),
    onCardSelect: createQueryChangeHandler(selectedCard => ({ selectedCard })),
    onOrderDelete: createQueryChangeHandler((id, { orderIds }) => ({
      orderIds: orderIds.delete(id).join(","),
    })),
    onOrderIdsChange: createQueryChangeHandler(orderIds => ({
      orderIds: orderIds.join(","),
      selectedCard: null,
    })),
    onOrdersDelete: createQueryChangeHandler(() => ({ orderIds: null })),
    onFindOrdersExpandChange: createQueryChangeHandler(expanded => ({
      selectedCard: expanded ? FIND_ORDERS_CARD : null,
    })),
    onClusterOrdersExpandChange: createQueryChangeHandler(expanded => ({
      selectedCard: expanded ? CLUSTER_ORDERS_CARD : null,
    })),
    onRequestCloseDialog: ({ router, location: { pathname, query } }) => () =>
      router.push({ pathname, query }),
    onOrderClusterChange: props => clusterIndex => {
      updateQuery(props, { editOrder: null });

      props.changeOrderRoute(
        props.editOrderId,
        clusterIndex,
        props.clusterOptions,
      );
    },
    onDriverChange: props => (clusterIndex, driver) => {
      props.updateDriverSuggestions(
        props.clusters,
        props.customSuggestions.set(clusterIndex, Map(driver || {})),
      );
    },
    onSubmitSuggestions: props => () => {
      const items = [];
      const now = new Date();

      props.clusters.forEach((cluster, index) => {
        const driverId = props.driverIds.get(index);

        if (driverId > 0) {
          cluster.forEach((metrics, item) => {
            const orderId = item.get("orderId");

            if (orderId > 0) {
              items.push({
                id: driverId,
                order_id: orderId,
                deadline_time: addMinutes(now, metrics.get("overAllDuration")),
              });
            }
          });
        }
      });

      props.batchAssignOrderLastMileDriver(items).then(() => {
        props.fetchOrderList(props.queueFilter.getDefinedValues());

        window.open(
          updateLocationQuery(CREATE_DRIVER_RUNSHEET_URL, {
            ids: props.driverIds.join(","),
          }),
        );
      });
    },
  }),
);

class OrderRouterIndex extends React.Component {
  static propTypes = {
    sheet: PropTypes.object,
    setLocationQuery: PropTypes.func,
    editOrderId: PropTypes.number,
    selectedOrderId: PropTypes.number,
    selectedCluster: PropTypes.number,
    selectedCard: PropTypes.string,
    warehouseId: PropTypes.number,
    destinationWarehouseId: PropTypes.number,
    onOrderIdsChange: PropTypes.func,
    orderIds: PropTypes.instanceOf(Set),
    queueFilter: PropTypes.instanceOf(DataListFilter),
    orderFilter: PropTypes.instanceOf(DataListFilter),
    clusterOptions: PropTypes.instanceOf(Map),
    fitToOrderIds: PropTypes.instanceOf(Set),
    orders: PropTypes.instanceOf(Map),
    clusters: PropTypes.instanceOf(List),
    suggestions: PropTypes.instanceOf(List),
    customSuggestions: PropTypes.instanceOf(List),
    driverIds: PropTypes.instanceOf(List),
    zones: PropTypes.instanceOf(List),
    isOrdersUpdating: PropTypes.bool,
    changeOrderRoute: PropTypes.func,
    fetchOrderList: PropTypes.func,
    fetchWarehouse: PropTypes.func.isRequired,
    fetchSupplier: PropTypes.func,
    loadSupplierPredictions: PropTypes.func,
    fetchDriver: PropTypes.func,
    fetchDriverChoose: PropTypes.func,
    loadDriverPredictions: PropTypes.func,
    fetchDriverSuggestions: PropTypes.func,
    onFilterChange: PropTypes.func,
    onQueryChange: PropTypes.func,
    onOrderSelect: PropTypes.func,
    onFitToOrders: PropTypes.func,
    onMapCenterChange: PropTypes.func,
    onOrderEdit: PropTypes.func,
    onOrderEditClose: PropTypes.func,
    onClusterSelect: PropTypes.func,
    onCardSelect: PropTypes.func,
    onOrdersAdd: PropTypes.func,
    onOrderDelete: PropTypes.func,
    onOrdersDelete: PropTypes.func,
    onFindOrdersExpandChange: PropTypes.func,
    onClusterOrdersExpandChange: PropTypes.func,
    onRemoveDuplicatedOrderClick: PropTypes.func,
    onOrderClusterChange: PropTypes.func,
    onDriverChange: PropTypes.func,
    onSubmitSuggestions: PropTypes.func,
    updateDriverSuggestions: PropTypes.func,
    onFilterClick: PropTypes.func.isRequired,
    onRequestCloseDialog: PropTypes.func,
    getCachedWarehouse: PropTypes.func,
    getWarehousePredictions: PropTypes.func,
    loadWarehouse: PropTypes.func,
    location: PropTypes.object,
    clusteringProgress: PropTypes.number,
    isClusteringPending: PropTypes.bool,
    clusteringError: PropTypes.instanceOf(Error),
    clearOrderClusterError: PropTypes.func,
    getLocalisationMessage: PropTypes.func,
  };

  static childContextTypes = {
    fetchSupplier: PropTypes.func,
    loadSupplierPredictions: PropTypes.func,
    fetchDriver: PropTypes.func,
    fetchDriverChoose: PropTypes.func,
    loadDriverPredictions: PropTypes.func,
  };

  getChildContext() {
    return {
      fetchSupplier: this.props.fetchSupplier,
      loadSupplierPredictions: this.props.loadSupplierPredictions,
      fetchDriver: this.props.fetchDriver,
      fetchDriverChoose: this.props.fetchDriverChoose,
      loadDriverPredictions: this.props.loadDriverPredictions,
    };
  }

  componentDidMount() {
    this.fetchOrderList();
    this.fetchOrderByIds();

    this.fetchWarehouse();
    this.fetchDestinationWarehouse();
    this.fetchDriverSuggestions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.warehouseId !== prevProps.warehouseId) {
      this.fetchWarehouse();
    }

    if (
      this.props.destinationWarehouseId !== prevProps.destinationWarehouseId
    ) {
      this.fetchDestinationWarehouse();
    }

    if (!I.is(this.props.clusters, prevProps.clusters)) {
      this.fetchDriverSuggestions();
    }

    if (!I.is(this.props.queueFilter, prevProps.queueFilter)) {
      this.fetchOrderByIds();
    }

    if (!I.is(this.props.orderFilter, prevProps.orderFilter)) {
      this.fetchOrderList();
    }
  }

  fetchOrderList() {
    this.props.fetchOrderList(this.props.orderFilter.toRequestParams());
  }

  fetchOrderByIds() {
    if (!this.props.orderIds.isEmpty()) {
      this.props.fetchOrderList(this.props.queueFilter.toRequestParams());
    }
  }

  fetchWarehouse() {
    if (this.props.warehouseId > 0) {
      this.props.fetchWarehouse(this.props.warehouseId);
    }
  }

  fetchDestinationWarehouse() {
    if (this.props.destinationWarehouseId > 0) {
      this.props.fetchWarehouse(this.props.destinationWarehouseId);
    }
  }

  fetchDriverSuggestions() {
    if (this.props.clusters) {
      this.props.fetchDriverSuggestions(this.props.clusters);
    }
  }

  render() {
    const {
      sheet: { classes },
      location,
      orders,
      editOrderId,
      clusters,
      selectedCard,
      getLocalisationMessage,
    } = this.props;

    const hasOrders = !orders.isEmpty();
    const withoutSelectedCard = !selectedCard;
    const expandFindOrders = selectedCard === FIND_ORDERS_CARD;
    const expandClusterOrders = selectedCard === CLUSTER_ORDERS_CARD;
    const showChangeClusterDialog =
      Boolean(clusters) && orders.has(editOrderId);

    return (
      <div>
        <PageLoading
          isLoading={this.props.isOrdersUpdating || !this.props.zones}
        />

        {Boolean(this.props.clusteringError) && (
          <Notification
            open={true}
            type="error"
            uid="clusteringError"
            action={
              <Button onClick={this.props.clearOrderClusterError}>
                {getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
            }
          >
            {this.props.clusteringError.message}
          </Notification>
        )}

        <PageProgressOverlay
          open={this.props.isClusteringPending}
          progress={this.props.clusteringProgress}
        />

        <FormWarehouseDialog
          open={
            location.query.edit_warehouse === "true" ||
            location.query.edit_destination_warehouse === "true"
          }
          onRequestClose={() =>
            this.props.setLocationQuery(
              fp.omit(["edit_warehouse", "edit_destination_warehouse"]),
            )
          }
          getCachedWarehouse={this.props.getCachedWarehouse}
          getWarehousePredictions={this.props.getWarehousePredictions}
          initialValues={{
            warehouse: {
              id:
                location.query.edit_warehouse === "true"
                  ? this.props.warehouseId
                  : this.props.destinationWarehouseId,
            },
          }}
          onSubmit={values =>
            !isValidObjectId(values.warehouse)
              ? null
              : this.props
                  .loadWarehouse(values.warehouse.id)
                  .toPromise()
                  .then(
                    fp.flow(fp.get("payload.data"), warehouse => {
                      if (fp.isNil(warehouse.lat) || fp.isNil(warehouse.lon)) {
                        throw new SubmissionError({
                          warehouse: getLocalisationMessage(
                            "selected_warehouse_has_no_location_coordinates",
                            "Selected warehouse has no location coordinates",
                          ),
                        });
                      }

                      return warehouse;
                    }),
                  )
          }
          onSubmitSuccess={warehouse => {
            const queryParam =
              location.query.edit_warehouse === "true"
                ? "warehouse_id"
                : "destination_warehouse_id";

            this.props.setLocationQuery(
              fp.flow(
                fp.omit(["edit_warehouse", "edit_destination_warehouse"]),
                warehouse
                  ? fp.set(queryParam, warehouse.id)
                  : fp.unset(queryParam),
              ),
            );
          }}
        />

        <OrderRouterMap
          clusters={this.props.clusters}
          selectedOrderId={this.props.selectedOrderId}
          selectedClusterIndex={this.props.selectedCluster}
          zones={this.props.zones}
          orders={this.props.orders}
          onOrderClick={this.props.onOrderSelect}
          onOrderDelete={this.props.onOrderDelete}
          fitToOrderIds={this.props.fitToOrderIds}
          onCenterChanged={this.props.onMapCenterChange}
          onChangeClusterClick={this.props.onOrderEdit}
          getLocalisationMessage={getLocalisationMessage}
        />

        {showChangeClusterDialog ? (
          <OrderRouterChangeClusterDialog
            clusters={this.props.clusters}
            order={this.props.orders.get(this.props.editOrderId)}
            onRequestClose={this.props.onOrderEditClose}
            onClusterChange={this.props.onOrderClusterChange}
          />
        ) : null}

        <FlexBox gutter={8} container={8}>
          {withoutSelectedCard || expandFindOrders || !hasOrders ? (
            <FlexBox flex={true} direction="column">
              <Card
                className={classes.card}
                expanded={expandFindOrders}
                onExpandChange={this.props.onFindOrdersExpandChange}
              >
                <CardHeader
                  title={getLocalisationMessage("find_orders", "Find Orders")}
                  actAsExpander={true}
                  showExpandableButton={true}
                />

                <CardContent expandable={true}>
                  <OrderRouterOrders
                    filter={this.props.orderFilter}
                    onFilterChange={this.props.onFilterChange}
                    orderIds={this.props.orderIds}
                    onOrderIdsChange={this.props.onOrderIdsChange}
                    onFilterClick={this.props.onFilterClick}
                  />
                </CardContent>
              </Card>
            </FlexBox>
          ) : null}

          {(withoutSelectedCard || expandClusterOrders) && hasOrders ? (
            <FlexBox flex={true} direction="column">
              <Card
                className={classes.card}
                expanded={expandClusterOrders}
                onExpandChange={this.props.onClusterOrdersExpandChange}
              >
                <CardHeader
                  title={getLocalisationMessage(
                    "cluster_orders",
                    "Cluster Orders",
                  )}
                  actAsExpander={true}
                  showExpandableButton={true}
                />

                <CardContent expandable={true}>
                  <AdminOrderListMapCluster
                    orders={this.props.orders}
                    clusterOptions={this.props.clusterOptions}
                    onOptionsChange={this.props.onQueryChange}
                    onEditWarehouseClick={() =>
                      this.props.setLocationQuery(
                        fp.set("edit_warehouse", true),
                      )
                    }
                    onEditDestinationWarehouseClick={() =>
                      this.props.setLocationQuery(
                        fp.set("edit_destination_warehouse", true),
                      )
                    }
                  />
                </CardContent>
              </Card>
            </FlexBox>
          ) : null}

          {(withoutSelectedCard || expandClusterOrders) && hasOrders ? (
            <FlexBox flex={true} direction="column">
              <Card className={classes.card}>
                <OrderRouterSidebar
                  driverIds={this.props.driverIds}
                  orders={this.props.orders}
                  clusters={this.props.clusters}
                  suggestions={this.props.suggestions}
                  customSuggestions={this.props.customSuggestions}
                  onOrderClick={this.props.onOrderSelect}
                  onDeleteAll={this.props.onOrdersDelete}
                  onOrderDelete={this.props.onOrderDelete}
                  onFitToOrders={this.props.onFitToOrders}
                  onClusterClick={this.props.onClusterSelect}
                  onChangeClusterClick={this.props.onOrderEdit}
                  onClusterSelect={this.props.onClusterSelect}
                  selectedClusterIndex={this.props.selectedCluster}
                  onDriverChange={this.props.onDriverChange}
                  onSubmitSuggestions={this.props.onSubmitSuggestions}
                  onRemoveDuplicatedOrderClick={
                    this.props.onRemoveDuplicatedOrderClick
                  }
                />
              </Card>
            </FlexBox>
          ) : null}
        </FlexBox>
      </div>
    );
  }
}

export default enhancer(OrderRouterIndex);
