import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { Card } from "@material-ui/core";
import { grey, orange } from "@material-ui/core/colors";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FlexBox from "../ui-core/FlexBox";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    container: {
      width: "100%",
      padding: {
        top: "10px",
        left: "15px",
        bottom: "30px",
        right: "15px",
      },
      color: "#43425D",
    },
    hintText: {
      fontSize: "12px",
      zIndex: "10",
    },
  }),
  withState("state", "setState", {
    focus: false,
  }),
  reduxForm({
    form: "TrackOrderForm",
    enableReinitialize: true,
  }),
);

TrackOrderWidget.propTypes = {
  classes: PropTypes.object,
  filter: PropTypes.instanceOf(DataListFilter),
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  setState: PropTypes.func,
  state: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
};

function TrackOrderWidget(props) {
  const { classes, state, getLocalisationMessage } = props;

  return (
    <form onSubmit={props.handleSubmit} style={{ width: "100%" }}>
      <Card className={classes.container}>
        <FlexBox direction="column">
          <FlexBox direction="column" flex={true}>
            <FormTextField
              name="orderNumber"
              fullWidth={true}
              label={getLocalisationMessage(
                "track_shipment",
                "Track a Shipment",
              )}
              floatingLabelStyle={{
                color: state.focus ? orange[500] : grey[500],
              }}
              underlineFocusStyle={{ borderColor: orange[500] }}
              placeholder={getLocalisationMessage(
                "awb_or_reference_id_or_phone",
                "AWB or Reference ID or Phone",
              )}
              errorText={getLocalisationMessage(
                "this_field_is_required",
                "This field is required.",
              )}
              parseOnBlur={() => props.setState(fp.set("focus", false))}
              onFocus={() => props.setState(fp.set("focus", true))}
            />
            <span className={classes.hintText}>
              {getLocalisationMessage(
                "search_term",
                "Search term can be an awb or reference id, or phone number",
              )}
            </span>
          </FlexBox>
        </FlexBox>
      </Card>
    </form>
  );
}

export default enhancer(TrackOrderWidget);
