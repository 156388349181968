import React from "react";
import { format as dateFormat } from "date-fns";
import { Map, List, OrderedSet } from "immutable";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { MenuItem, fade } from "@material-ui/core";
import { connect } from "react-redux";
import SpikeChart from "../charts/SpikeChart";
import MenuButtonMore from "../ui-core/MenuButtonMore";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDate, formatNumber } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const formatDayMonthYear = (year, month, day) =>
  dateFormat(new Date(year, month, day), "dd MMM yyyy");

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const statsStream = propsStream
      .distinctUntilChanged(isEqualData)
      .map(props =>
        Map().withMutations(items => {
          const datasets = OrderedSet().asMutable();
          const info = List().asMutable();
          const data = OrderedSet().asMutable();
          const labels = [];

          const maxThreshold = 6;
          const minThreshold = 4;

          const percentage =
            (props.reports1.get("rto_returned") /
              props.reports1.get("rto_dispatched")) *
            100
              ? (props.reports1.get("rto_returned") /
                  props.reports1.get("rto_dispatched")) *
                100
              : 0;

          items.set("percentage", percentage);

          const { getLocalisationMessage } = props;

          props.reports.forEach(item => {
            labels.push(
              formatDate(
                formatDayMonthYear(
                  item.get("year"),
                  item.get("month") - 1,
                  item.get("day"),
                ),
              ),
            );
            data.add(formatNumber(item.get("daily_rtopercantage", 0)));
          });

          info.push(
            Map({
              label: getLocalisationMessage("dispatched", "Dispatched"),
              align: "flex-start",
              value: props.reports1.get("rto_dispatched"),
            }),
          );
          info.push(
            Map({
              label: getLocalisationMessage("rto_count", "RTO Count"),
              align: "flex-end",
              value: props.reports1.get("rto_returned"),
            }),
          );

          datasets.add(Map({ data }));

          items.set(
            "chartData",
            Map({ labels, datasets: datasets.asImmutable() }),
          );
          items.set("infoData", info);
          items.set(
            "backgroundColor",
            percentage >= maxThreshold
              ? "#E53935"
              : percentage >= minThreshold
              ? "#FFB300"
              : "#7CB342",
          );
        }),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(statsStream, (props, stats) => ({
        ...props,
        stats,
      }));
  }),
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
    },
    title: {
      fontSize: "18px",
      textTransform: "uppercase",
    },
    info: {
      paddingTop: "20px",
    },
    moreBtn: {
      color: fade("#fff", 0.5),
    },
  }),
);

OFDChartComponent.propTypes = {
  classes: PropTypes.object,
  stats: PropTypes.object,
  reports: PropTypes.instanceOf(List),
  reports1: PropTypes.instanceOf(Map),
  title: PropTypes.string,
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function OFDChartComponent(props) {
  return (
    <SpikeChart
      backgroundColor={props.stats.get("backgroundColor")}
      color="#ffffff"
      title={props.getLocalisationMessage("rto", "RTO")}
      data={props.stats}
      actionIcons={
        <div>
          <MenuButtonMore
            isHorizontal={true}
            iconClassName={props.classes.moreBtn}
          >
            <MenuItem onClick={() => props.onRequestRefresh()}>
              {props.getLocalisationMessage("refresh", "Refresh")}
            </MenuItem>
          </MenuButtonMore>
        </div>
      }
      tooltipFormat={{
        displayColors: false,
        callbacks: {
          title() {
            return null;
          },
          label(tooltipItem) {
            return `${tooltipItem.xLabel}: ${tooltipItem.yLabel}%`;
          },
        },
      }}
    />
  );
}

export default enhancer(OFDChartComponent);
