import React from "react";
import { differenceInMinutes, formatDistance } from "date-fns";
import Immutable from "immutable";
import PropTypes from "prop-types";
import { Marker, InfoWindow, MarkerIcon } from "react-google-map-components";
import FlexBox from "../ui-core/FlexBox";
import { formatOrderStatusCode } from "../../helpers/OrderHelper";
import { formatDriverName } from "../../helpers/DriverHelper";
import CarRed from "./assets/car_red.png";
import CarGrey from "./assets/car_grey.png";
import CarGreen from "./assets/car_green.png";

const isIdle = x => differenceInMinutes(new Date(), x);

DriverMarker.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onActivate: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,

  order: PropTypes.instanceOf(Immutable.Map),
  driver: PropTypes.instanceOf(Immutable.Map).isRequired,
  position: PropTypes.instanceOf(Immutable.Map).isRequired,
  getLocalisationMessage: PropTypes.func,
};

export default function DriverMarker(props) {
  const { driver, position, order, getLocalisationMessage } = props;

  const center = {
    lat: position.get("lat"),
    lng: position.get("lon"),
  };

  return (
    <div>
      <Marker
        position={center}
        onClick={props.onActivate}
        icon={
          <MarkerIcon
            url={
              driver.get("has_order")
                ? CarRed
                : isIdle(props.position.get("date"))
                ? CarGrey
                : CarGreen
            }
          />
        }
      />

      {props.isActive && (
        <InfoWindow
          open={true}
          position={center}
          onCloseClick={props.onDeactivate}
        >
          <FlexBox>
            <FlexBox flex={true} direction="column">
              <strong>Name: {formatDriverName(driver)}</strong>
              <strong>Phone: {driver.get("phone") || "N/A"}</strong>
              <strong>
                {getLocalisationMessage(
                  "update_location_time",
                  "Update Location Time",
                )}
                :{" "}
                {formatDistance(position.get("date"), {
                  includeSeconds: true,
                })}
              </strong>
            </FlexBox>

            {Boolean(order) && (
              <FlexBox flex={true} direction="column">
                <strong>
                  {getLocalisationMessage("order_id", "Order ID")}:{" "}
                  {order.get("order_number")}
                </strong>

                <strong>
                  {getLocalisationMessage("order_status", "Order Status")}:{" "}
                  {formatOrderStatusCode(order.get("status"))}
                </strong>

                <strong>
                  {getLocalisationMessage("courier", "Courier")}:{" "}
                  {order.getIn(["supplier", "name"])}
                </strong>
              </FlexBox>
            )}
          </FlexBox>
        </InfoWindow>
      )}
    </div>
  );
}
