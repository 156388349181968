import React from "react";
import { Map, List } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  Card,
  Table,
  CardContent,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
} from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { formatDate } from "../../helpers/FormatUtils";
import { formatOrderStatusCode } from "../../helpers/OrderHelper";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    card: { "& > div": { display: "flex", flex: "1 1 0%" } },
    header: { textTransform: "uppercase", textAlign: "center" },
    table: {
      "& td": {
        textAlign: "center",
      },
    },
  }),
);

PendingRTOShipmentsReport.propTypes = {
  showTable: PropTypes.bool,
  classes: PropTypes.object,
  reportList: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
};

function PendingRTOShipmentsReport(props) {
  const { classes, getLocalisationMessage } = props;
  const rows = [];
  const list = (props.showTable && props.reportList.get("list")) || List();

  list.forEach((item, key) => {
    const items = item.get("items") || List();

    rows.push(
      <TableRow key={key}>
        <TableCell>{`${item.get("city")} (${items.size})`}</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>,
    );

    items.forEach((i, k) => {
      rows.push(
        <TableRow key={`${key}-${k}`}>
          <TableCell />
          <TableCell>{i.get("code")}</TableCell>
          <TableCell>{formatDate(i.get("pickup_date"))}</TableCell>
          <TableCell>{formatOrderStatusCode(i.get("status"))}</TableCell>
          <TableCell>{i.get("driver_name")}</TableCell>
        </TableRow>,
      );
    });
  });

  return (
    <FlexBox gutter={8} flex={true}>
      <FlexBox flex={true}>
        <FlexBox gutter={8} flex={true} direction="column">
          <FlexBox flex={true} element={<Card className={classes.card} />}>
            <FlexBox
              gutter={8}
              flex={true}
              direction="column"
              element={<CardContent />}
            >
              <FlexBox flex={true}>
                <Table>
                  <TableHead displaySelectAll={false} adjustForCheckbox={false}>
                    <TableRow>
                      <TableCell className={classes.header}>
                        {getLocalisationMessage("to_city", "To City")}
                      </TableCell>
                      <TableCell className={classes.header}>
                        {getLocalisationMessage("awb", "AWB")}
                      </TableCell>
                      <TableCell className={classes.header}>
                        {getLocalisationMessage(
                          "picked_up_date",
                          "Picked Up Date",
                        )}
                      </TableCell>
                      <TableCell className={classes.header}>
                        {getLocalisationMessage("last_status", "Last Status")}
                      </TableCell>
                      <TableCell className={classes.header}>
                        {getLocalisationMessage(
                          "current_driver",
                          "Current Driver",
                        )}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody
                    className={classes.table}
                    displayRowCheckbox={false}
                  >
                    {rows}
                  </TableBody>
                </Table>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(PendingRTOShipmentsReport);
