import React from "react";
import { Map, OrderedSet } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormDateField from "../form/FormDateField";
import FormTimeField from "../form/FormTimeField";
import FormSelectField from "../form/FormSelectField";
import FormCustomerChips from "../form/FormCustomerChips";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FlexBox from "../ui-core/FlexBox";
import { getMessages } from "../../reducers/LocalizationReducer";
import HeatMapTypes, {
  GIS,
  PLACE_API,
  CITY_CENTER,
  SOUQ_ADDRESS,
  REGION_CENTER,
  EXIST_LOCATION,
  PLACE_LOCATION,
} from "../../constants/HeatMapTypes";

const TRUE_FALSE_OPTION = OrderedSet.of(false, true);
const formatTrueFalseOption = (x, i18n) =>
  x === true ? i18n.get("yes", "Yes") : i18n.get("no", "No");

function formatHeapMapTypes(code, i18n) {
  switch (code) {
    case GIS:
      return i18n.get("gis", "GIS");
    case PLACE_API:
      return i18n.get("place_api", "Place API");
    case SOUQ_ADDRESS:
      return i18n.get("souq_address", "Souq address");
    case EXIST_LOCATION:
      return i18n.get("exist_location", "Exist Location");
    case REGION_CENTER:
      return i18n.get("region_center", "Region center");
    case CITY_CENTER:
      return i18n.get("city_center", "City center");
    case PLACE_LOCATION:
      return i18n.get("place_location", "Place location");
    default:
      return i18n.get("gis", "GIS");
  }
}

const getValues = formValueSelector("HeatMapFilterFormDialog");

const enhancer = compose(
  reduxForm({
    form: "HeatMapFilterFormDialog",
    enableReinitialize: true,
  }),
  connect(state => ({
    values: getValues(
      state,
      "fromDateTime",
      "toDateTime",
      "isPickUp",
      "locationSourceTypes",
    ),
  })),
  useSheet({
    body: {
      width: "400px",
      maxWidth: "400px",
    },
  }),
  connect(state => ({
    i18n: getMessages(state),
  })),
);

HeatMapFilterFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  values: PropTypes.object,
  initialValues: PropTypes.object,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  showCustomer: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  i18n: PropTypes.instanceOf(Map),
};

HeatMapFilterFormDialog.defaultProps = {
  showCustomer: true,
};

function HeatMapFilterFormDialog(props) {
  const { classes, open, i18n } = props;

  return (
    <Dialog open={open} modal={true} contentClassName={classes.body}>
      <DialogTitle>
        {i18n.get("heat_map_filter", "Heat Map Filter")}
      </DialogTitle>
      <DialogContent>
        <FormSelectField
          name="isPickUp"
          autoWidth={true}
          fullWidth={true}
          options={TRUE_FALSE_OPTION}
          formatOption={x => formatTrueFalseOption(x, i18n)}
          label={i18n.get("is_pickup", "Is Pickup")}
        />

        <FormChipAutoComplete
          name="locationSourceTypes"
          fullWidth={true}
          options={HeatMapTypes}
          formatOption={option => formatHeapMapTypes(option, i18n)}
          hintText={i18n.get("type_to_search", "Type to search ...")}
          label={i18n.get("location_source_types", "Location Source Types")}
        />

        {props.showCustomer && (
          <FormCustomerChips
            name="customerIds"
            fullWidth={true}
            placeholder={i18n.get("type_to_search", "Type to search ...")}
            label={i18n.get("customer", "Customer")}
          />
        )}

        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true} direction="column">
            <FormDateField
              fullWidth={true}
              name="fromDateTime"
              placeholder={i18n.get("to_date", "To Date")}
              initialTime={props.values.from_date_time}
            />
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <FormTimeField
              fullWidth={true}
              name="fromDateTime"
              placeholder={i18n.get("to_time", "To Time")}
            />
          </FlexBox>
        </FlexBox>

        <FlexBox gutter={8} flex={true}>
          <FlexBox flex={true} direction="column">
            <FormDateField
              fullWidth={true}
              name="toDateTime"
              placeholder={i18n.get("to_date", "To Date")}
              initialTime={props.values.to_date_time}
            />
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <FormTimeField
              fullWidth={true}
              name="toDateTime"
              placeholder={i18n.get("to_time", "To Time")}
            />
          </FlexBox>
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <div>
          <Button variant="text" onClick={props.onDismiss}>
            {" "}
            {i18n.get("dismiss", "Dismiss")}{" "}
          </Button>
          <Button
            variant="text"
            onClick={() => {
              props.handleSubmit();
            }}
          >
            {" "}
            {i18n.get("submit", "Submit")}{" "}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(HeatMapFilterFormDialog);
