import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Tabs from "../ui-core/Tabs";
import TabBadge from "../deprecated/TabBadge";
import { isEqualData, isEqualDataKeys } from "../../helpers/DataUtils";
import {
  mergeFilterToFilter,
  unmergeValuesFromFilter,
} from "../../helpers/FilterHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { parserOrderSmartFilter } from "../../helpers/OrderSmartFiltersHelper";
import { getMessages } from "../../reducers/LocalizationReducer";

const baseFilter = fromJS({
  page: 0,
  size: 1,
  simple: true,
  order_by: null,
  order_by_direction: null,
});

const getTabName = fp.flow(
  fp.split("?"),
  fp.last,
  fp.split("&"),
  fp.map(fp.split("=")),
  x => {
    let tab;

    fp.forEach(v => {
      if (fp.first(v) === "tab") {
        tab = fp.last(v);
      }
    })(x);

    return tab || "all";
  },
  decodeURI,
);

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  withRouter,
  mapPropsStream(propsStream =>
    propsStream.distinctUntilChanged(isEqualData).map(props => ({
      ...props,
      tabBaseFilters: OrderedMap().withMutations(map => {
        map.set(
          props.i18n.get("all", "All"),
          fromJS({
            page: 0,
            include_exception: null,
            status: null,
          }),
        );
        props.filterList
          .filter(i => i.get("is_tab"))
          .sort((a, b) => {
            const aOrder = a.get("sort_order");
            const bOrder = b.get("sort_order");

            if (aOrder > bOrder) {
              return 1;
            }

            if (aOrder < bOrder) {
              return -1;
            }

            return 0;
          })
          .forEach(i => {
            map.set(i.get("name"), i.get("filter"));
          });
      }),
    })),
  ),
  mapPropsStream(propsStream =>
    propsStream
      .distinctUntilChanged(
        isEqualDataKeys(["filter", "basePathname", "tabBaseFilters"]),
      )
      .map(props => {
        const filters = props.tabBaseFilters.map((x, idx) => {
          const filter = props.filter.setValueMap(
            parserOrderSmartFilter(x.set("tab", fp.snakeCase(idx))),
          );

          let queryFilter = props.filter;
          const currentFilter = props.tabBaseFilters
            .filter(
              (i, value) =>
                fp.snakeCase(value) ===
                getTabName(props.router.location.search),
            )
            .first();

          if (fp.snakeCase(idx) !== getTabName(props.router.location.search)) {
            queryFilter = unmergeValuesFromFilter(
              props.filter,
              currentFilter,
              x,
            );
          }
          return mergeFilterToFilter(filter, queryFilter);
        });

        return {
          filters,
          batchGetOrderListCount: props.batchGetOrderListCount,

          value: getTabName(props.router.location.search),

          tabs: filters.map((filter, label) => {
            const href = props.router.createHref({
              pathname: props.basePathname,
              query: filter.getDefinedValues(),
            });

            const value = getTabName(href);

            return Map({ label: props.i18n.get(value, label), value, href });
          }),
        };
      })
      .distinctUntilChanged(isEqualData),
  ),
  mapPropsStream(propsStream => {
    const tabCountsStream = propsStream
      .distinctUntilKeyChanged("filters", isEqualData)
      .switchMap(props =>
        !props.batchGetOrderListCount
          ? Observable.of(Map())
          : props.batchGetOrderListCount(
              props.filters.map(x => x.setValueMap(baseFilter)),
            ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(tabCountsStream, (props, tabCounts) => ({
        value: props.value,
        tabs: props.tabs.map((tab, label) =>
          tab.set("count", tabCounts.get(label)),
        ),
      }))
      .distinctUntilChanged(isEqualData)
      .map(
        fp.update("tabs", tabs =>
          tabs.reduce((acc, x) => {
            acc.push({
              value: x.get("value"),
              path: x.get("href"),
              label: <TabBadge label={x.get("label")} badge={x.get("count")} />,
            });
            return acc;
          }, []),
        ),
      );
  }),
);

const OrderCustomFilterTabs = enhancer(Tabs);

OrderCustomFilterTabs.propTypes = {
  batchGetOrderListCount: PropTypes.func,

  filterList: PropTypes.instanceOf(List),

  basePathname: PropTypes.string.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,

  i18n: PropTypes.instanceOf(Map),
  onChange: PropTypes.func,
};

export default OrderCustomFilterTabs;
