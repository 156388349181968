import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const MARKETPLACES_SAAS_URL = `${API_ROOT_URL}/supplier/saas_marketplace/choose`;

const parseResponse = fp.flow(fp.get("data"), fp.toPlainObject, fromJS);

const saasMarketplaceCache = new ObservableCache({
  loader: multiMarketplace =>
    api
      .get(MARKETPLACES_SAAS_URL, {
        params: { multi_marketplace: multiMarketplace },
      })
      .then(parseResponse),
});

export const getSaaSMarketplacesList = (multiMarketplace = true) =>
  saasMarketplaceCache.get(multiMarketplace);
