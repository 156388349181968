import { Observable } from "rxjs";
import React from "react";
import _ from "lodash";
import { fromJS, List, Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  createEventHandler,
  getContext,
  mapPropsStream,
} from "recompose";
import PropTypes from "prop-types";
import { Button, Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import SupplierOrderDriverSuggestionDialogWrapper from "./SupplierOrderDriverSuggestionDialogWrapper";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import {
  getCourierType,
  isEnabledAssignDriver,
  mapOrderNotesAndHistoryToActivities,
} from "../../helpers/OrderHelper";
import ResponseError from "../../helpers/ResponseError";
import { getMarketplaceEnableAssignDriver } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import ReasonTypes from "../../constants/ReasonTypes";
import InquiriesTypes from "../../constants/InquiriesTypes";
import NoteTypeCategory from "../../constants/NoteTypeCategory";
import SupplierPrivacyTypes from "../../constants/SupplierPrivacyTypes";
import { DRIVER_LIST_URL } from "../../constants/SupplierPathConstants";
import { getDriverLocation } from "../../api/shared/DriverApi";
import {
  createOrderNote,
  getOrder,
  getOrderActivities,
  uploadOrderSignature,
} from "../../api/supplier/SupplierOrderApi";
import { getCustomer } from "../../api/supplier/SupplierCustomerApi";
import FlexBox from "../../components/ui-core/FlexBox";
import OrderNoteDialog from "../../components/orders-core/OrderNoteDialog";
import OrderActivitiesTimeline from "../../components/orders-core/OrderActivitiesTimeline";
import UpdateOrderContactDialog from "../../components/orders-core/UpdateOrderContactDialog";
import OrderDetailsDialog from "../../components/order-details-dialog/OrderDetailsDialog";
import OrderDetailsDialogMap from "../../components/order-details-dialog/OrderDetailsDialogMap";
import {
  BRIEF_TAB,
  CUSTOMER_TAB,
  HISTORY_TAB,
  MAP_TAB,
  SIGNATURE_TAB,
} from "../../components/order-details-dialog/OrderDetailsDialogTabs";
import OrderDetailsDialogCustomer from "../../components/order-details-dialog/OrderDetailsDialogCustomer";
import OrderDetailsDialogSignature from "../../components/order-details-dialog/OrderDetailsDialogSignature";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import OrderDetailsDialogBriefV2 from "../../components/order-details-dialog/v2/OrderDetailsDialogBriefV2";

const DELIVERY_TO = "delivery_signature";
const SENDER_FROM = "sender_signature";

const enhancer = compose(
  renderIf((props) => props.orderId > 0),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
  }),
  connect(
    (state) => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        enableAssignDriverForSettings: getMarketplaceEnableAssignDriver(state),
        getLocalisationMessage,
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
  useSheet({
    container: {
      minHeight: "300px",
      maxHeight: "480px",
      overflowY: "auto",
      margin: "0 6px 6px",
    },
    buttonRight: { float: "right" },
  }),
  mapPropsStream((propsStream) => {
    const { handler: onShowAddNote, stream: onShowAddNoteStream } =
      createEventHandler();
    const { handler: onShowSubDialog, stream: onShowSubDialogStream } =
      createEventHandler();
    const { handler: onRequestRefresh, stream: onRequestRefreshStream } =
      createEventHandler();

    const showAddNoteStream = onShowAddNoteStream.startWith(false);

    const orderResponseStream = propsStream
      .pluck("orderId")
      .distinctUntilChanged()
      .switchMap((id) =>
        getOrder(id)
          .repeatWhen(() => onRequestRefreshStream)
          .catch((error) => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    const getOrderActivitiesResponseStream = propsStream
      .filter((props) => props.tab === HISTORY_TAB)
      .distinctUntilKeyChanged("orderId")
      .switchMap((props) =>
        getOrderActivities(props.orderId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch((error) => Observable.of({ error })),
      )
      .startWith({})
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", mapOrderNotesAndHistoryToActivities),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        showAddNoteStream,
        orderResponseStream,
        getOrderActivitiesResponseStream,
        onShowSubDialogStream.startWith(null),
        (props, showAddNote, orderResponse, orderActivities, uploadType) => ({
          ...props,
          uploadType,
          showAddNote,
          onShowAddNote,
          onShowSubDialog,
          onRequestRefresh,
          order: orderResponse.get("payload"),
          isLoading: orderResponse.get("pending"),
          activities: orderActivities.get("payload"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

SupplierOrderDetailsDialogWrapper.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.instanceOf(Map),
  activities: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,
  location: PropTypes.object,
  tab: PropTypes.oneOf([
    BRIEF_TAB,
    HISTORY_TAB,
    CUSTOMER_TAB,
    MAP_TAB,
    SIGNATURE_TAB,
  ]),
  showAddNote: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onShowAddNote: PropTypes.func,
  onRequestRefresh: PropTypes.func,

  setLocationQuery: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  createDriverSuggestHref: PropTypes.func,

  orderId: PropTypes.number,
  onTabChange: PropTypes.func,
  onEditClick: PropTypes.func,
  onShowSubDialog: PropTypes.func,
  uploadType: PropTypes.string,
  getLocalisationMessage: PropTypes.func.isRequired,
  enableAssignDriverForSettings: PropTypes.object,
};

SupplierOrderDetailsDialogWrapper.defaultProps = {
  tab: BRIEF_TAB,
};

function SupplierOrderDetailsDialogWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <OrderDetailsDialog
      open={true}
      onRequestClose={props.onRequestClose}
      tab={props.tab}
      order={props.order}
      isLoading={props.isLoading}
      onTabChange={props.onTabChange}
      onEditClick={props.onEditClick}
      withoutCourierTab={true}
      onShowSuggestedDriverLink={true}
      getReverseUrl={(id) => updateQuery(props.location, fp.set("view", id))}
      createDriverSuggestHref={props.createDriverSuggestHref}
    >
      {Boolean(
        isEnabledAssignDriver(
          props.enableAssignDriverForSettings,
          getCourierType(props.order),
        ),
      ) && (
        <SupplierOrderDriverSuggestionDialogWrapper
          open={Boolean(_.get(props.location, "query.driver_view"))}
          location={props.location}
          orderId={props.orderId}
          onRequestClose={() => {
            props.setLocationQuery(fp.unset("driver_view"));
          }}
          onSubmitSuccess={() => {
            props.onRequestRefresh();
            props.setLocationQuery(fp.unset("driver_view"));
            props.showSuccessMessage(
              getLocalisationMessage(
                "driver_has_been_assigned_successfully",
                "Driver Has Been Assigned Successfully",
              ),
            );
          }}
          onSubmitFail={(error) => {
            props.onRequestRefresh();
            props.showErrorMessage(error.errors.status);
          }}
        />
      )}
      {Boolean(props.tab === BRIEF_TAB) && (
        <OrderDetailsDialogBriefV2
          order={props.order}
          hideActualCharge={true}
        />
      )}

      {Boolean(props.tab === CUSTOMER_TAB) && (
        <OrderDetailsDialogCustomer
          getCustomer={getCustomer}
          customerId={props.order.getIn(["customer", "id"])}
        />
      )}

      {Boolean(props.tab === HISTORY_TAB) && (
        <Card className={classes.container}>
          <CardContent>
            <Button
              className={classes.buttonRight}
              onClick={() => props.onShowAddNote(true)}
            >
              {getLocalisationMessage("add_note", "Add Note")}
            </Button>

            <OrderActivitiesTimeline
              showDriver={true}
              showSupplier={true}
              showWarehouse={true}
              driverItemUrl={(id) =>
                updateQuery(DRIVER_LIST_URL, fp.set("view", id))
              }
              driverItemLocationUrl={(id) =>
                updateQuery(
                  DRIVER_LIST_URL,
                  fp.flow(fp.set("view", id), fp.set("view_tab", "map")),
                )
              }
              activities={props.activities}
            />

            <OrderNoteDialog
              open={props.showAddNote}
              reasonTypes={ReasonTypes}
              inquiriesTypes={InquiriesTypes}
              privacyTypes={SupplierPrivacyTypes}
              noteTypeCategory={NoteTypeCategory}
              withRequiresAttention={true}
              withScheduledForCancellation={true}
              onRequestClose={() => props.onShowAddNote(false)}
              initialValues={{
                requiresAttention: Boolean(
                  props.order.get("requires_attention"),
                ),
                scheduledForCancellation: Boolean(
                  props.order.get("scheduled_for_cancellation"),
                ),
              }}
              onSubmit={(values) =>
                createOrderNote(props.orderId, toSnakeCase(values)).catch(
                  ResponseError.throw,
                )
              }
              onSubmitSuccess={() => {
                props.onRequestRefresh();
                props.onShowAddNote(false);
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "successfully_note_created",
                    "Successfully Note Created",
                  ),
                );
              }}
              onSubmitFail={() => props.showErrorMessage()}
            />
          </CardContent>
        </Card>
      )}

      {Boolean(props.tab === MAP_TAB) && (
        <OrderDetailsDialogMap
          order={props.order}
          getDriverLocation={getDriverLocation}
        />
      )}

      {Boolean(props.tab === SIGNATURE_TAB) && (
        <FlexBox container={8} flex={true} className={classes.container}>
          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true} direction="column">
              <OrderDetailsDialogSignature
                photoId={props.order.get("sender_photo_id")}
                signatureId={props.order.get("sender_signature_id")}
                onUpload={() => props.onShowSubDialog(SENDER_FROM)}
                header={getLocalisationMessage(
                  "proof_of_pickup",
                  "Proof of Pickup",
                )}
              />
            </FlexBox>

            <FlexBox flex={true} direction="column">
              <OrderDetailsDialogSignature
                header={getLocalisationMessage(
                  "proof_of_delivery",
                  "Proof of Delivery",
                )}
                photoId={props.order.get("delivered_to_photo_id")}
                signatureId={props.order.get("delivery_signature_id")}
                onUpload={() => props.onShowSubDialog(DELIVERY_TO)}
              />

              <UpdateOrderContactDialog
                onRequestClose={() => props.onShowSubDialog(null)}
                open={Boolean(
                  props.uploadType === DELIVERY_TO ||
                    props.uploadType === SENDER_FROM,
                )}
                title={
                  props.uploadType === DELIVERY_TO
                    ? getLocalisationMessage(
                        "update_recipient_info",
                        "Update Recipient Info",
                      )
                    : getLocalisationMessage(
                        "update_sender_info",
                        "Update Sender Info",
                      )
                }
                initialValues={
                  props.uploadType === DELIVERY_TO
                    ? {
                        type: DELIVERY_TO,
                        orderId: props.orderId,
                        name: props.order.get("delivered_to"),
                        phone: props.order.get("delivered_to_phone"),
                        photoId: props.order.get("delivered_to_photo_id"),
                        signatureId: props.order.get("delivery_signature_id"),
                      }
                    : {
                        type: SENDER_FROM,
                        orderId: props.orderId,
                        name: props.order.get("sender_from"),
                        phone: props.order.get("sender_phone"),
                        photoId: props.order.get("sender_photo_id"),
                        signatureId: props.order.get("sender_signature_id"),
                      }
                }
                onSubmit={(values) =>
                  uploadOrderSignature(
                    props.orderId,
                    toSnakeCase(values),
                  ).catch(ResponseError.throw)
                }
                onSubmitSuccess={() => {
                  props.onRequestRefresh();
                  props.onShowSubDialog(null);
                }}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
    </OrderDetailsDialog>
  );
}

export default enhancer(SupplierOrderDetailsDialogWrapper);
