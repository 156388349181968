import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SupplierSignIn from "./SupplierSignIn";
import { fetchUser } from "../../actions/ProfileActions";
import FlexBox from "../../components/ui-core/FlexBox";
import DashboardRoot from "../../components/app-core/DashboardRoot";
import PushNotification from "../../components/push-notifications/PushNotification";

const enhancer = connect(null, { fetchUser });

SupplierRoot.propTypes = { children: PropTypes.node };

function SupplierRoot(props) {
  return (
    <FlexBox direction="column" style={{ height: "100%" }}>
      <PushNotification />
      <DashboardRoot {...props} SignIn={SupplierSignIn} />
    </FlexBox>
  );
}

export default enhancer(SupplierRoot);
