import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toCustomerFeedbacksFilter } from "../../helpers/CustomerFeedbacksMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  DRIVER_LIST_URL,
  SUPPLIER_USER_LIST_URL,
} from "../../constants/SupplierPathConstants";
import { getCustomerFeedback } from "../../api/supplier/SupplierCustomerApi";
import SupplierUserEditDialogWrapper from "../../wrappers/supplier/SupplierUserEditDialogWrapper";
import SupplierDriverEditDialogWrapper from "../../wrappers/supplier/SupplierDriverEditDialogWrapper";
import SupplierOrderDetailsDialogWrapper from "../../wrappers/supplier/SupplierOrderDetailsDialogWrapper";
import SupplierCustomerFeedbackFilterWrapper from "../../wrappers/supplier/SupplierCustomerFeedbackFilterWrapper";
import CustomerFeedbackList from "../../components/customer/CustomerFeedbackList";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import CheckOverallStatus from "../../components/route-validators/CheckOverallStatus";

const FILTER_DIALOG_HASH = "#FD";

const enhancer = compose(
  getContext({
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func,
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  mapPropsStream(propsStream => {
    const {
      stream: onRequestRefreshStream,
      handler: onRequestRefresh,
    } = createEventHandler();
    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), toCustomerFeedbacksFilter))
      .distinctUntilChanged(isEqualData);

    const responseStream = filterStream
      .switchMap(filter =>
        getCustomerFeedback(filter)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(() => Observable.of({})),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(
        filterStream,
        responseStream,
        (props, filter, response) => ({
          ...props,
          filter,
          onRequestRefresh,
          list: response.get("list"),
          total: response.get("total"),
          isLoading: response.get("pending"),
        }),
      );
  }),
);

SupplierCustomerFeedbackList.propTypes = {
  location: PropTypes.object,
  replaceLocationHash: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  getLocalisationMessage: PropTypes.func,
};

function SupplierCustomerFeedbackList(props) {
  const { location, getLocalisationMessage } = props;

  return (
    <SupplierAppLayout
      slug="my_customer_feedbacks"
      title={getLocalisationMessage(
        "my_customer_feedbacks",
        "My Customer feedbacks",
      )}
    >
      <CheckOverallStatus location={props.location} />

      <SupplierDriverEditDialogWrapper
        driverId={fp.toFinite(location.query.view_driver)}
        onRequestClose={() => {
          props.setLocationQuery(fp.unset("view_driver"));
        }}
        onSupplierClick={id =>
          props.setLocationQuery(fp.set("view_supplier", id))
        }
      />

      <SupplierUserEditDialogWrapper
        supplierUserId={fp.toFinite(props.location.query.view_user)}
        onRequestClose={() => {
          props.setLocationQuery(fp.unset("view_user"));
        }}
        onSupplierClick={id =>
          props.setLocationQuery(fp.set("view_supplier", id))
        }
      />
      <SupplierOrderDetailsDialogWrapper
        tab={location.query.view_tab}
        orderId={fp.toFinite(location.query.view)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(fp.omit(["view", "view_tab"]))
        }
        createDriverSuggestHref={() =>
          props.setLocationQuery(fp.flow(fp.set("driver_view", true)))
        }
        location={location}
      />
      <SupplierCustomerFeedbackFilterWrapper
        filter={props.filter.withMutations((filter: DataListFilter) => {
          if (!location.query.custom) {
            filter.setValue("cod_status", null);
          }
        })}
        onFilterChange={filter => {
          props.replaceLocationHash(null);
          props.setLocationQueryFilter(
            filter.setValueMap(
              filter.getValue("cod_status")
                ? { custom: 1 }
                : {
                    custom: null,
                    cod_status: props.filter.getValue("cod_status"),
                    include_exception: props.filter.getValue(
                      "include_exception",
                    ),
                  },
            ),
          );
        }}
        open={location.hash === FILTER_DIALOG_HASH}
        onRequestClose={() => props.replaceLocationHash(null)}
      />
      <CustomerFeedbackList
        isLoading={props.isLoading}
        totalCount={props.total}
        list={props.list}
        filter={props.filter}
        setLocationQueryFilter={props.setLocationQueryFilter}
        replaceLocationHash={props.replaceLocationHash}
        location={location}
        driverUrl={DRIVER_LIST_URL}
        supplierUrl={SUPPLIER_USER_LIST_URL}
        getLocalisationMessage={getLocalisationMessage}
      />
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierCustomerFeedbackList);
