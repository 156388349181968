import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  CardHeader,
  TableHead,
  TableCell,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import AvatarWithName from "../avatars/AvatarWithName";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDate, formatText } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import MultiLineCell from "../../components/data-list/MultiLineCell";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    container: {
      width: "100%",
      padding: {
        top: "10px",
        left: "15px",
        bottom: "30px",
        right: "15px",
      },
      color: "#43425D",
    },
    cardHeader: {
      width: "100%",
      "& > div": {
        paddingRight: 0,
        width: "100%",
      },
    },
    multiLineFirst: {
      fontSize: "16px",
      lineHeight: "16px",
      paddingTop: "10px",
      whiteSpace: "break-spaces",
    },
    multiLineSecond: { color: "rgba(0,0,0,0.54)" },
    tableRowColumn: {
      paddingTop: "5px",
      paddingBottom: "5px",
      paddingLeft: "10px",
      paddingRight: "10px",
    },
    tableHeadRowColumn: { textAlign: "center" },
    customerColumn: {
      width: "220px",
    },
    phoneColumn: {
      width: "170px",
    },
    alertColumn: {
      width: "150px",
    },
    phone: {
      backgroundColor: "#7CB342",
      color: "#fff",
      padding: "3px 5px",
      borderRadius: "5px",
      width: "120px",
      textAlign: "center",
      display: "inline-block",
    },
  }),
  mapPropsStream(propsStream => {
    const responseStream = propsStream
      .switchMap(props =>
        props
          .getAlerts(
            new DataListFilter({
              current_alerts: true,
              order_by: "created_date",
              order_by_direction: "desc",
              current_orders: true,
              use_solr: true,
              size: 5,
            }),
          )
          .catch(() => Observable.of({})),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              total: response.getIn(["payload", "data", "total"], 0),
              payload: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(responseStream, (props, response) => ({
      ...props,
      list: response.get("payload"),
      isLoading: response.get("pending"),
    }));
  }),
);

LatestAlerts.propTypes = {
  classes: PropTypes.object,
  list: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,
  getAlerts: PropTypes.func.isRequired,
  title: PropTypes.string,
  radarURL: PropTypes.string,
  getLocalisationMessage: PropTypes.func,
};

function LatestAlerts(props) {
  const { classes, list, getLocalisationMessage } = props;
  const NA = getLocalisationMessage("na", "N/A");

  return (
    <Card className={classes.container}>
      <CardHeader
        className={classes.cardHeader}
        title={
          <FlexBox style={{ width: "100%" }}>
            <FlexBox flex={true}>{props.title}</FlexBox>
            <FlexBox flex={true} justify="flex-end">
              <Link href={props.radarURL}>
                {getLocalisationMessage("show_in_radar", "Show in Radar")}
              </Link>
            </FlexBox>
          </FlexBox>
        }
      />
      <FlexBox direction="column">
        <FlexBox direction="row" flex={true}>
          {!props.isLoading && (
            <Table selectable={false}>
              <TableHead displaySelectAll={false} enableSelectAll={false}>
                <TableRow className={classes.tableHeader} selectable={false}>
                  <TableCell
                    className={cx(
                      classes.customerColumnHeader,
                      classes.tableHeadRowColumn,
                    )}
                  >
                    {getLocalisationMessage("driver", "Driver")}
                  </TableCell>
                  <TableCell
                    className={cx(
                      classes.tableRowColumn,
                      classes.tableHeadRowColumn,
                    )}
                  >
                    {getLocalisationMessage("alert", "Alert")}
                  </TableCell>
                  <TableCell
                    className={cx(
                      classes.tableRowColumn,
                      classes.tableHeadRowColumn,
                      classes.alertColumn,
                    )}
                  >
                    {getLocalisationMessage("count", "Count")}
                  </TableCell>
                  <TableCell
                    className={cx(
                      classes.tableRowColumn,
                      classes.tableHeadRowColumn,
                      classes.alertColumn,
                    )}
                  >
                    {getLocalisationMessage("driver_phone", "Driver Phone")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody displayRowCheckbox={false}>
                {list.map((item, key) => (
                  <TableRow selectable={false} key={key}>
                    <TableCell className={classes.tableRowColumn}>
                      <AvatarWithName name={item.get("driver_name", NA)}>
                        <MultiLineCell
                          firstLine={
                            <div className={classes.multiLineFirst}>
                              {item.get("driver_name", NA)}
                            </div>
                          }
                          secondLine={
                            <div className={classes.multiLineSecond}>
                              {item.get("supplier_name", NA)}
                            </div>
                          }
                        />
                      </AvatarWithName>
                    </TableCell>
                    <TableCell className={classes.tableRowColumn}>
                      <AvatarWithName
                        name={formatText(item.get("alert_type", NA))}
                      >
                        <MultiLineCell
                          firstLine={
                            <div className={classes.multiLineFirst}>
                              <span>
                                {formatText(item.get("alert_type", NA))}
                              </span>
                            </div>
                          }
                          secondLine={
                            <div className={classes.multiLineSecond}>
                              {`${getLocalisationMessage(
                                "last_alert",
                                "Last Alert",
                              )} ${formatDate(item.get("alert_date"))}`}
                            </div>
                          }
                        />
                      </AvatarWithName>
                    </TableCell>
                    <TableCell
                      className={cx(
                        classes.tableRowColumn,
                        classes.alertColumn,
                      )}
                    >
                      <MultiLineCell
                        firstLine={
                          <div className={classes.multiLineFirst}>
                            <div style={{ textAlign: "center" }}>{`${item.get(
                              "last_week_active_alerts",
                              0,
                            )} ${getLocalisationMessage(
                              "alerts",
                              "Alerts",
                            )}`}</div>
                          </div>
                        }
                        secondLine={
                          <div className={classes.multiLineSecond}>
                            <div style={{ textAlign: "center" }}>
                              {getLocalisationMessage("this_week", "this week")}
                            </div>
                          </div>
                        }
                      />
                    </TableCell>
                    <TableCell
                      className={cx(
                        classes.tableRowColumn,
                        classes.alertColumn,
                      )}
                    >
                      <span className={classes.phone}>
                        {item.get("driver_phone", NA)}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </FlexBox>
      </FlexBox>
    </Card>
  );
}

export default enhancer(LatestAlerts);
