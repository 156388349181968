import React from "react";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sizeMe } from "react-sizeme";
import GoogleMapReact from "google-map-react";
import LinkButton from "../ui-core/LinkButton";
import { googleMapKey } from "../../helpers/GoogleMapsHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import { SETTINGS_MAP_URL } from "../../constants/AdminPathConstants";
import {
  getAppName,
  getMapProviderKey,
} from "../../../shared/reducers/AppReducer";
import { ADMIN, CUSTOMER, SUPPLIER } from "../../../server/constants/AppTypes";

export const DUBAI_CENTER = { lat: 25.1, lng: 55.1922004 };
export const RIYADH_CENTER = { lat: 24.7136, lng: 46.6753 };

const options = {
  styles: { position: "relative", width: "100%", height: "100%" },
  maxZoom: 30,
};

const enhancer = compose(
  connect(state => ({
    mapProviderKey: getMapProviderKey(state),
    app: getAppName(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  sizeMe({ monitorWidth: true, monitorHeight: true }),
  useSheet({
    map: { height: "100%" },
    errorMessage: {
      backgroundColor: "white",
      fontWeight: 500,
      fontSize: "15px",
      padding: "15px 25px",
      boxSizing: "border-box",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "5px",
      maxWidth: "375px",
      position: "absolute",
      transform: "translateX(-50%)",
      width: "calc(100% - 10px)",
      margin: "0 auto",
      zIndex: 1,
      top: 5,
      height: "140px",
    },
  }),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

GoogleMapReactComponent.propTypes = {
  size: PropTypes.object,
  classes: PropTypes.object,

  maps: PropTypes.object,
  children: PropTypes.node,

  style: PropTypes.object,
  className: PropTypes.string,

  zoom: PropTypes.number,
  mapCenter: PropTypes.object,
  heatMapData: PropTypes.object,

  hasZoomControl: PropTypes.bool,
  heatMapLibrary: PropTypes.bool,
  clickableIcons: PropTypes.bool,
  disableDoubleClickZoom: PropTypes.bool,

  onClick: PropTypes.func,
  onCenterChanged: PropTypes.func,
  onBoundsChanged: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  setLocation: PropTypes.func,
  mapProviderKey: PropTypes.string,
  app: PropTypes.string,
};

GoogleMapReactComponent.defaultProps = {
  zoom: 10,
  mapCenter: DUBAI_CENTER,
  heatMapLibrary: false,

  hasZoomControl: false,
  clickableIcons: false,
  disableDoubleClickZoom: false,
};

function GoogleMapReactComponent(props) {
  return (
    <GoogleMapReact
      style={{ minHeight: "500px", width: "100%" }}
      defaultZoom={props.zoom}
      defaultCenter={props.mapCenter}
      options={options}
      onChange={props.onClick}
      yesIWantToUseGoogleMapApiInternals={true}
      bootstrapURLKeys={{ key: googleMapKey }}
      heatmapLibrary={props.heatMapLibrary}
      heatmap={props.heatMapData}
    >
      {!props.mapProviderKey &&
        props.app === ADMIN && (
          <div className={props.classes.errorMessage}>
            {props.getLocalisationMessage(
              "",
              "In order to use Map Services, billing must be enabled on your account, and all requests must include a valid API key. Once the API key purchased from preferred Map Service Provider it can be linked to your account",
            )}{" "}
            <LinkButton
              style={{
                color: "red",
              }}
              onClick={() => props.setLocation(SETTINGS_MAP_URL)}
            >
              HERE
            </LinkButton>
          </div>
        )}

      {!props.mapProviderKey &&
        (props.app === CUSTOMER || props.app === SUPPLIER) && (
          <div className={props.classes.errorMessage}>
            {props.getLocalisationMessage(
              "",
              "Please contact your company administrator in order to use Map Services.",
            )}
          </div>
        )}
      {props.children}
    </GoogleMapReact>
  );
}

export default enhancer(GoogleMapReactComponent);
