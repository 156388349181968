import { Observable } from "rxjs";
import React from "react";
import { fromJS, Map } from "immutable";
import fp from "lodash/fp";
import { compose, createEventHandler, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMarketplaceDefaultBatchUpdateStatuses } from "../../reducers/MarketplaceReducer";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import OrderStatusCodes from "../../constants/OrderStatusCodes";
import { getTimeSlots } from "../../api/customer/CustomerTimeSlotApiV2";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/supplier/SupplierDriverApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import OrderForm from "../../components/orders-core/OrderFormV2";
import { getOrder, updateOrder } from "../../api/v2/supplier/SupplierOrderApi";
import { getPaymentMethods } from "../../api/v2/supplier/SupplierCustomerApi";

const enhancer = compose(
  renderIf((props) => props.orderId > 0),
  connect(
    (state) => ({
      i18n: getMessages(state),
      orderStatuses: getMarketplaceDefaultBatchUpdateStatuses(state),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream((propsStream) => {
    const { handler: onRequestRefresh, stream: onRequestRefreshStream } =
      createEventHandler();

    const responseStream = propsStream
      .distinctUntilKeyChanged("orderId")
      .switchMap((props) =>
        getOrder(props.orderId)
          .catch((error) => Observable.of({ error }))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(responseStream, (props, response) => ({
        ...props,
        onRequestRefresh,
        item: response.get("payload"),
        isLoading: response.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

SupplierOrderEditDialogWrapperV2.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  isLoading: PropTypes.bool,
  item: PropTypes.instanceOf(Map),

  orderId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  orderStatuses: PropTypes.object,
  i18n: PropTypes.instanceOf(Map),
};

function SupplierOrderEditDialogWrapperV2(props) {
  const statusList =
    props.orderStatuses && props.orderStatuses.size > 0
      ? props.orderStatuses
      : OrderStatusCodes;
  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoading}
      onRequestClose={props.onRequestClose}
      title={
        props.item.get("id")
          ? `${props.i18n.get("edit_order", "Edit Order")} #${props.item.get(
              "order_number",
            )}`
          : null
      }
    >
      <FlexBox direction="column">
        <OrderForm
          orderStatusCodes={statusList}
          order={props.item}
          onDismiss={props.onRequestClose}
          onSubmit={(values) =>
            updateOrder(props.item.get("id"), values).catch(ResponseError.throw)
          }
          onSubmitFail={props.showErrorMessage}
          onSubmitSuccess={() => {
            props.onRequestRefresh();

            props.showSuccessMessage(
              props.i18n.get(
                "successfully_order_edited",
                "Successfully Order Edited",
              ),
            );
          }}
          getTimeSlots={getTimeSlots}
          getPaymentMethods={getPaymentMethods}
          getCachedDriver={getCachedDriver}
          getDriverPredictions={getDriverPredictions}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(SupplierOrderEditDialogWrapperV2);
