import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { createDriver } from "../../api/supplier/SupplierDriverApi";
import {
  getVehicle,
  updateVehicle,
  getCachedVehicle,
  getVehiclePredictions,
} from "../../api/supplier/SupplierVehicleApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import DriverForm from "../../components/drivers-core/DriverForm";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
);

SupplierDriverCreateDialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,

  onVehicleClick: PropTypes.func,
  onCreateVehicleClick: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function SupplierDriverCreateDialogWrapper(props) {
  return (
    <ModalPaper
      title={props.getLocalisationMessage("create_driver", "Create Driver")}
      open={props.open}
      onRequestClose={props.onRequestClose}
    >
      <FlexBox container={8} direction="column">
        <DriverForm
          disableEmail={false}
          onDismiss={props.onRequestClose}
          onSubmit={values =>
            createDriver(toSnakeCase({ ...values, login: values.email })).catch(
              ResponseError.throw,
            )
          }
          onSubmitSuccess={() => {
            props.onRequestClose();
            props.showSuccessMessage(
              props.getLocalisationMessage(
                "successfully_saved",
                "Successfully saved",
              ),
            );
          }}
          onSubmitFail={props.showErrorMessage}
          getCachedVehicle={getCachedVehicle}
          getVehiclePredictions={getVehiclePredictions}
          onVehicleClick={props.onVehicleClick}
          onCreateVehicleClick={props.onCreateVehicleClick}
          getVehicle={getVehicle}
          updateVehicle={updateVehicle}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(SupplierDriverCreateDialogWrapper);
