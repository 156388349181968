import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import {
  isEqualData,
  isEqualWithoutFunctions,
} from "../../../helpers/DataUtils";
import DataListFilter from "../../../helpers/DataListFilter";
import { getSupplierScores } from "../../../api/supplier/SupplierChartApi";
import SupplierScoresWidget from "../../../components/dashboard-core/SupplierScoresWidget";

const enhancer = compose(
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
      position: "relative",
    },
  }),
  mapPropsStream(propsStream => {
    const getOfdDeaFddsReportsStream = propsStream
      .distinctUntilChanged(isEqualData)
      .switchMap(() =>
        getSupplierScores(new DataListFilter()).catch(() => Observable.of({})),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], Map()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(getOfdDeaFddsReportsStream, (props, reports) => ({
        ...props,
        reports: reports.get("payload"),
        isLoading: reports.get("pending"),
      }))
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

SupplierSupplierScoresChartWrapper.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  location: PropTypes.object,
  reports: PropTypes.instanceOf(Map),
};

function SupplierSupplierScoresChartWrapper(props) {
  return <SupplierScoresWidget title={props.title} reports={props.reports} />;
}

export default enhancer(SupplierSupplierScoresChartWrapper);
