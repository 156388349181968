import sprintf from "sprintf";
import { api } from "../../shared/BaseApi";
import { API_V2_ROOT_URL } from "../../../../shared/constants/ApiConstants";

const ORDER_ITEM_URL = `${API_V2_ROOT_URL}/supplier/order/%s`;
const ORDER_CREATE_URL = `${API_V2_ROOT_URL}/supplier/order`;

export const createOrder = values =>
  api.post(ORDER_CREATE_URL, { body: values });
export const getOrder = id => api.getStream(sprintf(ORDER_ITEM_URL, id));
export const updateOrder = (id, values) =>
  api.put(sprintf(ORDER_ITEM_URL, id), { body: values });
