import React from "react";
import { subDays, startOfToday } from "date-fns";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { FilterList } from "@material-ui/icons";
import { batchAssignDriversWarehouse } from "../../actions/AdminDriverActions";
import { loadCustomerList } from "../../actions/AdminCustomerActions";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDashboardFilter } from "../../helpers/DashboardFilterMapper";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  DRIVER_RADAR_URL,
  DRIVERS_DASHBOARD_URL,
  CUSTOMER_FEEDBACK_LIST_URL,
} from "../../constants/AdminPathConstants";
import {
  getTopDrivers,
  getPickupRateV2,
  getDeliveryRateV2,
  getDeliveryAttemptsV2,
  getWarehouseInboundOutboundReportsV2,
} from "../../api/supplier/SupplierChartApi";
import { getAlertsList } from "../../api/supplier/SupplierAlertsApi";
import { getCustomerFeedback } from "../../api/supplier/SupplierCustomerApi";
import Redirect from "../../components/router/Redirect";
import FlexBox from "../../components/ui-core/FlexBox";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import LatestAlerts from "../../components/dashboard-core/LatestAlerts";
import PickupRateWidget from "../../components/dashboard-core/PickupRateWidget";
import TopDriversWidget from "../../components/dashboard-core/TopDriversWidget";
import DeliveryAttemptsWidget from "../../components/dashboard-core/DeliveryAttemptsWidget";
import LatestCustomerFeedback from "../../components/dashboard-core/LatestCustomerFeedback";
import DeliverySuccessRateWidget from "../../components/dashboard-core/DeliverySuccessRateWidget";
import DeliveryTimeChartComponent from "../../components/dashboard-core/DeliveryTimeChartComponent";
import PickedUpTimeChartComponent from "../../components/dashboard-core/PickedUpTimeChartComponent";
import WarehouseInboundOutboundWidget from "../../components/dashboard-core/WarehouseInboundOutboundWidget";
import OrderCreatedCountChartComponent from "../../components/dashboard-core/OrderCreatedCountChartComponent";
import DeliverySuccessRateChartComponent from "../../components/dashboard-core/DeliverySuccessRateChartComponent";
import { updateHash, updateQuery } from "../../../shared/helpers/UrlUtils";
import SupplierCODWrapper from "../../wrappers/supplier/dashboard/SupplierCODWrapper";
import SupplierAdtChartWrapper from "../../wrappers/supplier/dashboard/SupplierAdtChartWrapper";
import SupplierAptChartWrapper from "../../wrappers/supplier/dashboard/SupplierAptChartWrapper";
import SupplierDeaChartWrapper from "../../wrappers/supplier/dashboard/SupplierDeaChartWrapper";
import SupplierDSRChartWrapper from "../../wrappers/supplier/dashboard/SupplierDSRChartWrapper";
import SupplierOccChartWrapper from "../../wrappers/supplier/dashboard/SupplierOccChartWrapper";
import SupplierOfdChartWrapper from "../../wrappers/supplier/dashboard/SupplierOfdChartWrapper";
import SupplierRTOChartWrapper from "../../wrappers/supplier/dashboard/SupplierRTOChartWrapper";
import SupplierFddsChartWrapper from "../../wrappers/supplier/dashboard/SupplierFddsChartWrapper";
import SupplierGoogleHeatMapWrapper from "../../wrappers/supplier/dashboard/SupplierGoogleHeatMapWrapper";
import SupplierKPIMissesChartWrapper from "../../wrappers/supplier/dashboard/SupplierKPIMissesChartWrapper";
import SupplierArrivalTimeChartWrapper from "../../wrappers/supplier/dashboard/SupplierArrivalTimeChartWrapper";
import SupplierSupplierScoresChartWrapper from "../../wrappers/supplier/dashboard/SupplierSupplierScoresChartWrapper";
import SupplierDashboardFilterDialogWrapper from "../../wrappers/supplier/dashboard/SupplierDashboardFilterDialogWrapper";
import SupplierDashboardOrderTrackerWrapper from "../../wrappers/supplier/dashboard/SupplierDashboardOrderTrackerWrapper";
import SupplierDispatchedVSAttemptedChartWrapper from "../../wrappers/supplier/dashboard/SupplierDispatchedVSAttemptedChartWrapper";

const DASHBOARD_FILTER_DIALOG_HASH = "#DFDH";

const enhancer = compose(
  useSheet({
    appBarRightAction: {
      marginTop: "10px",
      marginRight: "5px",
      fontSize: "15px",
    },
    appBarRightActionToggle: {
      whiteSpace: "nowrap",
      float: "right",
      marginLeft: "12px",
    },
    appBarRightActionButton: {
      color: "#fff",
      paddingTop: 0,
      "& svg": {
        color: "#fff",
      },
    },
  }),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
      batchAssignDriversWarehouse,
      loadCustomerList,
    },
  ),
  getContext({
    setLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  mapPropsStream(propsStream => {
    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), toDashboardFilter))
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(filterStream, (props, filter) => ({
        ...props,
        filter,
      }));
  }),
);

SupplierDashboardContainer.propTypes = {
  classes: PropTypes.object,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  location: PropTypes.object,
  setLocationHash: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  batchAssignDriversWarehouse: PropTypes.func,
  isLoading: PropTypes.bool,
  onRowSelect: PropTypes.func,
  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  loadCustomerList: PropTypes.func,

  filter: PropTypes.instanceOf(DataListFilter),
  isAdminViewer: PropTypes.bool,
  i18n: PropTypes.instanceOf(Map),
};

function SupplierDashboardContainer(props) {
  const { location, classes, i18n } = props;
  return (
    <SupplierAppLayout
      slug="dashboard"
      title={i18n.get("dashboard", "Dashboard")}
      appBarRightAction={
        <FlexBox align="center" className={classes.appBarRightAction}>
          <Link to={updateHash(location, DASHBOARD_FILTER_DIALOG_HASH)}>
            <Tooltip title={i18n.get("filter", "Filter")}>
              <IconButton className={classes.appBarRightActionButton}>
                <FilterList />
              </IconButton>
            </Tooltip>
          </Link>
        </FlexBox>
      }
    >
      <Redirect
        when={
          !(
            "from_date_time" in location.query &&
            "to_date_time" in location.query
          )
        }
        to={updateQuery(
          location,
          fp.flow(
            fp.set(
              "from_date_time",
              formatDateTimeToUrl(subDays(startOfToday(), 7)),
            ),
            fp.set("to_date_time", formatDateTimeToUrl(startOfToday())),
          ),
        )}
      />

      <SupplierDashboardFilterDialogWrapper
        open={location.hash === DASHBOARD_FILTER_DIALOG_HASH}
        filter={props.filter}
        onFilterChange={filter => {
          props.replaceLocationHash(null);
          props.setLocationQueryFilter(filter);
        }}
        onRequestClose={() => props.replaceLocationHash(null)}
      />

      <FlexBox container={24} flex="none" direction="column">
        <FlexBox justify="space-between">
          <FlexBox flex={true} container={24}>
            <SupplierOfdChartWrapper filter={props.filter} type="ofd" />
          </FlexBox>
          <FlexBox flex={true} container={24}>
            <SupplierDeaChartWrapper filter={props.filter} type="dea" />
          </FlexBox>
          <FlexBox flex={true} container={24}>
            <SupplierFddsChartWrapper filter={props.filter} type="fdds" />
          </FlexBox>
        </FlexBox>

        <FlexBox justify="space-between">
          <FlexBox flex={true} container={24}>
            <SupplierOccChartWrapper filter={props.filter}>
              <OrderCreatedCountChartComponent />
            </SupplierOccChartWrapper>
          </FlexBox>
          <FlexBox flex={true} container={24}>
            <SupplierAptChartWrapper filter={props.filter}>
              <PickedUpTimeChartComponent />
            </SupplierAptChartWrapper>
          </FlexBox>
          <FlexBox flex={true} container={24}>
            <SupplierAdtChartWrapper filter={props.filter}>
              <DeliveryTimeChartComponent />
            </SupplierAdtChartWrapper>
          </FlexBox>
        </FlexBox>

        <FlexBox>
          <FlexBox flex={true} container={24}>
            <SupplierArrivalTimeChartWrapper filter={props.filter} />
          </FlexBox>
        </FlexBox>

        <FlexBox justify="space-between">
          <FlexBox flex={4} wrap={true}>
            <FlexBox flex={true} container={24}>
              <SupplierDSRChartWrapper filter={props.filter}>
                <DeliverySuccessRateChartComponent />
              </SupplierDSRChartWrapper>
            </FlexBox>
            <FlexBox direction="column" flex={12} container={24}>
              <SupplierDashboardOrderTrackerWrapper location={location} />
            </FlexBox>
            <FlexBox direction="row" flex={12} container={24}>
              <SupplierRTOChartWrapper filter={props.filter} />
            </FlexBox>
            <FlexBox direction="row" flex={12} container={24}>
              <SupplierCODWrapper />
            </FlexBox>
          </FlexBox>

          <FlexBox flex={8} wrap={true}>
            <FlexBox container={24} flex={12}>
              <SupplierKPIMissesChartWrapper filter={props.filter} />
            </FlexBox>

            <FlexBox flex={12}>
              <FlexBox flex={6} container={24}>
                <WarehouseInboundOutboundWidget
                  title={i18n.get("warehouse_transit", "Warehouse Transit")}
                  filter={props.filter}
                  getReport={getWarehouseInboundOutboundReportsV2}
                />
              </FlexBox>
              <FlexBox flex={6} container={24}>
                <DeliveryAttemptsWidget
                  title={i18n.get("delivery_attempts", "Delivery Attempts")}
                  filter={props.filter}
                  getReport={getDeliveryAttemptsV2}
                />
              </FlexBox>
            </FlexBox>
            <FlexBox flex={12}>
              <FlexBox flex={6} container={24}>
                <DeliverySuccessRateWidget
                  filter={props.filter}
                  getReports={getDeliveryRateV2}
                />
              </FlexBox>
              <FlexBox flex={6} container={24}>
                <PickupRateWidget
                  filter={props.filter}
                  getReports={getPickupRateV2}
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <FlexBox>
          <FlexBox flex={true} container={24}>
            <SupplierDispatchedVSAttemptedChartWrapper filter={props.filter} />
          </FlexBox>
        </FlexBox>

        <FlexBox>
          <FlexBox flex={6} container={24}>
            <TopDriversWidget
              filter={props.filter}
              title={i18n.get("top_drivers", "Top Drivers")}
              getDrivers={getTopDrivers}
              driverUrl={DRIVERS_DASHBOARD_URL}
            />
          </FlexBox>
          <FlexBox flex={6} container={24}>
            <SupplierGoogleHeatMapWrapper
              filter={props.filter}
              location={props.location}
            />
          </FlexBox>
        </FlexBox>

        <FlexBox>
          <FlexBox flex={6} container={24}>
            <LatestAlerts
              title={i18n.get("latest_alerts", "Latest Alerts")}
              getAlerts={getAlertsList}
              radarURL={DRIVER_RADAR_URL}
            />
          </FlexBox>
          <FlexBox flex={6} container={24}>
            <LatestCustomerFeedback
              title={i18n.get(
                "latest_customer_feedback",
                "Latest Customer Feedback",
              )}
              getCustomerFeedbacks={getCustomerFeedback}
              createMoreFeedbackListHref={() => CUSTOMER_FEEDBACK_LIST_URL}
              onClickOrderUrl={orderNumber =>
                props.setLocationQuery(fp.set("search", orderNumber))
              }
            />
          </FlexBox>
        </FlexBox>

        {false && (
          <FlexBox>
            <FlexBox flex={true} container={24}>
              <SupplierSupplierScoresChartWrapper
                title={i18n.get("supplier_rating", "Supplier Rating")}
              />
            </FlexBox>
          </FlexBox>
        )}
      </FlexBox>
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierDashboardContainer);
