import { SIGN_IN, isSigningIn } from "../../shared/reducers/AuthReducer";
import { API_ROOT_URL } from "../../shared/constants/ApiConstants";

const SIGN_IN_URL = `${API_ROOT_URL}/supplier/authenticate`;

export function signIn({ username, password, rememberMe }, nextUrl) {
  return (dispatch, getState, api) => {
    if (isSigningIn(getState())) {
      return Promise.reject(new Error("Already signing in"));
    }

    return dispatch({
      meta: { next: nextUrl },
      type: SIGN_IN,
      payload: api.post(SIGN_IN_URL, {
        body: { username, password, remember_me: rememberMe },
      }),
    });
  };
}
