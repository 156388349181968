import React from "react";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import SupplierCODChartWrapper from "./SupplierCODChartWrapper";
import { isEqualData } from "../../../helpers/DataUtils";
import DataListFilter from "../../../helpers/DataListFilter";
import { toCODFilter } from "../../../helpers/CODFilterMapper";

const enhancer = compose(
  mapPropsStream(propsStream => {
    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), toCODFilter))
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(filterStream, (props, filter) => ({
        ...props,
        filter,
      }));
  }),
);

SupplierCODWrapper.propTypes = {
  filter: PropTypes.instanceOf(DataListFilter),
};

function SupplierCODWrapper(props) {
  return <SupplierCODChartWrapper filter={props.filter} />;
}

export default enhancer(SupplierCODWrapper);
