import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { getMessage } from "../../reducers/LocalizationReducer";
import { REPORTING_OPERATIONAL_URL } from "../../constants/SupplierPathConstants";
import FlexBox from "../../components/ui-core/FlexBox";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    paper: { height: "200px" },
  }),
);

SupplierReportContainer.propTypes = {
  classes: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
};

function SupplierReportContainer(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <SupplierAppLayout
      slug="reports_dashboard"
      title={getLocalisationMessage("reports", "Reports")}
    >
      <FlexBox container={8}>
        <FlexBox gutter={8} flex={true} direction="column">
          <FlexBox flex={true}>
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true}>
                <FlexBox
                  className={classes.paper}
                  flex={true}
                  align="center"
                  justify="center"
                  element={<Paper />}
                >
                  <Link href={REPORTING_OPERATIONAL_URL}>
                    {getLocalisationMessage(
                      "operational_reports",
                      "OPERATIONAL REPORTS",
                    )}
                  </Link>
                </FlexBox>
              </FlexBox>
              <FlexBox flex={true} />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierReportContainer);
