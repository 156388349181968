import React from "react";
import { List } from "immutable";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import OrderCreateWizardStepPayItem from "./OrderCreateWizardStepPayItem";
import OrderCreateWizardStepCard from "../internal/OrderCreateWizardStepCard";
import { getMessage } from "../../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    progress: { display: "flex", paddingTop: "24px", justifyContent: "center" },
  }),
  reduxForm({
    form: "OrderCreateWizardSupplier",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
);

OrderCreateWizardStepPayForm.propTypes = {
  classes: PropTypes.object,
  setPaymentType: PropTypes.func,

  change: PropTypes.func,
  handleSubmit: PropTypes.func,

  showContent: PropTypes.bool,

  onSubmit: PropTypes.func,
  paymentTypesFetching: PropTypes.bool.isRequired,
  paymentTypes: PropTypes.instanceOf(List).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardStepPayForm(props) {
  return (
    <OrderCreateWizardStepCard
      title={props.getLocalisationMessage(
        "how_are_you_paying",
        "How are you paying?",
      )}
      showContent={props.showContent}
    >
      {props.paymentTypesFetching ? (
        <div className={props.classes.progress}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          {props.paymentTypes
            .map(paymentType => (
              <OrderCreateWizardStepPayItem
                key={paymentType}
                paymentType={paymentType}
                onClick={() => {
                  props.change("paymentType", paymentType);
                  setTimeout(() => {
                    props.handleSubmit();
                  });
                }}
              />
            ))
            .toArray()}
        </div>
      )}
    </OrderCreateWizardStepCard>
  );
}

export default enhancer(OrderCreateWizardStepPayForm);
