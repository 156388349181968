import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import FlagIcon from "react-flag-kit/lib/CDNFlagIcon";
import OrderCreateWizardStepDetails from "../internal/OrderCreateWizardStepDetails";
import UserAvatar from "../../avatars/UserAvatar";

const valueSelector = formValueSelector("OrderCreateWizardSupplier");

const enhancer = compose(
  useSheet({ flag: { verticalAlign: "sub" } }),
  connect(state => ({
    values: valueSelector(state, "dropoffLocation", "dropoffContactName"),
  })),
);

OrderCreateWizardStepToDetails.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

function OrderCreateWizardStepToDetails(props) {
  const { classes, values } = props;

  if (!values.dropoffLocation) {
    return null;
  }

  return (
    <OrderCreateWizardStepDetails
      label="To"
      onClick={props.onClick}
      avatar={<UserAvatar size={40} name={values.dropoffContactName} />}
      primaryText={
        <div>
          {values.dropoffContactName}{" "}
          <FlagIcon
            code={values.dropoffLocation.country}
            size={18}
            className={classes.flag}
          />
        </div>
      }
      secondaryText={values.dropoffLocation.address}
    />
  );
}

export default enhancer(OrderCreateWizardStepToDetails);
