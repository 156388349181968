import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";

const DEA_FDDS_OFD_REPORT_URL = `${
  API_ROOT_URL
}/dashboard/supplier/dea_fdds_ofd_report`;
const HEATMAP_REPORT_URL = `${API_ROOT_URL}/dashboard/supplier/heat_map_report`;
const KPI_MISSES_REPORT_URL = `${
  API_V2_ROOT_URL
}/dashboard/supplier/report/kpi`;
const RTO_REPORT_URL = `${API_V2_ROOT_URL}/dashboard/supplier/report/rto`;
const COD_REPORT_URL = `${API_ROOT_URL}/dashboard/supplier/cod_report`;
const SUPPLIER_SCORES_URL = `${
  API_ROOT_URL
}/dashboard/supplier/supplier_score_for_chart`;
const DRIVER_SCORES_URL = `${
  API_ROOT_URL
}/dashboard/supplier/driver_score_for_chart`;
const WAREHOUSE_INBOUND_OUTBOUND_URL = `${
  API_ROOT_URL
}/dashboard/supplier/in_out_bound`;
const DELIVERY_ATTEMPTS_URL = `${
  API_ROOT_URL
}/dashboard/supplier/delivery_attempt`;

const OFD_REPORT_URL_V2 = `${API_V2_ROOT_URL}/dashboard/supplier/report/ofd`;
const OCC_REPORT_URL_V2 = `${API_V2_ROOT_URL}/dashboard/supplier/report/occ`;
const DEA_REPORT_URL_V2 = `${API_V2_ROOT_URL}/dashboard/supplier/report/dea`;
const FDDS_REPORT_URL_V2 = `${API_V2_ROOT_URL}/dashboard/supplier/report/fdds`;
const ADT_REPORT_URL_V2 = `${API_V2_ROOT_URL}/dashboard/supplier/report/adt`;
const APT_REPORT_URL_V2 = `${API_V2_ROOT_URL}/dashboard/supplier/report/apt`;
const TOP_DRIVERS_URL_V2 = `${
  API_V2_ROOT_URL
}/dashboard/supplier/report/top-drivers`;
const DISPATCHED_ATTEMPTED_V2 = `${
  API_V2_ROOT_URL
}/dashboard/supplier/report/dispatched-attempted`;

const DSR_REPORT_URL_V2 = `${API_V2_ROOT_URL}/dashboard/supplier/report/dsr`;

const WAREHOUSE_INBOUND_OUTBOUND_REPORTS_URL_V2 = `${
  API_V2_ROOT_URL
}/dashboard/supplier/report/warehouse-transit`;

const ETD_ATD_REPORTS_URL_V2 = `${
  API_V2_ROOT_URL
}/dashboard/admin/report/etd-atd`;

export const getEtdAndAtdReports = (request: DataListFilter) =>
  api.getStream(ETD_ATD_REPORTS_URL_V2, {
    params: request.getDefinedValues(),
  });

export const getWarehouseInboundOutboundReportsV2 = (request: DataListFilter) =>
  api.getStream(WAREHOUSE_INBOUND_OUTBOUND_REPORTS_URL_V2, {
    params: request.getDefinedValues(),
  });

export const getDSRReports = (request: DataListFilter) =>
  api.getStream(DSR_REPORT_URL_V2, {
    params: request.getDefinedValues(),
  });

export const getDispatchedAttemptedReportsV2 = (request: DataListFilter) =>
  api.getStream(DISPATCHED_ATTEMPTED_V2, {
    params: request.getDefinedValues(),
  });

const DELIVERY_ATTEMPTS_URL_V2 = `${
  API_V2_ROOT_URL
}/dashboard/supplier/report/delivery-attempt`;
const DELIVERY_RATE_URL_V2 = `${
  API_V2_ROOT_URL
}/dashboard/supplier/report/delivery-rate`;
const PICKUP_RATE_URL_V2 = `${
  API_V2_ROOT_URL
}/dashboard/supplier/report/picked-up-rate`;

export const getDeliveryAttemptsV2 = (request: DataListFilter) =>
  api.getStream(DELIVERY_ATTEMPTS_URL_V2, {
    params: request.getDefinedValues(),
  });

export const getDeliveryRateV2 = (request: DataListFilter) =>
  api.getStream(DELIVERY_RATE_URL_V2, {
    params: request.getDefinedValues(),
  });

export const getPickupRateV2 = (request: DataListFilter) =>
  api.getStream(PICKUP_RATE_URL_V2, {
    params: request.getDefinedValues(),
  });

export const getOFDReportsV2 = (request: DataListFilter) =>
  api.getStream(OFD_REPORT_URL_V2, {
    params: request.getDefinedValues(),
  });

export const getOCCReportsV2 = (request: DataListFilter) =>
  api.getStream(OCC_REPORT_URL_V2, {
    params: request.getDefinedValues(),
  });

export const getFDDSReportsV2 = (request: DataListFilter) =>
  api.getStream(FDDS_REPORT_URL_V2, {
    params: request.getDefinedValues(),
  });

export const getDEAReportsV2 = (request: DataListFilter) =>
  api.getStream(DEA_REPORT_URL_V2, {
    params: request.getDefinedValues(),
  });

export const getADTReportsV2 = (request: DataListFilter) =>
  api.getStream(ADT_REPORT_URL_V2, {
    params: request.getDefinedValues(),
  });

export const getAPTReportsV2 = (request: DataListFilter) =>
  api.getStream(APT_REPORT_URL_V2, {
    params: request.getDefinedValues(),
  });

export const getTopDrivers = (request: DataListFilter) =>
  api.getStream(TOP_DRIVERS_URL_V2, {
    params: request.getDefinedValues(),
  });

export const getDeaFddsOfdReports = (request: DataListFilter) =>
  api.getStream(DEA_FDDS_OFD_REPORT_URL, {
    params: request.getDefinedValues(),
  });

export const getHeatMapLocations = (request: DataListFilter) =>
  api.getStream(HEATMAP_REPORT_URL, {
    timeout: 10 * 60 * 1000,
    params: request.getDefinedValues(),
  });

export const getKPIMissesReports = (request: DataListFilter) =>
  api.getStream(KPI_MISSES_REPORT_URL, {
    params: request.getDefinedValues(),
  });

export const getRTOReports = (request: DataListFilter) =>
  api.getStream(RTO_REPORT_URL, {
    params: request.getDefinedValues(),
  });

export const getCODReports = (request: DataListFilter) =>
  api.getStream(COD_REPORT_URL, {
    params: request.getDefinedValues(),
  });

export const getSupplierScores = (request: DataListFilter) =>
  api.getStream(SUPPLIER_SCORES_URL, {
    params: request.getDefinedValues(),
  });

export const getDriverScores = (request: DataListFilter) =>
  api.getStream(DRIVER_SCORES_URL, {
    params: request.getDefinedValues(),
  });

export const getWarehouseInboundOutBoundReport = (request: DataListFilter) =>
  api.getStream(WAREHOUSE_INBOUND_OUTBOUND_URL, {
    params: request.getDefinedValues(),
  });

export const getDeliveryAttemptsReport = (request: DataListFilter) =>
  api.getStream(DELIVERY_ATTEMPTS_URL, {
    params: request.getDefinedValues(),
  });
