import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { Checkbox, Button, FormControlLabel } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import ScannerTextField from "../deprecated/ScannerTextField";
import { pureComponent } from "../../helpers/HOCUtils";
import { cleanupStringArray } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const parseOrderNumbers = fp.flow(
  fp.trim,
  // x => x.replace(/\W/g, " "),
  x => x.replace(/[^A-Za-z0-9-]/g, " "),
  fp.split(" "),
  cleanupStringArray,
);

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    checkbox: { whiteSpace: "nowrap" },
    input: { "& input": { fontSize: "32px" } },
    scanner: { flexDirection: "column" },
    "@media (min-width: 998px)": {
      scanner: { flexDirection: "row" },
    },
  }),
  withHandlers({
    handleSubmit: props => value => {
      let response = [];
      response.multiBox = false;

      const orderNumbers = value.split(",");
      const order = orderNumbers[0].split("-");

      if (fp.size(order) > 1) {
        response = {
          multiBox: true,
          barcode: orderNumbers,
          orderNumber: parseOrderNumbers(order[0]),
          piece: order[1],
          pieceCount: order[2],
        };
      } else {
        response = { multiBox: false, orders: parseOrderNumbers(value) };
      }
      props.onSubmit(response);
    },
  }),
  pureComponent(fp.pick(["autoAssign", "focusInput"])),
);

OrderSortingForm.propTypes = {
  classes: PropTypes.object,

  autoAssign: PropTypes.bool,
  onAutoAssignChange: PropTypes.func.isRequired,

  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  focusInput: PropTypes.bool.isRequired,
  onPrefetchOrdersClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function OrderSortingForm(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <FlexBox gutter={8} className={classes.scanner}>
      <FlexBox flex={true}>
        <ScannerTextField
          fullWidth={true}
          hintText={getLocalisationMessage("add_orders", "Add Orders")}
          focus={props.focusInput}
          className={classes.input}
          id="OrderSortingFormScannerTextField"
          onChange={props.handleSubmit}
        />
      </FlexBox>

      <FlexBox align="center">
        <FlexBox align="center">
          <FormControlLabel
            control={
              <Checkbox
                checked={props.autoAssign}
                className={classes.checkbox}
                onChange={e => props.onAutoAssignChange(e.target.checked)}
              />
            }
            label={getLocalisationMessage(
              "assign_to_warehouse",
              "Assign To Warehouse",
            )}
          />
        </FlexBox>

        <FlexBox align="center">
          <Button onClick={props.onPrefetchOrdersClick}>
            {" "}
            {getLocalisationMessage("prefetch_orders", "Prefetch Orders")}{" "}
          </Button>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(OrderSortingForm);
