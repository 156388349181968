import React from "react";
import { List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import {
  Divider,
  MenuItem,
  Button,
  IconButton,
  CircularProgress,
  Typography,
  ListItemIcon,
} from "@material-ui/core";
import { connect } from "react-redux";
import { sizeMe } from "react-sizeme";
import { Settings, FilterList } from "@material-ui/icons";
import getWidth from "dom-helpers/query/width";
import { withTheme } from "@material-ui/core/styles";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { parserOrderSmartFilter } from "../../helpers/OrderSmartFiltersHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  sizeMe(),
  withTheme,
  useSheet({
    progressWrapper: { padding: "12px" },
    hidden: {
      top: "-9999px",
      left: "-9999px",
      position: "absolute",
      visibility: "hidden",
    },
  }),
  mapPropsStream(propsStream => {
    const buttonNodes = createEventHandler();
    const containerNode = createEventHandler();

    const showButtonsStream = containerNode.stream
      .combineLatest(
        buttonNodes.stream
          .scan((acc, [idx, x]) => {
            if (!x) {
              delete acc[idx];
            } else {
              acc[idx] = getWidth(x);
            }

            return acc;
          }, {})
          .distinctUntilChanged(isEqualData),
        propsStream
          .map(fp.get("size.width"))
          .distinctUntilChanged()
          .debounceTime(100),
        (container, buttonWidths) => {
          const containerWidth = !container ? 0 : getWidth(container);
          const showButtons = {};

          if (containerWidth > 0) {
            let totalWidth = 0;

            fp.keys(buttonWidths).forEach(i => {
              totalWidth += buttonWidths[i];

              if (totalWidth < containerWidth) {
                showButtons[i] = true;
              }
            });
          }

          return showButtons;
        },
      )
      .distinctUntilChanged(isEqualData)
      .startWith({});

    return propsStream.combineLatest(
      showButtonsStream,
      propsStream.map(props => props.filters.filter(i => !i.get("is_tab"))),
      (props, showButtons, filters) => ({
        ...props,
        filters,
        showButtons,
        buttonNodeRef: buttonNodes.handler,
        containerNodeRef: containerNode.handler,
      }),
    );
  }),
);

OrderCustomFilterButtons.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object,

  showButtons: PropTypes.object,
  buttonNodeRef: PropTypes.func,
  containerNodeRef: PropTypes.func,

  isLoading: PropTypes.bool.isRequired,
  filters: PropTypes.instanceOf(List).isRequired,

  filter: PropTypes.instanceOf(DataListFilter),

  onFilterClick: PropTypes.func.isRequired,
  onEditFiltersClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCustomFilterButtons(props) {
  const { classes, getLocalisationMessage } = props;

  if (props.isLoading) {
    return (
      <FlexBox className={classes.progressWrapper}>
        <CircularProgress color="secondary" size={24} thickness={2} />
      </FlexBox>
    );
  }

  return (
    <FlexBox gutter={8} flex={true}>
      <FlexBox flex={true}>
        <FlexBox
          flex={true}
          justify="flex-end"
          element={<div ref={props.containerNodeRef} />}
        >
          {props.filters.map((item, idx) => (
            <FlexBox key={idx} align="center">
              <div
                ref={x => props.buttonNodeRef([idx, x])}
                className={cx(!props.showButtons[idx] && classes.hidden)}
              >
                <Button
                  onClick={() =>
                    props.onFilterClick(
                      parserOrderSmartFilter(item.get("filter")),
                    )
                  }
                >
                  {" "}
                  {getLocalisationMessage(item.get("name"))}{" "}
                </Button>
              </div>
            </FlexBox>
          ))}
        </FlexBox>
      </FlexBox>

      {Boolean(
        !props.isLoading && props.filters.size > fp.size(props.showButtons),
      ) && (
        <FlexBox>
          <MenuButtonMore>
            {props.filters.map((item, idx) =>
              props.showButtons[idx] ? null : (
                <MenuItem
                  key={idx}
                  onClick={() =>
                    props.onFilterClick(
                      parserOrderSmartFilter(item.get("filter")),
                    )
                  }
                >
                  <ListItemIcon>
                    <FilterList />
                  </ListItemIcon>
                  <Typography>
                    {getLocalisationMessage(item.get("name"))}
                  </Typography>
                </MenuItem>
              ),
            )}

            {!fp.isEmpty(props.showButtons) && <Divider />}

            <MenuItem onClick={props.onEditFiltersClick}>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <Typography>
                {getLocalisationMessage("edit_filters", "Edit Filters")}
              </Typography>
            </MenuItem>
          </MenuButtonMore>
        </FlexBox>
      )}

      {Boolean(
        !props.isLoading && props.filters.size <= fp.size(props.showButtons),
      ) && (
        <IconButton
          title={getLocalisationMessage("edit_filters", "Edit Filters")}
          onClick={props.onEditFiltersClick}
        >
          <Settings />
        </IconButton>
      )}
    </FlexBox>
  );
}

export default enhancer(OrderCustomFilterButtons);
