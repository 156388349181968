import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add } from "@material-ui/icons";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDataListFilter } from "../../helpers/DataListFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getSupplierUserList } from "../../api/supplier/SupplierUserApi";
import SupplierUserEditDialogWrapper from "../../wrappers/supplier/SupplierUserEditDialogWrapper";
import SupplierUserCreateDialogWrapper from "../../wrappers/supplier/SupplierUserCreateDialogWrapper";
import OverallStatusTabs from "../../components/ui-core/OverallStatusTabs";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import DataList, { DataListColumn } from "../../components/data-list/DataList";
import DateTimeCell from "../../components/data-list/DateTimeCell";
import MultiLineCell from "../../components/data-list/MultiLineCell";
import CheckOverallStatus from "../../components/route-validators/CheckOverallStatus";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toDataListFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getSupplierUserList(props.filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          );

        return propsStream
          .combineLatest(listResponseStream, (props, listResponse) => ({
            ...props,
            onRequestRefresh,
            list: listResponse.get("list"),
            total: listResponse.get("total"),
            isLoading: listResponse.get("pending"),
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

SupplierUserList.propTypes = {
  filter: PropTypes.instanceOf(DataListFilter),
  total: PropTypes.number,
  location: PropTypes.object,
  list: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  onRequestRefresh: PropTypes.func,
  setLocationQueryFilter: PropTypes.func.isRequired,
  setLocationQuery: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function SupplierUserList(props) {
  const { location, getLocalisationMessage } = props;

  const NA = getLocalisationMessage("na", "N/A");

  return (
    <SupplierAppLayout
      slug="users"
      title={getLocalisationMessage("users", "Users")}
    >
      <CheckOverallStatus location={location} />

      <OverallStatusTabs location={location} />

      <SupplierUserCreateDialogWrapper
        open={location.query.create === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
      />

      <SupplierUserEditDialogWrapper
        supplierUserId={fp.toFinite(location.query.view)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("view"));
        }}
      />

      <DataList
        isLoading={props.isLoading}
        totalCount={props.total}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        overscanRowCount={7}
        rowCount={props.list.size}
        rowGetter={options => props.list.get(options.index)}
        cardActionIcons={
          <Tooltip
            title={getLocalisationMessage("create_new_user", "Create New User")}
          >
            <IconButton
              onClick={() => props.setLocationQuery(fp.set("create", true))}
            >
              <Add />
            </IconButton>
          </Tooltip>
        }
      >
        <DataListColumn
          width={96}
          label={getLocalisationMessage("id", "ID")}
          dataKey="id"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row => (
            <strong>
              <Link
                to={updateQuery(
                  props.location,
                  fp.set("view", row.cellData.get("id")),
                )}
              >
                {row.cellData.get("user_id")}
              </Link>
            </strong>
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("name", "Name")}
          dataKey="name"
          disableSort={true}
          cellRenderer={row => (
            <MultiLineCell
              fallBackValue={NA}
              firstLine={row.cellData.get("first_name")}
              secondLine={row.cellData.get("last_name")}
            />
          )}
        />
        <DataListColumn
          width={128}
          label={getLocalisationMessage("login", "Login")}
          dataKey="login"
          cellRenderer={row => row.cellData.get("login")}
        />
        <DataListColumn
          width={128}
          label={getLocalisationMessage("email", "Email")}
          dataKey="email"
          cellRenderer={row => row.cellData.get("email")}
        />
        <DataListColumn
          width={128}
          label={getLocalisationMessage("phone", "Phone")}
          dataKey="phone"
          cellRenderer={row => row.cellData.get("phone", NA)}
        />
        <DataListColumn
          width={128}
          label={getLocalisationMessage("courier_name", "Courier Name")}
          dataKey="supplier_name"
          cellRenderer={row => row.cellData.getIn(["supplier", "name"], NA)}
        />
        <DataListColumn
          width={96}
          justifyContent="center"
          label={getLocalisationMessage("activated", "Activated")}
          dataKey="activated"
          disableSort={true}
          cellRenderer={row =>
            formatText(
              row.cellData.get("activated")
                ? getLocalisationMessage("true", "true")
                : getLocalisationMessage("false", "false"),
            )
          }
        />
        <DataListColumn
          width={96}
          justifyContent="center"
          label={getLocalisationMessage("joined_date", "Joined Date")}
          dataKey="created_date"
          cellRenderer={row => (
            <DateTimeCell date={row.cellData.get("created_date")} />
          )}
        />
      </DataList>
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierUserList);
