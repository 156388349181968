import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import ResponseError from "../../helpers/ResponseError";
import { getMarketplaceDefaultBatchUpdateStatuses } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import OrderStatusCodes, {
  NO_CAPACITY_FOR_DELIVERY,
  DELIVERY_DELAY_DUE_TO_LATE_BOOKING,
} from "../../constants/OrderStatusCodes";
import { getTimeSlots } from "../../api/customer/CustomerTimeSlotApi";
import { getOrder, updateOrder } from "../../api/supplier/SupplierOrderApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/supplier/SupplierDriverApi";
import { getPaymentMethods } from "../../api/supplier/SupplierCustomerApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import OrderForm from "../../components/orders-core/OrderForm";

const enhancer = compose(
  connect(
    (state) => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
        orderStatuses: getMarketplaceDefaultBatchUpdateStatuses(state),
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
  renderIf((props) => props.orderId > 0),
  mapPropsStream((propsStream) => {
    const { handler: onRequestRefresh, stream: onRequestRefreshStream } =
      createEventHandler();

    const responseStream = propsStream
      .distinctUntilKeyChanged("orderId")
      .switchMap((props) =>
        getOrder(props.orderId)
          .catch((error) => Observable.of({ error }))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(responseStream, (props, response) => ({
        ...props,
        onRequestRefresh,
        item: response.get("payload"),
        isLoading: response.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

SupplierOrderEditDialogWrapper.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  isLoading: PropTypes.bool,
  item: PropTypes.instanceOf(Map),

  orderId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  orderStatuses: PropTypes.object,
};

function SupplierOrderEditDialogWrapper(props) {
  const statusList =
    props.orderStatuses && props.orderStatuses.size > 0
      ? props.orderStatuses
      : OrderStatusCodes;
  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoading}
      onRequestClose={props.onRequestClose}
      title={
        props.item.get("id")
          ? `${props.getLocalisationMessage(
              "edit_order",
              "Edit Order",
            )} #${props.item.get("order_number")}`
          : null
      }
    >
      <FlexBox direction="column">
        <OrderForm
          orderStatusCodes={statusList
            .delete(NO_CAPACITY_FOR_DELIVERY)
            .delete(DELIVERY_DELAY_DUE_TO_LATE_BOOKING)}
          order={props.item}
          onDismiss={props.onRequestClose}
          onSubmit={(values) =>
            updateOrder(props.item.get("id"), values).catch(ResponseError.throw)
          }
          onSubmitFail={props.showErrorMessage}
          onSubmitSuccess={() => {
            props.onRequestRefresh();

            props.showSuccessMessage(
              props.getLocalisationMessage(
                "successfully_order_edited",
                "Successfully Order Edited",
              ),
            );
          }}
          getTimeSlots={getTimeSlots}
          getPaymentMethods={getPaymentMethods}
          getCachedDriver={getCachedDriver}
          getDriverPredictions={getDriverPredictions}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(SupplierOrderEditDialogWrapper);
