import React from "react";
import fp from "lodash/fp";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  COMMUNICATION_SETTINGS_ITEM_URL,
  COMMUNICATION_SETTINGS_CREATE_URL,
} from "../../constants/SupplierPathConstants";
import { getSupplierCommunicationEventsList } from "../../api/shared/CommunicationApi";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import CommunicationSettingsTable, {
  BY_EVENTS,
} from "../../components/communication-settings/CommunicationSettingsTable";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    setLocation: PropTypes.func.isRequired,
  }),
);

SupplierCommunicationSettingsList.propTypes = {
  location: PropTypes.object,

  setLocation: PropTypes.func,
  setLocationQuery: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SupplierCommunicationSettingsList(props) {
  return (
    <SupplierAppLayout
      slug="communication_settings"
      title={props.getLocalisationMessage(
        "communication_settings",
        "Communication Settings",
      )}
    >
      <CommunicationSettingsTable
        location={props.location}
        onChangeFilter={(type, value) =>
          type === "courier"
            ? props.setLocationQuery(fp.set("eventCourierType", value))
            : props.setLocationQuery(fp.set("toCityId", value))
        }
        getCommunicationList={getSupplierCommunicationEventsList}
        onFilterChange={data => props.setLocationQuery({ filterType: data })}
        onItemClick={id =>
          props.setLocation(
            updateQuery(COMMUNICATION_SETTINGS_ITEM_URL + id, {
              ...props.location.query,
            }),
          )
        }
        onCreateClick={query =>
          props.setLocation(
            updateQuery(COMMUNICATION_SETTINGS_CREATE_URL, {
              ...props.location.query,
              ...query,
            }),
          )
        }
        onEventChange={(filterType, data) =>
          filterType === BY_EVENTS
            ? props.setLocationQuery(fp.set("eventType", data))
            : props.setLocationQuery(fp.set("eventTarget", data))
        }
      />
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierCommunicationSettingsList);
