import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { createSupplierUser } from "../../api/supplier/SupplierUserApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import SupplierUserForm from "../../components/suppliers-core/SupplierUserForm";

const enhancer = compose(
  renderIf("open"),
  connect(
    state => {
      const getLocalisationMessage = (code, defaultMessage) =>
        getMessage(state, code, defaultMessage);

      return {
        getLocalisationMessage,
      };
    },
    {
      showErrorMessage,
      showSuccessMessage,
    },
  ),
);

SupplierUserCreateDialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SupplierUserCreateDialogWrapper(props) {
  const { getLocalisationMessage } = props;
  return (
    <ModalPaper
      title={getLocalisationMessage(
        "create_supplier_user",
        "Create Supplier User",
      )}
      open={props.open}
      onRequestClose={props.onRequestClose}
    >
      <FlexBox container={8} direction="column">
        <SupplierUserForm
          onDismiss={props.onRequestClose}
          onSubmit={values =>
            createSupplierUser(
              toSnakeCase({
                ...values,
                login: values.email,
              }),
            ).catch(ResponseError.throw)
          }
          onSubmitSuccess={() => {
            props.onRequestClose();
            props.showSuccessMessage(
              getLocalisationMessage(
                "successfully_saved",
                "Successfully saved",
              ),
            );
          }}
          onSubmitFail={props.showErrorMessage}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(SupplierUserCreateDialogWrapper);
