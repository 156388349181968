import React from "react";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import {
  Dialog,
  Button,
  IconButton,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { InfoOutlined } from "@material-ui/icons";
import OrderCreateWizardStepCard from "../internal/OrderCreateWizardStepCard";
import FormCheckbox from "../../form/FormCheckbox";
import FormTextField from "../../form/FormTextField";
import FlexBox from "../../ui-core/FlexBox";
import { parseFloat } from "../../../helpers/FormatUtils";
import { isCustomMarketplace } from "../../../reducers/MarketplaceReducer";
import { getMessage } from "../../../reducers/LocalizationReducer";

const valueSelector = formValueSelector("OrderCreateWizardSupplier");

const enhancer = compose(
  reduxForm({
    form: "OrderCreateWizardSupplier",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    isCustomMarketplace: isCustomMarketplace(state),
    values: valueSelector(state, "codCharge", "acceptCodTerms"),
  })),
  mapPropsStream(propsStream => {
    const {
      handler: setOpenTermsDialog,
      stream: openTermsDialogStream,
    } = createEventHandler();

    return propsStream.combineLatest(
      openTermsDialogStream.startWith(false),
      (props, openTermsDialog) => ({
        ...props,
        openTermsDialog,
        setOpenTermsDialog,
      }),
    );
  }),
);

OrderCreateWizardStepCollectForm.propTypes = {
  openTermsDialog: PropTypes.bool,
  setOpenTermsDialog: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  values: PropTypes.object,
  initialValues: PropTypes.object,

  showContent: PropTypes.bool,
  isCustomMarketplace: PropTypes.bool,

  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardStepCollectForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <OrderCreateWizardStepCard
      title={getLocalisationMessage(
        "collect_cash_on_delivery",
        "Collect cash on delivery?",
      )}
      onSubmit={props.handleSubmit}
      showContent={props.showContent}
      showSubmit={Boolean(props.showContent && props.values.codCharge > 0)}
      onDismiss={() => {
        props.change("acceptCodTerms", false);
        props.change("codCharge", props.initialValues.codCharge || null);

        props.onDismiss();
      }}
    >
      <Dialog
        open={props.openTermsDialog}
        onClose={() => props.setOpenTermsDialog(false)}
      >
        <DialogTitle>
          {getLocalisationMessage(
            "cod_order_terms_and_conditions",
            "COD order Terms and Conditions",
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>
              {getLocalisationMessage(
                "terms_and_conditions_1",
                "Customer placing an order is the ultimately responsible for having a\n" +
                  "          Trade License. It's Customers responsibility for placing a COD order\n" +
                  "          with Company with or without Trade License. Any legal issues arising from\n" +
                  "          this will be the responsibility of the Customer. To avoid such issues,\n" +
                  "          you are required to send your copy of your Company Trade License to",
              )}{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:sales@wing.ae"
              >
                legal@wing.ae
              </a>
              .
            </p>

            <p>
              {getLocalisationMessage(
                "terms_and_conditions_2",
                "COD settlement means. Company will settle COD amount with the Customer by\n" +
                  "          Bank Transfer only if the COD amount is more than 500AED. Bank account\n" +
                  "          holder name and the Company account name should match 1 to 1 to avoid\n" +
                  "          confusion or unexpected issues. Anything less than 500AED can be\n" +
                  "          written as a cheque on Customers name. It's Customer's responsibility\n" +
                  "          to collect the cheque for COD from Company.",
              )}
            </p>

            <p>
              {getLocalisationMessage(
                "terms_and_conditions_3",
                "COD settlement period. Company will do it's best to settle any COD amount\n" +
                  '          with the Customer within 30-day period if it has the required{" "}\n' +
                  "          information to process the payment.",
              )}
            </p>

            <p>
              {getLocalisationMessage(
                "terms_and_conditions_4",
                "If you are a Merchant that already had bilateral contract with Company on\n" +
                  "          special terms, then that takes over the terms stated here.",
              )}
            </p>

            <p>
              {getLocalisationMessage(
                "terms_and_conditions_5",
                "If you want to have a different agreement than whatever stated above,\n" +
                  "          please contact",
              )}{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:sales@wing.ae"
              >
                sales@wing.ae
              </a>{" "}
              {getLocalisationMessage(
                "to_upgrade_your_account_to_merchant_account",
                "to upgrade your account to Merchant account",
              )}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div>
            <Button onClick={() => props.setOpenTermsDialog(false)}>
              {getLocalisationMessage("dismiss", "Dismiss")}
            </Button>
            <Button
              onClick={() => {
                props.setOpenTermsDialog(false);
                props.change("acceptCodTerms", true);
              }}
            >
              {getLocalisationMessage("accept", "Accept")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <FormTextField
        name="codCharge"
        fullWidth={true}
        parseOnBlur={parseFloat}
        label="How much to collect? *"
      />

      <FlexBox align="center" justify="space-between">
        <FlexBox flex={true}>
          <FormCheckbox
            name="acceptCodTerms"
            label={getLocalisationMessage(
              "i_accept_cod_terms_and_conditions",
              "I accept COD terms and conditions.",
            )}
          />
        </FlexBox>

        <FlexBox>
          {!props.isCustomMarketplace && (
            <IconButton onClick={() => props.setOpenTermsDialog(true)}>
              <InfoOutlined />
            </IconButton>
          )}
        </FlexBox>
      </FlexBox>
    </OrderCreateWizardStepCard>
  );
}

export default enhancer(OrderCreateWizardStepCollectForm);
