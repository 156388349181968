import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import { isEqualData } from "../../helpers/DataUtils";
import { formatNumber, formatPercentage } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    container: {
      width: "100%",
      padding: {
        top: "5px",
        left: "15px",
        bottom: "15px",
        right: "15px",
      },
      color: "#43425D",
    },
    trend: {
      fontSize: "12px",
      marginBottom: "10px",
    },
    value: {
      fontSize: "30px",
      color: "#43425D",
      padding: "8px",
      marginTop: "30px",
    },
    title: {
      fontSize: "18px",
      textTransform: "uppercase",
      padding: "8px",
      textAlign: "center",
      lineHeight: "14px",
    },
    flexPadding: {
      paddingTop: "20px",
      padding: "8px",
    },
    stats: {
      textTransform: "uppercase",
      fontSize: "12px",
      lineHeight: "16px",
    },
  }),
  mapPropsStream(propsStream => {
    const responseStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .switchMap(props =>
        props.getReports(props.filter).catch(() => Observable.of({})),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], Map()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData)
      .filter(response => response.get("payload"))
      .map(response => {
        const info = List().asMutable();
        const report = response.get("payload");

        const promised = report.get("promised_count");
        const delivery = report.get("delivery_time");

        const percentage = (delivery / promised) * 100;

        info.push(
          Map({
            label: "promised",
            align: "flex-start",
            value: promised,
          }),
        );
        info.push(
          Map({
            label: "delivered",
            align: "flex-end",
            value: delivery,
          }),
        );

        return Map({
          percentage,
          info: info.asImmutable(),
        });
      })
      .startWith(Map())
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(responseStream, (props, response) => ({
      ...props,
      data: response,
    }));
  }),
);

DeliverySuccessRateWidget.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.instanceOf(Map),
  getReports: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter),
  title: PropTypes.string,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function DeliverySuccessRateWidget(props) {
  const { classes, data } = props;

  return (
    <Card className={classes.container}>
      <FlexBox direction="column">
        <FlexBox
          flex={true}
          justify="center"
          align="center"
          className={classes.value}
        >
          {formatPercentage(data.get("percentage", 0))}
        </FlexBox>
        <FlexBox flex={true} justify="center" className={classes.title}>
          {props.getLocalisationMessage("delivery_rate", "Delivery Rate")}
        </FlexBox>
        <FlexBox justify="space-between">
          {data.get("info") &&
            data.get("info").map((item, idx) => (
              <FlexBox
                key={idx}
                flex={true}
                justify={item.get("align")}
                className={classes.flexPadding}
              >
                <h4 className={classes.stats}>
                  {props.getLocalisationMessage(
                    item.get("label"),
                    item.get("label"),
                  )}
                  : {formatNumber(item.get("value"))}
                </h4>
              </FlexBox>
            ))}
        </FlexBox>
      </FlexBox>
    </Card>
  );
}

export default enhancer(DeliverySuccessRateWidget);
