import React from "react";
import _ from "lodash";
import Immutable, { Map, List, fromJS, OrderedMap } from "immutable";
import { compose, withState, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Search,
  Settings,
  FolderOpen,
  Dashboard,
  EditLocation,
  DeviceHub,
  FlightTakeoff,
  PeopleOutline,
  AccountBalance,
  Message,
  AssignmentReturned,
} from "@material-ui/icons";
import RadarIcon from "../icons/RadarIcon";
import VehicleIcon from "../icons/VehicleIcon";
import TruckDelivery from "../icons/TruckDelivery";
import CommunicationIcon from "../icons/CommunicationIcon";
import AccountCardDetails from "../icons/AccountCardDetails";
import AppLayout from "../app-layout/AppLayout";
import AppSidebar from "../app-sidebar/AppSidebar";
import AppSidebarLink from "../app-sidebar/AppSidebarLink";
import AppSidebarBlock from "../app-sidebar/AppSidebarBlock";
import AppSidebarProfileBlock from "../app-sidebar/AppSidebarProfileBlock";
import { isEqualData } from "../../helpers/DataUtils";
import { layoutIcon } from "../../helpers/MarketplaceHelper";
import { getUserAccountAuthorities } from "../../reducers/UserReducer";
import {
  getMarketplace,
  marketplaceDefaultOrderCreationEnabled,
} from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  CHARTS_URL,
  ORDER_JOBS_URL,
  ORDER_LIST_URL,
  DRIVER_LIST_URL,
  ORDER_TRACK_URL,
  VEHICLE_LIST_URL,
  ORDER_LIST_MAP_URL,
  ORDER_TRACKING_URL,
  DRIVER_RADAR_V2_URL,
  DRIVER_LIABILITY_URL,
  REPORTING_REPORTS_URL,
  FINANCE_ORDER_LIST_URL,
  ORDER_SORTING_VIEW_URL,
  FINANCE_TRANSFER_LIST_URL,
  CUSTOMER_FEEDBACK_LIST_URL,
  SUPPLIER_USER_LIST_ALL_URL,
  COMMUNICATION_SETTINGS_LIST_URL,
} from "../../constants/SupplierPathConstants";
import supplierMenuList, {
  ORDERS_ROUTING,
} from "../../constants/SupplierMarketplaceMenuConstants";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  getAppName,
  getMapProviderKey,
} from "../../../shared/reducers/AppReducer";
import { ROLE_SUPPLIER_BASIC } from "../../../shared/constants/Authorities";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    app: getAppName(state),
    userAuthorities: getUserAccountAuthorities(state),
    marketplace: getMarketplace(state),
    isDefaultOrderCreation: marketplaceDefaultOrderCreationEnabled(state),
    mapProviderKey: getMapProviderKey(state),
  })),
  withRouter,
  withState("state", "setState", {
    isOpen: true,
    menuName: "",
    loaded: true,
  }),
  mapPropsStream(propsStream => {
    const settingsStream = propsStream
      .take(1)
      .filter(props => props.marketplace)
      .distinctUntilChanged(isEqualData)
      .map(props => {
        const menuList = props.marketplace.getIn(
          ["setting", "settings", "leftMenuSupplier"],
          Map(),
        );
        const isSupplierBasic =
          props.userAuthorities &&
          props.userAuthorities.includes(ROLE_SUPPLIER_BASIC);
        const menu = List().asMutable();
        let currentMenu = null;

        supplierMenuList.forEach(item => {
          const enabledItem = menuList.get(item.slug, null);
          if (enabledItem && enabledItem.get("active", false)) {
            const slug = enabledItem.get("slug", null);
            let menuItem = null;

            if (isSupplierBasic) {
              if (
                slug === "dashboard" ||
                slug === "order_tracking" ||
                slug === "domestic_orders" ||
                slug === "international_orders" ||
                slug === "orders_sorting"
              ) {
                menuItem = Map({
                  ...item,
                  name: enabledItem.get("name", null),
                });
              }
            } else {
              menuItem = Map({
                ...item,
                name: enabledItem.get("name", null),
              });
            }

            if (props.slug && props.slug === item.slug) {
              currentMenu = menuItem;
            }

            switch (item.slug) {
              case ORDERS_ROUTING.slug:
                if (!props.isDefaultOrderCreation) menu.push(menuItem);
                break;
              default:
                menu.push(menuItem);
                break;
            }
          }
        });

        return Map({
          currentMenu,
          settings: fromJS(_.groupBy(menu.toJS(), "group")).sortBy(item =>
            item.get(0).get("ind"),
          ),
        });
      })
      .startWith(OrderedMap())
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(settingsStream, (props, settings) => ({
      ...props,
      settings: settings.get("settings", null),
      currentMenu: settings.get("currentMenu", null),
    }));
  }),
);

export const sidebar = data => {
  const { settings, getLocalisationMessage, userAuthorities } = data;

  const isSupplierBasic =
    userAuthorities && userAuthorities.includes(ROLE_SUPPLIER_BASIC);

  if (settings && settings.size > 0) {
    return (
      <AppSidebar>
        {settings
          .map((group, key) => {
            const groupName = _.startCase(key);

            // const groupToJS = group.toJS();
            // const groupNameIsEqual = groupName === data.state.menuName;
            // const toggle = groupNameIsEqual && data.state.isOpen;
            // const initiallyOpen = _.some(
            //   groupToJS,
            //   // eslint-disable-next-line lodash/matches-prop-shorthand
            //   x => x.url === data.router.location.pathname,
            // );
            return (
              <AppSidebarBlock title={getLocalisationMessage(key, groupName)}>
                {group
                  .map(item => (
                    <AppSidebarLink
                      leftIcon={layoutIcon(item.get("icon"))}
                      to={
                        item.has("params")
                          ? updateQuery(
                              item.get("url"),
                              item.get("params").toJS(),
                            )
                          : item.get("url")
                      }
                    >
                      {item.get("name") ||
                        getLocalisationMessage(
                          item.get("slug"),
                          _.startCase(item.slug),
                        )}
                    </AppSidebarLink>
                  ))
                  .toArray()}
              </AppSidebarBlock>
            );
          })
          .toArray()}
        <AppSidebarProfileBlock />
      </AppSidebar>
    );
  }

  if (isSupplierBasic) {
    return (
      <AppSidebar>
        <AppSidebarBlock
          title={getLocalisationMessage("dashboard", "Dashboard")}
        >
          <AppSidebarLink leftIcon={<Dashboard />} to={CHARTS_URL}>
            {getLocalisationMessage("dashboard", "Dashboard")}
          </AppSidebarLink>
        </AppSidebarBlock>

        <AppSidebarBlock title={getLocalisationMessage("orders", "Orders")}>
          <AppSidebarLink leftIcon={<Search />} to={ORDER_TRACK_URL}>
            {getLocalisationMessage("order_tracking", "Order Tracking")}
          </AppSidebarLink>
          <AppSidebarLink
            leftIcon={<TruckDelivery />}
            to={updateQuery(ORDER_LIST_URL, { is_uae: true })}
          >
            {getLocalisationMessage("domestic_orders", "Domestic Orders")}
          </AppSidebarLink>
          <AppSidebarLink
            leftIcon={<FlightTakeoff />}
            to={updateQuery(ORDER_LIST_URL, { is_uae: false })}
          >
            {getLocalisationMessage(
              "international_orders",
              "International Orders",
            )}
          </AppSidebarLink>
          <AppSidebarLink to={ORDER_SORTING_VIEW_URL} leftIcon={<DeviceHub />}>
            {getLocalisationMessage("order_sorting", "Order Sorting")}
          </AppSidebarLink>
        </AppSidebarBlock>
        <AppSidebarProfileBlock />
      </AppSidebar>
    );
  }

  return (
    <AppSidebar>
      <AppSidebarBlock title={getLocalisationMessage("dashboard", "Dashboard")}>
        <AppSidebarLink leftIcon={<Dashboard />} to={CHARTS_URL}>
          {getLocalisationMessage("dashboard", "Dashboard")}
        </AppSidebarLink>
      </AppSidebarBlock>

      <AppSidebarBlock title={getLocalisationMessage("orders", "Orders")}>
        <AppSidebarLink leftIcon={<Search />} to={ORDER_TRACK_URL}>
          {getLocalisationMessage("order_tracking", "Order Tracking")}
        </AppSidebarLink>
        <AppSidebarLink
          leftIcon={<TruckDelivery />}
          to={updateQuery(ORDER_LIST_URL, { is_uae: true })}
        >
          {getLocalisationMessage("domestic_orders", "Domestic Orders")}
        </AppSidebarLink>
        <AppSidebarLink
          leftIcon={<FlightTakeoff />}
          to={updateQuery(ORDER_LIST_URL, { is_uae: false })}
        >
          {getLocalisationMessage(
            "international_orders",
            "International Orders",
          )}
        </AppSidebarLink>
        <AppSidebarLink
          to={ORDER_TRACKING_URL}
          leftIcon={<AssignmentReturned />}
        >
          {getLocalisationMessage("orders_tracking", "Orders Tracking")}
        </AppSidebarLink>
        <AppSidebarLink to={ORDER_SORTING_VIEW_URL} leftIcon={<DeviceHub />}>
          {getLocalisationMessage("order_sorting", "Order Sorting")}
        </AppSidebarLink>
        <AppSidebarLink
          leftIcon={<FolderOpen />}
          to={updateQuery(ORDER_JOBS_URL, { is_uae: true })}
        >
          {getLocalisationMessage("jobs", "Jobs")}
        </AppSidebarLink>
        <AppSidebarLink to={ORDER_LIST_MAP_URL} leftIcon={<EditLocation />}>
          {getLocalisationMessage("orders_routing", "Orders Routing")}
        </AppSidebarLink>
      </AppSidebarBlock>
      <AppSidebarBlock title={getLocalisationMessage("finance", "Finance")}>
        <AppSidebarLink
          to={FINANCE_ORDER_LIST_URL}
          leftIcon={<AccountBalance />}
        >
          {getLocalisationMessage("orders", "Orders")}
        </AppSidebarLink>
        <AppSidebarLink
          to={FINANCE_TRANSFER_LIST_URL}
          leftIcon={<AssignmentReturned />}
        >
          {getLocalisationMessage("cod_transfers", "COD Transfers")}
        </AppSidebarLink>
        <AppSidebarLink
          to={DRIVER_LIABILITY_URL}
          leftIcon={<AssignmentReturned />}
        >
          {getLocalisationMessage("driver_liability", "Driver Liability")}
        </AppSidebarLink>
      </AppSidebarBlock>
      <AppSidebarBlock title={getLocalisationMessage("customer", "Customer")}>
        <AppSidebarLink to={CUSTOMER_FEEDBACK_LIST_URL} leftIcon={<Message />}>
          {getLocalisationMessage(
            "my_customer_feedbacks",
            "My Customer feedbacks",
          )}
        </AppSidebarLink>
      </AppSidebarBlock>
      <AppSidebarBlock title={getLocalisationMessage("staff", "Staff")}>
        <AppSidebarLink
          to={SUPPLIER_USER_LIST_ALL_URL}
          leftIcon={<PeopleOutline />}
        >
          {getLocalisationMessage("users", "Users")}
        </AppSidebarLink>
        <AppSidebarLink to={DRIVER_LIST_URL} leftIcon={<AccountCardDetails />}>
          {getLocalisationMessage("drivers", "Drivers")}
        </AppSidebarLink>
        <AppSidebarLink to={DRIVER_RADAR_V2_URL} leftIcon={<RadarIcon />}>
          {getLocalisationMessage("radar", "Radar")}
        </AppSidebarLink>
        <AppSidebarLink to={VEHICLE_LIST_URL} leftIcon={<VehicleIcon />}>
          {getLocalisationMessage("vehicles", "Vehicles")}
        </AppSidebarLink>
      </AppSidebarBlock>

      <AppSidebarBlock title={getLocalisationMessage("reports", "Reports")}>
        <AppSidebarLink to={REPORTING_REPORTS_URL} leftIcon={<Settings />}>
          {getLocalisationMessage("reports_dashboard", "Reports Dashboard")}
        </AppSidebarLink>
      </AppSidebarBlock>

      <AppSidebarBlock title={getLocalisationMessage("settings", "Settings")}>
        <AppSidebarLink
          to={COMMUNICATION_SETTINGS_LIST_URL}
          leftIcon={<CommunicationIcon />}
        >
          {getLocalisationMessage("communication", "Communication")}
        </AppSidebarLink>
      </AppSidebarBlock>
      <AppSidebarProfileBlock />
    </AppSidebar>
  );
};

export const SidebarMenu = enhancer(sidebar);

SupplierAppLayout.propTypes = {
  title: PropTypes.string,
  slug: PropTypes.string,
  app: PropTypes.string,
  children: PropTypes.node,
  appBarRightAction: PropTypes.node,
  marketplace: PropTypes.instanceOf(Immutable.Map),
  settings: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
  currentMenu: PropTypes.instanceOf(Map),
  state: PropTypes.object,
  setState: PropTypes.func,
  router: PropTypes.object,
};

function SupplierAppLayout({ title, ...props }) {
  let pageTitle = title;

  if (props.currentMenu)
    pageTitle =
      props.currentMenu.get("name") ||
      props.getLocalisationMessage(
        props.currentMenu.get("slug"),
        _.startCase(props.currentMenu.get("slug")),
      ) ||
      title;

  if (!props.marketplace)
    return (
      <AppLayout
        {...props}
        title={title}
        sidebar={
          <AppSidebar>
            <AppSidebarBlock
              title={props.getLocalisationMessage("loading", "Loading...")}
            >
              {props.getLocalisationMessage("loading", "Loading...")}
            </AppSidebarBlock>
            <AppSidebarProfileBlock />
          </AppSidebar>
        }
      />
    );

  return <AppLayout {...props} title={pageTitle} sidebar={sidebar(props)} />;
}

export default enhancer(SupplierAppLayout);
