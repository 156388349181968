import React from "react";
import _ from "lodash";
import { Map, Set } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import ResponseError from "../../helpers/ResponseError";
import {
  FAILED_BIN,
  UNSORTED_BIN,
  INCOMPLETE_BIN,
  UNASSIGNED_BIN,
} from "../../helpers/OrderSortingHelper";
import { OrderSortingDB } from "../../firebase/OrderSortingDB";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  ON_HOLD,
  DISPATCHED,
  IN_TRANSIT,
  UNASSIGNED,
  OUT_FOR_DELIVERY,
  ASSIGNED_TO_COURIER,
  IN_SORTING_FACILITY,
  IN_DIFFERENT_WAREHOUSE,
  PARTIALLY_IN_SORTING_FACILITY,
} from "../../constants/OrderStatusCodes";
import FormDialog from "../../components/form/FormDialog";
import SupplierOrderEditDialogWrapper from "../../wrappers/supplier/SupplierOrderEditDialogWrapper";
import SupplierOrderDetailsDialogWrapper from "../../wrappers/supplier/SupplierOrderDetailsDialogWrapper";
import SupplierBatchUpdateOrderDialogWrapper from "../../wrappers/supplier/SupplierBatchUpdateOrderDialogWrapper";
import SupplierBatchUpdatesItemDialogWrapper from "../../wrappers/supplier/SupplierBatchUpdatesItemDialogWrapper";
import FlexBox from "../../components/ui-core/FlexBox";
import MenuItemForm from "../../components/ui-core/MenuItemForm";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import ConfirmDialog from "../../components/deprecated/ConfirmDialog";
import OrderSortingChips from "../../components/order-multi-shipment-sorting/OrderSortingChips";
import OrderSortingTable from "../../components/order-multi-shipment-sorting/OrderSortingTable";
import OrderSortingChangeBinDialog from "../../components/order-multi-shipment-sorting/OrderSortingChangeBinDialog";
import OrderSortingVerifyOrdersDialog from "../../components/order-multi-shipment-sorting/OrderSortingVerifyOrdersDialog";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  CREATE_ORDER_MANIFEST_URL,
  CREATE_DRIVER_RUNSHEET_URL,
  CREATE_ORDER_AIRWAYBILL_URL,
} from "../../../shared/constants/FileProxyControllerConstants";

const SORTED_GROUP = "Sorted";
const UNSORTED_GROUP = "Unsorted";
const PROCESSED_GROUP = "Processed";

function getOrderGroup(taskWarehouseId, order) {
  if (order.get("info")) {
    const status = order.getIn(["info", "status"]);
    const warehouseId = order.getIn(["info", "warehouse", "id"]);

    const inWarehouse = warehouseId === taskWarehouseId;

    if (
      inWarehouse &&
      (status === ASSIGNED_TO_COURIER ||
        status === ON_HOLD ||
        status === IN_SORTING_FACILITY)
    ) {
      return "Sorted";
    }

    if (status === UNASSIGNED || status === ASSIGNED_TO_COURIER) {
      return "Unsorted";
    }

    return "Processed";
  }

  return null;
}

function formatPromptMessage(
  orderSize,
  getLocalisationMessage,
  defaultMessage = "",
) {
  if (!orderSize) {
    return defaultMessage;
  }
  const messageHead = getLocalisationMessage("regroup_orders_prompt");
  if (!messageHead) {
    return defaultMessage;
  }
  let messageTail = null;
  switch (orderSize) {
    case 1:
      messageTail = getLocalisationMessage("orders_1");
      break;
    case 0:
    case 2:
    case 3:
    case 4:
    case 5:
      messageTail = getLocalisationMessage("orders_2");
      break;
    default:
      messageTail = getLocalisationMessage("orders_3");
  }
  return `${messageHead} ${orderSize} ${messageTail}`;
}

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    chipsCard: { paddingTop: 0, paddingBottom: 0 },
    tableCard: { paddingTop: 0 },
  }),
  mapPropsStream(
    /**
     * 1. Generate sets of selected order, driver and warehouse ids.
     * 2. Count failed orders.
     * 3. Combine and sort order bins.
     */
    (propsStream) => {
      const statsStream = propsStream
        .map((props) => ({
          orders: props.orders,
          warehouseId: props.warehouseId,
          localCounter: props.task.get("counter"),
          sharedCounter: props.sharedCounter,
          sharedBoxCounter: props.sharedBoxCounter,
          selectedOrders: props.task.get("selectedOrders"),
        }))
        .distinctUntilChanged(isEqualData)
        .map((props) => {
          const bins = Map().asMutable();
          const groups = Map().asMutable();

          const ids = Set().asMutable();
          const driverIds = Set().asMutable();
          const warehouseIds = Set().asMutable();

          const orderIdPath = ["info", "id"];
          const statusPath = ["info", "status"];
          const driverIdPath = ["info", "driver", "id"];
          const warehouseIdPath = ["info", "destination_warehouse", "id"];
          const courierTypePath = ["info", "package", "courier_type"];

          props.orders.forEach((order, orderNumber) => {
            const orderId = order.getIn(orderIdPath);
            const loaded = orderId > 0;
            const selected = props.selectedOrders.has(orderNumber);

            const bin = order.get("bin")
              ? order.getIn(statusPath) === IN_DIFFERENT_WAREHOUSE ||
                order.getIn(statusPath) === PARTIALLY_IN_SORTING_FACILITY
                ? INCOMPLETE_BIN
                : order.get("bin")
              : !loaded && order.get("failed")
              ? FAILED_BIN
              : UNASSIGNED_BIN;

            if (!bins.has(bin)) {
              bins.set(
                bin,
                Map({
                  label: bin,
                  selected: 0,
                  orders: Set(),
                  inBatch: Set(),
                  inBatchLocal: Set(),
                  box: 0,
                  courierTypes: Set(),
                }),
              );
            }

            bins.updateIn([bin, "orders"], (x) => x.add(orderNumber));

            if (loaded) {
              bins.updateIn([bin, "courierTypes"], (x) =>
                x.add(order.getIn(courierTypePath)),
              );
            }

            if (selected) {
              bins.updateIn([bin, "selected"], fp.add(1));
            }

            if (props.sharedCounter.has(orderNumber)) {
              bins.updateIn([bin, "inBatch"], (x) => x.add(orderNumber));

              // if (order.get("multi_box", false)) {
              const scannedOrderBox = props.sharedBoxCounter.filter(
                (value, barcode) => _.startsWith(barcode, orderNumber),
              );
              bins.updateIn([bin, "box"], fp.add(scannedOrderBox.size));
              // }
            }

            if (props.localCounter.has(orderNumber)) {
              bins.updateIn([bin, "inBatchLocal"], (x) => x.add(orderNumber));
            }

            const group = getOrderGroup(props.warehouseId, order);

            if (group) {
              if (!groups.has(group)) {
                groups.set(
                  group,
                  Map({ label: group, selected: 0, orders: Set() }),
                );
              }

              groups.updateIn([group, "orders"], (x) => x.add(orderNumber));

              if (selected) {
                groups.updateIn([group, "selected"], fp.add(1));
              }
            }

            if (loaded) {
              if (selected) {
                ids.add(orderId);

                const driverId = order.getIn(driverIdPath);

                if (driverId > 0) {
                  driverIds.add(driverId);
                }

                const warehouseId = order.getIn(warehouseIdPath);

                if (warehouseId > 0) {
                  warehouseIds.add(warehouseId);
                }
              }
            }
          });

          return Map({
            selectedIds: ids.asImmutable(),
            selectedDriverIds: driverIds.asImmutable(),
            selectedWarehouseIds: warehouseIds.asImmutable(),

            groups: groups
              .asImmutable()
              .toList()
              .sort((a, b) => {
                const aName = a.get("label");
                const bName = b.get("label");

                const defaultGroups = [
                  UNSORTED_GROUP,
                  SORTED_GROUP,
                  PROCESSED_GROUP,
                ];

                for (let i = 0; i < defaultGroups.length; i++) {
                  const bin = defaultGroups[i];

                  if (aName === bin) {
                    return -1;
                  }

                  if (bName === bin) {
                    return 1;
                  }
                }

                return 0;
              }),
            bins: bins
              .asImmutable()
              .toList()
              .sort((a, b) => {
                const aName = a.get("label");
                const bName = b.get("label");

                const defaultBins = [
                  UNASSIGNED_BIN,
                  FAILED_BIN,
                  UNSORTED_BIN,
                  INCOMPLETE_BIN,
                ];

                for (let i = 0; i < defaultBins.length; i++) {
                  const bin = defaultBins[i];

                  if (aName === bin) {
                    return -1;
                  }

                  if (bName === bin) {
                    return 1;
                  }
                }

                return aName.localeCompare(bName);
              }),
          });
        })
        .distinctUntilChanged(isEqualData);

      return propsStream.combineLatest(statsStream, (props, stats) => ({
        ...props,
        stats,
      }));
    },
  ),
  pureComponent(fp.pick(["location", "task", "stats", "orders"])),
);

SupplierOrderSortingTableContainer.propTypes = {
  classes: PropTypes.object,
  stats: PropTypes.instanceOf(Map),

  location: PropTypes.object.isRequired,
  task: PropTypes.instanceOf(Map).isRequired,
  orders: PropTypes.instanceOf(Map).isRequired,
  warehouseId: PropTypes.number.isRequired,
  setLocationQuery: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  updateSortingTask: PropTypes.func.isRequired,
  sharedCounter: PropTypes.instanceOf(Map).isRequired,
  sharedBoxCounter: PropTypes.instanceOf(Map).isRequired,
  getLocalisationMessage: PropTypes.func,
};

function SupplierOrderSortingTableContainer(props) {
  const {
    getLocalisationMessage,
    classes,
    task,
    stats,
    orders,
    location: { query },
  } = props;

  const db = new OrderSortingDB(props.warehouseId);

  const selectedOrders = task.get("selectedOrders");
  const selectedIds = stats.get("selectedIds").toArray();
  const selectedIdsString = selectedIds.join(",");
  const selectedDriverIdsString = stats.get("selectedDriverIds").join(",");

  return (
    <div>
      {query.remove_after_update === "true" && (
        <ConfirmDialog
          open={true}
          onRequestClose={() => {
            db.batchReloadOrders(selectedOrders.toArray())
              .toPromise()
              .catch(props.showErrorMessage);

            props.setLocationQuery(fp.unset("remove_after_update"));
          }}
          onConfirm={() =>
            props.setLocationQuery(
              fp.flow(
                fp.set("remove_selected", true),
                fp.unset("remove_after_update"),
              ),
            )
          }
        >
          {getLocalisationMessage(
            "you_want_to_remove_updated_orders",
            "You want to remove updated orders?",
          )}
        </ConfirmDialog>
      )}

      {query.remove_selected === "true" && (
        <FormDialog
          open={true}
          onRequestClose={() =>
            props.setLocationQuery(fp.unset("remove_selected"))
          }
          onSubmit={() =>
            db
              .batchRemoveOrders(selectedOrders.toArray())
              .toPromise()
              .catch(ResponseError.throw)
          }
          onSubmitSuccess={() =>
            props.setLocationQuery(fp.unset("remove_selected"))
          }
          onSubmitFail={props.showErrorMessage}
        >
          {getLocalisationMessage("delete_prompt") ||
            `Are you sure you want to remove ${selectedOrders.size} orders from\n queue ?`}
        </FormDialog>
      )}

      {query.verify_selected === "true" && (
        <OrderSortingVerifyOrdersDialog
          selectedOrders={selectedOrders}
          onSelectOrders={(numbers) => {
            props.setLocationQuery(fp.unset("verify_selected"));
            props.updateSortingTask((x) => x.set("selectedOrders", numbers));
          }}
          open={true}
          onRequestClose={() =>
            props.setLocationQuery(
              fp.set("confirm_close_verify_selected", true),
            )
          }
        />
      )}

      {query.confirm_close_verify_selected === "true" && (
        <ConfirmDialog
          open={true}
          onRequestClose={() =>
            props.setLocationQuery(fp.unset("confirm_close_verify_selected"))
          }
          onConfirm={() =>
            props.setLocationQuery(
              fp.flow(
                fp.unset("verify_selected"),
                fp.unset("confirm_close_verify_selected"),
              ),
            )
          }
          title={
            getLocalisationMessage(
              "are_you_sure_you_want_to_close_order_verification_dialog",
            ) || "Are you sure you want to close order verification dialog?"
          }
        >
          <p>
            {getLocalisationMessage("all_order_scanned_verification_removed")} (
            <em>{getLocalisationMessage("not_from_sorting_list")}</em>)
          </p>
        </ConfirmDialog>
      )}

      {query.regroup_bins === "true" && (
        <FormDialog
          open={true}
          onRequestClose={() =>
            props.setLocationQuery(fp.unset("regroup_bins"))
          }
          onSubmit={() =>
            db
              .batchUpdateOrderBins(selectedOrders.toArray(), null)
              .toPromise()
              .catch(ResponseError.throw)
          }
          onSubmitSuccess={() =>
            props.setLocationQuery(fp.unset("regroup_bins"))
          }
          onSubmitFail={props.showErrorMessage}
        >
          {formatPromptMessage(
            selectedOrders.size,
            getLocalisationMessage,
            `Are you sure you want to regroup ${selectedOrders.size} orders?`,
          )}
        </FormDialog>
      )}

      {query.change_bin === "true" && (
        <OrderSortingChangeBinDialog
          open={true}
          orderBins={stats.get("bins")}
          selectedOrders={selectedOrders}
          onRequestClose={() => props.setLocationQuery(fp.unset("change_bin"))}
          onSubmit={(values) =>
            db
              .batchUpdateOrderBins(selectedOrders.toArray(), values.name)
              .toPromise()
              .then(() => ({ selectedOrders, bin: values.name }))
              .catch(ResponseError.throw)
          }
          onSubmitSuccess={(request) => {
            const nextBin = stats
              .get("bins")
              .find((x) => x.get("label") === request.bin);

            if (nextBin) {
              const getSupplierId = (x) =>
                orders.getIn([x, "info", "supplier", "id"]);

              const originSuppliers = request.selectedOrders
                .map(getSupplierId)
                .filter(Boolean);

              const destinationSuppliers = nextBin
                .get("orders")
                .map(getSupplierId)
                .filter(Boolean);

              if (
                destinationSuppliers.size === 1 &&
                !destinationSuppliers.equals(originSuppliers)
              ) {
                props.setLocationQuery(
                  fp.flow(
                    fp.set("set_supplier", true),
                    fp.set("set_supplier_id", destinationSuppliers.first()),
                  ),
                );
              }
            }

            props.setLocationQuery(fp.unset("change_bin"));
          }}
          onSubmitFail={props.showErrorMessage}
        />
      )}

      {query.batch_update === "true" && (
        <SupplierBatchUpdateOrderDialogWrapper
          open={query.batch_update === "true"}
          initialValues={{
            orderNumbers: selectedOrders.toArray(),
            orderStatus: query.batch_update_status,
            driver: {
              id:
                stats.get("selectedDriverIds").size !== 1
                  ? null
                  : stats.get("selectedDriverIds").first(),
            },
            warehouse: {
              id:
                stats.get("selectedWarehouseIds").size !== 1
                  ? null
                  : stats.get("selectedWarehouseIds").first(),
            },
          }}
          selectedItems={selectedOrders}
          list={orders}
          isSorting={true}
          onRequestClose={() =>
            props.setLocationQuery(fp.unset("batch_update"))
          }
          onSubmitFail={props.showErrorMessage}
          onSubmitSuccess={(response) => {
            props.setLocationQuery(
              fp.flow(
                fp.unset("batch_update"),
                fp.set("batch_id", response.data.id),
              ),
            );
          }}
        />
      )}

      {fp.toFinite(query.batch_id) > 0 && (
        <SupplierBatchUpdatesItemDialogWrapper
          batchId={fp.toFinite(query.batch_id)}
          onRequestClose={() => {
            props.setLocationQuery(fp.unset("batch_id"));

            if (selectedOrders.size > 0) {
              props.setLocationQuery(fp.set("remove_after_update", true));
            }
          }}
        />
      )}

      {fp.toFinite(query.view_order) > 0 && (
        <SupplierOrderDetailsDialogWrapper
          tab={query.view_order_tab}
          orderId={fp.toFinite(query.view_order)}
          onEditClick={() =>
            props.setLocationQuery(
              fp.flow(
                fp.unset("view_order"),
                fp.set("edit_order", query.view_order),
              ),
            )
          }
          onTabChange={(x) =>
            props.setLocationQuery(fp.set("view_order_tab", x))
          }
          onRequestClose={() =>
            props.setLocationQuery(
              fp.flow(fp.unset("view_order"), fp.unset("view_order_tab")),
            )
          }
          location={props.location}
        />
      )}

      {fp.toFinite(query.edit_order) > 0 && (
        <SupplierOrderEditDialogWrapper
          orderId={fp.toFinite(query.edit_order)}
          onRequestClose={() =>
            props.setLocationQuery(
              fp.flow(
                fp.unset("edit_order"),
                fp.set("view_order", query.edit_order),
              ),
            )
          }
          onSubmitSuccess={() => {
            props.setLocationQuery(
              fp.flow(
                fp.unset("edit_order"),
                fp.set("view_order", query.edit_order),
              ),
            );
          }}
        />
      )}

      {Boolean(
        query.set_supplier !== "true" &&
          query.batch_update !== "true" &&
          query.verify_selected !== "true",
      ) && (
        <FlexBox direction="column">
          <Card>
            <CardContent className={classes.chipsCard}>
              <OrderSortingChips
                groups={stats.get("groups")}
                filterChips={stats.get("bins")}
                onSelectOrders={(orderNumbers) =>
                  props.updateSortingTask((x) =>
                    x.update("selectedOrders", (selected) =>
                      selected.merge(orderNumbers),
                    ),
                  )
                }
                onDeselectOrders={(orderNumbers) =>
                  props.updateSortingTask((x) =>
                    x.update("selectedOrders", (selected) =>
                      selected.subtract(orderNumbers),
                    ),
                  )
                }
              />
            </CardContent>

            <CardContent className={classes.tableCard}>
              <OrderSortingTable
                orders={orders}
                onOrderClick={(x) =>
                  props.setLocationQuery(fp.set("view_order", x))
                }
                selectedOrders={selectedOrders}
                onOrdersSelect={(numbers) =>
                  props.updateSortingTask((x) =>
                    x.set("selectedOrders", numbers),
                  )
                }
                onReloadClick={(orderNumber) =>
                  db
                    .reloadOrder(orderNumber)
                    .toPromise()
                    .catch(props.showErrorMessage)
                }
                onRemoveClick={(orderNumber) =>
                  db
                    .removeOrder(orderNumber)
                    .toPromise()
                    .catch(props.showErrorMessage)
                }
                headerActions={
                  <MenuButtonMore desktop={false}>
                    <MenuItem
                      onClick={() =>
                        props.setLocationQuery(fp.set("change_bin", true))
                      }
                    >
                      {getLocalisationMessage("change_bin")}
                    </MenuItem>

                    <MenuItem
                      onClick={() =>
                        props.setLocationQuery(fp.set("regroup_bins", true))
                      }
                    >
                      {getLocalisationMessage("regroup_bins")}
                    </MenuItem>

                    <MenuItem
                      onClick={() =>
                        props.setLocationQuery(fp.set("remove_selected", true))
                      }
                    >
                      {getLocalisationMessage("remove")}
                    </MenuItem>

                    <MenuItem
                      onClick={() =>
                        props.setLocationQuery(fp.set("verify_selected", true))
                      }
                    >
                      {getLocalisationMessage("verify_orders")}
                    </MenuItem>

                    <MenuItem
                      onClick={() =>
                        db
                          .batchReloadOrders(selectedOrders.toArray())
                          .toPromise()
                          .catch(props.showErrorMessage)
                      }
                    >
                      {getLocalisationMessage("batch_reload")}
                    </MenuItem>

                    <MenuItem
                      onClick={() =>
                        props.setLocationQuery(
                          fp.flow(
                            fp.set("batch_update", true),
                            fp.unset("batch_update_status"),
                          ),
                        )
                      }
                    >
                      {getLocalisationMessage("update")}
                    </MenuItem>

                    <MenuItem
                      onClick={() =>
                        props.setLocationQuery(
                          fp.flow(
                            fp.set("batch_update", true),
                            fp.set("batch_update_status", IN_TRANSIT),
                          ),
                        )
                      }
                    >
                      {getLocalisationMessage("to_transit")}
                    </MenuItem>

                    <MenuItem
                      onClick={() =>
                        props.setLocationQuery(
                          fp.flow(
                            fp.set("batch_update", true),
                            fp.set("batch_update_status", DISPATCHED),
                          ),
                        )
                      }
                    >
                      {getLocalisationMessage("dispatch")}
                    </MenuItem>

                    <MenuItem
                      onClick={() =>
                        props.setLocationQuery(
                          fp.flow(
                            fp.set("batch_update", true),
                            fp.set("batch_update_status", OUT_FOR_DELIVERY),
                          ),
                        )
                      }
                    >
                      {getLocalisationMessage("deliver")}
                    </MenuItem>

                    <MenuItem
                      target="_blank"
                      component="a"
                      href={updateQuery(CREATE_ORDER_AIRWAYBILL_URL, {
                        ids: selectedIdsString,
                      })}
                    >
                      {getLocalisationMessage("airwaybill")}
                    </MenuItem>

                    <MenuItem>{getLocalisationMessage("test")}</MenuItem>

                    <MenuItemForm
                      url={CREATE_ORDER_MANIFEST_URL}
                      params={{
                        ids: selectedIds,
                      }}
                      primaryText={getLocalisationMessage("manifest")}
                    />

                    <MenuItemForm
                      url={CREATE_ORDER_MANIFEST_URL}
                      params={{
                        compact: true,
                        ids: selectedIds,
                      }}
                      primaryText={getLocalisationMessage("compact_manifest")}
                    />

                    {Boolean(selectedDriverIdsString) && (
                      <MenuItem
                        target="_blank"
                        component="a"
                        href={updateQuery(CREATE_DRIVER_RUNSHEET_URL, {
                          ids: selectedDriverIdsString,
                        })}
                      >
                        {getLocalisationMessage("runsheet")}
                      </MenuItem>
                    )}
                  </MenuButtonMore>
                }
              />
            </CardContent>
          </Card>
        </FlexBox>
      )}
    </div>
  );
}

export default enhancer(SupplierOrderSortingTableContainer);
