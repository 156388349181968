import { Observable } from "rxjs";
import React from "react";
import { startOfToday } from "date-fns";
import Immutable, { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import reduxActions from "redux-form/es/actions";
import { getCustomerAccount } from "../../actions/CustomerProfileActions";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import ResponseError from "../../helpers/ResponseError";
import { getUserName, getUserPhone } from "../../reducers/ProfileReducer";
import { getMessages } from "../../reducers/LocalizationReducer";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import { SENDER } from "../../constants/OrderPayerTypes";
import { ORDER_CREATE_SUCCESS_URL } from "../../constants/CustomerPathConstants";
import { DO_NOT_DELIVER } from "../../constants/OrderRecipientNotAvailableActionTypes";
import { getCountries } from "../../api/shared/CountryApi";
import { getMarketplace } from "../../api/shared/MarketplaceApi";
import { getMostUsedAddresses } from "../../api/customer/CustomerApi";
import { validatePromo } from "../../api/customer/CustomerPromoApi";
import { getTimeSlots } from "../../api/customer/CustomerTimeSlotApi";
import {
  createOrder,
  getPaymentMethods,
} from "../../api/supplier/SupplierOrderApi";
import PageLoading from "../../components/ui-core/PageLoading";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import OrderCreateWizard from "../../components/order-create-wizard-supplier/OrderCreateWizard";
import { base64ToObject } from "../../../shared/helpers/CryptoUtils";
import { WING_AE_ID } from "../../../server/constants/MarketplaceId";

const AE = "AE";

const parseInitialData = fp.flow(base64ToObject, fp.toPlainObject, data => ({
  initialMenuId: data.menuId,
  initialPackageId: data.packageId,
  initialPickupLocation: data.pickup,
  initialDropoffLocation: data.dropoff,
  initialDropoffCountry: data.dropoffCountry,
}));

const findCountry = (countries, id, code) => {
  let country;

  if (id > 0) {
    country = countries.find(x => x.get("id") === id);
  }

  if (!country && code) {
    country = countries.find(x => x.get("code") === code);
  }

  return country || null;
};

const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
  connect(
    state => ({
      userName: getUserName(state),
      userPhone: getUserPhone(state),
      i18n: getMessages(state),
    }),
    dispatch => ({
      destroy: () =>
        dispatch(reduxActions.destroy("OrderCreateWizardSupplier")),
      showErrorMessage: (...args) => dispatch(showErrorMessage(...args)),
    }),
  ),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const initialQueryValuesStream = propsStream
          .take(1)
          .map(fp.flow(fp.get("location.query.q"), parseInitialData));

        const asyncDataStream = Observable.combineLatest(
          getCountries()
            .takeLast(1)
            .catch(() => Observable.of(Map())),
          getMarketplace()
            .takeLast(1)
            .catch(() => Observable.of({}))
            .map(fp.flow(fp.get("payload.data"), fp.toPlainObject, fromJS)),
          getMostUsedAddresses()
            .takeLast(1)
            .catch(() => Observable.of({}))
            .map(
              fp.flow(
                response => fromJS(response),
                response =>
                  fromJS({
                    pending: response.get("pending", false),
                    senderLocations: response.getIn(
                      ["payload", "data", "sender_locations"],
                      List(),
                    ),
                    recipientLocations: response.getIn(
                      ["payload", "data", "recipient_locations"],
                      List(),
                    ),
                  }),
              ),
            ),
          (countries, marketplace, mostUsedAddresses) =>
            fromJS({
              pending: false,
              countries,
              marketplace,
              mostUsedAddresses,
            }),
        ).startWith(Map({ pending: true }));

        const sideEffectsStream = propsStream
          .filter(fp.get("location.query.destroy"))
          .do(props => props.destroy())
          .startWith(null);

        return propsStream.combineLatest(
          asyncDataStream,
          initialQueryValuesStream,
          sideEffectsStream,
          (props, asyncData, initialQueryValues) => ({
            ...props,
            initialQueryValues,
            countries: asyncData.get("countries"),
            marketplace: asyncData.get("marketplace"),
            isLoading: asyncData.get("pending"),
            senderLocations: asyncData.getIn([
              "mostUsedAddresses",
              "senderLocations",
            ]),
            recipientLocations: asyncData.getIn([
              "mostUsedAddresses",
              "recipientLocations",
            ]),
          }),
        );
      },
      propsStream => {
        const initialValuesStream = propsStream
          .filter(props => !props.isLoading)
          .map(
            fp.pick([
              "userName",
              "userPhone",
              "countries",
              "marketplace",
              "initialQueryValues",
            ]),
          )
          .distinctUntilChanged(isEqualData)
          .map(props => {
            const defaultCountry = findCountry(
              props.countries,
              props.marketplace.getIn(["country", "id"]),
              AE,
            );

            return {
              ...props.initialQueryValues,

              pickupCountry: defaultCountry,
              dropoffCountry: defaultCountry,

              pickupContactName: props.userName,
              pickupContactPhone: props.userPhone,

              estimatedDeliveryTime: startOfToday(),
              estimatedPickupTime: startOfToday(),

              payer: SENDER,
              pieceCount: 1,
              recipientNotAvailable: DO_NOT_DELIVER,
            };
          })
          .distinctUntilChanged(isEqualData)
          .startWith(null);

        return propsStream
          .combineLatest(initialValuesStream, (props, initialValues) => ({
            ...props,
            initialValues,
            isLoading: props.isLoading || !initialValues,
          }))
          .distinctUntilChanged(isEqualData);
      },
      propsStream => {
        const customerStream = getCustomerAccount()
          .takeLast(1)
          .catch(() => Observable.of({}))
          .map(
            fp.flow(fp.get("payload.data"), fp.toPlainObject, Immutable.fromJS),
          );

        return propsStream
          .combineLatest(customerStream, (props, customer) => ({
            ...props,
            customer,
          }))
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

SupplierOrderCreateContainer.propTypes = {
  isLoading: PropTypes.bool,
  setLocation: PropTypes.func,
  destroy: PropTypes.func,
  initialValues: PropTypes.object,
  showErrorMessage: PropTypes.func,
  countries: PropTypes.instanceOf(Map),
  marketplace: PropTypes.instanceOf(Map),
  senderLocations: PropTypes.instanceOf(List),
  recipientLocations: PropTypes.instanceOf(List),
  customer: PropTypes.instanceOf(Immutable.Map),
  i18n: PropTypes.instanceOf(Map),
};

function SupplierOrderCreateContainer(props) {
  return (
    <SupplierAppLayout title={props.i18n.get("create_order", "Create Order")}>
      <PageLoading isLoading={props.isLoading} />

      {!props.isLoading && (
        <div>
          <OrderCreateWizard
            isMerchant={true}
            getTimeSlots={getTimeSlots}
            validatePromo={validatePromo}
            getPaymentMethods={getPaymentMethods}
            countries={props.countries}
            initialValues={props.initialValues}
            senderLocations={props.senderLocations}
            recipientLocations={props.recipientLocations}
            allowInternationalCOD={props.marketplace.get("id") === WING_AE_ID}
            onSubmit={values => createOrder(values).catch(ResponseError.throw)}
            onSubmitSuccess={response => {
              props.setLocation(ORDER_CREATE_SUCCESS_URL + response.data.id);
              return props.destroy();
            }}
            onSubmitFail={error => props.showErrorMessage(error)}
          />
        </div>
      )}
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierOrderCreateContainer);
