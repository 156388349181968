import sprintf from "sprintf";
import WingApi from "../helpers/WingApi";
import { FETCH_WAREHOUSE } from "../reducers/WarehouseReducer";
import { API_ROOT_URL } from "../../shared/constants/ApiConstants";

const WAREHOUSE_ITEM_URL = `${API_ROOT_URL}/supplier/warehouse/%s`;

export function fetchWarehouse(id) {
  return (dispatch, getState, api: WingApi) =>
    dispatch({
      meta: { id },
      type: FETCH_WAREHOUSE,
      payload: api.get(sprintf(WAREHOUSE_ITEM_URL, id)),
    });
}
