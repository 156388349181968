import React from "react";
import { format as dateFormat } from "date-fns";
import { Map, List, OrderedSet } from "immutable";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import SpikeChart from "../charts/SpikeChart";
import MenuButtonMore from "../ui-core/MenuButtonMore";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDate, formatNumber } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { DEA, OFD, RTO, FDDS } from "../../constants/DashboardConstants";

const formatDayMonthYear = (year, month, day) =>
  dateFormat(new Date(year, month, day), "dd MMM yyyy");

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const statsStream = propsStream
      .distinctUntilChanged(isEqualData)
      .map(props =>
        Map().withMutations(items => {
          const datasets = OrderedSet().asMutable();
          const info = List().asMutable();
          const data = [];
          const labels = [];

          let type = "dispatched";
          let percentVariable = "daily_deapercanatge";
          let maxThreshold = 86;
          let minThreshold = 80;

          const { getLocalisationMessage } = props;

          switch (props.type) {
            case OFD:
              type = "daily_ofdpercantage";
              percentVariable =
                (props.reports1.get("dispatched") /
                  props.reports1.get("to_dispatch")) *
                  100 >
                0
                  ? (props.reports1.get("dispatched") /
                      props.reports1.get("to_dispatch")) *
                    100
                  : null;
              maxThreshold = 96;
              minThreshold = 92;
              info.push(
                Map({
                  label: getLocalisationMessage("to_dispatch", "To Dispatch"),
                  align: "flex-start",
                  value: props.reports1.get("to_dispatch"),
                }),
              );
              info.push(
                Map({
                  label: getLocalisationMessage("dispatched", "Dispatched"),
                  align: "center",
                  value: props.reports1.get("dispatched"),
                }),
              );
              info.push(
                Map({
                  label: getLocalisationMessage(
                    "not_dispatched",
                    "Not Dispatched",
                  ),
                  align: "flex-end",
                  value:
                    props.reports1.get("to_dispatch") >
                    props.reports1.get("dispatched")
                      ? props.reports1.get("to_dispatch") -
                        props.reports1.get("dispatched")
                      : 0,
                }),
              );
              break;
            case FDDS:
              type = "daily_fddspercantage";
              percentVariable =
                (props.reports1.get("first_attempted_and_completed") /
                  props.reports1.get("first_attempted")) *
                  100 >
                0
                  ? (props.reports1.get("first_attempted_and_completed") /
                      props.reports1.get("first_attempted")) *
                    100
                  : 0;
              maxThreshold = 86;
              minThreshold = 80;
              info.push(
                Map({
                  label: getLocalisationMessage(
                    "first_attempts",
                    "First Attempts",
                  ),
                  align: "flex-start",
                  value: props.reports1.get("first_attempted"),
                }),
              );
              info.push(
                Map({
                  label: getLocalisationMessage(
                    "first_success_attempts",
                    "First Success Attempts",
                  ),
                  align: "flex-end",
                  value: props.reports1.get("first_attempted_and_completed"),
                }),
              );
              break;
            default:
              break;
          }

          const percentage = percentVariable;

          items.set("percentage", percentage);

          props.reports.forEach(item => {
            labels.push(
              formatDate(
                formatDayMonthYear(
                  item.get("year"),
                  item.get("month") - 1,
                  item.get("day"),
                ),
              ),
            );
            data.push(
              item.get(type, 0) === 0
                ? formatNumber(0)
                : formatNumber(item.get(type, 0)),
            );
          });

          datasets.add(Map({ data }));

          items.set(
            "chartData",
            Map({ labels, datasets: datasets.asImmutable() }),
          );
          items.set("infoData", info);
          items.set(
            "backgroundColor",
            percentage >= maxThreshold
              ? "#7CB342"
              : percentage >= minThreshold
              ? "#FFB300"
              : "#E53935",
          );
        }),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(statsStream, (props, stats) => ({
        ...props,
        stats,
      }));
  }),
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
    },
    title: {
      fontSize: "18px",
      textTransform: "uppercase",
    },
    info: {
      paddingTop: "20px",
    },
    moreBtn: {},
  }),
);

OFDChartComponent.propTypes = {
  classes: PropTypes.object,
  stats: PropTypes.object,
  reports: PropTypes.instanceOf(List),
  reports1: PropTypes.instanceOf(Map),
  title: PropTypes.string,
  type: PropTypes.oneOf([OFD, FDDS, DEA, RTO]),
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

OFDChartComponent.defaultProps = {
  type: OFD,
};

function OFDChartComponent(props) {
  const { getLocalisationMessage } = props;
  return (
    <SpikeChart
      backgroundColor={props.stats.get("backgroundColor")}
      color="#ffffff"
      title={getLocalisationMessage(props.type, props.type)}
      data={props.stats}
      actionIcons={
        <div>
          <MenuButtonMore
            isHorizontal={true}
            iconClassName={props.classes.moreBtn}
          >
            <MenuItem onClick={() => props.onRequestRefresh()}>
              {getLocalisationMessage("refresh", "Refresh")}
            </MenuItem>
          </MenuButtonMore>
        </div>
      }
      tooltipFormat={{
        displayColors: false,
        callbacks: {
          title() {
            return null;
          },
          label(tooltipItem) {
            return `${tooltipItem.xLabel}: ${tooltipItem.yLabel}%`;
          },
        },
      }}
    />
  );
}

export default enhancer(OFDChartComponent);
