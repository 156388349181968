import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const OPERATIONAL_REPORT_LIST_URL = `${
  API_ROOT_URL
}/report/supplier/operational_report/report_by_type`;

export const getOperationalReports = (request: DataListFilter) =>
  api.getStream(OPERATIONAL_REPORT_LIST_URL, {
    params: request.getDefinedValues(),
  });
