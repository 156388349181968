import sprintf from "sprintf";
import fp from "lodash/fp";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const COD_TOTAL_COUNT_URL = `${API_ROOT_URL}/supplier/cod/total_counts`;
const COD_TRANSFER_TOTAL_URL = `${
  API_ROOT_URL
}/admin/codtransfer/calculate_total`;
const COD_TRANSFER_ITEM_REFERENCE_URL = `${
  API_ROOT_URL
}/supplier/codtransfer/%s/reference`;
const SUPPLIER_BALANCE_URL = `${API_ROOT_URL}/supplier/mybalance`;

const COD_TRANSFER_URL = `${API_ROOT_URL}/supplier/codtransfer`;
const COD_TRANSFER_ITEM_URL = `${API_ROOT_URL}/supplier/codtransfer/%s`;
const COD_TRANSFER_ITEM_ORDERS_URL = `${
  API_ROOT_URL
}/supplier/codtransfer/%s/orders`;
const COD_TRANSFER_LIST_URL = `${API_ROOT_URL}/supplier/codtransfers/`;
const COD_TRANSFER_STATUS_COUNT_URL = `${
  API_ROOT_URL
}/supplier/codtransfer/status_counts`;

export const createCODTransfer = (request, getLocalisationMessage) =>
  api.post(COD_TRANSFER_URL, { body: request }).catch(error => {
    const orderNumbers = error.response;

    if (fp.isArray(orderNumbers)) {
      // eslint-disable-next-line no-param-reassign
      error.response = {
        orderNumbers: `${getLocalisationMessage(
          "could_not_update",
          "Could not update",
        )} ${orderNumbers.join(", ")} ${getLocalisationMessage(
          "orders_4",
          "orders",
        )}`,
      };
    }

    throw error;
  });

export const getCODTotalCount = request =>
  api.getStream(COD_TOTAL_COUNT_URL, { params: request.getDefinedValues() });

export const calculateCodTransferTotal = body =>
  api.post(COD_TRANSFER_TOTAL_URL, { body });

export const getCODTransferList = (request: DataListFilter) =>
  api.getStream(COD_TRANSFER_LIST_URL, { params: request.getDefinedValues() });

export const getCODTransferStatusCount = (request: DataListFilter) =>
  api.getStream(COD_TRANSFER_STATUS_COUNT_URL, {
    params: request.getDefinedValues(),
  });

export const getCODTransfer = (id: number) =>
  api.getStream(sprintf(COD_TRANSFER_ITEM_URL, id));

export const getCODTransferOrders = (id: number, request: DataListFilter) =>
  api.getStream(sprintf(COD_TRANSFER_ITEM_ORDERS_URL, id), {
    params: request.getDefinedValues(),
  });

export const updateCODTransferReference = (id: number, body) =>
  api.put(sprintf(COD_TRANSFER_ITEM_REFERENCE_URL, id), {
    body,
  });

export const getSupplierBalance = () => api.getStream(SUPPLIER_BALANCE_URL);
