import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toCODTransferFilter } from "../../helpers/CODTransferFilterMapper";
import { getMessage } from "../../reducers/LocalizationReducer";
import CODTransferStatus from "../../constants/CODTransferStatus";
import { FINANCE_TRANSFER_ITEM_URL } from "../../constants/SupplierPathConstants";
import {
  getCODTransferList,
  getCODTransferStatusCount,
} from "../../api/supplier/SupplierFinanceApi";
import Redirect from "../../components/router/Redirect";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import CODTransferTable from "../../components/finance-core/CODTransferTable";
import CODTransferTableTabs from "../../components/finance-core/CODTransferTableTabs";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const createCODTransferHref = id => FINANCE_TRANSFER_ITEM_URL + id;

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return { getLocalisationMessage };
  }),
  getContext({ setLocationQueryFilter: PropTypes.func }),
  mapPropsStream(
    pipeStreams(
      propsStream =>
        propsStream.combineLatest(
          propsStream
            .map(fp.flow(fp.get("location.query"), toCODTransferFilter))
            .distinctUntilChanged(isEqualData),
          (props, filter) => ({ ...props, filter }),
        ),
      propsStream => {
        const statusCountsStream = propsStream
          .map(
            fp.flow(fp.get("filter"), filter =>
              filter.setValue("status", null),
            ),
          )
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            getCODTransferStatusCount(filter).catch(error =>
              Observable.of({ error }),
            ),
          )
          .map(
            fp.flow(
              fp.get("payload.data"),
              fp.toArray,
              fp.keyBy("cod_transfer_status"),
              fp.mapValues("count"),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        const responseStream = propsStream
          .map(fp.get("filter"))
          .distinctUntilChanged(isEqualData)
          .switchMap(filter =>
            getCODTransferList(filter).catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  payload: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            responseStream,
            statusCountsStream,
            (props, response, statusCounts) => ({
              ...props,
              statusCounts,
              total: response.get("total"),
              list: response.get("payload"),
              isLoading: response.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualData);
      },
    ),
  ),
);

SupplierCODTransferListContainer.propTypes = {
  location: PropTypes.object,
  columns: PropTypes.array,
  statusCounts: PropTypes.instanceOf(Map),
  list: PropTypes.instanceOf(List),
  total: PropTypes.number,
  isLoading: PropTypes.bool,
  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQueryFilter: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SupplierCODTransferListContainer(props) {
  return (
    <SupplierAppLayout
      slug="COD_transfers"
      title={props.getLocalisationMessage("cod_transfers", "COD Transfers")}
    >
      <Redirect
        when={!CODTransferStatus.has(props.location.query.status)}
        to={updateQuery(
          props.location,
          fp.set("status", CODTransferStatus.first()),
        )}
      />

      <CODTransferTableTabs
        location={props.location}
        statusCounts={props.statusCounts}
        getLocalisationMessage={props.getLocalisationMessage}
      />

      <CODTransferTable
        columns={props.columns}
        list={props.list}
        total={props.total}
        isLoading={props.isLoading}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        createCODTransferHref={createCODTransferHref}
      />
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierCODTransferListContainer);
