import { Map, fromJS } from "immutable";
import { combineReducers } from "redux-immutable";
import { mapOrderNotesAndHistoryToActivities } from "../helpers/OrderHelper";
import {
  getItem,
  isItemFetching,
  isItemNotFound,
  createAsyncItemReducer,
} from "../helpers/ReducerFactory";
import { injectReducer } from "../../shared/helpers/ReducerContext";

export const FETCH_PUBLIC_ORDER = "PUBLIC_ORDER/PUBLIC_ORDER_FETCH";

const selector = injectReducer(
  "publicOrder",
  combineReducers({
    order: createAsyncItemReducer(FETCH_PUBLIC_ORDER, {
      keySelector: action => action.meta.publicId,
      payloadSelector: action =>
        fromJS(action.payload.data)
          .set(
            "activities",
            mapOrderNotesAndHistoryToActivities(action.payload.data),
          )
          .update("locations", list =>
            list.sortBy(item => !item.get("pickup")),
          ),
    }),
  }),
);

function getPublicOrder(state, publicId): Map {
  return getItem(selector(state).get("order"), publicId);
}

function isPublicOrderFetching(state, publicId): boolean {
  return isItemFetching(selector(state).get("order"), publicId);
}

function isPublicOrderNotFound(state, publicId): boolean {
  return isItemNotFound(selector(state).get("order"), publicId);
}

export function getPublicOrdersByIds(state, publicIds) {
  return publicIds.toMap().mapEntries(([, id]) => [
    id,
    Map({
      order: getPublicOrder(state, id),
      isLoading: isPublicOrderFetching(state, id),
      isNotFound: isPublicOrderNotFound(state, id),
    }),
  ]);
}
