import React from "react";
import { endOfYesterday, startOfYesterday } from "date-fns";
import { List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  getContext,
  withContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { reduxForm, getFormValues } from "redux-form";
import { IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { FilterList } from "@material-ui/icons";
import GoogleMapReactComponent from "./GoogleMapReactComponent";
import FlexBox from "../ui-core/FlexBox";
import HeatMapFilterFormDialog from "../dashboard-core/HeatMapFilterFormDialog";
import { isEqualData } from "../../helpers/DataUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { parseIntString, stringifyArray } from "../../helpers/SerializeUtils";
import { getMarketplaceMapCenter } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getObjectId } from "../../../client/helpers/FormUtils";
import {
  formatNumber,
  formatDateTimeToUrl,
} from "../../../client/helpers/FormatUtils";
import { updateHash } from "../../../shared/helpers/UrlUtils";
import contentLoader from "../../../client/assets/content-loader.gif";

const size = 200;

const baseFilter = new DataListFilter({
  size,
  use_solr: true,
});

const getValues = getFormValues("GoogleHeatMapForm");

const idsToObjectArray = fp.flow(
  parseIntString,
  fp.map(id => ({ id })),
);

const enhancer = compose(
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .map(props => {
        const customerIds =
          props.initialFilter && props.initialFilter.getValue("customerIds");

        return {
          isPickUp: false,
          fromDateTime: formatDateTimeToUrl(startOfYesterday()),
          toDateTime: formatDateTimeToUrl(endOfYesterday()),
          locationSourceTypes: null,
          customerIds: idsToObjectArray(customerIds),
        };
      })
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({
        ...props,
        initialValues,
      }),
    );
  }),
  withContext(
    {
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
    },
    props => ({
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
    }),
  ),
  reduxForm({
    form: "GoogleHeatMapForm",
    enableReinitialize: true,
  }),
  useSheet({
    title: {
      fontSize: "18px",
      textTransform: "uppercase",
    },
    info: {
      paddingTop: "20px",
    },
    loading: {
      background: `url(${contentLoader}) no-repeat center center`,
      height: "250px",
      width: "250px",
      backgroundSize: "contain",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      "-moz-transform": "translate(-50%, -50%)",
      "-webkit-transform": "translate(-50%, -50%)",
    },
    percentage: {
      position: "absolute",
      top: "50%",
      left: "50%",
      zIndex: 10,
      color: "#3F51B5",
      transform: "translate(-50%, -50%)",
      "-moz-transform": "translate(-50%, -50%)",
      "-webkit-transform": "translate(-50%, -50%)",
    },
  }),
  connect(state => ({
    baseCountryMapCenter: getMarketplaceMapCenter(state),
    values: getValues(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  getContext({
    replaceLocationHash: PropTypes.func.isRequired,
  }),
  withState("state", "setState", {
    fetchMapLocationsPending: false,
    loadingPercentage: 0,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const {
          handler: onSubmit,
          stream: onSubmitStream,
        } = createEventHandler();

        const filterStream = onSubmitStream.startWith(null);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          initialValuesForm: filter || props.initialValues,
          filter: new DataListFilter({
            is_pick_up: filter
              ? filter && filter.isPickUp
              : props.initialValues && props.initialValues.isPickUp,
            from_date_time: filter
              ? filter && formatDateTimeToUrl(filter.fromDateTime)
              : props.initialValues && props.initialValues.fromDateTime,
            to_date_time: filter
              ? filter && formatDateTimeToUrl(filter.toDateTime)
              : props.initialValues && props.initialValues.toDateTime,
            location_source_types: filter
              ? filter && filter.locationSourceTypes
              : props.initialValues && props.initialValues.locationSourceTypes,
            customer_ids: filter
              ? filter && !fp.isEmpty(filter.customerIds)
                ? stringifyArray(filter.customerIds.map(getObjectId))
                : null
              : props.initialValues &&
                stringifyArray(
                  props.initialValues.customerIds.map(getObjectId),
                ),
          }).setValueMap(baseFilter),
          onSubmit,
        }));
      },
      propsStream => {
        const orderLocationsStream = propsStream
          .distinctUntilKeyChanged("filter", isEqualData)
          .switchMap(props => {
            props.setState(fp.set("fetchMapLocationsPending", true));

            return props
              .getAllLocationsByFilter(props.filter)
              .do(response => {
                if (response && response.get("list").size > 0) {
                  props.setState(
                    fp.set(
                      "loadingPercentage",
                      (response.get("list").size * 100) / response.get("total"),
                    ),
                  );
                }
              })
              .map(response => response.get("list"))
              .takeLast(1)
              .do(() => {
                props.setState(fp.set("fetchMapLocationsPending", false));
              });
          })
          .startWith(List());

        // const loadOrderLocationsStream = onSubmitStream
        //   .withLatestFrom(propsStream)
        //   .map(([values, props]) => ({
        //     ...props,
        //     filter: new DataListFilter({
        //       is_pick_up: values && values.isPickUp,
        //       from_date_time: values && values.fromDateTime,
        //     }).setValueMap(baseFilter),
        //   }))
        //   .distinctUntilKeyChanged("filter", isEqualData)
        //   .switchMap(props => {
        //     props.setState(fp.set("fetchMapLocationsPending", true));
        //
        //     return props.getAllLocationsByFilter(props.filter)
        //       .map(response => response.get("list"))
        //       .takeLast(1)
        //       .do(() => {
        //         props.setState(fp.set("fetchMapLocationsPending", false));
        //       })
        //   })
        //   .startWith(List());

        return propsStream.combineLatest(
          orderLocationsStream,
          (props, locations) => ({
            ...props,
            locations,
          }),
        );
      },
    ),
  ),
);

const GOOGLE_HEAT_MAP_FILTER_DIALOG_HASH = "#GHMFD";

GoogleHeatMapForm.propTypes = {
  location: PropTypes.object,
  title: PropTypes.string,
  classes: PropTypes.object,
  state: PropTypes.object,
  initialValues: PropTypes.object,
  initialValuesForm: PropTypes.object,
  getAllLocationsByFilter: PropTypes.func.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  locations: PropTypes.instanceOf(List),
  orderValues: PropTypes.instanceOf(List),
  initialFilter: PropTypes.instanceOf(DataListFilter),
  onSubmit: PropTypes.func,
  replaceLocationHash: PropTypes.func,
  baseCountryMapCenter: PropTypes.object,
  getCachedCustomer: PropTypes.func,
  getCustomerPredictions: PropTypes.func,
  showCustomer: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
};

const heatMapOptions = {
  dissipating: true,
  radius: 20,
  opacity: 0.7,
  maxIntensity: 10,
};

GoogleHeatMapForm.defaultProps = {
  title: "Google Heat Map Widget",
};

function GoogleHeatMapForm(props) {
  const { classes, state, locations, location, getLocalisationMessage } = props;

  return (
    <FlexBox direction="column" className={classes.title}>
      <HeatMapFilterFormDialog
        open={location.hash === GOOGLE_HEAT_MAP_FILTER_DIALOG_HASH}
        initialValues={props.initialValuesForm}
        showCustomer={props.showCustomer}
        onSubmit={data => {
          props.replaceLocationHash(null);
          props.onSubmit(data);
        }}
        onDismiss={() => {
          props.replaceLocationHash(null);
        }}
      />

      <FlexBox flex={true} align="center">
        <FlexBox flex={true}>
          <h6>
            {getLocalisationMessage("google_heat_map_widget", props.title)}
          </h6>
        </FlexBox>
        <FlexBox flex={true} justify="flex-end">
          <Link to={updateHash(location, GOOGLE_HEAT_MAP_FILTER_DIALOG_HASH)}>
            <Tooltip title={getLocalisationMessage("filter", "Filter")}>
              <IconButton>
                <FilterList />
              </IconButton>
            </Tooltip>
          </Link>
        </FlexBox>
      </FlexBox>
      <FlexBox flex={true} align="center" justify="center">
        {!state.fetchMapLocationsPending && fp.size(locations) > 0 ? (
          <GoogleMapReactComponent
            mapCenter={props.baseCountryMapCenter}
            heatMapData={{
              positions: locations.toJS(),
              options: heatMapOptions,
            }}
            heatMapLibrary={true}
          />
        ) : (
          <div className={classes.loading}>
            <div className={classes.percentage}>{`${formatNumber(
              state.loadingPercentage,
              "#0",
            )}%`}</div>
          </div>
        )}
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(GoogleHeatMapForm);
