import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import OrderCreateWizardStepHowItem from "./OrderCreateWizardStepHowItem";

const valueSelector = formValueSelector("OrderCreateWizardSupplier");

const enhancer = compose(
  connect(state => ({
    values: valueSelector(state, "package", "pickupCountry", "dropoffCountry"),
  })),
);

OrderCreateWizardStepHowDetails.propTypes = {
  values: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

function OrderCreateWizardStepHowDetails(props) {
  return (
    <OrderCreateWizardStepHowItem
      label="How"
      onClick={props.onClick}
      item={props.values.package}
      pickupCountry={props.values.pickupCountry}
      dropoffCountry={props.values.dropoffCountry}
    />
  );
}

export default enhancer(OrderCreateWizardStepHowDetails);
