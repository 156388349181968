import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { DRIVER_LIST_URL } from "../../constants/SupplierPathConstants";
import {
  getOrder,
  batchAsyncUpdateOrder,
  getOrderSuggestedDrivers,
} from "../../api/supplier/SupplierOrderApi";
import ModalPaper from "../../components/ui-core/ModalPaper";
import OrderSuggestedDrivers from "../../components/order-details-dialog/OrderSuggestedDrivers";
import OrderDriverSuggestionDialogHeader from "../../components/order-details-dialog/OrderDriverSuggestionDialogHeader";
import { primary1, primary4 } from "../../../shared/theme/main-theme";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const enhancer = compose(
  renderIf("open"),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  useSheet({
    rootStyle: {
      "& h3": { border: "0", backgroundColor: primary1, color: "white" },
    },
    background: {
      zIndex: -10,
      width: "100%",
      height: "100%",
      display: "flex",
      position: "absolute",
      flexDirection: "column",
    },

    topBackground: {
      zIndex: 1,
      flex: "1 1 0%",
      borderRadius: 0,
      backgroundColor: primary4,
      transition: "background 0.5s ease",
    },

    bottomBackground: { backgroundColor: "#ebebeb", flex: "1 1 0%" },
  }),
  connect(null, { batchAsyncUpdateOrder }),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();
    const {
      handler: onDriversRequestRefresh,
      stream: onDriversRequestRefreshStream,
    } = createEventHandler();

    const orderResponseStream = propsStream
      .distinctUntilKeyChanged("orderId")
      .switchMap(props =>
        getOrder(props.orderId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    const driverResponseStream = propsStream
      .distinctUntilKeyChanged("orderId")
      .switchMap(props =>
        getOrderSuggestedDrivers(
          props.orderId,
          new DataListFilter({
            distance_meters: 5000,
            next_hours: 4,
          }),
        )
          .catch(error => Observable.of({ error }))
          .repeatWhen(() => onDriversRequestRefreshStream),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              isLoading: response.get("pending", false),
              list: response.getIn(["payload", "data"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        orderResponseStream,
        driverResponseStream,
        (props, orderResponse, driverResponse) => ({
          ...props,
          onRequestRefresh,
          onDriversRequestRefresh,
          order: orderResponse.get("payload"),
          driverList: driverResponse.get("list"),
          isLoading: orderResponse.get("pending"),
          isDriverLoading: driverResponse.get("isLoading"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
);

SupplierOrderDriverSuggestionDialogWrapper.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  classes: PropTypes.object,
  driverList: PropTypes.instanceOf(List),
  order: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  isDriverLoading: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  batchAsyncUpdateOrder: PropTypes.func,
  location: PropTypes.object,
};

function SupplierOrderDriverSuggestionDialogWrapper(props) {
  const { classes } = props;
  return (
    <ModalPaper
      open={props.open}
      onRequestClose={props.onRequestClose}
      paperClassName={classes.paper}
    >
      <div className={classes.background}>
        <Paper className={classes.topBackground} />
        <div className={classes.bottomBackground} />
      </div>

      <OrderDriverSuggestionDialogHeader
        order={props.order}
        getDriverUrl={id => updateQuery(DRIVER_LIST_URL, { view: id })}
      />

      {!props.isDriverLoading && (
        <OrderSuggestedDrivers
          driverList={props.driverList}
          isLoading={props.isDriverLoading}
          order={props.order}
          onRequestRefresh={props.onRequestRefresh}
          onSubmitSuccess={props.onSubmitSuccess}
          batchAsyncUpdateOrder={props.batchAsyncUpdateOrder}
          onSubmitFail={props.onSubmitFail}
          location={props.location}
        />
      )}
    </ModalPaper>
  );
}

export default enhancer(SupplierOrderDriverSuggestionDialogWrapper);
