import { Observable } from "rxjs";
import React from "react";
import { Set, List, fromJS, OrderedSet } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { MenuItem, Button, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import { Add, CloudDownload } from "@material-ui/icons";
import { isEqualData, isEqualWithoutFunctions } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { toDataListFilter } from "../../helpers/DataListFilterMapper";
import { showDriverDynamicRunSheet } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getDriverList } from "../../api/supplier/SupplierDriverApi";
import SupplierDriverEditDialogWrapper from "../../wrappers/supplier/SupplierDriverEditDialogWrapper";
import SupplierVehicleEditDialogWrapper from "../../wrappers/supplier/SupplierVehicleEditDialogWrapper";
import SupplierDriverCreateDialogWrapper from "../../wrappers/supplier/SupplierDriverCreateDialogWrapper";
import SupplierVehicleCreateDialogWrapper from "../../wrappers/supplier/SupplierVehicleCreateDialogWrapper";
import SupplierDriverRunSheetFilterWrapper from "../../wrappers/supplier/SupplierDriverRunSheetFilterWrapper";
import MenuButtonMore from "../../components/ui-core/MenuButtonMore";
import OverallStatusTabs from "../../components/ui-core/OverallStatusTabs";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import DataList, {
  DataListColumn,
  DataListCheckbox,
} from "../../components/data-list/DataList";
import DateTimeCell from "../../components/data-list/DateTimeCell";
import MultiLineCell from "../../components/data-list/MultiLineCell";
import CheckOverallStatus from "../../components/route-validators/CheckOverallStatus";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  CREATE_DRIVER_CSV_URL,
  CREATE_DRIVER_RUNSHEET_URL,
} from "../../../shared/constants/FileProxyControllerConstants";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
      showDriverRunSheet: showDriverDynamicRunSheet(state),
    };
  }),
  getContext({
    setLocationQueryFilter: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const filterStream = propsStream
          .map(fp.flow(fp.get("location.query"), toDataListFilter))
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(filterStream, (props, filter) => ({
          ...props,
          filter,
        }));
      },
      propsStream => {
        const {
          handler: onRowSelect,
          stream: onRowSelectStream,
        } = createEventHandler();
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const listResponseStream = propsStream
          .distinctUntilKeyChanged("filter")
          .switchMap(props =>
            getDriverList(props.filter)
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  pending: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .do(() => onRowSelect(OrderedSet()));

        return propsStream
          .combineLatest(
            listResponseStream,
            onRowSelectStream
              .distinctUntilChanged(isEqualData)
              .startWith(OrderedSet()),
            (props, listResponse, selectedIds) => ({
              ...props,
              selectedIds,
              onRowSelect,
              onRequestRefresh,
              list: listResponse.get("list"),
              total: listResponse.get("total"),
              isLoading: listResponse.get("pending"),
            }),
          )
          .distinctUntilChanged(isEqualWithoutFunctions);
      },
    ),
  ),
);

SupplierDriverList.propTypes = {
  location: PropTypes.object,
  router: PropTypes.object,
  isLoading: PropTypes.bool,
  showDriverRunSheet: PropTypes.bool,
  onRequestRefresh: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
  setLocationQuery: PropTypes.func,
  setLocationQueryFilter: PropTypes.func,
  total: PropTypes.number,
  list: PropTypes.instanceOf(List),
  selectedIds: PropTypes.instanceOf(Set),
  onRowSelect: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SupplierDriverList(props) {
  const { location, getLocalisationMessage } = props;

  const NA = getLocalisationMessage("na", "N/A");

  return (
    <SupplierAppLayout
      slug="drivers"
      title={getLocalisationMessage("drivers", "Drivers")}
    >
      <CheckOverallStatus location={location} />

      <OverallStatusTabs location={location} />

      <SupplierDriverCreateDialogWrapper
        open={location.query.create === "true"}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.unset("create"));
        }}
        onVehicleClick={id =>
          props.setLocationQuery(fp.set("view_vehicle", id))
        }
        onCreateVehicleClick={() =>
          props.setLocationQuery(fp.set("create_vehicle", true))
        }
      />

      {props.showDriverRunSheet && (
        <SupplierDriverRunSheetFilterWrapper
          open={fp.has("run_sheet_filter")(location.query)}
          driverId={fp.get("run_sheet_filter")(location.query)}
          onRequestClose={() =>
            props.setLocationQuery(fp.unset("run_sheet_filter"))
          }
        />
      )}

      <SupplierDriverEditDialogWrapper
        driverId={fp.toFinite(location.query.view)}
        tab={location.query.view_tab}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(fp.omit(["view", "view_tab"]));
        }}
        onVehicleClick={id =>
          props.setLocationQuery(fp.set("view_vehicle", id))
        }
        onCreateVehicleClick={() =>
          props.setLocationQuery(fp.set("create_vehicle", true))
        }
      />

      <SupplierVehicleCreateDialogWrapper
        open={location.query.create_vehicle === "true"}
        onRequestClose={() =>
          props.setLocationQuery(fp.unset("create_vehicle"))
        }
        onDriverClick={id =>
          props.setLocationQuery(
            fp.flow(
              fp.set("view", id),
              /* Close VehicleDialog to avoid recursion. */
              fp.unset("view_vehicle"),
            ),
          )
        }
        onCreateDriverClick={() =>
          props.setLocationQuery(fp.set("create", true))
        }
      />

      <SupplierVehicleEditDialogWrapper
        vehicleId={fp.toFinite(location.query.view_vehicle)}
        onRequestClose={() => props.setLocationQuery(fp.unset("view_vehicle"))}
        onDriverClick={id =>
          props.setLocationQuery(
            fp.flow(
              fp.set("view", id),
              /* Close VehicleDialog to avoid recursion. */
              fp.unset("view_vehicle"),
            ),
          )
        }
        onCreateDriverClick={() =>
          props.setLocationQuery(fp.set("create", true))
        }
      />

      <DataList
        isLoading={props.isLoading}
        selectedRowCount={props.selectedIds.size}
        totalCount={props.total}
        list={props.list}
        rowCount={props.list.size}
        overscanRowCount={8}
        rowGetter={options => props.list.get(options.index)}
        filter={props.filter}
        onFilterChange={filter => props.setLocationQueryFilter(filter)}
        altHeader={
          <Button
            target="_blank"
            component="a"
            href={updateQuery(CREATE_DRIVER_RUNSHEET_URL, {
              ids: props.selectedIds.toJS().join(","),
            })}
          >
            {getLocalisationMessage("download_run_sheet", "Download Run Sheet")}
          </Button>
        }
        cardActionIcons={
          <div>
            <Tooltip
              title={getLocalisationMessage(
                "create_new_driver",
                "Create New Driver",
              )}
            >
              <IconButton
                onClick={() => props.setLocationQuery(fp.set("create", true))}
              >
                <Add />
              </IconButton>
            </Tooltip>
            <MenuButtonMore>
              <MenuItem
                target="_blank"
                component="a"
                href={updateQuery(
                  CREATE_DRIVER_CSV_URL,
                  props.filter.getDefinedValues(),
                )}
              >
                {getLocalisationMessage("download_csv", "Download CSV")}
              </MenuItem>
            </MenuButtonMore>
          </div>
        }
      >
        <DataListCheckbox
          allRowsSelected={() =>
            props.selectedIds.size > 0 &&
            props.selectedIds.size === props.list.size
          }
          onAllRowsSelect={value =>
            props.onRowSelect(
              value
                ? props.list.toOrderedSet().map(item => item.get("driver_id"))
                : OrderedSet(),
            )
          }
          rowSelected={row =>
            props.selectedIds.has(row.cellData.get("driver_id"))
          }
          onRowSelect={row =>
            props.onRowSelect(
              row.selected
                ? props.selectedIds.add(row.cellData.get("driver_id"))
                : props.selectedIds.delete(row.cellData.get("driver_id")),
            )
          }
        />

        <DataListColumn
          width={94}
          label={getLocalisationMessage("id", "ID")}
          dataKey="driver_id"
          /* disableSort={true} */
          justifyContent="center"
          cellRenderer={row => (
            <Link
              to={updateQuery(
                location,
                fp.set("view", row.cellData.get("driver_id")),
              )}
            >
              {row.cellData.get("driver_id")}
            </Link>
          )}
        />

        <DataListColumn
          width={128}
          label={getLocalisationMessage("name", "Name")}
          dataKey="name"
          /* disableSort={true} */
          cellRenderer={row => (
            <MultiLineCell
              firstLine={row.cellData.get("first_name")}
              secondLine={row.cellData.get("last_name")}
            />
          )}
        />
        <DataListColumn
          width={256}
          label={getLocalisationMessage("email", "Email")}
          dataKey="email"
          /* disableSort={true} */
          cellRenderer={row => row.cellData.get("email") || NA}
        />
        <DataListColumn
          width={128}
          label={getLocalisationMessage("source_supplier", "Source Supplier")}
          dataKey="parent_supplier_name"
          /* disableSort={true} */
          cellRenderer={row => row.cellData.getIn(["parent_supplier", "name"])}
        />
        <DataListColumn
          width={128}
          label={getLocalisationMessage("phone", "Phone")}
          dataKey="phone"
          disableSort={true}
          cellRenderer={row => row.cellData.get("phone") || NA}
        />
        <DataListColumn
          width={94}
          label={getLocalisationMessage("status", "Status")}
          dataKey="status"
          disableSort={true}
          justifyContent="center"
          cellRenderer={row =>
            getLocalisationMessage(row.cellData.get("status")) ||
            formatText(row.cellData.get("status"))
          }
        />
        <DataListColumn
          width={94}
          label={getLocalisationMessage("join_date", "Join date")}
          dataKey="created_date"
          justifyContent="center"
          cellRenderer={row => (
            <DateTimeCell date={row.cellData.get("created_date")} />
          )}
        />
        {props.showDriverRunSheet && (
          <DataListColumn
            width={64}
            label="Runsheet"
            dataKey="runsheet_filter"
            cellRenderer={row => (
              <IconButton
                onClick={() =>
                  props.setLocationQuery(
                    fp.set("run_sheet_filter", row.cellData.get("driver_id")),
                  )
                }
              >
                <CloudDownload />
              </IconButton>
            )}
          />
        )}
      </DataList>
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierDriverList);
