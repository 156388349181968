import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import {
  compose,
  withState,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import SupplierJobDetailsDialogOrders from "./SupplierJobDetailsDialogOrders";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toSnakeCase } from "../../helpers/CaseMapper";
import { mapOrderNotesAndHistoryToActivities } from "../../helpers/OrderHelper";
import ResponseError from "../../helpers/ResponseError";
import DataListFilter from "../../helpers/DataListFilter";
import { toOrderFilter } from "../../helpers/OrderFilterMapper";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { ACTIVE } from "../../constants/OverallStatus";
import {
  DRIVER_LIST_URL,
  CUSTOMER_ITEM_URL,
  SUPPLIER_LIST_URL,
} from "../../constants/AdminPathConstants";
import { getDriverLocation } from "../../api/shared/DriverApi";
import {
  getOrder,
  getOrderList,
  getOrderActivities,
  uploadOrderSignature,
} from "../../api/supplier/SupplierOrderApi";
import SupplierBatchUpdateJobDialogWrapper from "../../wrappers/supplier/SupplierBatchUpdateJobDialogWrapper";
import SupplierJobDriverSuggestionDialogWrapper from "../../wrappers/supplier/SupplierJobDriverSuggestionDialogWrapper";
import SupplierBatchRescheduleOrderDialogWrapper from "../../wrappers/supplier/SupplierBatchRescheduleOrderDialogWrapper";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import OrderJobDetailsDialog from "../../components/order-job/OrderJobDetailsDialog";
import OrderJobDetailsDialogMap from "../../components/order-job/OrderJobDetailsDialogMap";
import {
  MAP_TAB,
  ORDERS_TAB,
  HISTORY_TAB,
  SIGNATURE_TAB,
} from "../../components/order-job/OrderJobDetailsDialogTabs";
import OrderActivitiesTimeline from "../../components/orders-core/OrderActivitiesTimeline";
import UpdateOrderContactDialog from "../../components/orders-core/UpdateOrderContactDialog";
import OrderDetailsDialogSignature from "../../components/order-details-dialog/OrderDetailsDialogSignature";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const DELIVERY_TO = "delivery_signature";
const SENDER_FROM = "sender_signature";

const baseFilter = new DataListFilter({ page: 0, size: 50, status: ACTIVE });

const enhancer = compose(
  renderIf(props => props.orderId > 0),
  getContext({
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  connect(null, { showErrorMessage, showSuccessMessage }),
  useSheet({
    container: {
      minHeight: "300px",
      maxHeight: "480px",
      overflowY: "auto",
      margin: "0",
      paddingTop: "64px",
    },
    buttonRight: { float: "right" },
  }),
  mapPropsStream(propsStream => {
    const {
      stream: onRowSelectStream,
      handler: onRowSelect,
    } = createEventHandler();
    const {
      handler: onShowAddNote,
      stream: onShowAddNoteStream,
    } = createEventHandler();
    const {
      handler: onShowSubDialog,
      stream: onShowSubDialogStream,
    } = createEventHandler();
    const {
      handler: onShowChargeEdit,
      stream: onShowChargeEditStream,
    } = createEventHandler();
    const {
      handler: onOrderRequestRefresh,
      stream: onOrderRequestRefreshStream,
    } = createEventHandler();
    const {
      handler: onActivitiesRequestRefresh,
      stream: onActivitiesRequestRefreshStream,
    } = createEventHandler();
    const {
      handler: onJobChildOrdersRequestRefresh,
      stream: onJobChildOrdersRequestRefreshStream,
    } = createEventHandler();

    const showAddNoteStream = onShowAddNoteStream.startWith(false);

    const filterStream = propsStream
      .map(fp.flow(fp.get("location.query"), toOrderFilter))
      .distinctUntilChanged(isEqualData);

    const orderResponseStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .switchMap(props =>
        getOrder(props.orderId)
          .repeatWhen(() => onOrderRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    const getOrderActivitiesResponseStream = propsStream
      .filter(props => props.tab === HISTORY_TAB)
      .distinctUntilKeyChanged("orderId")
      .switchMap(props =>
        getOrderActivities(props.orderId)
          .repeatWhen(() =>
            Observable.merge(
              onOrderRequestRefresh,
              onActivitiesRequestRefreshStream,
            ),
          )
          .catch(error => Observable.of({ error })),
      )
      .startWith({})
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", mapOrderNotesAndHistoryToActivities),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    const getJobChildOrdersResponseStream = filterStream
      .withLatestFrom(propsStream)
      .switchMap(([filter, props]) =>
        getOrderList(
          baseFilter.setValueMap({
            parent_order_id: props.orderId,
            types: null,
            use_solr: false,
            page: filter.getPage(),
            size: filter.getSize(),
          }),
        )
          .catch(error => Observable.of({ error }))
          .repeatWhen(() => onJobChildOrdersRequestRefreshStream),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              isLoading: response.get("pending", false),
              count: response.getIn(["payload", "data", "total"], 0),
              list: response.getIn(["payload", "data", "list"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData)
      .do(() => onRowSelect(OrderedMap()));

    const selectedItemsStream = onRowSelectStream
      .distinctUntilChanged(isEqualData)
      .startWith(OrderedMap());

    return propsStream
      .combineLatest(
        showAddNoteStream,
        orderResponseStream,
        selectedItemsStream,
        getOrderActivitiesResponseStream,
        getJobChildOrdersResponseStream,
        filterStream,
        onShowSubDialogStream.startWith(null),
        onShowChargeEditStream.startWith(false),
        (
          props,
          showAddNote,
          orderResponse,
          selectedItems,
          orderActivities,
          jobChildOrdersResponse,
          filter,
          uploadType,
          showChargeEdit,
        ) => ({
          ...props,
          uploadType,
          showAddNote,
          onShowAddNote,
          showChargeEdit,
          onShowSubDialog,
          onRowSelect,
          selectedItems,
          onShowChargeEdit,
          onOrderRequestRefresh,
          onActivitiesRequestRefresh,
          onJobChildOrdersRequestRefresh,
          filter,
          order: orderResponse.get("payload"),
          isLoading: orderResponse.get("pending"),
          activities: orderActivities.get("payload"),
          isLoadingActivities: orderActivities.get("pending"),
          jobOrdersCount: jobChildOrdersResponse.get("count"),
          jobOrders: jobChildOrdersResponse.get("list"),
          isLoadingJobOrders: jobChildOrdersResponse.get("isLoading"),
        }),
      )
      .distinctUntilChanged(isEqualData);
  }),
  withState("submitting", "onSubmitting", false),
);

const BATCH_JOB_ORDER_UPDATE_DIALOG_HASH = "#BJOUDH";
const BATCH_JOB_ORDER_RESCHEDULE_DIALOG_HASH = "#BJORDH";

SupplierOrderJobDetailsDialogWrapper.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.instanceOf(Map),
  activities: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,

  jobOrders: PropTypes.instanceOf(List),
  jobOrdersCount: PropTypes.number,
  isLoadingJobOrders: PropTypes.bool,

  onOrderRequestRefresh: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  onJobChildOrdersRequestRefresh: PropTypes.func,

  onShowChargeEdit: PropTypes.func,
  onShowSubDialog: PropTypes.func,
  uploadType: PropTypes.string,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  orderId: PropTypes.number,
  onRequestClose: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter),

  onTabChange: PropTypes.func.isRequired,
  tab: PropTypes.oneOf([ORDERS_TAB, HISTORY_TAB, MAP_TAB, SIGNATURE_TAB]),

  onEditClick: PropTypes.func,

  submitting: PropTypes.bool,
  isLoadingActivities: PropTypes.bool,

  onRowSelect: PropTypes.func,
  selectedItems: PropTypes.instanceOf(OrderedMap),

  location: PropTypes.object,
  setLocationQuery: PropTypes.func,
  replaceLocationHash: PropTypes.func,
};

SupplierOrderJobDetailsDialogWrapper.defaultProps = { tab: ORDERS_TAB };

function SupplierOrderJobDetailsDialogWrapper(props) {
  const { classes } = props;

  return (
    <OrderJobDetailsDialog
      open={true}
      onRequestClose={props.onRequestClose}
      tab={props.tab}
      order={props.order}
      isLoading={props.isLoading}
      onTabChange={props.onTabChange}
      onEditClick={props.onEditClick}
      onJobEditClick={() =>
        props.setLocationQuery(
          fp.flow(
            fp.unset("view_job"),
            fp.set("edit", props.location.query.view_job),
          ),
        )
      }
      onEditCostsClick={() => props.onShowChargeEdit(true)}
      getDriverUrl={id => updateQuery(DRIVER_LIST_URL, { view: id })}
      getSupplierUrl={id => updateQuery(SUPPLIER_LIST_URL, { view: id })}
      getCustomerUrl={id => CUSTOMER_ITEM_URL + id}
    >
      <PageLoading isLoading={props.submitting || props.isLoadingActivities} />

      {Boolean(props.tab === ORDERS_TAB) && (
        <SupplierJobDetailsDialogOrders
          order={props.order}
          list={props.jobOrders}
          isLoading={props.isLoadingJobOrders}
          selectedItems={props.selectedItems}
          onRowSelect={props.onRowSelect}
          location={props.location}
          onRequestClose={props.onRequestClose}
          totalCount={props.jobOrdersCount}
          filter={props.filter}
          onSubmitSuccess={() => {
            props.onJobChildOrdersRequestRefresh();
            props.showSuccessMessage("Order(s) has been successfully removed");
          }}
          onSubmitFail={error => {
            props.onJobChildOrdersRequestRefresh();
            props.showErrorMessage(error.errors.status);
          }}
        />
      )}

      {Boolean(props.tab === HISTORY_TAB) && (
        <Card className={classes.container}>
          <CardContent>
            <OrderActivitiesTimeline
              showDriver={true}
              showSupplier={true}
              showWarehouse={true}
              driverItemUrl={id =>
                updateQuery(DRIVER_LIST_URL, fp.set("view", id))
              }
              driverItemLocationUrl={id =>
                updateQuery(
                  DRIVER_LIST_URL,
                  fp.flow(fp.set("view", id), fp.set("view_tab", "map")),
                )
              }
              activities={props.activities}
            />
          </CardContent>
        </Card>
      )}

      {Boolean(props.tab === MAP_TAB) && (
        <OrderJobDetailsDialogMap
          order={props.order}
          getDriverLocation={getDriverLocation}
        />
      )}

      {Boolean(props.tab === SIGNATURE_TAB) && (
        <FlexBox
          container={8}
          flex={true}
          className={classes.container}
          style={{ backgroundColor: "#fff" }}
        >
          <FlexBox gutter={8} flex={true}>
            <FlexBox flex={true} direction="column">
              <OrderDetailsDialogSignature
                photoId={props.order.get("sender_photo_id")}
                signatureId={props.order.get("sender_signature_id")}
                onUpload={() => props.onShowSubDialog(SENDER_FROM)}
                header="Proof of Pickup"
              />
            </FlexBox>
            <FlexBox flex={true} direction="column">
              <OrderDetailsDialogSignature
                photoId={props.order.get("delivered_to_photo_id")}
                signatureId={props.order.get("delivery_signature_id")}
                header="Proof of Delivery"
                onUpload={() => props.onShowSubDialog(DELIVERY_TO)}
              />

              <UpdateOrderContactDialog
                onRequestClose={() => props.onShowSubDialog(null)}
                open={Boolean(
                  props.uploadType === DELIVERY_TO ||
                    props.uploadType === SENDER_FROM,
                )}
                title={
                  props.uploadType === DELIVERY_TO
                    ? "Update Recipient Info"
                    : "Update Sender Info"
                }
                initialValues={
                  props.uploadType === DELIVERY_TO
                    ? {
                        type: DELIVERY_TO,
                        orderId: props.orderId,
                        name: props.order.get("delivered_to"),
                        phone: props.order.get("delivered_to_phone"),
                        photo: props.order.get("delivered_to_photo"),
                        signature: props.order.get("delivery_signature"),
                      }
                    : {
                        type: SENDER_FROM,
                        orderId: props.orderId,
                        name: props.order.get("sender_from"),
                        phone: props.order.get("sender_phone"),
                        photo: props.order.get("sender_photo"),
                        signature: props.order.get("sender_signature"),
                      }
                }
                onSubmit={values =>
                  uploadOrderSignature(
                    props.orderId,
                    toSnakeCase(values),
                  ).catch(ResponseError.throw)
                }
                onSubmitSuccess={() => {
                  props.onOrderRequestRefresh();
                  props.onShowSubDialog(null);
                }}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}

      {props.location.hash === BATCH_JOB_ORDER_UPDATE_DIALOG_HASH && (
        <SupplierBatchUpdateJobDialogWrapper
          open={true}
          initialValues={{ orderNumbers: [props.order.get("order_number")] }}
          onRequestClose={() => props.replaceLocationHash(null)}
          onSubmitFail={props.showErrorMessage}
          onSubmitSuccess={response => {
            props.replaceLocationHash(null);
            props.setLocationQuery(fp.set("batch_id", response.data.id));
          }}
        />
      )}

      {props.location.hash === BATCH_JOB_ORDER_RESCHEDULE_DIALOG_HASH && (
        <SupplierBatchRescheduleOrderDialogWrapper
          initialValues={{ orderNumbers: [props.order.get("order_number")] }}
          open={props.location.hash === BATCH_JOB_ORDER_RESCHEDULE_DIALOG_HASH}
          onRequestClose={() => props.replaceLocationHash(null)}
          onSubmitSuccess={() => {
            props.onOrderRequestRefresh();
            props.replaceLocationHash(null);
            props.showSuccessMessage("Successfully Rescheduled Orders.");
          }}
          onSubmitFail={props.showErrorMessage}
        />
      )}

      <SupplierJobDriverSuggestionDialogWrapper
        initialValues={props.order}
        jobId={fp.toFinite(props.location.query.view_job)}
        open={props.location.query.assign_driver === "true"}
        onRequestClose={() => props.setLocationQuery(fp.unset("assign_driver"))}
        location={props.location}
        onSubmitSuccess={() => {
          props.onOrderRequestRefresh();
          props.setLocationQuery(fp.unset("assign_driver"));
          props.showSuccessMessage("Driver has been assigned successfully");
        }}
        onSubmitFail={error => {
          props.onOrderRequestRefresh();
          props.showErrorMessage(error.errors.status);
        }}
      />
    </OrderJobDetailsDialog>
  );
}

export default enhancer(SupplierOrderJobDetailsDialogWrapper);
