import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { Avatar, Button } from "@material-ui/core";
import { connect } from "react-redux";
import ModalPaper from "../ui-core/ModalPaper";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { isPickUpAddress } from "../../helpers/OrderHelper";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { primary1 } from "../../../shared/theme/main-theme";
import IconPickUp from "./assets/map_icon.svg";
import NewAddressIcon from "./assets/new_icon.svg";
import OldAddressIcon from "./assets/old_icon.svg";

const enhancer = compose(
  renderIf(props => props.orderId > 0),
  useSheet({
    paper: {
      minWidth: "400px",
      maxWidth: "400px",
      minHeight: "auto",
    },
    root: {
      backgroundColor: primary1,
      borderBottomRadius: "100px",
      height: "60px",
    },
    avatarContainer: {
      textAlign: "center",
      width: "100%",
    },
    avatarIcon: {
      marginTop: "-40px",
    },
    panel: {
      paddingTop: "20px",
      textAlign: "center",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    subHeader: {
      textAlign: "center",
      marginBottom: "15px",
      fontSize: 20,
    },
    footer: {
      textAlign: "right",
      padding: "15px",
    },
    addressLine1: {
      fontSize: "12px",
    },
    addressLine2: {
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: "500",
    },
    container: {
      width: "100%",
      display: "table",
      padding: "1em",
      paddingLeft: "0",
      alignItems: "center",
    },
    avatar: {
      width: "36px",
      borderRadius: "50%",
      textAlign: "center",
      display: "table-cell",
      textTransform: "uppercase",
      color: "white",
      verticalAlign: "middle",
    },
    nameBox: {
      display: "table-cell",
      verticalAlign: "middle",
      textAlign: "left",
      paddingLeft: "1em",
      fontSize: "1em",
      color: "#757575",
    },
  }),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const orderResponseStream = propsStream
      .distinctUntilKeyChanged("orderId")
      .switchMap(props =>
        props
          .getOrder(props.orderId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(orderResponseStream, (props, orderResponse) => ({
        ...props,
        onRequestRefresh,
        order: orderResponse.get("payload"),
        isLoading: orderResponse.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

OrderAddressChangeNotificationDialog.propTypes = {
  classes: PropTypes.object,
  order: PropTypes.instanceOf(Map),
  onRequestClose: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getOrder: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderAddressChangeNotificationDialog(props) {
  const { classes, order, getLocalisationMessage } = props;
  const isPickup = isPickUpAddress(order);

  return (
    <ModalPaper
      open={true}
      paperClassName={classes.paper}
      autoScrollBodyContent={false}
      onRequestClose={props.onRequestClose}
    >
      <div className={classes.root} />
      <div className={classes.avatarContainer}>
        <img
          style={{
            width: 100,
            height: 100,
          }}
          color={primary1}
          className={classes.avatarIcon}
          src={IconPickUp}
          alt={name}
        />
      </div>
      {isPickup > -1 && (
        <div className={classes.panel}>
          <h5 className={classes.subHeader}>
            {isPickup === 0
              ? getLocalisationMessage("pickup", "Pickup")
              : getLocalisationMessage("drop_off", "DropOff")}{" "}
            {getLocalisationMessage(
              "address_has_been_changed",
              "address has been changed",
            )}
          </h5>
          <div className={classes.container}>
            <div className={classes.avatar}>
              <Avatar
                src={OldAddressIcon}
                name={name}
                alt={name}
                size={36}
                style={{
                  backgroundColor: "#eaeaea",
                }}
              />
            </div>
            <div className={classes.nameBox}>
              <div className={classes.addressLine1}>
                {getLocalisationMessage("old_address", "Old Address")}
              </div>
              <div className={classes.addressLine2}>
                {order.getIn(["location_history", isPickup, "address"])}
              </div>
            </div>
          </div>

          <div className={classes.container}>
            <div className={classes.avatar}>
              <Avatar
                src={NewAddressIcon}
                name={name}
                alt={name}
                size={36}
                style={{
                  backgroundColor: "#eaeaea",
                }}
              />
            </div>
            <div className={classes.nameBox}>
              <div className={classes.addressLine1}>
                {getLocalisationMessage("new_address", "New Address")}
              </div>
              <div
                className={classes.addressLine2}
                style={{ color: "#212121" }}
              >
                {order.getIn(["locations", isPickup, "address"])}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={classes.footer}>
        <Button onClick={props.onRequestClose}>
          {getLocalisationMessage("close", "Close")}
        </Button>
      </div>
    </ModalPaper>
  );
}

export default enhancer(OrderAddressChangeNotificationDialog);
