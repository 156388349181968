import React from "react";
import { isAfter, isBefore, format as dateFormat } from "date-fns";
import { Map, List, OrderedSet } from "immutable";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import {
  MenuItem,
  // fade
} from "@material-ui/core";
import { connect } from "react-redux";
import SpikeChart from "../charts/SpikeChart";
import MenuButtonMore from "../ui-core/MenuButtonMore";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDate, formatNumber } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const formatDayMonthYear = (year, month, day) =>
  dateFormat(new Date(year, month, day), "dd MMM yyyy");

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const statsStream = propsStream
      .distinctUntilChanged(isEqualData)
      .map(props =>
        Map().withMutations(items => {
          const datasets = OrderedSet().asMutable();
          const info = List().asMutable();
          const data = [];
          const labels = [];

          const maxThreshold = 86;
          const minThreshold = 80;

          const sortedReports = props.reports.sort((a, b) => {
            const aDate = new Date(
              a.get("year"),
              a.get("month") - 1,
              a.get("day"),
            );

            const bDate = new Date(
              b.get("year"),
              b.get("month") - 1,
              b.get("day"),
            );

            if (isAfter(aDate, bDate)) return 1;

            if (isBefore(aDate, bDate)) return -1;

            return 0;
          });

          const difference =
            sortedReports.getIn([0, "daily_deapercanatge"], 0) -
            sortedReports.getIn([1, "daily_deapercanatge"], 0);

          const percentage =
            (props.reports1.get("attempted_before_promised_date") /
              props.reports1.get("promised")) *
            100
              ? (props.reports1.get("attempted_before_promised_date") /
                  props.reports1.get("promised")) *
                100
              : 0;

          items.set("percentage", percentage);
          items.set("diff_percentage", difference);

          const { getLocalisationMessage } = props;

          sortedReports.forEach(item => {
            labels.push(
              formatDate(
                formatDayMonthYear(
                  item.get("year"),
                  item.get("month") - 1,
                  item.get("day"),
                ),
              ),
            );
            data.push(
              item.get("daily_deapercanatge", 0) === 0
                ? formatNumber(0)
                : formatNumber(item.get("daily_deapercanatge", 0)),
            );
          });

          info.push(
            Map({
              label: getLocalisationMessage("promised", "Promised"),
              align: "flex-start",
              value: props.reports1.get("promised"),
            }),
          );
          info.push(
            Map({
              label: getLocalisationMessage("attempted", "Attempted"),
              align: "center",
              value: props.reports1.get("attempted_before_promised_date"),
            }),
          );

          info.push(
            Map({
              label: getLocalisationMessage("not_attempted", "Not Attempted"),
              align: "flex-end",
              value:
                props.reports1.get("promised") -
                props.reports1.get("attempted_before_promised_date"),
            }),
          );

          datasets.add(Map({ data }));

          items.set(
            "chartData",
            Map({ labels, datasets: datasets.asImmutable() }),
          );
          items.set("infoData", info);
          items.set(
            "backgroundColor",
            percentage >= maxThreshold
              ? "#7CB342"
              : percentage >= minThreshold
              ? "#FFB300"
              : "#E53935",
          );
        }),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(statsStream, (props, stats) => ({
        ...props,
        stats,
      }));
  }),
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
    },
    title: {
      fontSize: "18px",
      textTransform: "uppercase",
    },
    info: {
      paddingTop: "20px",
    },
    moreBtn: {
      // color: fade("#fff", 0.5),
    },
  }),
);

OFDChartComponent.propTypes = {
  classes: PropTypes.object,
  stats: PropTypes.object,
  reports: PropTypes.instanceOf(List),
  reports1: PropTypes.instanceOf(Map),
  title: PropTypes.string,
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function OFDChartComponent(props) {
  return (
    <SpikeChart
      backgroundColor={props.stats.get("backgroundColor")}
      color="#ffffff"
      title={props.getLocalisationMessage("dea", "Delivery Estimated Attempt")}
      data={props.stats}
      actionIcons={
        <div>
          <MenuButtonMore
            isHorizontal={true}
            iconClassName={props.classes.moreBtn}
          >
            <MenuItem onClick={() => props.onRequestRefresh()}>
              {props.getLocalisationMessage("refresh", "Refresh")}
            </MenuItem>
          </MenuButtonMore>
        </div>
      }
      tooltipFormat={{
        displayColors: false,
        callbacks: {
          title() {
            return null;
          },
          label(tooltipItem) {
            return `${tooltipItem.xLabel}: ${tooltipItem.yLabel}%`;
          },
        },
      }}
    />
  );
}

export default enhancer(OFDChartComponent);
