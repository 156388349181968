import sprintf from "sprintf";
import WingApi from "../helpers/WingApi";
import { FETCH_PUBLIC_ORDER } from "../reducers/PublicOrderReducer";
import { API_ROOT_URL } from "../../shared/constants/ApiConstants";

const PUBLIC_ORDER_URL = `${API_ROOT_URL}/public/order/%s`;
const PUBLIC_ORDER_NOTES_URL = `${API_ROOT_URL}/public/order/%s/notes`;
const PUBLIC_ORDER_HISTORY_URL = `${
  API_ROOT_URL
}/public/order/%s/history_items`;

const PUBLIC_ORDER_DRIVER_URL = `${API_ROOT_URL}/public/order/%s/driver`;

const fallbackResolve = (promise, fallbackValue) =>
  promise.catch(() => Promise.resolve(fallbackValue));

export function fetchPublicOrder(publicId) {
  return (dispatch, getState, api: WingApi) => {
    const request = Promise.all([
      api.get(sprintf(PUBLIC_ORDER_URL, publicId)),
      fallbackResolve(api.get(sprintf(PUBLIC_ORDER_NOTES_URL, publicId)), {}),
      fallbackResolve(api.get(sprintf(PUBLIC_ORDER_DRIVER_URL, publicId)), {}),
      fallbackResolve(api.get(sprintf(PUBLIC_ORDER_HISTORY_URL, publicId)), {}),
    ]).then(([order, notes, driver, history]) => {
      const newOrder = { ...order };

      newOrder.data.notes = notes;
      newOrder.data.driver = driver.data;
      newOrder.data.history = history;

      return newOrder;
    });

    return dispatch({
      meta: { publicId },
      payload: request,
      type: FETCH_PUBLIC_ORDER,
    });
  };
}
