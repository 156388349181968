import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../../helpers/HOCUtils";
import DataListFilter from "../../../helpers/DataListFilter";
import { getMessage } from "../../../reducers/LocalizationReducer";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../../api/admin/AdminWarehouseApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../../api/supplier/SupplierCustomerApi";
import ModalPaper from "../../../components/ui-core/ModalPaper";
import DashboardFilterForm from "../../../components/dashboard-core/DashbordFilterForm";

const enhancer = compose(
  renderIf("open"),
  useSheet({
    paper: {
      minWidth: "600px",
      maxWidth: "800px",
      minHeight: "auto",
    },
  }),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

SupplierDashboardFilterDialogWrapper.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function SupplierDashboardFilterDialogWrapper(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <ModalPaper
      open={true}
      title={getLocalisationMessage("filter", "Filter")}
      paperClassName={classes.paper}
      autoScrollBodyContent={false}
      onRequestClose={props.onRequestClose}
    >
      <DashboardFilterForm
        filter={props.filter}
        getCachedWarehouse={getCachedWarehouse}
        getWarehousePredictions={getWarehousePredictions}
        getCachedCustomer={getCachedCustomer}
        getCustomerPredictions={getCustomerPredictions}
        onFilterChange={props.onFilterChange}
        onDismiss={props.onRequestClose}
        showSupplier={false}
        showDriver={false}
      />
    </ModalPaper>
  );
}

export default enhancer(SupplierDashboardFilterDialogWrapper);
