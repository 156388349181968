import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import OrderCreateWizardStepPayItem from "./OrderCreateWizardStepPayItem";

const valueSelector = formValueSelector("OrderCreateWizardSupplier");

const enhancer = compose(
  connect(state => ({ paymentType: valueSelector(state, "paymentType") })),
);

OrderCreateWizardStepPayDetails.propTypes = {
  paymentType: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

function OrderCreateWizardStepPayDetails(props) {
  return (
    <OrderCreateWizardStepPayItem
      label="Pay"
      onClick={props.onClick}
      paymentType={props.paymentType}
    />
  );
}

export default enhancer(OrderCreateWizardStepPayDetails);
