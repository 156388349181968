import React from "react";
import { differenceInMinutes } from "date-fns";
import Immutable from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Drawer, ListSubheader } from "@material-ui/core";
import { connect } from "react-redux";
import DriverMarker from "../maps/DriverMarker";
import GoogleMapWrapper from "../maps/GoogleMapWrapper";
import FlexBox from "../ui-core/FlexBox";
import LinkButton from "../ui-core/LinkButton";
import DataList, { DataListColumn } from "../data-list/DataList";
import { pureComponent } from "../../helpers/HOCUtils";
import { formatDriverName } from "../../helpers/DriverHelper";
import { getMessage } from "../../reducers/LocalizationReducer";

const isOutDated = x => differenceInMinutes(new Date(), x) > 24 * 60;

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  useSheet({
    root: {
      left: "0",
      bottom: "0",
      top: "64px",
      right: "320px",
      position: "fixed",
    },

    drawer: { top: "64px", bottom: "0", height: "auto" },
    drawerContent: { height: "100%" },
  }),
  mapPropsStream(propsStream => {
    const driverLocationsStream = propsStream
      .distinctUntilKeyChanged("driverLocations", Immutable.is)
      .map(props =>
        props.driverLocations.filter(
          x =>
            x.getIn(["location", "lat"]) < 40 &&
            isOutDated(x.getIn(["location", "date"])),
        ),
      );

    return propsStream.combineLatest(
      driverLocationsStream,
      (props, driverLocations) => ({ ...props, driverLocations }),
    );
  }),
  pureComponent(
    fp.pick(["activeDriverId", "driverLocations", "activeDriverOrder"]),
  ),
);

DriversRadar.propTypes = {
  classes: PropTypes.object,

  activeDriverId: PropTypes.number,
  onActivateDriver: PropTypes.func,

  activeDriverOrder: PropTypes.instanceOf(Immutable.Map),
  driverLocations: PropTypes.instanceOf(Immutable.List).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function DriversRadar(props) {
  const { classes, driverLocations, getLocalisationMessage } = props;

  return (
    <div className={classes.root}>
      <Drawer
        open={true}
        width={320}
        openSecondary={true}
        containerClassName={classes.drawer}
      >
        <FlexBox
          flex={true}
          direction="column"
          className={classes.drawerContent}
        >
          <FlexBox>
            <ListSubheader>
              {driverLocations.size}{" "}
              {getLocalisationMessage("drivers", "Drivers")}
            </ListSubheader>
          </FlexBox>

          <DataList
            overscanRowCount={10}
            rowCount={driverLocations.size}
            totalCount={driverLocations.size}
            isLoading={driverLocations.isEmpty()}
            rowGetter={row => driverLocations.get(row.index)}
          >
            <DataListColumn
              width={80}
              label={getLocalisationMessage("name", "Name")}
              dataKey="name"
              justifyContent="center"
              cellRenderer={x => (
                <LinkButton
                  onClick={() =>
                    props.onActivateDriver(
                      x.rowData.getIn(["driver", "driver_id"]),
                    )
                  }
                >
                  {formatDriverName(x.rowData.get("driver"))}
                </LinkButton>
              )}
            />

            <DataListColumn
              width={80}
              label={getLocalisationMessage("phone", "Phone")}
              dataKey="phone"
              justifyContent="center"
              cellRenderer={x => x.rowData.getIn(["driver", "phone"])}
            />
          </DataList>
        </FlexBox>
      </Drawer>

      <GoogleMapWrapper>
        {driverLocations.map(x => {
          const id = x.getIn(["driver", "driver_id"]);
          const isActive = props.activeDriverId === id;

          return (
            <DriverMarker
              key={id}
              driver={x.get("driver")}
              position={x.get("location")}
              order={isActive ? props.activeDriverOrder : null}
              isActive={isActive}
              onActivate={() => props.onActivateDriver(id)}
              onDeactivate={() => props.onActivateDriver(null)}
              getLocalisationMessage={getLocalisationMessage}
            />
          );
        })}
      </GoogleMapWrapper>
    </div>
  );
}

export default enhancer(DriversRadar);
