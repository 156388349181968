import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  isEqualData,
  isEqualDataIn,
  isEqualWithoutFunctions,
} from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import ResponseError from "../../helpers/ResponseError";
import { getMessages } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { COMMUNICATION_SETTINGS_LIST_URL } from "../../constants/SupplierPathConstants";
import {
  getCommunicationMessage,
  deleteCommunicationMessage,
  saveSupplierCommunicationMessage,
} from "../../api/shared/CommunicationApi";
import FormDialog from "../../components/form/FormDialog";
import SupplierAppLayout from "../../components/supplier/SupplierAppLayout";
import CommunicationSettingsForm from "../../components/communication-settings/CommunicationSettingsForm";
import { updateQuery } from "../../../shared/helpers/UrlUtils";

const DELETE_MESSAGE_DIALOG_HASH = "#DMDH";

const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
    setLocationHash: PropTypes.func.isRequired,
  }),
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    { showSuccessMessage, showErrorMessage },
  ),
  mapPropsStream(propsStream => {
    const itemCommunicationResponseStream = propsStream
      .distinctUntilChanged(isEqualDataIn(["params", "id"]))
      .switchMap(props =>
        getCommunicationMessage(props.params.id).catch(ResponseError.throw),
      )
      .startWith({})
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              item: response.getIn(["payload", "data"], Map()),
              isLoading: response.get("pending", false),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(
        itemCommunicationResponseStream,
        (props, itemCommunicationResponse) => ({
          ...props,
          item: itemCommunicationResponse.get("item"),
          isLoading: itemCommunicationResponse.get("isLoading"),
        }),
      )
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

SupplierCommunicationSettingsItem.propTypes = {
  params: PropTypes.object,

  location: PropTypes.object,

  setLocation: PropTypes.func,
  setLocationHash: PropTypes.func,

  item: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,

  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,

  i18n: PropTypes.instanceOf(Map),
};

function SupplierCommunicationSettingsItem(props) {
  return (
    <SupplierAppLayout
      title={props.i18n.get("communication_settings", "Communication Settings")}
    >
      <FormDialog
        onSubmitFail={props.showErrorMessage}
        onRequestClose={() => props.setLocationHash(null)}
        open={props.location.hash === DELETE_MESSAGE_DIALOG_HASH}
        onSubmit={() =>
          deleteCommunicationMessage(props.params.id).catch(ResponseError.throw)
        }
        onSubmitSuccess={() => {
          props.showSuccessMessage(
            props.i18n.get("successfully_removed", "Successfully Removed"),
          );
          props.setLocation(
            updateQuery(COMMUNICATION_SETTINGS_LIST_URL, {
              ...props.location.query,
            }),
          );
        }}
      >
        {props.i18n.get(
          "confirm_deleted",
          "Are you sure you want to remove this message?",
        )}
      </FormDialog>

      <CommunicationSettingsForm
        isLoading={props.isLoading}
        breadcrumbTitle={props.i18n.get("edit_message", "Edit Message")}
        onSubmitFail={props.showErrorMessage}
        initialValues={toCamelCase(props.item)}
        onSubmitSuccess={() =>
          props.showSuccessMessage(
            props.i18n.get("successfully_saved", "Successfully saved"),
          )
        }
        onDeleteClick={() => props.setLocationHash(DELETE_MESSAGE_DIALOG_HASH)}
        onSubmit={values =>
          saveSupplierCommunicationMessage(toSnakeCase(values)).catch(
            ResponseError.throw,
          )
        }
      />
    </SupplierAppLayout>
  );
}

export default enhancer(SupplierCommunicationSettingsItem);
