import React from "react";
import { format as dateFormat } from "date-fns";
import { Map, List, OrderedSet } from "immutable";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { MenuItem } from "@material-ui/core";
import { connect } from "react-redux";

import SpikeChart from "../charts/SpikeChart";
import MenuButtonMore from "../ui-core/MenuButtonMore";
import { isEqualData } from "../../helpers/DataUtils";
import { formatDate, formatNumber } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const formatDayMonthYear = (year, month, day) =>
  dateFormat(new Date(year, month, day), "dd MMM yyyy");

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  mapPropsStream(propsStream => {
    const statsStream = propsStream
      .distinctUntilChanged(isEqualData)
      .map(props =>
        Map().withMutations(items => {
          const datasets = OrderedSet().asMutable();
          const info = List().asMutable();
          const data = [];
          const labels = [];

          props.reports.forEach(item => {
            labels.push(
              formatDate(
                formatDayMonthYear(
                  item.get("year"),
                  item.get("month") - 1,
                  item.get("day"),
                ),
              ),
            );

            // const weekIdOfTheDay = getISOWeek(
            //   new Date(
            //     item.get("year"),
            //     item.get("month") - 1,
            //     item.get("day"),
            //   ),
            //   {
            //     weekStartsOn: 0,
            //   },
            // );

            data.push(item.get("apt", 0));
          });

          info.push(
            Map({
              label: props.getLocalisationMessage("today", "Today"),
              align: "flex-start",
              value: props.reports1.get("today_apt")
                ? props.reports1.get("today_apt")
                : "N/A",
            }),
          );
          info.push(
            Map({
              label: props.getLocalisationMessage(
                "current_week",
                "Current Week",
              ),
              align: "center",
              value: props.reports1.get("current_week_apt")
                ? props.reports1.get("current_week_apt")
                : "N/A",
            }),
          );

          info.push(
            Map({
              label: props.getLocalisationMessage(
                "current_month",
                "Current Month",
              ),
              align: "flex-end",
              value: props.reports1.get("current_month_apt")
                ? props.reports1.get("current_month_apt")
                : "N/A",
            }),
          );

          datasets.add(Map({ data }));

          items.set(
            "chartData",
            Map({ labels, datasets: datasets.asImmutable() }),
          );

          items.set("infoData", info || List());
        }),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .distinctUntilChanged(isEqualData)
      .combineLatest(statsStream, (props, stats) => ({
        ...props,
        stats,
      }));
  }),
  useSheet({
    container: {
      width: "100%",
      padding: "15px",
    },
    title: {
      fontSize: "18px",
      textTransform: "uppercase",
    },
    info: {
      paddingTop: "20px",
    },
    moreBtn: {
      // color: fade("#fff", 0.5),
    },
  }),
);

PickedUpTimeChartComponent.propTypes = {
  classes: PropTypes.object,
  stats: PropTypes.object,
  reports: PropTypes.instanceOf(List),
  reports1: PropTypes.instanceOf(Map),
  onRequestRefresh: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

function PickedUpTimeChartComponent(props) {
  const { getLocalisationMessage } = props;
  return (
    <SpikeChart
      backgroundColor={props.stats.get("backgroundColor")}
      color="#ffffff"
      minHeight={80}
      title={<span>{getLocalisationMessage("apt", "APT")}</span>}
      data={props.stats}
      actionIcons={
        <div>
          <MenuButtonMore
            isHorizontal={true}
            iconClassName={props.classes.moreBtn}
          >
            <MenuItem onClick={() => props.onRequestRefresh()}>
              {getLocalisationMessage("refresh", "Refresh")}
            </MenuItem>
          </MenuButtonMore>
        </div>
      }
      tooltipFormat={{
        displayColors: false,
        callbacks: {
          title() {
            return null;
          },
          label(tooltipItem) {
            const label = `${tooltipItem.xLabel}: ${formatNumber(
              tooltipItem.yLabel,
            )}`;
            return label;
          },
        },
      }}
    />
  );
}

export default enhancer(PickedUpTimeChartComponent);
