import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { SwapCalls } from "@material-ui/icons";
import FlagIcon from "react-flag-kit/lib/CDNFlagIcon";
import OrderCreateWizardStepDetails from "../internal/OrderCreateWizardStepDetails";
import UserAvatar from "../../avatars/UserAvatar";

const valueSelector = formValueSelector("OrderCreateWizardSupplier");

const enhancer = compose(
  useSheet({
    swapButton: {
      "& button": { height: "36px", width: "36px" },
      "& svg": { height: "24px", fill: "rgba(0, 0, 0, 0.54)" },
    },
    flag: { verticalAlign: "sub" },
  }),
  connect(state => ({
    values: valueSelector(state, "pickupLocation", "pickupContactName"),
  })),
);

OrderCreateWizardStepFromDetails.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  showSwapButton: PropTypes.bool,
  onSwapButtonClick: PropTypes.func,
};

function OrderCreateWizardStepFromDetails(props) {
  const { classes, values } = props;

  if (!values.pickupLocation) {
    return null;
  }

  return (
    <OrderCreateWizardStepDetails
      label="From"
      onClick={props.onClick}
      avatar={<UserAvatar size={40} name={values.pickupContactName} />}
      floatingActionButton={
        props.showSwapButton && (
          <Button
            backgroundColor="#fff"
            onClick={props.onSwapButtonClick}
            className={classes.swapButton}
          >
            <SwapCalls />
          </Button>
        )
      }
      primaryText={
        <div>
          {values.pickupContactName}{" "}
          <FlagIcon
            code={values.pickupLocation.country}
            size={18}
            className={classes.flag}
          />
        </div>
      }
      secondaryText={values.pickupLocation.address}
    />
  );
}

export default enhancer(OrderCreateWizardStepFromDetails);
