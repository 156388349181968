import React from "react";
import { List } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValueSelector } from "redux-form";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import OrderCreateWizardStepHowItem from "./OrderCreateWizardStepHowItem";
import OrderCreateWizardStepCard from "../internal/OrderCreateWizardStepCard";
import { isEqualData } from "../../../helpers/DataUtils";
import { pipeStreams } from "../../../helpers/StreamUtils";
import { getMessage } from "../../../reducers/LocalizationReducer";
import OrderCreateNoNSAArea from "../../order-create-wizard-new/internal/OrderCreateNoNSAArea";
import { primary1 } from "../../../../shared/theme/main-theme";

const valueSelector = formValueSelector("OrderCreateWizardSupplier");

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    progress: { display: "flex", paddingTop: "24px", justifyContent: "center" },
    vehicleIcon: {
      width: "18px",
      height: "18px",
      color: primary1,
      marginLeft: "6px",
      verticalAlign: "sub",
    },
  }),
  reduxForm({
    form: "OrderCreateWizardSupplier",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  connect(state => ({
    values: valueSelector(
      state,
      "pickupCountry",
      "dropoffCountry",
      "initialPackageId",
    ),
  })),
  mapPropsStream(
    pipeStreams(
      propsStream =>
        propsStream.combineLatest(
          propsStream
            .distinctUntilKeyChanged("packagePrices", isEqualData)
            .map(props =>
              props.packagePrices.flatMap(price =>
                price
                  .get("packages")
                  .map(item =>
                    item.set("vehicle_type", price.get("vehicle_type")),
                  ),
              ),
            ),
          (props, packagePrices) => ({ ...props, packagePrices }),
        ),
      propsStream =>
        propsStream.combineLatest(
          propsStream
            .filter(
              props =>
                props.values.initialPackageId > 0 &&
                !props.packagePrices.isEmpty(),
            )
            .take(1)
            .do(props => {
              const item = props.packagePrices.find(
                v => v.get("id") === props.values.initialPackageId,
              );

              props.change("initialPackageId", null);

              if (item) {
                props.change("package", item);
                props.handleSubmit();
              }
            })
            .startWith(null),
          fp.identity,
        ),
      propsStream =>
        propsStream.combineLatest(
          propsStream
            .filter(
              props =>
                !props.values.initialPackageId &&
                !props.packagePrices.isEmpty() &&
                props.packagePrices.size === 1,
            )
            .take(1)
            .do(props => {
              const item = props.packagePrices.get(0);

              if (item) {
                props.change("package", item);
                props.handleSubmit();
              }
            })
            .startWith(null),
          fp.identity,
        ),
    ),
  ),
);

OrderCreateWizardStepHowForm.propTypes = {
  classes: PropTypes.object,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  showContent: PropTypes.bool,

  values: PropTypes.object,
  onSubmit: PropTypes.func,
  packagePricesFetching: PropTypes.bool.isRequired,
  packagePrices: PropTypes.instanceOf(List).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderCreateWizardStepHowForm(props) {
  const { getLocalisationMessage } = props;
  return (
    <OrderCreateWizardStepCard
      title={getLocalisationMessage(
        "how_do_you_want_us_to_deliver",
        "How do you want us to deliver?",
      )}
      showContent={props.showContent}
    >
      {props.packagePricesFetching ? (
        <div className={props.classes.progress}>
          <CircularProgress />
        </div>
      ) : (
        <div>
          {props.packagePrices.size > 0 ? (
            props.packagePrices
              .map(item => (
                <OrderCreateWizardStepHowItem
                  item={item}
                  key={item.get("id")}
                  pickupCountry={props.values.pickupCountry}
                  dropoffCountry={props.values.dropoffCountry}
                  onClick={() => {
                    props.change("package", item);
                    props.handleSubmit();
                  }}
                />
              ))
              .toArray()
          ) : (
            <OrderCreateNoNSAArea />
          )}
        </div>
      )}
    </OrderCreateWizardStepCard>
  );
}

export default enhancer(OrderCreateWizardStepHowForm);
