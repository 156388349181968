import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import {
  isEqualData,
  isEqualWithoutFunctions,
} from "../../../helpers/DataUtils";
import DataListFilter from "../../../helpers/DataListFilter";
import { DEA, OFD, RTO, FDDS } from "../../../constants/DashboardConstants";
import { getDSRReports } from "../../../api/supplier/SupplierChartApi";
import OFDChartComponent from "../../../components/dashboard-core/OFDChartComponent";

const enhancer = compose(
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const getAdtReportsStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .switchMap(props =>
        getDSRReports(props.filter)
          .repeatWhen(stream =>
            stream.delay(60 * 60 * 1000).merge(onRequestRefreshStream),
          )
          .catch(() => Observable.of({})),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data", "list"], List()),
              payloadMap: response.getIn(["payload", "data"], Map()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(getAdtReportsStream, (props, reports) => ({
        ...props,
        onRequestRefresh,
        reports: reports.get("payload"),
        reportsMap: reports.get("payloadMap"),
        isLoading: reports.get("pending"),
      }))
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);
SupplierDSRChartWrapper.propTypes = {
  type: PropTypes.oneOf([OFD, FDDS, DEA, RTO]),
  reports: PropTypes.instanceOf(List),
  filter: PropTypes.instanceOf(DataListFilter),
  reportsMap: PropTypes.instanceOf(Map),
  onRequestRefresh: PropTypes.func,
  children: PropTypes.object,
};

function SupplierDSRChartWrapper(props) {
  if (props.type) {
    return <OFDChartComponent reports={props.reports} {...props} />;
  }
  return React.cloneElement(props.children, {
    reportsMap: props.reportsMap,
    reports: props.reports,
    ...props,
  });
}

export default enhancer(SupplierDSRChartWrapper);
