// @flow weak

import { Observable } from "rxjs";
import sprintf from "sprintf";
import { Map } from "immutable";
import type { BatchUpdateWarehouseRequest } from "../admin/AdminOrderApi";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const ORDER_CREATE_URL = `${API_ROOT_URL}/supplier/order`;
const ORDER_LIST_URL = `${API_ROOT_URL}/supplier/orders`;
const ORDER_ITEM_URL = `${API_ROOT_URL}/supplier/order/%s`;
const ORDER_NOTES_URL = `${API_ROOT_URL}/supplier/order/%s/notes`;
const BATCH_CREATE_NOTE_URL = `${API_ROOT_URL}/supplier/order/notes`;
const BATCH_UPDATE_ORDER_URL = `${API_ROOT_URL}/supplier/orders/batch_update`;
const ORDER_HISTORY_URL = `${API_ROOT_URL}/supplier/order/%s/history_items`;
const BATCH_ORDER_RESCHEDULE_URL = `${API_ROOT_URL}/supplier/orders/deadline`;
const ORDER_ITEM_SIGNATURE_URL = `${API_ROOT_URL}/supplier/order/%s/signature`;
const ORDER_PRIVATE_NOTE_URL = `${API_ROOT_URL}/supplier/order/%s/private_note`;

const ORDER_DRIVERS_SUGGESTION_URL = `${
  API_ROOT_URL
}/supplier/order/%s/driver/suggestion/`;

const CALCULATE_COD_TOTAL_URL = `${
  API_ROOT_URL
}/supplier/orders/calculate_cod_total`;
const OVERWEIGHT_UPLOAD_CSV_URL = `${
  API_ROOT_URL
}/supplier/orders/upload_overweight_csv`;
const COD_COLLECTION_STATUS_URL = `${
  API_ROOT_URL
}/supplier/order/collection_status`;
const ORDER_BARCODE_PIECE_SCANNED = `${
  API_ROOT_URL
}/supplier/order/%s/piece_scanned`;
const BATCH_SET_ORDER_SIZE_URL = `${API_ROOT_URL}/supplier/orders/size`;

const DYNAMIC_FIELDS = `${API_ROOT_URL}/supplier/order/csv/dynamic_fields`;
const SUPPLIER_PAYMENT_METHODS_URL = `${API_ROOT_URL}/supplier/payment_methods`;
const BATCH_UPDATE_WAREHOUSE_URL = `${API_ROOT_URL}/supplier/orders/warehouse`;

export const getPaymentMethods = (request: Map) =>
  api.postStream(SUPPLIER_PAYMENT_METHODS_URL, {
    body: request.toJS(),
    headers: {
      "x-app-type": "supplier-web",
    },
  });

export const createOrder = values =>
  api.post(ORDER_CREATE_URL, { body: values });
export const getOrderList = (request: DataListFilter) =>
  api.getStream(ORDER_LIST_URL, {
    params: request.getDefinedValues(),
    timeout: 999999,
  });

export const getDynamicFields = () => api.getStream(DYNAMIC_FIELDS);

export const uploadOverweightCSV = (file: File) =>
  api.postStream(OVERWEIGHT_UPLOAD_CSV_URL, {
    file,
  });

export const updateCODCollectionStatus = body =>
  api.put(COD_COLLECTION_STATUS_URL, { body });

export const getOrder = id => api.getStream(sprintf(ORDER_ITEM_URL, id));
export const updateOrder = (id, values) =>
  api.put(sprintf(ORDER_ITEM_URL, id), { body: values });

export type UploadOrderSignatureRequest = {
  name: string,
  phone: string,
  photo: string,
  signature: string,
  type: string,
};

export const getOrdersCODTotal = body =>
  api.post(CALCULATE_COD_TOTAL_URL, { body });

export const uploadOrderSignature = (id, body: UploadOrderSignatureRequest) =>
  api.put(sprintf(ORDER_ITEM_SIGNATURE_URL, id), { body });

export type OrderNote = {
  cancellation_reason: string,
  category: string,
  content: string,
  created_date: string,
  entity_class: string,
  entity_id: number,
  id: number,
  noted_by: { id: number },
  noted_by_role: string,
  privacy: string,
  requires_attention: boolean,
  scheduled_for_cancellation: boolean,
};

export const createOrderNote = (id, body: OrderNote) =>
  api.put(sprintf(ORDER_PRIVATE_NOTE_URL, id), { body });

export type BatchAsyncUpdateOrderRequest = {
  category: string,
  driver: { id: number },
  note: string,
  order_numbers: string[],
  order_status: string,
  paid_to_customer: boolean,
  paid_to_wing: boolean,
  privacy: string,
  reason: string,
  requires_attention: boolean,
  scheduled_for_cancellation: boolean,
  warehouse: { id: number },
};

export const batchAsyncUpdateOrder = (body: BatchAsyncUpdateOrderRequest) =>
  api.put(BATCH_UPDATE_ORDER_URL, { body });

export const getOrderSuggestedDrivers = (id, request: DataListFilter) =>
  api.getStream(sprintf(ORDER_DRIVERS_SUGGESTION_URL, id), {
    params: request.getDefinedValues(),
  });

export const getOrderActivities = id =>
  Observable.combineLatest(
    api.getStream(sprintf(ORDER_NOTES_URL, id)).catch(() => Observable.of({})),
    api
      .getStream(sprintf(ORDER_HISTORY_URL, id))
      .catch(() => Observable.of({})),
    (notes, history) => ({
      pending: Boolean(notes.pending || history.pending),
      progress: (notes.progress + history.progress) / 2,
      payload: {
        notes: notes.payload,
        history: history.payload,
      },
    }),
  );

export const batchCreateNote = (ids, values) =>
  api.post(BATCH_CREATE_NOTE_URL, {
    params: { orderNumbers: ids.join(",") },
    body: values,
  });

export type BatchRescheduleOrderRequest = {
  deadline: string,
  order_numbers: string[],
};

export const batchRescheduleOrder = (body: BatchRescheduleOrderRequest) =>
  api.put(BATCH_ORDER_RESCHEDULE_URL, { body });

export const setOrderBarcodeScannedPieces = (orderBarcode, body) =>
  api.post(sprintf(ORDER_BARCODE_PIECE_SCANNED, orderBarcode), { body });

export const batchSetOrderSize = (body: {
  size: string,
  order_numbers: string[],
}) => api.put(BATCH_SET_ORDER_SIZE_URL, { body });

export const batchUpdateOrderWarehouse = (body: BatchUpdateWarehouseRequest) =>
  api.put(BATCH_UPDATE_WAREHOUSE_URL, { body });
