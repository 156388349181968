import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import OrderCreateWizardStepDetails from "../internal/OrderCreateWizardStepDetails";
import PriceWrapper from "../../ui-core/PriceWrapper";
import collect from "./assets/collect.png";

const valueSelector = formValueSelector("OrderCreateWizardSupplier");

const enhancer = compose(
  connect(state => ({ codCharge: valueSelector(state, "codCharge") })),
);

OrderCreateWizardStepCollectDetails.propTypes = {
  currencyCode: PropTypes.string,
  codCharge: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

function OrderCreateWizardStepCollectDetails(props) {
  return (
    <OrderCreateWizardStepDetails
      label="Collect"
      onClick={props.onClick}
      avatar={
        <Avatar
          src={collect}
          style={{
            backgroundColor: "transparent",
          }}
        />
      }
      primaryText={
        <div>
          Collect -{" "}
          <PriceWrapper price={props.codCharge} code={props.currencyCode} />
        </div>
      }
    />
  );
}

export default enhancer(OrderCreateWizardStepCollectDetails);
