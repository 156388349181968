import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { Menu, Button, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import MenuItemForm from "../ui-core/MenuItemForm";
import { getMessages } from "../../reducers/LocalizationReducer";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import {
  CREATE_ORDER_MANIFEST_URL,
  CREATE_ORDER_AIRWAYBILL_URL,
  CREATE_ORDER_AIRWAYBILL_MINI_URL,
} from "../../../shared/constants/FileProxyControllerConstants";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
  withState("state", "setState", {}),
);

OrderAWBManifestPopover.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,

  withAWB: PropTypes.bool,
  withAWBZLabel: PropTypes.bool,
  withAWBCompact: PropTypes.bool,

  withManifest: PropTypes.bool,
  withManifestCompact: PropTypes.bool,
  withManifestForDriver: PropTypes.bool,
  withManifestForCustomer: PropTypes.bool,

  label: PropTypes.string.isRequired,

  ids: PropTypes.array,
  i18n: PropTypes.instanceOf(Map),
};

function OrderAWBManifestPopover(props) {
  const { state, i18n } = props;

  return (
    <FlexBox>
      <Button
        onClick={event => {
          event.preventDefault();

          props.setState(
            fp.flow(
              fp.update("openSort", value => !value),
              fp.set("anchorEl", event.currentTarget),
            ),
          );
        }}
      >
        {props.label}
      </Button>

      <Menu
        onClose={() =>
          props.setState(fp.flow(fp.unset("anchoreEl"), fp.unset("openSort")))
        }
        open={state.openSort}
        anchorEl={state.anchorEl}
      >
        {props.withAWB && (
          <MenuItem
            target="_blank"
            component="a"
            href={updateQuery(CREATE_ORDER_AIRWAYBILL_URL, {
              ids: props.ids.join(","),
            })}
            onClick={() => {
              props.setState(
                fp.flow(fp.unset("anchoreEl"), fp.unset("openSort")),
              );
            }}
          >
            {i18n.get("awb", "AWB")}
          </MenuItem>
        )}

        {props.withAWBCompact && (
          <MenuItem
            target="_blank"
            component="a"
            href={updateQuery(CREATE_ORDER_AIRWAYBILL_URL, {
              compact: true,
              ids: props.ids.join(","),
            })}
            onClick={() => {
              props.setState(
                fp.flow(fp.unset("anchoreEl"), fp.unset("openSort")),
              );
            }}
          >
            {i18n.get("compact", "Compact")}
          </MenuItem>
        )}

        {props.withAWBZLabel && (
          <MenuItem
            target="_blank"
            component="a"
            href={updateQuery(CREATE_ORDER_AIRWAYBILL_MINI_URL, {
              ids: props.ids.join(","),
            })}
            onClick={() => {
              props.setState(
                fp.flow(fp.unset("anchoreEl"), fp.unset("openSort")),
              );
            }}
          >
            {i18n.get("awbz_label", "AWBZ Label")}
          </MenuItem>
        )}

        {props.withManifestForCustomer && (
          <MenuItemForm
            url={CREATE_ORDER_MANIFEST_URL}
            params={{
              customer: true,
              ids: props.ids,
            }}
            primaryText={i18n.get("for_customer", "For Customer")}
            onClick={() => {
              props.setState(
                fp.flow(fp.unset("anchoreEl"), fp.unset("openSort")),
              );
            }}
          />
        )}

        {props.withManifestForDriver && (
          <MenuItemForm
            url={CREATE_ORDER_MANIFEST_URL}
            params={{
              ids: props.ids,
            }}
            primaryText={i18n.get("for_driver", "For Driver")}
            onClick={() => {
              props.setState(
                fp.flow(fp.unset("anchoreEl"), fp.unset("openSort")),
              );
            }}
          />
        )}

        {props.withManifest && (
          <MenuItemForm
            url={CREATE_ORDER_MANIFEST_URL}
            params={{
              ids: props.ids,
            }}
            primaryText={i18n.get("manifest", "Manifest")}
            onClick={() => {
              props.setState(
                fp.flow(fp.unset("anchoreEl"), fp.unset("openSort")),
              );
            }}
          />
        )}

        {props.withManifestCompact && (
          <MenuItemForm
            url={CREATE_ORDER_MANIFEST_URL}
            params={{
              compact: true,
              ids: props.ids,
            }}
            primaryText={i18n.get("compact", "Compact")}
            onClick={() => {
              props.setState(
                fp.flow(fp.unset("anchoreEl"), fp.unset("openSort")),
              );
            }}
          />
        )}
      </Menu>
    </FlexBox>
  );
}

export default enhancer(OrderAWBManifestPopover);
