import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS, OrderedMap } from "immutable";
import fp from "lodash/fp";
import {
  compose,
  getContext,
  mapPropsStream,
  createEventHandler,
} from "recompose";
import PropTypes from "prop-types";
import SupplierOrderEditDialogWrapper from "../SupplierOrderEditDialogWrapper";
import SupplierOrderEditDialogWrapperV2 from "../SupplierOrderEditDialogWrapperV2";
import SupplierOrderDetailsDialogWrapper from "../SupplierOrderDetailsDialogWrapper";
import SupplierOrderDetailsDialogWrapperV2 from "../SupplierOrderDetailsDialogWrapperV2";
import {
  isEqualData,
  isEqualWithoutFunctions,
} from "../../../helpers/DataUtils";
import { pipeStreams } from "../../../helpers/StreamUtils";
import DataListFilter from "../../../helpers/DataListFilter";
import { toOrderFilter } from "../../../helpers/OrderFilterMapper";
import { getOrderList } from "../../../api/supplier/SupplierOrderApi";
import Redirect from "../../../components/router/Redirect";
import TrackOrderWidget from "../../../components/dashboard-core/TrackOrderWidget";
import { updateQuery } from "../../../../shared/helpers/UrlUtils";

const searchTypes = OrderedMap()
  .set("all", "Everything")
  .set("order_number", "Order Number");

const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
    setLocationQuery: PropTypes.func.isRequired,
    replaceLocationHash: PropTypes.func.isRequired,
    replaceLocationQuery: PropTypes.func.isRequired,
    setLocationQueryFilter: PropTypes.func.isRequired,
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();

        const filterStream = propsStream
          .pluck("location", "query")
          .map(
            fp.flow(toOrderFilter, (filter: DataListFilter) =>
              filter.setValue("is_uae", null),
            ),
          )
          .distinctUntilChanged(isEqualData);

        const searchResultStream = filterStream
          .switchMap(
            (filter: DataListFilter) =>
              filter.getValue("search")
                ? getOrderList(filter)
                    .repeatWhen(() => onRequestRefreshStream)
                    .catch(error => Observable.of({ error }))
                : Observable.of({}),
          )
          .map(
            fp.flow(
              response => fromJS(response),
              response =>
                fromJS({
                  isLoading: response.get("pending", false),
                  total: response.getIn(["payload", "data", "total"], 0),
                  list: response.getIn(["payload", "data", "list"], List()),
                }),
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .combineLatest(
            filterStream,
            searchResultStream,
            (props, filter, searchResult) => ({
              ...props,
              filter,
              onRequestRefresh,
              list: searchResult.get("list"),
              total: searchResult.get("total"),
              isLoading: searchResult.get("isLoading"),
            }),
          )
          .distinctUntilChanged(isEqualWithoutFunctions);
      },
      propsStream => {
        const sideEffectsStream = Observable.merge(
          propsStream
            .map(props => ({
              replaceLocationQuery: props.replaceLocationQuery,
              order: props.list.size === 1 ? props.list.get(0, null) : null,
            }))
            .distinctUntilKeyChanged("order")
            .filter(props => props.order && props.order.get("id") > 0)
            .do(props =>
              props.replaceLocationQuery(
                fp.set(
                  props.order.get("version") === 2 ? "view_v2" : "view",
                  props.order.get("id"),
                ),
              ),
            ),
        )
          .mapTo(null)
          .startWith(null)
          .distinctUntilChanged();

        return propsStream.combineLatest(sideEffectsStream, fp.identity);
      },
    ),
  ),
);

SupplierDashboardOrderTrackerWrapper.propTypes = {
  reports: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  location: PropTypes.object,
  setLocationQueryFilter: PropTypes.func,
  setLocationQuery: PropTypes.func,
  onRequestRefresh: PropTypes.func,
  filter: PropTypes.instanceOf(DataListFilter),
};

function SupplierDashboardOrderTrackerWrapper(props) {
  const { location, filter } = props;
  return (
    <div style={{ width: "100%" }}>
      <Redirect
        when={!searchTypes.has(location.query.search_type)}
        to={updateQuery(location, fp.set("search_type", "all"))}
      />

      <SupplierOrderDetailsDialogWrapper
        tab={props.location.query.view_tab}
        orderId={fp.toFinite(props.location.query.view)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(
            fp.omit(["view", "view_v2", "view_tab", "search"]),
          )
        }
        onEditClick={() =>
          props.setLocationQuery(
            fp.flow(
              fp.unset("view"),
              fp.unset("view_v2"),
              fp.set("edit", location.query.view),
            ),
          )
        }
        createDriverSuggestHref={() =>
          props.setLocationQuery(fp.flow(fp.set("driver_view", true)))
        }
        location={location}
      />

      <SupplierOrderDetailsDialogWrapperV2
        tab={props.location.query.view_tab}
        orderId={fp.toFinite(props.location.query.view_v2)}
        onTabChange={tab => props.setLocationQuery(fp.set("view_tab", tab))}
        onRequestClose={() =>
          props.setLocationQuery(
            fp.omit(["view", "view_v2", "view_tab", "search"]),
          )
        }
        onEditClick={() =>
          props.setLocationQuery(
            fp.flow(
              fp.unset("view"),
              fp.unset("view_v2"),
              fp.set("edit_v2", location.query.view_v2),
            ),
          )
        }
        location={location}
      />

      <SupplierOrderEditDialogWrapper
        orderId={fp.toFinite(location.query.edit)}
        onRequestClose={() => {
          props.onRequestRefresh();
          props.setLocationQuery(
            fp.flow(fp.unset("edit"), fp.unset("edit_v2")),
          );
        }}
      />

      <SupplierOrderEditDialogWrapperV2
        orderId={fp.toFinite(location.query.edit_v2)}
        onRequestClose={() =>
          props.setLocationQuery(fp.flow(fp.unset("edit"), fp.unset("edit_v2")))
        }
      />

      <TrackOrderWidget
        filter={filter}
        onSubmit={values => {
          props.setLocationQueryFilter(
            filter.setValue("search", values.orderNumber),
          );
        }}
      />
    </div>
  );
}

export default enhancer(SupplierDashboardOrderTrackerWrapper);
