import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import {
  isEqualData,
  isEqualWithoutFunctions,
} from "../../../helpers/DataUtils";
import DataListFilter from "../../../helpers/DataListFilter";
import { getCODTotalCount } from "../../../api/supplier/SupplierFinanceApi";
import SupplierCODChartComponent from "../../../components/dashboard-core/SupplierCODChartComponent";

const enhancer = compose(
  mapPropsStream(propsStream => {
    const getReportsStream = propsStream
      .distinctUntilChanged(isEqualData)
      .switchMap(props =>
        getCODTotalCount(props.filter).catch(() => Observable.of({})),
      )
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending", false),
              payload: response.getIn(["payload", "data"], List()),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(getReportsStream, (props, reports) => ({
        ...props,
        reports: reports.get("payload"),
        isLoading: reports.get("pending"),
      }))
      .distinctUntilChanged(isEqualWithoutFunctions);
  }),
);

SupplierCODChartWrapper.propTypes = {
  reports: PropTypes.instanceOf(List),
  isLoading: PropTypes.bool,
  filter: PropTypes.instanceOf(DataListFilter),
};

function SupplierCODChartWrapper(props) {
  return (
    !props.isLoading && (
      <SupplierCODChartComponent reports={props.reports} {...props} />
    )
  );
}

export default enhancer(SupplierCODChartWrapper);
