import React from "react";
import { Map } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector } from "redux-form";
import { Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import OrderCreateWizardStepDetails from "../internal/OrderCreateWizardStepDetails";

const valueSelector = formValueSelector("OrderCreateWizardSupplier");

const enhancer = compose(
  connect(state => ({ menu: valueSelector(state, "menu") })),
);

OrderCreateWizardStepWhatDetails.propTypes = {
  menu: PropTypes.instanceOf(Map),
  onClick: PropTypes.func.isRequired,
};

function OrderCreateWizardStepWhatDetails(props) {
  return (
    props.menu && (
      <OrderCreateWizardStepDetails
        label="What"
        onClick={props.onClick}
        primaryText={<div>{props.menu.get("title")}</div>}
        avatar={
          <Avatar
            src={props.menu.get("icon")}
            style={{
              backgroundColor: "transparent",
            }}
          />
        }
      />
    )
  );
}

export default enhancer(OrderCreateWizardStepWhatDetails);
