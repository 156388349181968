import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { formatDriverName } from "../../helpers/DriverHelper";
import ResponseError from "../../helpers/ResponseError";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { getDriverLocation } from "../../api/shared/DriverApi";
import { getDriver, updateDriver } from "../../api/supplier/SupplierDriverApi";
import {
  getVehicle,
  updateVehicle,
  getCachedVehicle,
  getVehiclePredictions,
} from "../../api/supplier/SupplierVehicleApi";
import Tabs from "../../components/ui-core/Tabs";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import DriverForm from "../../components/drivers-core/DriverForm";
import DriverLocationMap from "../../components/drivers-core/DriverLocationMap";

const DETAILS_TAB = "details";
const MAP_TAB = "map";

const enhancer = compose(
  renderIf(props => props.driverId > 0),
  connect(
    state => ({
      getLocalisationMessage: (code, defaultMessage) =>
        getMessage(state, code, defaultMessage),
    }),
    { showErrorMessage, showSuccessMessage },
  ),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const driverResponseStream = propsStream
      .distinctUntilKeyChanged("driverId")
      .switchMap(props =>
        getDriver(props.driverId)
          .repeatWhen(() => onRequestRefreshStream)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(driverResponseStream, (props, driverResponse) => ({
        ...props,
        onRequestRefresh,
        driver: driverResponse.get("payload"),
        isLoading: driverResponse.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

SupplierDriverEditDialogWrapper.propTypes = {
  tab: PropTypes.oneOf([DETAILS_TAB, MAP_TAB]),
  onTabChange: PropTypes.func.isRequired,
  driver: PropTypes.instanceOf(Map),
  isLoading: PropTypes.bool,
  driverId: PropTypes.number,
  onRequestRefresh: PropTypes.func,
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,

  onVehicleClick: PropTypes.func,
  onCreateVehicleClick: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
};

SupplierDriverEditDialogWrapper.defaultProps = {
  tab: DETAILS_TAB,
};

function SupplierDriverEditDialogWrapper(props) {
  return (
    <ModalPaper
      open={true}
      isLoading={props.isLoading}
      onRequestClose={props.onRequestClose}
      title={`${props.getLocalisationMessage(
        "driver",
        "Driver",
      )} - ${formatDriverName(
        props.driver,
        props.getLocalisationMessage("not_found", "Not Found"),
      )}`}
    >
      <FlexBox container={8} direction="column">
        <FlexBox gutter={8} direction="column">
          <FlexBox flex="none" direction="column">
            <Tabs
              width={300}
              value={props.tab}
              onChange={(e, v) => props.onTabChange(v)}
              tabs={[
                {
                  label: props.getLocalisationMessage("details", "Details"),
                  value: DETAILS_TAB,
                },
                {
                  label: props.getLocalisationMessage("map", "Map"),
                  value: MAP_TAB,
                },
              ]}
            />
          </FlexBox>

          <FlexBox flex="none" direction="column">
            {props.tab === DETAILS_TAB && (
              <DriverForm
                disableEmail={false}
                onDismiss={props.onRequestClose}
                initialValues={toCamelCase(props.driver)}
                onSubmit={values =>
                  updateDriver(props.driverId, toSnakeCase(values)).catch(
                    ResponseError.throw,
                  )
                }
                onSubmitSuccess={() => {
                  props.onRequestRefresh();
                  props.showSuccessMessage(
                    props.getLocalisationMessage(
                      "successfully_saved",
                      "Successfully saved",
                    ),
                  );
                }}
                onSubmitFail={props.showErrorMessage}
                getCachedVehicle={getCachedVehicle}
                getVehiclePredictions={getVehiclePredictions}
                onVehicleClick={props.onVehicleClick}
                onCreateVehicleClick={props.onCreateVehicleClick}
                getVehicle={getVehicle}
                updateVehicle={updateVehicle}
              />
            )}

            {props.tab === MAP_TAB && (
              <DriverLocationMap
                driverId={props.driverId}
                getDriverLocation={getDriverLocation}
              />
            )}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(SupplierDriverEditDialogWrapper);
