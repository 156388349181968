import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const VEHICLE_LIST_URL = `${API_ROOT_URL}/supplier/vehicles`;
const VEHICLE_CREATE_URL = `${API_ROOT_URL}/supplier/vehicle`;
const VEHICLE_ITEM_URL = `${API_ROOT_URL}/supplier/vehicle/%s`;
const VEHICLE_CHOOSE_URL = `${API_ROOT_URL}/supplier/vehicle/choose`;

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(VEHICLE_ITEM_URL, id)),
});
const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(VEHICLE_CHOOSE_URL, { params: request.getDefinedValues() }),
});

export const getVehicle = id => itemCache.refresh(id);
export const getCachedVehicle = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));
export const getVehiclePredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export const getVehicleList = (request: DataListFilter) =>
  api.getStream(VEHICLE_LIST_URL, { params: request.getDefinedValues() });
export const createVehicle = body => api.post(VEHICLE_CREATE_URL, { body });
export const updateVehicle = (id, body) =>
  api.put(sprintf(VEHICLE_ITEM_URL, id), { body });
