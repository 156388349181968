import sprintf from "sprintf";
import { api } from "../../shared/BaseApi";
import { API_V2_ROOT_URL } from "../../../../shared/constants/ApiConstants";

const CUSTOMER_PAYMENT_METHODS_URL = `${
  API_V2_ROOT_URL
}/admin/customer/%s/payment_methods`;

export const getPaymentMethods = (id, body) =>
  api.getStream(sprintf(CUSTOMER_PAYMENT_METHODS_URL, id), {
    params: body.getDefinedValues(),
  });
